import {
  buildTestIds,
  getSpotPrice,
  useAppContext,
  useCurrentPathName,
} from "application";
import { useCrm } from "application/state-manager";
import { useRouter } from "next/router";
import { memo, useCallback, useEffect, useState } from "react";
import { ArrowButtonProps, CustomProductListDto } from "typing";
import { ArrowFilledDown, RightArrowProduct, theme } from "ui";
import ProductCard from "../../ProductCard";
import { Skeleton } from "../../Skeleton";
import { useGetBranchLinkConfig } from "../utils";
import { CustomProductListSlider } from "./components/Slider";
import {
  calculateSlidesToShow,
  customProductListData,
  handleComponentWarnings,
  setIsVertical,
  setShouldRenderButtonViewMore,
  slidesToShowValueIncrement,
} from "./data";
import {
  ButtonViewMore,
  ContainerView,
  CtaButton,
  CustomProductListContainer,
  CustomStoriesTitle,
  ProductCardContainer,
  ProductListHeader,
  ProductListTitle,
  SkeletonContainer,
} from "./styles";
import RecommendationCarouselProductCard from "../../RecommendationCarouselProductCard";

const propsAreEqual = (
  {
    isClientMobile: oldIsClientMobile,
    isClientTablet: oldIsClientTablet,
    isClientDesktop: oldIsClientDesktop,
    productCards: oldProductCards,
    // crm: oldCrm,
    isLoading: oldIsLoading,
    properties: oldProperties,
  }: CustomProductListDto,
  {
    isClientMobile,
    isClientTablet,
    isClientDesktop,
    productCards,
    // crm,
    isLoading,
    properties,
  }: CustomProductListDto
) => {
  return (
    oldIsClientMobile === isClientMobile &&
    oldIsClientTablet === isClientTablet &&
    oldIsClientDesktop === isClientDesktop &&
    oldProductCards?.length === productCards?.length &&
    // oldCrm?.campaignId === crm?.campaignId &&
    oldIsLoading === isLoading &&
    oldProperties === properties
  );
};

const CustomProductList = memo(
  ({
    properties,
    productCards,
    config,
    position,
    isBlackTheme,
    crm,
    isLoading,
    isCrmComponent,
    isCrmProductList,
    isCrmProductListVertical,
    isCrmProductListMinimalist,
    preloadComponent = false,
  }: /**
   * @note Não é possível reduzir mais a lógica desse componente sem quebra-lo. Todas as funcionalidades possíveis foram extraídas para funções e posta em data.tsx
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  CustomProductListDto) => {
    const {
      showTitle,
      title,
      showCta,
      showArrows,
      ctaText,
      quantity,
      orientation,
      productListBlackFridayTheme,
    } = customProductListData(properties, !!isBlackTheme);
    const router = useRouter();
    const { isClientDesktop, isClientMobile, isClientTablet, isClientTabletL } =
      useAppContext();
    const [arrowsIsActive, setArrowsIsActive] = useState<boolean>(false);
    const [increaseCardSize, setIncreaseCardSize] = useState<boolean>(false);
    const isVertical = setIsVertical(
      isClientMobile,
      isCrmProductListVertical,
      orientation
    );
    const [slidesToShow, setSlidesToShow] = useState<number>(
      calculateSlidesToShow(
        isVertical,
        isCrmProductListVertical,
        isClientDesktop,
        isClientMobile,
        arrowsIsActive
      )
    );

    const userCrmId = useCrm((state) => state.userCrmId);

    const productCardVertical = Boolean(
      isVertical &&
        (isCrmProductListVertical || isClientMobile) &&
        router?.route !== "/pesquisa/[term]"
    );

    const heightsFromProducts = productCards?.map((product) => {
      const minHeight = 292;
      const heightBlackFriday = product?.isBlackFriday ? 24 : 0;
      const heightCoupon = product?.coupons?.length ? 18 : 0;
      const heightSpotPrice = getSpotPrice(product) ? 13 : 0;
      const heightInstallments =
        product?.prices?.[0]?.maxNumberInstallments > 1 ? 17 : 0;
      const heightTagProduct =
        product?.tag?.title || product?.tagProduct?.title ? 24 : 0;

      return (
        heightBlackFriday +
        heightCoupon +
        heightSpotPrice +
        heightInstallments +
        heightTagProduct +
        minHeight
      );
    });

    const maxHeightForProductCards = heightsFromProducts
      ? Math.max(...heightsFromProducts)
      : 292;

    const productListTheme = productListBlackFridayTheme?.horizontal;

    const linkConfig = useGetBranchLinkConfig(config?.branches);

    handleComponentWarnings(
      config?.branches,
      useCurrentPathName(),
      position,
      productCards
    );

    const handleViewMoreClick = () => {
      setSlidesToShow((previousSlidesToShow) => {
        return productCards?.length > previousSlidesToShow
          ? slidesToShowValueIncrement(previousSlidesToShow)
          : previousSlidesToShow;
      });
    };

    useEffect(() => {
      if (
        (Number(quantity) === 6 &&
          productCards?.length === 6 &&
          showArrows === "true") ||
        Number(quantity) > 6 ||
        productCards?.length > 6
      )
        setArrowsIsActive(true);

      if (isVertical && productCardVertical) {
        setArrowsIsActive(false);
        setIncreaseCardSize(false);
      }

      if (
        Number(quantity) === 6 ||
        (productCards?.length === 6 && showArrows === "true")
      )
        setIncreaseCardSize(true);

      if (Number(quantity) > 6 || productCards?.length > 6)
        setIncreaseCardSize(true);
    }, [
      isClientDesktop,
      isVertical,
      orientation,
      position,
      productCardVertical,
      productCards,
      quantity,
      showArrows,
    ]);

    useEffect(() => {
      setSlidesToShow(
        calculateSlidesToShow(
          isVertical,
          isCrmProductListVertical,
          isClientDesktop,
          isClientMobile,
          arrowsIsActive
        )
      );
    }, [
      isClientDesktop,
      isClientMobile,
      isVertical,
      arrowsIsActive,
      isCrmProductListVertical,
    ]);

    const shouldCentralizeCards = useCallback(() => {
      return Number(quantity) <= 3;
    }, [quantity]);

    if (isLoading) {
      return (
        <SkeletonContainer>
          <Skeleton
            type="card"
            count={isClientTablet ? 3 : 5}
            width={210}
            height={360}
          />
        </SkeletonContainer>
      );
    }

    if (
      Boolean(crm?.needCrmUserId && Boolean(!userCrmId)) ||
      Boolean(!productCards?.length && !isLoading)
    ) {
      return null;
    }

    return (
      <CustomProductListContainer
        className={crm ? `ins-preview-wrapper-${crm.campaignVariationId}` : ""}
        {...buildTestIds("custom-product-list-container")}
        increaseCardSize={increaseCardSize}
        customTheme={productListBlackFridayTheme}
        isVertical={isVertical}
        isBlackFriday={isBlackTheme}
        isCrmProductListVertical={isCrmProductListVertical}
      >
        {showTitle === "true" && (
          <ProductListHeader
            increaseCardSize={increaseCardSize}
            {...buildTestIds("product-list-header")}
          >
            <ProductListTitle
              {...buildTestIds("product-list-header-title")}
              color={productListTheme?.titleColor}
              href={`/${linkConfig?.url}`}
              target={linkConfig?.target}
              isCrmComponent={isCrmComponent}
            >
              <CustomStoriesTitle>{title}</CustomStoriesTitle>
            </ProductListTitle>
            {showCta && showCta === "true" && !isCrmComponent && (
              <CtaButton
                {...buildTestIds("product-list-header-cta-button")}
                color={productListTheme?.ctaButton.textColor.default}
                mouseOver={{
                  color:
                    productListTheme?.ctaButton.textColor.hover ||
                    theme.colors.primary["200"],
                }}
                href={`/${linkConfig?.url}`}
                target={linkConfig?.target}
                aria-label="Visualizar todos ao clicar aqui"
              >
                {isClientTablet ? null : ctaText}
                <RightArrowProduct
                  alt="Seta para direita"
                  color={productListTheme?.ctaButton.textColor.default}
                />
              </CtaButton>
            )}
          </ProductListHeader>
        )}

        {(isVertical || !isClientTablet) &&
        (!isCrmProductListVertical || !isClientTabletL) ? (
          <CustomProductListSlider
            {...buildTestIds("custom-product-list-slider")}
            crm={crm}
            customTheme={productListTheme?.sliderButton as ArrowButtonProps}
            arrowsIsActive={arrowsIsActive}
            isBlackFriday={isBlackTheme}
            slidesToShow={slidesToShow}
            productCards={productCards}
            isVertical={isVertical}
            height={productCardVertical ? 198 : maxHeightForProductCards}
            isCrmProductList={isCrmProductList}
            isCrmProductListMinimalist={isCrmProductListMinimalist}
          />
        ) : (
          <ProductCardContainer
            className={crm ? "ins-web-smart-recommender-body" : ""}
            data-recommended-items={crm?.recommendedProductsId}
            centralizeCards={shouldCentralizeCards()}
            {...buildTestIds("product-card-container")}
          >
            {isCrmProductList
              ? productCards?.map((product) => {
                  return (
                    <RecommendationCarouselProductCard
                      {...buildTestIds(`product-card`)}
                      key={`${product.id}`}
                      item={{
                        ...product,
                        index: productCards.indexOf(product),
                      }}
                      isPlaylist={false}
                      preloadComponent={preloadComponent}
                      isMinimalist={isCrmProductListMinimalist}
                    />
                  );
                })
              : productCards?.map((product) => {
                  return (
                    <ProductCard
                      {...buildTestIds(`product-card`)}
                      key={`${product.id}`}
                      item={{
                        ...product,
                        index: productCards.indexOf(product),
                      }}
                      shouldShowInGridOnMobile={false}
                      isPlaylist
                      preloadComponent={preloadComponent}
                    />
                  );
                })}
          </ProductCardContainer>
        )}

        <ContainerView {...buildTestIds("container-view")}>
          {setShouldRenderButtonViewMore(
            isVertical,
            productCards,
            slidesToShow,
            isCrmProductListVertical
          ) && (
            <ButtonViewMore
              {...buildTestIds("button-view-more")}
              onClick={handleViewMoreClick}
            >
              {showCta && showCta === "true" && ctaText}
              <ArrowFilledDown alt="Seta para baixo" />
            </ButtonViewMore>
          )}
        </ContainerView>
      </CustomProductListContainer>
    );
  },
  propsAreEqual
);

CustomProductList.displayName = "CustomProductList";

export { CustomProductList };
