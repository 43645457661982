import { createStore } from "../adapter";

type CounterState = {
  isCodeTimerActive: boolean;
  isSendCodeAvailable: boolean;
  initTimer: boolean;
  countdownTime: number;
};

type CounterDispatch = {
  setCodeTimerActive: (value: boolean) => void;
  setIsSendCodeAvailable: (value: boolean) => void;
  setCountdownTime: (value: number) => void;
  setInitTimer: (value: boolean) => void;
  updateTimer: (faType: number, channel: number, retryTokenDate: Date) => void;
};

type CounterProps = CounterState & CounterDispatch;

const useCounter = createStore<CounterProps>((set) => ({
  isCodeTimerActive: false,
  setCodeTimerActive: (value: boolean) => set({ isCodeTimerActive: value }),
  isSendCodeAvailable: true,
  setIsSendCodeAvailable: (value: boolean) =>
    set({ isSendCodeAvailable: value }),
  initTimer: false,
  setInitTimer: (initTimer: boolean) => set({ initTimer }),
  countdownTime: 0,
  setCountdownTime: (countdownTime: number) => set({ countdownTime }),
  updateTimer: (faType: number, channel: number, retryTokenDate: Date) => {
    const retryTime = new Date(
      new Date(retryTokenDate).getTime() - 3 * 60 * 60 * 1000
    );

    const remainingTime = Math.floor((retryTime.getTime() - Date.now()) / 1000);

    set({ countdownTime: remainingTime });

    localStorage.setItem(
      `countdownTime_${faType}_${channel}`,
      remainingTime.toString()
    );
    localStorage.setItem(
      `countdownTimeTimestamp_${faType}_${channel}`,
      Date.now().toString()
    );
  },
}));

export { useCounter };
