import { ChangePasswordFeedback } from "../components/ChangePasswordFeedback";
import { useEmailFeedbackData } from "./data";

const EmailFeedback = () => {
  const {
    closeAuthenticationModal,
    handleRedirect,
    returnButtonText,
    returnDescription,
    returnTitle,
  } = useEmailFeedbackData();

  return (
    <ChangePasswordFeedback
      onCloseFeedback={closeAuthenticationModal}
      onCallAction={handleRedirect}
      title={returnTitle()}
      buttonText={returnButtonText()}
      description={returnDescription()}
    />
  );
};

export { EmailFeedback };
