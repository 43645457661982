import styled from "styled-components";
import { Search, theme } from "ui";

export const InputContainer = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${theme.space.x4};
  align-items: center;
  gap: ${theme.space.x4};
  border-radius: ${theme.space.x1};
  border: 1px solid ${theme.colors.neutral["88"]};
  background: ${theme.colors.neutral.white};
  flex: 1;

  &:focus-within {
    box-shadow: 0px 2px 8px 0px ${theme.colors.secondary["760"]};
    border: 1px solid ${theme.colors.neutral["550"]};
  }

  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`;

export const Input = styled.input`
  border: none;
  width: 100%;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["400"]};
  color: ${theme.colors.neutral["550"]};

  &::placeholder {
    color: ${theme.colors.neutral["330"]};
  }
`;

export const SearchIcon = styled(Search).attrs({
  width: 16,
  height: 16,
  color: theme.colors.neutral["350"],
})``;
