import {
  buildTestIds,
  formatCurrency,
  useAuth,
  useCartContext,
  useCountdown,
  useEnvs,
  useModalGroup,
} from "application";
import { CartItemsContextReduce } from "application/context/ShoppingHookContext/typesContextCart";
import { cookie, weddingGiftListApi } from "implementations";
import { NextRouter, useRouter } from "next/router";
import { useCallback, useState } from "react";
import { GiftProducts, GiftProductsParams, ProductInCart } from "typing";
import { ArrowRight, CartIcon, theme } from "ui";
import { withUserPhoneNumberValidation } from "../../../../../hocs";
import ItemProductCartModal from "./ItemProductCartModal";
import {
  ArrowItem,
  BoxEmpty,
  BoxExtraInfo,
  Button,
  ButtonCart,
  CloseButton,
  CloseIcon,
  ColumnInfo,
  ContentModalCart,
  DescriptionEmptyCart,
  DescriptionPrice,
  DescriptionTitleSub,
  FooterModalCart,
  HeaderModalCart,
  ModalCart,
} from "./styles";

interface ModalItemsProps {
  isOpen: boolean;
  userHasValidPhoneNumberRegistered?: boolean;
  isLoadingUserPhoneNumber?: boolean;
  closeModal: () => void;
  navigate: () => void;
  deleteAction: (ProductId: number) => void;
}

type CartItemWithGiftProduct = CartItemsContextReduce & {
  giftProduct?: GiftProducts;
};

const ModalItems = ({
  isOpen,
  deleteAction,
  navigate,
  closeModal,
  userHasValidPhoneNumberRegistered,
  isLoadingUserPhoneNumber,
}: ModalItemsProps) => {
  const router = useRouter();
  const { userData } = useAuth();
  const { cartItems, clientId, totalSalePrice } = useCartContext();
  const { timeInSeconds } = useCountdown();
  const { handleOpenModalToConfirmEmail, sendOrResendEmailConfirmation } =
    useModalGroup();
  const [modalItemsUnderAction, setModalItemsUnderAction] =
    useState<boolean>(false);
  const cartItem = cartItems;
  const productsInCart = cartItem?.filter((item) => !item?.listId);
  const GIFTS_ITEMS = cartItem?.filter((item) => item.listId);
  const giftProductsParams: GiftProductsParams[] = GIFTS_ITEMS?.reduce(
    (result: GiftProductsParams[], item) => {
      const existingItem = result.find(
        (listItem) => listItem.listId === item.listId
      );

      if (existingItem) {
        existingItem.productKeys.push({
          packaging: item.package,
          branchId: item.branchId,
          productId: item.productId,
          sellerId: item.sellerId,
        });
      } else {
        result.push({
          listId: item.listId,
          productKeys: [
            {
              packaging: item.package,
              branchId: item.branchId,
              productId: item.productId,
              sellerId: item.sellerId,
            },
          ],
        });
      }

      return result;
    },
    []
  );

  const accessToken = cookie.getCookie({
    name: "@FC:Ecom:Token:Access",
  });

  const { data: giftProducts } = weddingGiftListApi.getGiftProduct(
    giftProductsParams,
    accessToken,
    !!giftProductsParams?.length
  );

  const giftItensWithProducts: CartItemWithGiftProduct[] = GIFTS_ITEMS?.map(
    (giftItem) => {
      const giftProduct = giftProducts?.find(
        (item) => item.productId === giftItem.productId
      );

      return giftProduct ? { ...giftItem, giftProduct } : giftItem;
    }
  );

  const allConfigs = useEnvs((state) => state.allConfigs);

  const handleFinishOrder = useCallback(
    (
      userAccessToken: string | undefined,
      thereIsPhoneNumberRegistered: boolean,
      nextRouter: NextRouter
    ) => {
      setModalItemsUnderAction(true);
      if (!userAccessToken) {
        nextRouter.push({
          pathname: "/login",
          query: {
            redirectTo: "/checkout/pagamento",
          },
        });
        setModalItemsUnderAction(false);
        return;
      }

      if (
        allConfigs?.isEmailConfirmationAvailable === "true" &&
        !userData?.email_verified
      ) {
        handleOpenModalToConfirmEmail(
          String(nextRouter?.route || "/checkout/meu-carrinho")
        );
        sendOrResendEmailConfirmation(
          userData?.email,
          timeInSeconds === 0 || timeInSeconds === 60
        );
        setModalItemsUnderAction(false);

        return;
      }

      nextRouter.push("/checkout/pagamento");
      setModalItemsUnderAction(false);
    },
    [
      allConfigs?.isEmailConfirmationAvailable,
      handleOpenModalToConfirmEmail,
      sendOrResendEmailConfirmation,
      timeInSeconds,
      userData?.email,
      userData?.email_verified,
    ]
  );

  return (
    <ModalCart {...buildTestIds("modal-cart-container")} visible={isOpen}>
      <HeaderModalCart
        {...buildTestIds("header-modal-cart")}
        alignAtTheEnd={Boolean(cartItems?.length)}
      >
        <CloseButton
          {...buildTestIds("close-button")}
          onClick={() => closeModal()}
        >
          <CloseIcon />
        </CloseButton>
        {cartItems?.length ? (
          <ButtonCart
            {...buildTestIds("button-cart-modal-cart")}
            onClick={navigate}
          >
            ver carrinho
            <ArrowRight
              alt="Seta para a direita"
              height={20}
              width={20}
              color={theme.colors.neutral.white}
            />
          </ButtonCart>
        ) : null}
        <ArrowItem />
      </HeaderModalCart>
      <ContentModalCart {...buildTestIds("content-modal-cart")}>
        {cartItems?.length ? (
          <>
            {productsInCart?.length
              ? productsInCart?.map((item, index) => (
                  <ItemProductCartModal
                    {...buildTestIds(`item-product-cart-modal-${index}`)}
                    deleteAction={deleteAction}
                    key={`${item.cartId}#${item?.productId}`}
                    productId={item.productId}
                    productIdCart={item.id}
                    quantity={item.quantity}
                    // packagePrice={item.package}
                    productInCart={item as unknown as ProductInCart}
                    clientId={clientId}
                  />
                ))
              : null}
            {giftItensWithProducts.length
              ? giftItensWithProducts?.map((item, index) => (
                  <ItemProductCartModal
                    {...buildTestIds(`item-product-cart-modal-${index}`)}
                    deleteAction={deleteAction}
                    key={`${item.cartId}#${item?.productId}`}
                    productId={item.productId}
                    productIdCart={item.id}
                    quantity={item?.quantity}
                    // packagePrice={item?.giftProduct?.price}
                    giftProduct={item.giftProduct}
                    clientId={clientId}
                  />
                ))
              : null}
          </>
        ) : (
          <BoxEmpty {...buildTestIds("box-empty")}>
            <CartIcon color={theme.colors.neutral["600"]} />
            <DescriptionEmptyCart {...buildTestIds("description-empty-cart")}>
              Seu carrinho está vazio
            </DescriptionEmptyCart>
          </BoxEmpty>
        )}
      </ContentModalCart>
      {cartItems?.length ? (
        <FooterModalCart {...buildTestIds("footer-modal-cart")}>
          <ColumnInfo {...buildTestIds("column-info")}>
            <DescriptionTitleSub {...buildTestIds("description-title-sub")}>
              SUBTOTAL
            </DescriptionTitleSub>
            <DescriptionPrice {...buildTestIds("description-price")}>
              {formatCurrency(totalSalePrice || 0)}
            </DescriptionPrice>
          </ColumnInfo>
          <BoxExtraInfo {...buildTestIds("box-extra-info")}>
            <Button
              text="Fechar pedido"
              textUnderAction="Fechando pedido..."
              isDisabled={isLoadingUserPhoneNumber}
              underAction={modalItemsUnderAction}
              onClickAction={() =>
                handleFinishOrder(
                  accessToken,
                  !!userHasValidPhoneNumberRegistered,
                  router
                )
              }
              {...buildTestIds("button-close-order")}
            />
          </BoxExtraInfo>
        </FooterModalCart>
      ) : null}
    </ModalCart>
  );
};

ModalItems.displayName = "CartButtonModalItems";

const CartButtonModalItemsWithUserPhoneNumberValidation =
  withUserPhoneNumberValidation<ModalItemsProps>(ModalItems);

export default CartButtonModalItemsWithUserPhoneNumberValidation;
