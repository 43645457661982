import styled from "styled-components";
import { theme } from "ui";

interface NoOrderContainerProps {
  padding?: string;
}

export const NoOrderContainer = styled.section<NoOrderContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -40px;
  gap: ${theme.space.x6};
  margin-bottom: ${theme.space.x4};
  height: 440px;
  padding: ${({ padding }) => padding ?? "0"};

  @media ${theme.device.desktopFirst.tabletL} {
    margin-top: ${theme.space.x4};
  }

  @media ${theme.device.mobileFirst.laptop} {
    margin-top: ${theme.space.x8};
  }
`;

export const NoOrderIcon = styled.div``;

export const NoOrderTitle = styled.span`
  color: ${theme.colors.neutral["550"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: ${theme.typography.fontWeight["600"]};
`;

export const NoOrderDescription = styled.span`
  color: ${theme.colors.neutral["350"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["400"]};
`;

export const ActionOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x4};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
`;

export const ActionOptionsText = styled.span`
  color: ${theme.colors.neutral["340"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["700"]};
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x4};
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space.x2};
  cursor: pointer;
`;

export const OptionText = styled.span`
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["400"]};
  color: ${theme.colors.secondary["380"]};
`;

export const OptionIcon = styled.div``;
