import { MultipleColorsIconProps } from "typing";

type UniqueClientXSMobileInfoProps = MultipleColorsIconProps & {
  dataTestId?: string;
};
export const UniqueClientXSMobileInfo = ({
  width = 32,
  height = 80,
  dataTestId = "unique-client-banner-xs-image",
}: UniqueClientXSMobileInfoProps) => {
  return (
    <svg
      data-testid={dataTestId}
      width={width}
      height={height}
      viewBox="0 0 32 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        borderRadius: "8px 0 0 8px",
        height: "100%",
        width: "100%",
        maxHeight: "80px",
        maxWidth: "32px",
      }}
    >
      <path d="M32 0H0V32H32V0Z" fill="#AA0E27" />
      <path
        d="M14.4151 24.7656H8.79096C8.39734 24.7656 8.07819 24.4465 8.07819 24.0529V14.3719C8.07819 14.0705 8.32287 13.8258 8.62429 13.8258H14.252C14.5534 13.8258 14.7981 14.0705 14.7981 14.3719V23.8224C14.901 23.9217 14.9648 24.06 14.9648 24.216C14.9648 24.5174 14.7201 24.7656 14.4187 24.7656M9.17394 23.6699H13.7059V14.9216H9.17394V23.6699Z"
        fill="#ED5568"
      />
      <path
        d="M23.5896 24.7656H17.9654C17.664 24.7656 17.4193 24.5209 17.4193 24.216C17.4193 24.0635 17.4831 23.9252 17.586 23.8224V14.3719C17.586 14.0705 17.8307 13.8258 18.1321 13.8258H23.7563C24.0577 13.8258 24.3024 14.0705 24.3024 14.3719V24.0529C24.3024 24.4465 23.9832 24.7656 23.5896 24.7656ZM18.6782 23.6699H23.2101V14.9216H18.6782V23.6699Z"
        fill="#ED5568"
      />
      <path
        d="M24.6677 14.9214H18.1284C17.827 14.9214 17.5823 14.6767 17.5823 14.3753V10.8893C17.4794 10.79 17.4156 10.6517 17.4156 10.4957C17.4156 10.1943 17.6603 9.94958 17.9617 9.94958H24.6677C25.0613 9.94958 25.3805 10.2687 25.3805 10.6624V14.2086C25.3805 14.6023 25.0613 14.9214 24.6677 14.9214ZM18.6781 13.8256H24.2882V11.0418H18.6781V13.8256Z"
        fill="#ED5568"
      />
      <path
        d="M14.2522 14.9214H7.71292C7.31929 14.9214 7.00012 14.6023 7.00012 14.2086V10.6624C7.00012 10.2687 7.31929 9.94958 7.71292 9.94958H14.4189C14.7203 9.94958 14.965 10.1943 14.965 10.4957C14.965 10.6482 14.9012 10.79 14.7983 10.8893V14.3753C14.7983 14.6767 14.5536 14.9214 14.2522 14.9214ZM8.09591 13.8256H13.7061V11.0418H8.09591V13.8256Z"
        fill="#ED5568"
      />
      <path
        d="M16.3693 11.0417H12.0997C10.8053 11.0417 9.53217 9.86793 9.52863 8.67284C9.52863 8.21892 9.6492 7.40683 10.4507 7.1054C11.2308 6.81106 12.433 7.13377 14.0288 8.06643C15.2806 8.79696 16.38 9.67643 16.7807 10.1339C16.9226 10.297 16.9545 10.524 16.8658 10.7226C16.7772 10.9176 16.5821 11.0453 16.3658 11.0453M11.0819 8.09835C10.979 8.09835 10.8975 8.11254 10.8336 8.13381C10.7769 8.15509 10.6209 8.21538 10.6209 8.67639C10.6209 9.27216 11.4081 9.9495 12.0961 9.9495H14.887C14.4863 9.65161 14.0005 9.32536 13.4756 9.01683C12.3089 8.33595 11.5181 8.09835 11.0819 8.09835Z"
        fill="#ED5568"
      />
      <path
        d="M20.2775 11.0418H16.0078C15.795 11.0418 15.5999 10.9176 15.5077 10.7191C15.4191 10.524 15.451 10.2935 15.5929 10.1304C15.99 9.67292 17.0929 8.79344 18.3447 8.06646C19.9406 7.13734 21.1427 6.81109 21.9229 7.10543C22.7244 7.40686 22.8449 8.2154 22.8449 8.67287C22.8449 9.86796 21.5718 11.0418 20.2739 11.0418M17.483 9.94598H20.2739C20.9619 9.94598 21.7492 9.26864 21.7492 8.67287C21.7492 8.21186 21.5967 8.15512 21.5364 8.13384C21.203 8.00972 20.3342 8.1764 18.8944 9.01686C18.366 9.32538 17.8837 9.65164 17.483 9.94952V9.94598Z"
        fill="#ED5568"
      />
      <path
        d="M17.9471 24.7651H14.433C14.0323 24.7651 13.7025 24.4388 13.7025 24.0346V10.6767C13.7025 10.2724 14.0287 9.94617 14.433 9.94617H17.9471C18.3478 9.94617 18.6776 10.2724 18.6776 10.6767V24.0346C18.6776 24.4353 18.3514 24.7651 17.9471 24.7651ZM14.7982 23.6693H17.5819V11.0419H14.7982V23.6693Z"
        fill="#ED5568"
      />
      <rect width="32" height="32" transform="translate(0 32)" fill="#C1D82F" />
      <path
        d="M19.542 56.7511H9.9254C8.86383 56.7511 8 55.8354 8 54.7101V41.041C8 39.9157 8.86383 39 9.9254 39H19.542C20.6036 39 21.4674 39.9157 21.4674 41.041V44.0124C21.4674 44.325 21.228 44.5787 20.9331 44.5787C20.6382 44.5787 20.3989 44.325 20.3989 44.0124V41.041C20.3989 40.5409 20.0138 40.1363 19.5455 40.1363H9.92887C9.45706 40.1363 9.07545 40.5409 9.07545 41.041V54.7101C9.07545 55.2103 9.45706 55.6148 9.92887 55.6148H19.5455C20.0173 55.6148 20.3989 55.2103 20.3989 54.7101V51.8969C20.3989 51.5843 20.6382 51.3305 20.9331 51.3305C21.228 51.3305 21.4674 51.5843 21.4674 51.8969V54.7101C21.4674 55.8354 20.6036 56.7511 19.542 56.7511Z"
        fill="#E3EDA1"
      />
      <path
        d="M18.5811 43.0599H10.8795C10.5846 43.0599 10.3452 42.8062 10.3452 42.4936C10.3452 42.181 10.5846 41.9236 10.8795 41.9236H18.5811C18.8759 41.9236 19.1153 42.1773 19.1153 42.4936C19.1153 42.8099 18.8759 43.0599 18.5811 43.0599Z"
        fill="#E3EDA1"
      />
      <path
        d="M16.4302 46.6417H10.8795C10.5846 46.6417 10.3452 46.3879 10.3452 46.0754C10.3452 45.7628 10.5846 45.509 10.8795 45.509H16.4302C16.7251 45.509 16.9644 45.7628 16.9644 46.0754C16.9644 46.3879 16.7251 46.6417 16.4302 46.6417Z"
        fill="#E3EDA1"
      />
      <path
        d="M16.3469 50.2273H10.8829C10.588 50.2273 10.3486 49.9735 10.3486 49.6609C10.3486 49.3483 10.588 49.0946 10.8829 49.0946H16.3469C16.6417 49.0946 16.8811 49.3483 16.8811 49.6609C16.8811 49.9735 16.6417 50.2273 16.3469 50.2273Z"
        fill="#E3EDA1"
      />
      <path
        d="M18.5811 53.8092H10.8795C10.5846 53.8092 10.3452 53.5554 10.3452 53.2428C10.3452 52.9303 10.5846 52.6765 10.8795 52.6765H18.5811C18.8759 52.6765 19.1153 52.9303 19.1153 53.2428C19.1153 53.5554 18.8759 53.8092 18.5811 53.8092Z"
        fill="#E3EDA1"
      />
      <path
        d="M26.2792 56.2546C25.908 56.2546 25.5576 56.1002 25.2974 55.8244L21.7207 52.0329C20.9991 52.4043 20.1873 52.5551 19.3859 52.4632C18.425 52.3529 17.5195 51.8932 16.8361 51.1687C16.0312 50.3155 15.5872 49.1792 15.5872 47.9693C15.5872 46.7594 16.0312 45.6231 16.8361 44.7699C17.6409 43.9168 18.7129 43.446 19.8543 43.446C20.9956 43.446 22.0641 43.9168 22.8724 44.7699C23.5559 45.4944 23.9895 46.4542 24.0936 47.4729C24.1803 48.3224 24.0381 49.1866 23.6877 49.9478L27.2644 53.7393C27.5281 54.0188 27.6738 54.3865 27.6738 54.7837C27.6738 55.1808 27.5281 55.5486 27.2644 55.8281C27.0008 56.1076 26.6539 56.2583 26.2792 56.2583M21.8109 50.7642C21.9496 50.7642 22.0849 50.8194 22.189 50.9297L26.0502 55.0227C26.1092 55.0852 26.189 55.122 26.2757 55.122C26.3624 55.122 26.4422 55.0852 26.5012 55.0227C26.5602 54.9602 26.5949 54.8756 26.5949 54.7837C26.5949 54.6917 26.5602 54.6071 26.5012 54.5446L22.64 50.4516C22.4631 50.2641 22.4319 49.9735 22.5637 49.7492C22.9384 49.1093 23.1014 48.3444 23.0216 47.5942C22.9418 46.822 22.6261 46.1232 22.1058 45.5716C21.5021 44.9317 20.7007 44.5787 19.8473 44.5787C18.9939 44.5787 18.189 44.9317 17.5854 45.5716C16.9818 46.2115 16.6487 47.061 16.6487 47.9656C16.6487 48.8703 16.9818 49.7235 17.5854 50.3634C18.1058 50.915 18.7649 51.2496 19.4935 51.3342C20.2012 51.4151 20.9228 51.2423 21.5264 50.8451C21.6131 50.7899 21.7103 50.7605 21.8074 50.7605L21.8109 50.7642Z"
        fill="#E3EDA1"
      />
      <path
        d="M0.688866 65.436C1.06932 65.436 1.37773 65.1146 1.37773 64.718C1.37773 64.3215 1.06932 64 0.688866 64C0.308416 64 0 64.3215 0 64.718C0 65.1146 0.308416 65.436 0.688866 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 65.436C3.85362 65.436 4.16203 65.1146 4.16203 64.718C4.16203 64.3215 3.85362 64 3.47317 64C3.09272 64 2.7843 64.3215 2.7843 64.718C2.7843 65.1146 3.09272 65.436 3.47317 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 65.436C6.63762 65.436 6.94603 65.1146 6.94603 64.718C6.94603 64.3215 6.63762 64 6.25716 64C5.87671 64 5.5683 64.3215 5.5683 64.718C5.5683 65.1146 5.87671 65.436 6.25716 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 65.436C9.42192 65.436 9.73033 65.1146 9.73033 64.718C9.73033 64.3215 9.42192 64 9.04147 64C8.66102 64 8.3526 64.3215 8.3526 64.718C8.3526 65.1146 8.66102 65.436 9.04147 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 65.436C12.2024 65.436 12.5108 65.1146 12.5108 64.718C12.5108 64.3215 12.2024 64 11.822 64C11.4415 64 11.1331 64.3215 11.1331 64.718C11.1331 65.1146 11.4415 65.436 11.822 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 65.436C14.9867 65.436 15.2951 65.1146 15.2951 64.718C15.2951 64.3215 14.9867 64 14.6062 64C14.2258 64 13.9174 64.3215 13.9174 64.718C13.9174 65.1146 14.2258 65.436 14.6062 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 65.436C17.7707 65.436 18.0791 65.1146 18.0791 64.718C18.0791 64.3215 17.7707 64 17.3902 64C17.0098 64 16.7014 64.3215 16.7014 64.718C16.7014 65.1146 17.0098 65.436 17.3902 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1745 65.436C20.555 65.436 20.8634 65.1146 20.8634 64.718C20.8634 64.3215 20.555 64 20.1745 64C19.7941 64 19.4857 64.3215 19.4857 64.718C19.4857 65.1146 19.7941 65.436 20.1745 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 65.436C23.3392 65.436 23.6476 65.1146 23.6476 64.718C23.6476 64.3215 23.3392 64 22.9587 64C22.5783 64 22.2698 64.3215 22.2698 64.718C22.2698 65.1146 22.5783 65.436 22.9587 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 65.436C26.1233 65.436 26.4317 65.1146 26.4317 64.718C26.4317 64.3215 26.1233 64 25.7428 64C25.3624 64 25.054 64.3215 25.054 64.718C25.054 65.1146 25.3624 65.436 25.7428 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 65.436C28.9075 65.436 29.2159 65.1146 29.2159 64.718C29.2159 64.3215 28.9075 64 28.527 64C28.1466 64 27.8381 64.3215 27.8381 64.718C27.8381 65.1146 28.1466 65.436 28.527 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 65.436C31.6916 65.436 32 65.1146 32 64.718C32 64.3215 31.6916 64 31.3111 64C30.9307 64 30.6223 64.3215 30.6223 64.718C30.6223 65.1146 30.9307 65.436 31.3111 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 68.2146C1.06932 68.2146 1.37773 67.8931 1.37773 67.4966C1.37773 67.1 1.06932 66.7786 0.688866 66.7786C0.308416 66.7786 0 67.1 0 67.4966C0 67.8931 0.308416 68.2146 0.688866 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 68.2146C3.85362 68.2146 4.16203 67.8931 4.16203 67.4966C4.16203 67.1 3.85362 66.7786 3.47317 66.7786C3.09272 66.7786 2.7843 67.1 2.7843 67.4966C2.7843 67.8931 3.09272 68.2146 3.47317 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 68.2146C6.63762 68.2146 6.94603 67.8931 6.94603 67.4966C6.94603 67.1 6.63762 66.7786 6.25716 66.7786C5.87671 66.7786 5.5683 67.1 5.5683 67.4966C5.5683 67.8931 5.87671 68.2146 6.25716 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 68.2146C9.42192 68.2146 9.73033 67.8931 9.73033 67.4966C9.73033 67.1 9.42192 66.7786 9.04147 66.7786C8.66102 66.7786 8.3526 67.1 8.3526 67.4966C8.3526 67.8931 8.66102 68.2146 9.04147 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 68.2146C12.2024 68.2146 12.5108 67.8931 12.5108 67.4966C12.5108 67.1 12.2024 66.7786 11.822 66.7786C11.4415 66.7786 11.1331 67.1 11.1331 67.4966C11.1331 67.8931 11.4415 68.2146 11.822 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 68.2146C14.9867 68.2146 15.2951 67.8931 15.2951 67.4966C15.2951 67.1 14.9867 66.7786 14.6062 66.7786C14.2258 66.7786 13.9174 67.1 13.9174 67.4966C13.9174 67.8931 14.2258 68.2146 14.6062 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 68.2146C17.7707 68.2146 18.0791 67.8931 18.0791 67.4966C18.0791 67.1 17.7707 66.7786 17.3902 66.7786C17.0098 66.7786 16.7014 67.1 16.7014 67.4966C16.7014 67.8931 17.0098 68.2146 17.3902 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 68.2146C20.5549 68.2146 20.8633 67.8931 20.8633 67.4966C20.8633 67.1 20.5549 66.7786 20.1744 66.7786C19.7939 66.7786 19.4855 67.1 19.4855 67.4966C19.4855 67.8931 19.7939 68.2146 20.1744 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 68.2146C23.3392 68.2146 23.6476 67.8931 23.6476 67.4966C23.6476 67.1 23.3392 66.7786 22.9587 66.7786C22.5783 66.7786 22.2698 67.1 22.2698 67.4966C22.2698 67.8931 22.5783 68.2146 22.9587 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 68.2146C26.1233 68.2146 26.4317 67.8931 26.4317 67.4966C26.4317 67.1 26.1233 66.7786 25.7428 66.7786C25.3624 66.7786 25.054 67.1 25.054 67.4966C25.054 67.8931 25.3624 68.2146 25.7428 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 68.2146C28.9075 68.2146 29.2159 67.8931 29.2159 67.4966C29.2159 67.1 28.9075 66.7786 28.527 66.7786C28.1466 66.7786 27.8381 67.1 27.8381 67.4966C27.8381 67.8931 28.1466 68.2146 28.527 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 68.2146C31.6916 68.2146 32 67.8931 32 67.4966C32 67.1 31.6916 66.7786 31.3111 66.7786C30.9307 66.7786 30.6223 67.1 30.6223 67.4966C30.6223 67.8931 30.9307 68.2146 31.3111 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 70.9934C1.06932 70.9934 1.37773 70.6719 1.37773 70.2754C1.37773 69.8788 1.06932 69.5574 0.688866 69.5574C0.308416 69.5574 0 69.8788 0 70.2754C0 70.6719 0.308416 70.9934 0.688866 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 70.9934C3.85362 70.9934 4.16203 70.6719 4.16203 70.2754C4.16203 69.8788 3.85362 69.5574 3.47317 69.5574C3.09272 69.5574 2.7843 69.8788 2.7843 70.2754C2.7843 70.6719 3.09272 70.9934 3.47317 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 70.9934C6.63762 70.9934 6.94603 70.6719 6.94603 70.2754C6.94603 69.8788 6.63762 69.5574 6.25716 69.5574C5.87671 69.5574 5.5683 69.8788 5.5683 70.2754C5.5683 70.6719 5.87671 70.9934 6.25716 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 70.9934C9.42192 70.9934 9.73033 70.6719 9.73033 70.2754C9.73033 69.8788 9.42192 69.5574 9.04147 69.5574C8.66102 69.5574 8.3526 69.8788 8.3526 70.2754C8.3526 70.6719 8.66102 70.9934 9.04147 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 70.9934C12.2024 70.9934 12.5108 70.6719 12.5108 70.2754C12.5108 69.8788 12.2024 69.5574 11.822 69.5574C11.4415 69.5574 11.1331 69.8788 11.1331 70.2754C11.1331 70.6719 11.4415 70.9934 11.822 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 70.9934C14.9867 70.9934 15.2951 70.6719 15.2951 70.2754C15.2951 69.8788 14.9867 69.5574 14.6062 69.5574C14.2258 69.5574 13.9174 69.8788 13.9174 70.2754C13.9174 70.6719 14.2258 70.9934 14.6062 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 70.9934C17.7707 70.9934 18.0791 70.6719 18.0791 70.2754C18.0791 69.8788 17.7707 69.5574 17.3902 69.5574C17.0098 69.5574 16.7014 69.8788 16.7014 70.2754C16.7014 70.6719 17.0098 70.9934 17.3902 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 70.9934C20.5549 70.9934 20.8633 70.6719 20.8633 70.2754C20.8633 69.8788 20.5549 69.5574 20.1744 69.5574C19.7939 69.5574 19.4855 69.8788 19.4855 70.2754C19.4855 70.6719 19.7939 70.9934 20.1744 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 70.9934C23.3392 70.9934 23.6476 70.6719 23.6476 70.2754C23.6476 69.8788 23.3392 69.5574 22.9587 69.5574C22.5783 69.5574 22.2698 69.8788 22.2698 70.2754C22.2698 70.6719 22.5783 70.9934 22.9587 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 70.9934C26.1233 70.9934 26.4317 70.6719 26.4317 70.2754C26.4317 69.8788 26.1233 69.5574 25.7428 69.5574C25.3624 69.5574 25.054 69.8788 25.054 70.2754C25.054 70.6719 25.3624 70.9934 25.7428 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 70.9934C28.9075 70.9934 29.2159 70.6719 29.2159 70.2754C29.2159 69.8788 28.9075 69.5574 28.527 69.5574C28.1466 69.5574 27.8381 69.8788 27.8381 70.2754C27.8381 70.6719 28.1466 70.9934 28.527 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 70.9934C31.6916 70.9934 32 70.6719 32 70.2754C32 69.8788 31.6916 69.5574 31.3111 69.5574C30.9307 69.5574 30.6223 69.8788 30.6223 70.2754C30.6223 70.6719 30.9307 70.9934 31.3111 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 73.7716C1.06932 73.7716 1.37773 73.4501 1.37773 73.0536C1.37773 72.657 1.06932 72.3356 0.688866 72.3356C0.308416 72.3356 0 72.657 0 73.0536C0 73.4501 0.308416 73.7716 0.688866 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 73.7716C3.85362 73.7716 4.16203 73.4501 4.16203 73.0536C4.16203 72.657 3.85362 72.3356 3.47317 72.3356C3.09272 72.3356 2.7843 72.657 2.7843 73.0536C2.7843 73.4501 3.09272 73.7716 3.47317 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 73.7716C6.63762 73.7716 6.94603 73.4501 6.94603 73.0536C6.94603 72.657 6.63762 72.3356 6.25716 72.3356C5.87671 72.3356 5.5683 72.657 5.5683 73.0536C5.5683 73.4501 5.87671 73.7716 6.25716 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 73.7716C9.42192 73.7716 9.73033 73.4501 9.73033 73.0536C9.73033 72.657 9.42192 72.3356 9.04147 72.3356C8.66102 72.3356 8.3526 72.657 8.3526 73.0536C8.3526 73.4501 8.66102 73.7716 9.04147 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 73.7716C12.2024 73.7716 12.5108 73.4501 12.5108 73.0536C12.5108 72.657 12.2024 72.3356 11.822 72.3356C11.4415 72.3356 11.1331 72.657 11.1331 73.0536C11.1331 73.4501 11.4415 73.7716 11.822 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 73.7716C14.9867 73.7716 15.2951 73.4501 15.2951 73.0536C15.2951 72.657 14.9867 72.3356 14.6062 72.3356C14.2258 72.3356 13.9174 72.657 13.9174 73.0536C13.9174 73.4501 14.2258 73.7716 14.6062 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 73.7716C17.7707 73.7716 18.0791 73.4501 18.0791 73.0536C18.0791 72.657 17.7707 72.3356 17.3902 72.3356C17.0098 72.3356 16.7014 72.657 16.7014 73.0536C16.7014 73.4501 17.0098 73.7716 17.3902 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 73.7716C20.5549 73.7716 20.8633 73.4501 20.8633 73.0536C20.8633 72.657 20.5549 72.3356 20.1744 72.3356C19.7939 72.3356 19.4855 72.657 19.4855 73.0536C19.4855 73.4501 19.7939 73.7716 20.1744 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 73.7716C23.3392 73.7716 23.6476 73.4501 23.6476 73.0536C23.6476 72.657 23.3392 72.3356 22.9587 72.3356C22.5783 72.3356 22.2698 72.657 22.2698 73.0536C22.2698 73.4501 22.5783 73.7716 22.9587 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 73.7716C26.1233 73.7716 26.4317 73.4501 26.4317 73.0536C26.4317 72.657 26.1233 72.3356 25.7428 72.3356C25.3624 72.3356 25.054 72.657 25.054 73.0536C25.054 73.4501 25.3624 73.7716 25.7428 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 73.7716C28.9075 73.7716 29.2159 73.4501 29.2159 73.0536C29.2159 72.657 28.9075 72.3356 28.527 72.3356C28.1466 72.3356 27.8381 72.657 27.8381 73.0536C27.8381 73.4501 28.1466 73.7716 28.527 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 73.7716C31.6916 73.7716 32 73.4501 32 73.0536C32 72.657 31.6916 72.3356 31.3111 72.3356C30.9307 72.3356 30.6223 72.657 30.6223 73.0536C30.6223 73.4501 30.9307 73.7716 31.3111 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 76.5503C1.06932 76.5503 1.37773 76.2288 1.37773 75.8323C1.37773 75.4357 1.06932 75.1143 0.688866 75.1143C0.308416 75.1143 0 75.4357 0 75.8323C0 76.2288 0.308416 76.5503 0.688866 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 76.5503C3.85362 76.5503 4.16203 76.2288 4.16203 75.8323C4.16203 75.4357 3.85362 75.1143 3.47317 75.1143C3.09272 75.1143 2.7843 75.4357 2.7843 75.8323C2.7843 76.2288 3.09272 76.5503 3.47317 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 76.5503C6.63762 76.5503 6.94603 76.2288 6.94603 75.8323C6.94603 75.4357 6.63762 75.1143 6.25716 75.1143C5.87671 75.1143 5.5683 75.4357 5.5683 75.8323C5.5683 76.2288 5.87671 76.5503 6.25716 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 76.5503C9.42192 76.5503 9.73033 76.2288 9.73033 75.8323C9.73033 75.4357 9.42192 75.1143 9.04147 75.1143C8.66102 75.1143 8.3526 75.4357 8.3526 75.8323C8.3526 76.2288 8.66102 76.5503 9.04147 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 76.5503C12.2024 76.5503 12.5108 76.2288 12.5108 75.8323C12.5108 75.4357 12.2024 75.1143 11.822 75.1143C11.4415 75.1143 11.1331 75.4357 11.1331 75.8323C11.1331 76.2288 11.4415 76.5503 11.822 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 76.5503C14.9867 76.5503 15.2951 76.2288 15.2951 75.8323C15.2951 75.4357 14.9867 75.1143 14.6062 75.1143C14.2258 75.1143 13.9174 75.4357 13.9174 75.8323C13.9174 76.2288 14.2258 76.5503 14.6062 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 76.5503C17.7707 76.5503 18.0791 76.2288 18.0791 75.8323C18.0791 75.4357 17.7707 75.1143 17.3902 75.1143C17.0098 75.1143 16.7014 75.4357 16.7014 75.8323C16.7014 76.2288 17.0098 76.5503 17.3902 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 76.5503C20.5549 76.5503 20.8633 76.2288 20.8633 75.8323C20.8633 75.4357 20.5549 75.1143 20.1744 75.1143C19.7939 75.1143 19.4855 75.4357 19.4855 75.8323C19.4855 76.2288 19.7939 76.5503 20.1744 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 76.5503C23.3392 76.5503 23.6476 76.2288 23.6476 75.8323C23.6476 75.4357 23.3392 75.1143 22.9587 75.1143C22.5783 75.1143 22.2698 75.4357 22.2698 75.8323C22.2698 76.2288 22.5783 76.5503 22.9587 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 76.5503C26.1233 76.5503 26.4317 76.2288 26.4317 75.8323C26.4317 75.4357 26.1233 75.1143 25.7428 75.1143C25.3624 75.1143 25.054 75.4357 25.054 75.8323C25.054 76.2288 25.3624 76.5503 25.7428 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 76.5503C28.9075 76.5503 29.2159 76.2288 29.2159 75.8323C29.2159 75.4357 28.9075 75.1143 28.527 75.1143C28.1466 75.1143 27.8381 75.4357 27.8381 75.8323C27.8381 76.2288 28.1466 76.5503 28.527 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 76.5503C31.6916 76.5503 32 76.2288 32 75.8323C32 75.4357 31.6916 75.1143 31.3111 75.1143C30.9307 75.1143 30.6223 75.4357 30.6223 75.8323C30.6223 76.2288 30.9307 76.5503 31.3111 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 79.3285C1.06932 79.3285 1.37773 79.007 1.37773 78.6105C1.37773 78.2139 1.06932 77.8925 0.688866 77.8925C0.308416 77.8925 0 78.2139 0 78.6105C0 79.007 0.308416 79.3285 0.688866 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 79.3285C3.85362 79.3285 4.16203 79.007 4.16203 78.6105C4.16203 78.2139 3.85362 77.8925 3.47317 77.8925C3.09272 77.8925 2.7843 78.2139 2.7843 78.6105C2.7843 79.007 3.09272 79.3285 3.47317 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 79.3285C6.63762 79.3285 6.94603 79.007 6.94603 78.6105C6.94603 78.2139 6.63762 77.8925 6.25716 77.8925C5.87671 77.8925 5.5683 78.2139 5.5683 78.6105C5.5683 79.007 5.87671 79.3285 6.25716 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 79.3285C9.42192 79.3285 9.73033 79.007 9.73033 78.6105C9.73033 78.2139 9.42192 77.8925 9.04147 77.8925C8.66102 77.8925 8.3526 78.2139 8.3526 78.6105C8.3526 79.007 8.66102 79.3285 9.04147 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 79.3285C12.2024 79.3285 12.5108 79.007 12.5108 78.6105C12.5108 78.2139 12.2024 77.8925 11.822 77.8925C11.4415 77.8925 11.1331 78.2139 11.1331 78.6105C11.1331 79.007 11.4415 79.3285 11.822 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 79.3285C14.9867 79.3285 15.2951 79.007 15.2951 78.6105C15.2951 78.2139 14.9867 77.8925 14.6062 77.8925C14.2258 77.8925 13.9174 78.2139 13.9174 78.6105C13.9174 79.007 14.2258 79.3285 14.6062 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 79.3285C17.7707 79.3285 18.0791 79.007 18.0791 78.6105C18.0791 78.2139 17.7707 77.8925 17.3902 77.8925C17.0098 77.8925 16.7014 78.2139 16.7014 78.6105C16.7014 79.007 17.0098 79.3285 17.3902 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 79.3285C20.5549 79.3285 20.8633 79.007 20.8633 78.6105C20.8633 78.2139 20.5549 77.8925 20.1744 77.8925C19.7939 77.8925 19.4855 78.2139 19.4855 78.6105C19.4855 79.007 19.7939 79.3285 20.1744 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 79.3285C23.3392 79.3285 23.6476 79.007 23.6476 78.6105C23.6476 78.2139 23.3392 77.8925 22.9587 77.8925C22.5783 77.8925 22.2698 78.2139 22.2698 78.6105C22.2698 79.007 22.5783 79.3285 22.9587 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 79.3285C26.1233 79.3285 26.4317 79.007 26.4317 78.6105C26.4317 78.2139 26.1233 77.8925 25.7428 77.8925C25.3624 77.8925 25.054 78.2139 25.054 78.6105C25.054 79.007 25.3624 79.3285 25.7428 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 79.3285C28.9075 79.3285 29.2159 79.007 29.2159 78.6105C29.2159 78.2139 28.9075 77.8925 28.527 77.8925C28.1466 77.8925 27.8381 78.2139 27.8381 78.6105C27.8381 79.007 28.1466 79.3285 28.527 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 79.3285C31.6916 79.3285 32 79.007 32 78.6105C32 78.2139 31.6916 77.8925 31.3111 77.8925C30.9307 77.8925 30.6223 78.2139 30.6223 78.6105C30.6223 79.007 30.9307 79.3285 31.3111 79.3285Z"
        fill="#E5E6E6"
      />
    </svg>
  );
};
