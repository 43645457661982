/* eslint-disable camelcase */
import { IAppMonitoringClient, ICrm } from "app-domain";

import {
  CRMDataDto,
  CrmPagesType,
  CrmProductObjectProps,
  CrmTransactionObjectProps,
  CrmUserObjectProps,
  RecommendedProductListDto,
} from "typing";

interface CrmObjectProps {
  page: object;
  user?: object;
  product?: object;
  transaction?: object;
}

interface EventManager {
  dispatch: (value: string) => void;
  once: (
    campaignString: string,
    campaignFunction: (
      event: Event,
      data: RecommendedProductListDto
    ) => RecommendedProductListDto | unknown
  ) => void;
}

interface CrmEventsProps {
  eventManager: EventManager;
  track: (event: string, key: unknown) => void;
}

declare const window: Window &
  // eslint-disable-next-line no-undef
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, camelcase
    insider_object: CrmObjectProps;
    Insider: CrmEventsProps;
  };

const InsiderObject: ICrm = {
  pageObject: (type: CrmPagesType) => {
    window.insider_object = window.insider_object || {};
    window.insider_object.page = {
      type,
    };
  },
  userObject: (params: CrmUserObjectProps) => {
    window.insider_object = window.insider_object || {};
    window.insider_object.user = params;
  },
  productObject: ({
    product_id,
    name,
    taxonomy,
    url,
    image_url,
    price,
    currency,
    color,
    voucherName,
    size,
    salePrice,
    spotPrice,
    voucherDiscountPercentage,
    voucherDiscountValue,
    product_tag,
    branch_id,
    model,
    brand,
  }: CrmProductObjectProps) => {
    window.insider_object = window.insider_object || {};
    window.insider_object.product = {
      product_id,
      name,
      taxonomy,
      url,
      image_url,
      price,
      currency,
      color,
      size,
      salePrice,
      custom: {
        spotPrice,
        voucherName,
        voucherDiscountPercentage,
        voucherDiscountValue,
        product_tag,
        branch_id,
        model,
        brand,
      },
    };
  },
  transactionObject: ({
    order_id,
    currency,
    total,
    shipping_cost,
    delivery,
    line_items,
    payment_type,
  }: CrmTransactionObjectProps) => {
    window.insider_object = window.insider_object || {};
    window.insider_object.transaction = {
      order_id,
      currency,
      total,
      shipping_cost,
      delivery,
      line_items,
      payment_type,
    };
  },
  getRecommendedProducts: async (
    crmEndpoint: string,
    userId?: string,
    branchId?: number | string,
    shuffle?: boolean,
    appMonitoringClient?: IAppMonitoringClient
  ) => {
    const insiderBaseUrl = "https://recommendationv2.api.useinsider.com/v2";
    const insiderUrl = `${insiderBaseUrl}${crmEndpoint}?partnerName=ferreiracosta&locale=pt_BR:${
      branchId || 2
    }&currency=BRL&platform=web&details=false&shuffle=${!!shuffle}&userId=${userId}`;
    const userData = window?.insider_object?.user as CrmUserObjectProps;

    try {
      const response = await fetch(insiderUrl, { cache: "no-cache" });
      const data: CRMDataDto = await response.json();
      if (data.success) {
        return data;
      }
      appMonitoringClient?.captureMessage(
        `Erro ao solicitar os produtos no endpoint ${crmEndpoint} da insider. Messagem de erro: ${data?.message}`,
        {
          level: "error",
          user: { email: userData?.email as unknown as string },
        }
      );
      return null;
    } catch (error) {
      appMonitoringClient?.captureException(error, {
        level: "error",
        user: { email: userData?.email as unknown as string },
      });
      return null;
    }
  },
  clearProductObject() {
    delete window?.insider_object?.product;
  },
  clearUserObject() {
    delete window?.insider_object?.user;
  },
  clearTransactionObject() {
    delete window?.insider_object?.transaction;
  },
  reinitializeTag() {
    // setTimeout(() => {
    window?.Insider?.eventManager?.dispatch("init-manager:re-initialize");
    // }, 500);
  },
  changePageTypeToOther() {
    setTimeout(() => {
      window.insider_object.page = {
        type: "Other",
      };
    }, 2000);
  },
  getUserData() {
    return window?.insider_object?.user as CrmUserObjectProps;
  },
  sendEvent(event: string, keys: Record<string, unknown>) {
    window?.Insider?.track(event, keys);
  },

  insiderId: "10008597",
};

export { InsiderObject };
