import styled from "styled-components";
import { theme } from "ui";

interface TextAreaProps {
  maxLengthOfMessage: boolean;
}

export const Container = styled.div<TextAreaProps>`
  margin-top: 1rem;
  width: 100%;
  border: 1px solid ${theme.colors.neutral["999-opacity-75"]};
  border-radius: 5px;
  padding: 0.8rem;

  transition: border-width 0.2s ease-in-out;
  transition: border-width 0.2s ease-in-out;

  &:focus-within {
    border-width: 2px;
  }
`;

export const TextArea = styled.textarea`
  border: none;
  resize: none;
  width: 100%;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const LabelText = styled.span`
  color: ${theme.colors.neutral["465"]};
  font-weight: ${theme.typography.fontWeight["700"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x3};
`;

export const TextAreaMessageError = styled.span`
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.primary["200"]};
`;

export const CharacterCounter = styled.div<TextAreaProps>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: left;
  font-weight: bold;
  color: ${({ maxLengthOfMessage }) =>
    maxLengthOfMessage
      ? `${theme.colors.primary["200"]}`
      : `${theme.colors.neutral["465"]}`};
`;
