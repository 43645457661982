import { IAppMonitoringClient, ICookie, ICrm } from "app-domain";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BranchDto, CRMProperties, ComponentDto, ProductCardDto } from "typing";
import { CatalogApi } from "../../services";
import { useCrm } from "../../state-manager";
import { insiderEndPoints, useOderCrmProducts } from "./data";

export const useProductsRecommendation = (
  crm: ICrm,
  props: ComponentDto,
  cookie: ICookie,
  catalogApi: CatalogApi,
  appMonitoringClient: IAppMonitoringClient
) => {
  const [productIdsList, setProductIdsList] = useState<string[]>([]);
  const [componentCrmProps, setComponentCrmProps] =
    useState<CRMProperties | null>(null);
  const [isCrmComponent, setIsCrmComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const userId = useCrm((state) => state.userCrmId);

  const [isProductsListEmpty, setIsProductsListEmpty] = useState(false);
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const quantity = props?.properties?.find(({ key }) => key === "quantity")
    ?.value as unknown as number;

  const clientBranchId =
    cookie.getCookie({
      name: "@FC:Ecom:Dropdown:BranchLocation",
    }) || "2";

  const accessToken = cookie.getCookie({
    name: "@FC:Ecom:Token:Access",
  });

  const branches = useMemo(
    () => props?.config?.branches || [],
    [props?.config?.branches]
  );

  const crmEndpoint = useMemo(() => {
    return branches?.find(({ id }) => id === clientBranchId)
      ?.linkUrl as unknown as string;
  }, [branches, clientBranchId]);

  const needCrmUserId = useMemo(() => {
    return insiderEndPoints.find(({ endPoint }) => endPoint === crmEndpoint)
      ?.needId as boolean;
  }, [crmEndpoint]);

  const shouldWaitForUserCrmId = useMemo(() => {
    return needCrmUserId && (!accessToken || !userId);
  }, [accessToken, needCrmUserId, userId]);

  const canGetProductCards = !!productIdsList?.length;

  const { data: productCards, isLoading: isLoadingProductCards } =
    catalogApi.getProductCards(
      Number(clientBranchId),
      productIdsList,
      undefined,
      undefined,
      0,
      canGetProductCards
    );

  const availableProducts = useMemo(() => {
    return (
      productCards?.filter(
        (product) =>
          product.maximumStockPerBranch > 0 && !product.tag?.isUnavailable
      ) ?? []
    );
  }, [productCards]);

  const orderedProductCards = useOderCrmProducts(
    availableProducts,
    productIdsList,
    quantity
  );

  useEffect(() => {
    if (shouldWaitForUserCrmId) {
      setIsProductsListEmpty(true);
      setProductIdsList([]);
      setIsFirstRequest(true);
    }
  }, [shouldWaitForUserCrmId]);

  useEffect(() => {
    if (
      (canGetProductCards || isProductsListEmpty) &&
      !isLoadingProductCards &&
      isLoading
    ) {
      setIsLoading(false);
    }
  }, [
    canGetProductCards,
    isLoading,
    isLoadingProductCards,
    isProductsListEmpty,
  ]);

  const handleRecommendedProducts = useCallback(
    async (
      passedCrmEndpoint: string,
      needCrm: boolean,
      passedUserId: string | null,
      passedClientBranchId: string
    ) => {
      const recommendedProducts = await crm.getRecommendedProducts(
        passedCrmEndpoint,
        String(passedUserId),
        passedClientBranchId,
        !needCrmUserId,
        appMonitoringClient
      );

      if (recommendedProducts?.data?.length && recommendedProducts?.success) {
        setProductIdsList(
          recommendedProducts.data.map((id) => {
            return id.split(".")[0];
          })
        );
      } else {
        setIsProductsListEmpty(true);
      }

      setComponentCrmProps({
        recommendedProductsId: productIdsList.splice(quantity),
        needCrmUserId: needCrm,
      });
    },
    [appMonitoringClient, crm, needCrmUserId, productIdsList, quantity]
  );

  const handleSetRecommendedProducts = useCallback(
    (passedBranches: BranchDto[], passedUserId: string | null) => {
      if (!productIdsList?.length && passedBranches.length && isFirstRequest) {
        const tempIsCrmComponent =
          passedBranches?.find(({ id }) => id === clientBranchId)?.typeLink ===
          "insider";

        setIsCrmComponent(tempIsCrmComponent);

        if (tempIsCrmComponent && !shouldWaitForUserCrmId) {
          setIsFirstRequest(false);
          handleRecommendedProducts(
            crmEndpoint,
            needCrmUserId,
            passedUserId,
            accessToken ? clientBranchId : "2"
          );
        }
      }
    },
    [
      accessToken,
      clientBranchId,
      crmEndpoint,
      handleRecommendedProducts,
      isFirstRequest,
      needCrmUserId,
      productIdsList?.length,
      shouldWaitForUserCrmId,
    ]
  );

  useEffect(() => {
    handleSetRecommendedProducts(branches, userId);
  }, [branches, handleSetRecommendedProducts, userId]);

  orderedProductCards?.splice(quantity) as ProductCardDto[];

  return {
    productCards: orderedProductCards,
    isLoading,
    componentCrmProps,
    isCrmComponent,
  };
};
