import { useMediaQuery } from "usehooks-ts";

export const useIsClientDesktop = () => {
  return useMediaQuery("(max-width: 2560px)");
};

export const useIsClientDesktopS = () => {
  return useMediaQuery("(max-width: 1980px)");
};

export const useIsClientLaptopXL = () => {
  return useMediaQuery("(max-width: 1600px)");
};

export const useIsClientLaptopL = () => {
  return useMediaQuery("(max-width: 1440px)");
};

export const useIsClientLaptopM = () => {
  return useMediaQuery("(max-width: 1280px)");
};

export const useIsClientLaptop = () => {
  return useMediaQuery("(max-width: 1024px)");
};

export const useIsClientLaptopS = () => {
  return useMediaQuery("(max-width: 924px)");
};

export const useIsClientTabletXL = () => {
  return useMediaQuery("(max-width: 850px)");
};

export const useIsClientTabletL = () => {
  return useMediaQuery("(max-width: 826px)");
};

export const useIsClientTablet = () => {
  return useMediaQuery("(max-width: 768px)");
};

export const useIsClientTabletXM = () => {
  return useMediaQuery("(max-width: 700px)");
};

export const useIsClientTabletML = () => {
  return useMediaQuery("(max-width: 678px)");
};

export const useIsClientTabletM = () => {
  return useMediaQuery("(max-width: 600px)");
};

export const useIsClientTabletSmall = () => {
  return useMediaQuery("(max-width: 576px)");
};

export const useIsClientMobile = () => {
  return useMediaQuery("(max-width: 480px)");
};

export const useIsClientMobileL = () => {
  return useMediaQuery("(max-width: 425px)");
};

export const useIsClientMobileM = () => {
  return useMediaQuery("(max-width: 375px)");
};

export const useIsClientMobileS = () => {
  return useMediaQuery("(max-width: 320px)");
};

export const useIsClientMobileXS = () => {
  return useMediaQuery("(max-width: 280px)");
};

// Only breakpoints
export const useIsOnlyClientLaptop = () => {
  return useMediaQuery("(min-width: 769px) and (max-width: 1024px)");
};

export const useIsOnlyClientTablet = () => {
  return useMediaQuery("(min-width: 481px) and (max-width: 768px)");
};

export const useIsOnlyClientMobile = () => {
  return useMediaQuery("(min-width: 320px) and (max-width: 480px)");
};

export const useIsOnlyMobileXSToLaptop = () => {
  return useMediaQuery("(min-width: 280px) and (max-width: 1023px)");
};
