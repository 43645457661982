import { buildTestIds } from "application";
import { ChangeEvent, FocusEvent, LegacyRef } from "react";
import {
  Path,
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
} from "react-hook-form";
import InputMask from "react-input-mask";
import { IFormValues, InputModeProps } from "typing";
import FieldError from "../FieldError";
import { FieldInfo } from "../FieldInfo";
import { IconContainer, InputContainer, StyledInput } from "./style";

export type InputProps = {
  id?: string;
  name?: string;
  className?: string;
  inputMode?: InputModeProps;
  mask?: string;
  maskChar?: string;
  autoComplete?: "off" | "false" | "none";
  role?: string;
  label?: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  setValue?: UseFormSetValue<IFormValues>;
  required?: string | boolean;
  pattern?: ValidationRule<RegExp>;
  minLength?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
  type?: string;
  placeholder?: string;
  value?: string | number | string[];
  hasError?: boolean | string | number;
  textError?: string;
  textInfo?: string | boolean;
  hasSuccessfully?: boolean;
  disabled?: boolean;
  width?: string;
  padding?: string;
  paddingErrorMessage?: string;
  max?: number;
  height?: string;
  borderRadius?: string;
  borderWidth?: string;
  hasIcon?: boolean;
  margin?: string;
  color?: string;
  fontSize?: string;
  paddingInfo?: string;
  icon?: JSX.Element;
  backgroundColor?: string;
  fontSizeInfo?: string;
  colorInfo?: string;
  cursor?: string;
  borderColor?: string;
  hasIconBorder?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onKeyUp?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseOut?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onClickIcon?: () => void;
  ref?: LegacyRef<InputMask>;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
};

const Input = ({
  id,
  type,
  mask,
  maskChar = "_",
  label,
  padding,
  paddingErrorMessage,
  hasError,
  hasSuccessfully,
  width,
  color,
  fontSize,
  borderRadius,
  borderWidth,
  hasIcon,
  autoComplete,
  margin,
  register,
  setValue,
  hasIconBorder = true,
  onChange,
  onBlur,
  required,
  pattern,
  minLength,
  maxLength,
  name,
  textError,
  textInfo,
  paddingInfo,
  max,
  onKeyUp,
  onKeyDown,
  inputMode,
  onMouseOver,
  height,
  onClickIcon,
  icon,
  backgroundColor,
  fontSizeInfo,
  colorInfo,
  cursor,
  disabled,
  borderColor,
  ref,
  value,
  ...props
}: InputProps) => {
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (type !== "tel") return;

    const input = e.target as HTMLInputElement;
    const { selectionStart } = input;
    const emptyInputWithMask = /^\(_{2}\) _{5}-_{4}$/;

    const hasAnyInputNumber =
      input.selectionStart !== 0 && emptyInputWithMask.test(input.value);

    if (hasAnyInputNumber) {
      input.setSelectionRange(1, 0);
    } else {
      const newPosition =
        selectionStart && selectionStart > 0 ? selectionStart : 0;
      input.setSelectionRange(newPosition, newPosition);
    }
  };

  return (
    <StyledInput
      hasSuccessfully={hasSuccessfully}
      hasError={hasError as unknown as boolean}
      width={width || "initial"}
      height={height}
      hasIcon={hasIcon}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={borderColor}
      padding={padding}
      margin={margin}
      color={color}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      hasIconBorder={hasIconBorder}
      {...buildTestIds(`form-styled-input-${id}`)}
      isDisabled={disabled}
    >
      {label && (
        <label htmlFor={id} {...buildTestIds(`form-input-label-${id}`)}>
          {label}
        </label>
      )}
      {mask ? (
        <>
          <InputContainer
            {...buildTestIds(`form-input-container-mask-${id}`)}
            width={width}
            hasIcon={hasIcon}
          >
            <InputMask
              {...buildTestIds(`form-input-mask-${id}`)}
              mask={mask}
              id={id}
              type={type || "text"}
              maskChar={maskChar}
              value={value}
              inputMode={inputMode}
              autoComplete={autoComplete}
              {...props}
              {...setValue}
              {...register(String(name), {
                required,
                pattern,
                minLength,
                maxLength,
              })}
              ref={ref}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onMouseOver={onMouseOver}
              onClick={handleClick}
            />
            {hasIcon && (
              <IconContainer
                cursor={cursor}
                hasSuccessfully={hasSuccessfully}
                margin={margin}
                hasError={hasError as unknown as boolean}
                onClick={onClickIcon}
              >
                {icon}
              </IconContainer>
            )}
          </InputContainer>
          {textError && hasError ? (
            <FieldError
              text={textError}
              padding={paddingErrorMessage}
              fontSize={fontSizeInfo}
              data-cy={`error-${id}`}
              data-testid={`form-field-error-input-${id}`}
            />
          ) : null}
          {textInfo && (
            <FieldInfo
              padding={paddingInfo}
              text={textInfo as string}
              fontSize={fontSizeInfo}
              color={colorInfo}
              data-cy={`info-${id}`}
              data-testid={`form-field-info-input-${id}`}
            />
          )}
        </>
      ) : (
        <>
          <InputContainer
            {...buildTestIds(`form-input-container-${id}`)}
            width={width}
            hasIcon={hasIcon}
          >
            <input
              autoComplete={autoComplete}
              height={height}
              id={id}
              value={value}
              {...buildTestIds(`form-input-${id}`)}
              type={type || "text"}
              inputMode={inputMode}
              {...props}
              {...setValue}
              {...register(String(name), {
                required,
                pattern,
                minLength,
                maxLength,
              })}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              maxLength={maxLength as number}
              max={max}
              disabled={disabled}
              onClick={handleClick}
            />
            {hasIcon && (
              <IconContainer
                cursor={cursor}
                hasSuccessfully={hasSuccessfully}
                margin={margin}
                hasError={hasError as unknown as boolean}
                onClick={onClickIcon}
              >
                {icon}
              </IconContainer>
            )}
          </InputContainer>

          {textError && hasError ? (
            <FieldError
              text={textError}
              data-cy={`error-${id}`}
              padding={paddingErrorMessage}
              fontSize={fontSizeInfo}
              data-testid={`form-field-error-input-${id}`}
            />
          ) : null}
          {textInfo && (
            <FieldInfo
              text={textInfo as string}
              data-cy={`info-${id}`}
              fontSize={fontSizeInfo}
              color={colorInfo}
              data-testid={`form-field-info-input-${id}`}
              padding={paddingInfo}
            />
          )}
        </>
      )}
    </StyledInput>
  );
};

export default Input;
