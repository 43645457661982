import {
  buildTestIds,
  calculateDiscountPercentage,
  handleProductTag,
  useAppContext,
} from "application";
import { appMonitoringClient, gtag } from "implementations";
import { useRouter } from "next/router";
import { memo } from "react";
import { GtagArgs, ListIdArgs, ProductCardDto } from "typing";
import { DefaultProductImageContainer, ImageProduct } from "./styles";

const getItemListId = ({
  isSearchPath,
  isExpandedPlaylistPath,
  playlistId,
  searchTerm,
}: ListIdArgs) => {
  return isSearchPath
    ? `search_${searchTerm}`
    : isExpandedPlaylistPath
    ? `expanded_playlist_${playlistId}`
    : `preview_playlist_${playlistId}`;
};

const getItemListValue = ({
  isSearchPath,
  playlistId,
  searchTerm,
}: ListIdArgs) => {
  return isSearchPath ? `${searchTerm}` : `${playlistId}`;
};

const useGetProductCardInfo = (
  productCard: ProductCardDto,
  isCardVertical: boolean,
  cardIndexInPlaylist = 0
) => {
  const { isClientMobile } = useAppContext();
  const router = useRouter();

  const isSearchPath = router?.pathname === "/pesquisa/[term]";
  const isExpandedPlaylistPath =
    router?.pathname === "/Destaque/[url]" ||
    router?.pathname === "/Categoria/[url]";
  const isProductScreen = router?.pathname?.includes("produto");

  const hideSecondBorderRightOnMobile = Number(cardIndexInPlaylist) % 2 === 0;

  const hasInstallments = productCard?.prices?.[0]?.maxNumberInstallments > 1;

  const productTagColor =
    productCard?.tag?.color || productCard?.tagProduct?.color;

  const productTagTitle =
    productCard?.tag?.title || productCard?.tagProduct?.title;

  const productCardVertical = Boolean(
    isCardVertical && isClientMobile && router.route !== "/pesquisa/[term]"
  );

  return {
    isSearchPath,
    isExpandedPlaylistPath,
    hideSecondBorderRightOnMobile,
    hasInstallments,
    productTagColor,
    productTagTitle,
    productCardVertical,
    isProductScreen,
  };
};

const handleSelectItemGtag = ({
  item,
  isSearchPath,
  searchTerm,
  playlistName,
  playlistId,
}: GtagArgs) => {
  const itemListId = getItemListId({
    isSearchPath,
    isExpandedPlaylistPath: isSearchPath,
    playlistId,
    searchTerm,
  });
  const itemListValue = getItemListValue({
    isSearchPath,
    isExpandedPlaylistPath: isSearchPath,
    playlistId,
    searchTerm,
  });

  if (!item) {
    appMonitoringClient.captureMessage("Nenhum produto foi retornado.");
  }

  handleProductTag(
    item?.id,
    item?.tag || item?.tagProduct,
    appMonitoringClient
  );

  return () => {
    if (!item) {
      return;
    }

    const { id, description, prices } = item;

    const discountValue = calculateDiscountPercentage(
      prices?.[0]?.discountPercentage
    );
    const itemListName = isSearchPath
      ? (searchTerm as string)
      : (playlistName as string);

    gtag.selectItem(
      prices?.[0]?.spotPrice || prices?.[0]?.salePrice,
      {
        itemId: id,
        itemName: description,
        price: prices?.[0]?.spotPrice || prices?.[0]?.salePrice,
        itemListId,
        itemListName,
        itemListValue,
        index: item?.index || 0,
      },
      discountValue,
      "BRL"
    );
  };
};

interface DynamicProductCardImageProps {
  imageUrl: string;
  description: string;
  preloadComponent: boolean;
}

const DynamicProductCardImage = memo(
  ({
    imageUrl,
    description,
    preloadComponent,
  }: DynamicProductCardImageProps) => {
    return imageUrl ? (
      <ImageProduct
        {...buildTestIds("product-card-dynamic-image")}
        src={imageUrl}
        alt={description}
        width={170}
        height={170}
        objectFit="contain"
        priority={preloadComponent}
      />
    ) : (
      <DefaultProductImageContainer />
    );
  }
);

DynamicProductCardImage.displayName = "DynamicProductCardImage";

export { DynamicProductCardImage, handleSelectItemGtag, useGetProductCardInfo };
