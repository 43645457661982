import styled from "styled-components";
import { theme } from "ui";

interface DividerProps {
  isWithShadow?: boolean;
  isWithoutMargin?: boolean;
  marginLeft?: string;
  isClientTablet?: boolean;
}

const shadow =
  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";

export const Divider = styled.div<DividerProps>`
  z-index: 1;
  position: initial;
  background-color: ${theme.colors.neutral["100"]};
  height: ${({ isClientTablet }) => isClientTablet && "1px"};
  width: ${({ isClientTablet }) => (!isClientTablet ? "1px" : "100%")};
  margin-top: ${({ isWithoutMargin }) => (isWithoutMargin ? 0 : "0.8rem")};
  margin-left: ${({ marginLeft }) => marginLeft ?? "initial"};
  box-shadow: ${({ isWithShadow }) => (isWithShadow ? shadow : 0)};

  @media ${theme.device.desktopFirst.mobileL} {
    margin-top: ${({ isWithoutMargin }) => (isWithoutMargin ? 0 : "0.35rem")};
  }

  @media ${theme.device.desktopFirst.mobileM} {
    margin-top: ${({ isWithoutMargin }) => (isWithoutMargin ? 0 : "0.35rem")};
  }

  @media ${theme.device.desktopFirst.mobileS} {
    margin-top: ${({ isWithoutMargin }) => (isWithoutMargin ? 0 : "0.35rem")};
  }
`;

export const VerticalDivider = styled.div<Pick<DividerProps, "isWithShadow">>`
  z-index: 1;
  position: initial;
  background-color: ${theme.colors.neutral["100"]};
  height: 100%;
  width: 1px;
  box-shadow: ${({ isWithShadow }) => (isWithShadow ? shadow : 0)};
`;

export const HorizontalDivider = styled.div<Pick<DividerProps, "isWithShadow">>`
  z-index: 1;
  position: initial;
  background-color: ${theme.colors.neutral["100"]};
  height: 1px;
  width: 100%;
  box-shadow: ${({ isWithShadow }) => (isWithShadow ? shadow : 0)};
`;
