import { SendTokenResponseDto } from "typing";
import { notificationApi } from "implementations";
import { useAuth, useNotification, useToast } from "application";
import { useCallback } from "react";
import { getMinutesAndSeconds } from "application/utils/validations/secondsCounter";
import { SendCodeAgainContainer } from "./styles";

interface SendCodeAgainProps {
  typeSMS: 1 | 2 | 3;
  color?: string;
  fontSize?: string;
  onSendCodeAgain?: (value: boolean) => void;
}

const SendCodeAgain = ({
  typeSMS,
  color,
  fontSize,
  onSendCodeAgain,
}: SendCodeAgainProps) => {
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token as string;
  const { addToast } = useToast();
  const { initializeValidationCounter } = useNotification();

  const handleSendCodeAgain = useCallback(() => {
    const onSendSMS = async (type: 1 | 2 | 3) => {
      const response = (await notificationApi.sendTwoFactorSMS(
        type,
        accessToken
      )) as unknown as SendTokenResponseDto;

      if (response?.validationResult?.errors?.[0]?.errorCode === "666") {
        const remainingTime = getMinutesAndSeconds(response?.retryTokenDate);
        initializeValidationCounter(remainingTime);
        return;
      }
      initializeValidationCounter();
    };

    if (onSendCodeAgain) {
      onSendCodeAgain(true);
    }

    onSendSMS(typeSMS);
    addToast({ title: "Código reenviado!", type: "success" });
  }, [onSendCodeAgain, typeSMS, addToast, accessToken]);

  return (
    <SendCodeAgainContainer
      color={color}
      fontSize={fontSize}
      onClick={handleSendCodeAgain}
    >
      Reenviar código
    </SendCodeAgainContainer>
  );
};

export { SendCodeAgain };
