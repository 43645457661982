import { cookie } from "implementations";
import { CustomPageSource, PlaylistUrlType } from "typing";

const mapPageSourceToUrlType = (source: CustomPageSource): PlaylistUrlType => {
  return {
    category: "Category",
    highlight: "Highlight",
  }[`${source}`] as PlaylistUrlType;
};

const identifier = "filterProducts";

const cookiesKey = `currentPage_${identifier}`;

const tabletSkeletonCardsGrid = "1fr 1fr 1fr";

const options = [
  { value: " ", label: "Mais relevante" },
  { value: "1", label: "Maiores preços" },
  { value: "0", label: "Menores preços" },
];

const pageUrlList = [
  "/Destaque/liquida",
  "/Destaque/construcao",
  "/Categoria/moveis-acessorios",
  "/Categoria/ar-ventilacao",
  "/Categoria/eletrodomesticos-acessorios",
  "/Categoria/ferramentas-e-epi",
  "/Categoria/automotivo",
  "/Categoria/utilidades-domesticas",
  "/Categoria/eletroportateis",
  "/Destaque/iluminacao-categoria",
  "/Destaque/outlet-rec",
];

const getItemListName = (
  title: string,
  term: string,
  pathname: string
): string => {
  if (pathname === "/pesquisa/[term]") {
    return `${term}`;
  }
  return `${title}`;
};

const getItemListId = (
  term: string,
  idInfo: string,
  pathname: string
): string => {
  if (pathname === "/pesquisa/[term]") {
    return `search_${term}`;
  }
  if (pathname === "/Destaque/[url]" || pathname === "/Categoria/[url]") {
    return `expanded_playlist_${idInfo}`;
  }
  return `preview_playlist_${idInfo}`;
};

const setCookiesItem = (value: string, name = cookiesKey) => {
  cookie.setCookie({ name, value });
};

export {
  cookiesKey,
  getItemListId,
  getItemListName,
  identifier,
  mapPageSourceToUrlType,
  options,
  pageUrlList,
  setCookiesItem,
  tabletSkeletonCardsGrid,
};
