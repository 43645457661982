export const breakpoints = <const>{
  mobile: {
    S: "320px",
    M: "375px",
    L: "425px",
    XL: "480px",
  },
  tablet: {
    XS: "560px",
    SM: "576px",
    MD: "600px",
    LG: "678px",
    XL: "700px",
    "2XL": "768px",
    "3XL": "826px",
    "4XL": "850px",
  },
  laptop: {
    XS: "900px",
    SM: "1024px",
    M: "1330px",
    L: "1440px",
    XL: "1600px",
  },
  desktop: {
    S: "1980px",
    M: "2560px",
  },
};
