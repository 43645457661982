export const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      return;
    }

    if (!navigator.geolocation) {
      reject(new Error("Geolocalização não é suportada pelo seu navegador."));
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        return resolve({
          latitude: position?.coords?.latitude,
          longitude: position?.coords?.longitude,
        });
      },
      (error) => {
        return resolve({
          latitude: "",
          longitude: "",
          message: error.message,
          code: error.code,
        });
      }
    );
  });
};
