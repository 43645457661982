import { buildTestIds, replaceImageBaseUrl } from "application";
import { Image as CustomImage } from "infra";

declare type ImgElementStyle = NonNullable<
  JSX.IntrinsicElements["img"]["style"]
>;

export interface ImageProps {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  layout?: "intrinsic" | "fixed" | "responsive" | "fill";
  objectFit?: ImgElementStyle["objectFit"];
  objectPosition?: ImgElementStyle["objectPosition"];
  priority?: boolean;
  sizes?: string;
  style?: object;
  loading?: "lazy" | "eager";
}

const Image = ({ src, alt, ...props }: ImageProps) => {
  const formattedImageSrc = replaceImageBaseUrl(src);

  return (
    <CustomImage
      {...buildTestIds(`image-${alt}`)}
      src={formattedImageSrc}
      alt={alt}
      aria-label={alt}
      {...props}
      quality={100}
    />
  );
};

export { Image };
