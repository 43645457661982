import { DigitalOrderDto, DigitalProduct, StoreOrderDto } from "typing";
import { getBranchName } from "../getBranchName";

export function convertStoreOrderToDigital(
  source: StoreOrderDto
): DigitalOrderDto {
  return {
    client: null,
    groupId: 0,
    saleChannel: 1,
    hasTaxDocument: source?.hasTaxDocument,
    payments: source.payments.map((payment) => ({
      card: null,
      installments: 0,
      installmentValue: 0,
      orderId: source.orderId,
      paymentType: payment.paymentType,
      totalValue: payment.totalValue,
    })),
    packages: source.packages.map((pkg) => ({
      branchId: source.branchId,
      canCancel: false,
      carrierId: 0,
      createdOn: source.createdOn,
      faturadoOn: source.createdOn,
      isActiveIntelipost: Boolean(pkg.orderRemoteId),
      isActiveSode: false,
      isGift: false,
      isInStorePayment: true,
      lastUpdateOn: source.createdOn,
      totalPrice: pkg.totalValue,
      totalValue: pkg.totalValue,
      orderItems: pkg.product.map(
        (item) =>
          ({
            descriptionPackagingUnitPlural: item.descriptionPackagingUnitPlural,
            descriptionPackagingUnitSingular:
              item.descriptionPackagingUnitSingular,
            id: item.productId,
            isPickUpInStore: false,
            measuredUnity: item.measuredUnity,
            mediaLink: [
              {
                defaultImage: item.imageUrl,
                description: item.description,
                imageUrl: item.imageUrl,
                videoUrl: "",
              },
            ],
            packaging: 0,
            packagingQuantity: Number(item.packagingQuantity),
            price: item.price,
            quantity: item.quantity,
            branchIdForPickup: pkg.branchIdForNfe,
            productId: item.productId,
            description: item.description,
            paintColor: item.paintColor,
            codeColor: item.codeColor,
          } as DigitalProduct)
      ),
      branchIdForNfe: pkg.branchIdForNfe,
      deliveryDate: source.createdOn,
      detailDescriptionStatus: `Compra realizada na unidade ${getBranchName(
        source.branchId
      )}`,
      forecastDateDescription: "",
      omniChannelId: 0,
      ordertHash: "",
      id: Number(pkg.packageId),
      orderId: source.orderId,
      statusForShow: "Entregue",
      trackingStatus: "Entregue",
      weddingList: null,
      sequential: 0,
      orderRemoteId: pkg.orderRemoteId,
      status: {
        currentStatus: "Entregue",
      },
    })),
  } as DigitalOrderDto;
}
