import { ICookie } from "app-domain";
import { IApi } from "app-domain/abstractions/apis/IApi";
import { AllConfigs, IHttpResponse } from "typing";

class EnvsApi implements IApi {
  baseUrl: string;

  allConfigs: AllConfigs | null = null;

  constructor(private cookie: ICookie, preloadAllConfigs = false) {
    this.baseUrl = `/api/envs`;

    if (preloadAllConfigs) {
      this.getAllConfigs();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setBaseUrl(url: string): void {
    // eslint-disable-next-line no-console
    console.error(`Method not implemented ${url ?? ""}`);
  }

  private getEnvEndpoint = (envKey: string) => {
    return `${this.baseUrl}?envKey=${envKey}`;
  };

  getEnv = async (envKey: string): Promise<IHttpResponse<string, unknown>> => {
    try {
      const response = await fetch(this.getEnvEndpoint(envKey), {
        method: "GET",
      });

      return (await response.json()) as unknown as IHttpResponse<
        string,
        unknown
      >;
    } catch {
      return {
        data: null,
        isLoading: false,
        error: null,
      };
    }
  };

  getAwaitAllConfigs = async (
    timeout = 250,
    retryTimes = 0
  ): Promise<AllConfigs | null> => {
    if (!this.allConfigs && retryTimes < 30) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.getAwaitAllConfigs(timeout, retryTimes + 1));
        }, timeout);
      });
    }

    return this.allConfigs;
  };

  getAllConfigs = async (): Promise<IHttpResponse<AllConfigs, unknown>> => {
    if (this.allConfigs) {
      return {
        data: this.allConfigs,
        isLoading: false,
        error: null,
      };
    }

    try {
      const response = await fetch(`${this.baseUrl}/configs`, {
        method: "GET",
      });

      const allConfigsResponse =
        (await response.json()) as unknown as IHttpResponse<
          AllConfigs,
          unknown
        >;

      const { data = null } = allConfigsResponse;

      this.allConfigs = data;

      return allConfigsResponse;
    } catch {
      return {
        data: null,
        isLoading: false,
        error: null,
      };
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getRawEnv = async (
    envKey: string
  ): Promise<IHttpResponse<string, unknown>> => {
    const envVariable = process.env[`${envKey}`];

    return Promise.resolve({
      data: envVariable,
      error: null,
      isLoading: false,
    });

    // try {
    //   const response = await fetch(this.getEnvEndpoint(envKey), {
    //     method: "GET",
    //   });
    //   return response.json() as unknown as IHttpResponse<string, unknown>;
    // } catch {
    //   return null;
    // }
  };

  getRawEnvImmediately = (envKey: string): IHttpResponse<string, unknown> => {
    if (process?.browser) {
      // const data =
      //   (document.getElementById("apisBaseUrl") as HTMLInputElement)?.value ||
      //   "";

      let data = this.cookie.getCookie({
        name: `@FC:Ecom:${envKey}`,
      });

      if (!data && envKey === "APIS_BASE_URL") {
        data = process.env.NEXT_PUBLIC_APIS_BASE_URL;
      }

      return {
        data,
        isLoading: false,
        error: null,
      };
    }

    const rawEnv = process.env[`${envKey}`];

    return {
      data: rawEnv,
      isLoading: false,
      error: null,
    };
  };
}

export { EnvsApi };
