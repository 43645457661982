const getPhoneNumberInvalidInformation = () => {
  return {
    title: "Número de telefone inválido",
    description:
      "Detectamos que o número de telefone da sua conta não segue o formato correto para o envio do código de verificação. Para que você possa concluir a alteração de senha, pedimos que entre em contato com o SAC para atualizar seu número de telefone para o formato (XX) XXXXX-XXXX. Assim que o número for registrado corretamente, você poderá fazer a verificação via SMS.",
    buttonText: "Alterar telefone",
    buttonAction: "https://ferreiracosta.movidesk.com/kb/",
  };
};

export { getPhoneNumberInvalidInformation };
