type ValidatePhoneNumberResponse =
  | ""
  | "O campo celular é obrigatório!"
  | "O telefone precisa ter 11 dígitos (xx) x xxxx-xxxx"
  | "O telefone precisa ser diferente do atual cadastrado"
  | "O telefone é inválido!";

const isInvalidAreaCode = (phoneNumberAreaCode: string) => {
  return !phoneNumberAreaCode?.match(/(1[1-9])|([2-9][0-9])/);
};

const isRepeatingSameNumberManyTimes = (phoneNumber: string) => {
  return /(1{8})|(2{8})|(3{8})|(4{8})|(5{8})|(6{8})|(7{8})|(8{8})|(9{8})/.test(
    phoneNumber
  );
};

const isNonexistentPhoneNumber = (phoneNumber: string) => {
  return [1, 2, 3, 4].indexOf(Number(phoneNumber?.[3])) > -1;
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber?.replace(/\D/g, "");
};

export const validatePhoneNumber = (
  phoneNumber: string | undefined,
  currentPhoneNumber?: string
): ValidatePhoneNumberResponse => {
  const blank = "";
  const phoneNumberStatus = {
    phoneNumberIsRequired: "O campo celular é obrigatório!",
    phoneNumberIsRequiredElevenDigits:
      "O telefone precisa ter 11 dígitos (xx) x xxxx-xxxx",
    phoneNumberIsInvalid: "O telefone é inválido!",
    phoneNumberIsTheSame:
      "O telefone precisa ser diferente do atual cadastrado",
  } as const;

  if (!phoneNumber) return phoneNumberStatus.phoneNumberIsRequired;

  if (typeof phoneNumber !== "string")
    return phoneNumberStatus.phoneNumberIsInvalid;

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  if (formattedPhoneNumber.length !== 11) {
    return phoneNumberStatus.phoneNumberIsRequiredElevenDigits;
  }

  const phoneNumberIncludesLetters = formattedPhoneNumber?.match(/[a-zA-Z]/);

  const isPhoneNumberInvalid =
    (!formattedPhoneNumber?.match(/\(\d{2}\)\s\d{5}-\d{4}/) &&
      !formattedPhoneNumber?.match(/\d{11}/)) ||
    isInvalidAreaCode(formattedPhoneNumber?.substring(0, 2)) ||
    isRepeatingSameNumberManyTimes(formattedPhoneNumber) ||
    isNonexistentPhoneNumber(formattedPhoneNumber) ||
    phoneNumberIncludesLetters;

  if (isPhoneNumberInvalid) {
    return phoneNumberStatus.phoneNumberIsInvalid;
  }

  if (phoneNumber.replace(/\D/g, "") === currentPhoneNumber) {
    return phoneNumberStatus.phoneNumberIsTheSame;
  }

  return blank;
};
