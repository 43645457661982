import styled from "styled-components";
import { theme } from "ui";

export const StyledDatePickerWrapper = styled.div`
  font-family: ${theme.typography.fontFamily.chivo};

  .react-datepicker__current-month {
    font-size: ${theme.typography.fontSize.sm.x7};
    font-weight: ${theme.typography.fontWeight["700"]};
  }

  .react-datepicker__day-names {
    margin-top: ${theme.space.x2};
  }

  .react-datepicker__day-name {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${theme.space.x6};
    height: ${theme.space.x6};
    line-height: ${theme.space.x6};
    font-weight: ${theme.typography.fontWeight["400"]};
    color: ${theme.colors.neutral["500"]};
    font-size: ${theme.typography.fontSize.sm.x4};
  }

  .react-datepicker__day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    width: ${theme.space.x6};
    height: ${theme.space.x6};
    line-height: ${theme.space.x6};
    font-size: ${theme.typography.fontSize.sm.x4};
    transition: background-color 0.2s ease, transform 0.2s ease;
  }

  .react-datepicker__day--today {
    background-color: ${theme.colors.secondary["33"]};
    font-weight: ${theme.typography.fontWeight["500"]};
  }

  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today) {
    background-color: transparent;
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.secondary["300"]};
    color: ${theme.colors.neutral.white};
    transform: scale(1.1);
  }

  .react-datepicker__header {
    text-align: center;
    display: block;
    background-color: ${theme.colors.neutral["white-050"]};
    padding: ${theme.space["x2.5"]} ${theme.space.x0};
    border-bottom: 1px solid ${theme.colors.neutral["300"]};
    font-size: ${theme.typography.fontSize.sm.x4};
    margin-bottom: ${theme.space["x2.5"]};
  }

  .react-datepicker__day--selected {
    background-color: ${theme.colors.secondary["300"]};
    color: ${theme.colors.secondary["33"]};
    font-weight: ${theme.typography.fontWeight.bold};
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    font-family: ${theme.typography.fontFamily.chivo};
    font-size: ${theme.typography.fontSize.sm.x4};
    color: ${theme.colors.neutral["700"]};
    background-color: ${theme.colors.neutral["white-050"]};
    border: none;
    border-radius: 4px;
    padding: ${theme.space.x1};
    width: 100%;
    max-width: 120px;
    cursor: pointer;
    margin-top: ${theme.space.x2};
  }

  .react-datepicker__month-select option,
  .react-datepicker__year-select option {
    font-size: ${theme.typography.fontSize.sm.x4};
    color: ${theme.colors.neutral["600"]};
  }

  .react-datepicker__month-select:hover,
  .react-datepicker__year-select:hover {
    border-color: ${theme.colors.primary["300"]};
  }
`;
