import { useEffect, useState } from "react";
import { useNotification } from "application";
import { Countdown } from "./styles";

interface CodeExpirationProps {
  codeCountdown?: (time: number) => void;
}

const CodeExpiration = ({ codeCountdown }: CodeExpirationProps) => {
  const { counterTimeContext } = useNotification();

  const [codeTimeout, setCodeTimeOut] = useState<number>(0);
  const seconds = codeTimeout % 60;
  const minutes = Math.floor(codeTimeout / 60);

  useEffect(() => {
    const idTime = setTimeout(() => {
      if (codeTimeout) {
        setCodeTimeOut(codeTimeout - 1);
      }
    }, 1000) as unknown as number;

    return () => clearTimeout(idTime);
  }, [codeTimeout]);

  useEffect(() => {
    if (codeCountdown) {
      codeCountdown(codeTimeout);
    }
  }, [codeTimeout, codeCountdown]);

  useEffect(() => {
    if (counterTimeContext) {
      setCodeTimeOut(counterTimeContext);
    }
  }, [counterTimeContext]);

  return (
    <Countdown>
      Reenviar código em {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </Countdown>
  );
};

export { CodeExpiration };
