import {
  useRegisterPhoneNumber,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { useRouter } from "next/router";
import { TelephoneAuthenticationIcon, theme } from "ui";
import { useCallback, useEffect } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import {
  FATypeValues,
  IFormValues,
  IResponsiveModalContent,
  SendTokenResponseDto,
} from "typing";
import { notificationApi } from "implementations";
import { useAppContext, useToast } from "application";
import { InputProps } from "../../Form/Input";

const useRegisterPhoneNumberData = () => {
  const { isClientMobile } = useAppContext();
  const {
    changeCurrentStep,
    setPhoneNumber,
    phoneNumber,
    isRegisterPhoneNumberModalOpen,
  } = useRegisterPhoneNumber(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
      setPhoneNumber: state.setPhoneNumber,
      isRegisterPhoneNumberModalOpen: state.isRegisterPhoneNumberModalOpen,
      phoneNumber: state.phoneNumber,
    }))
  );

  const {
    setIsValidationActive,
    setChannelSelected,
    setFaType,
    setIsBlockedToSendCode,
  } = useTwoFactor(
    useShallow((state) => ({
      setIsValidationActive: state.setIsValidationActive,
      setChannelSelected: state.setChannelSelected,
      setFaType: state.setFaType,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
    }))
  );

  const router = useRouter();

  const { addToast } = useToast();

  const onPrimaryButtonClick = async () => {
    const response = (await notificationApi.sendTokenForSMS(
      FATypeValues.AccountUpdatePhone2FA,
      phoneNumber?.replace(/\D/g, "")
    )) as unknown as SendTokenResponseDto;

    if (
      response?.validationResult?.isValid ||
      response?.validationResult?.errors?.[0]?.errorCode === "666"
    ) {
      setIsBlockedToSendCode(false);
      changeCurrentStep("validation");
      setIsValidationActive(true);
      setChannelSelected(1);
      setFaType(FATypeValues.AccountUpdatePhone2FA);
      return;
    }

    if (response?.validationResult?.errors?.[0]?.errorCode === "222") {
      setIsBlockedToSendCode(true);
      setIsValidationActive(true);
      changeCurrentStep("validation");
      return;
    }

    addToast({
      title: "Telefone inválido",
      description: response?.validationResult?.errors?.[0]?.errorMessage,
      isNewToast: true,
      type: "error",
      newToastTheme: "dark",
    });
  };

  const focusOnInput = useCallback(() => {
    const input = document.querySelector<HTMLInputElement>("#register-phone");

    if (input) {
      input.focus();
      input.setSelectionRange(0, 0);
    }
  }, []);

  useEffect(() => {
    if (isRegisterPhoneNumberModalOpen) {
      focusOnInput();
    }
  }, [focusOnInput, isRegisterPhoneNumberModalOpen]);

  const { register } = useForm<IFormValues>();

  const isCheckout = router?.pathname?.includes("pagamento");

  const handleInputChange = (e: { target: { value: string } }) => {
    const input = e.target.value.replace(/\D/g, "");

    setPhoneNumber(input);
  };

  const getRegisterPhoneNumberData = (): IResponsiveModalContent &
    InputProps => {
    return {
      hasInput: true,
      id: "register-phone",
      margin: `${theme.space.x4} 0`,
      type: "tel",
      name: "register-phone",
      onChange: handleInputChange,
      value: phoneNumber,
      primaryButtonIsDisabled:
        String(phoneNumber?.replace(/\D/g, ""))?.length < 11,
      onPrimaryButtonClick,
      register: register as unknown as UseFormRegister<IFormValues>,
      mask: "(99) 99999-9999",
      width: "489px !important",
      borderRadius: "4px",
      title: isCheckout
        ? "Para finalizar a compra, é preciso cadastrar e validar seu telefone"
        : "Sua conta ainda não possui um telefone cadastrado",
      description: isCheckout
        ? "Digite seu telefone"
        : "Cadastre e valide seu telefone para ter uma conta mais segura.",
      primaryButtonLabel: !isClientMobile ? "Continuar" : undefined,
      image: <TelephoneAuthenticationIcon />,
      onSecondaryButtonClick: () => changeCurrentStep("validation"),
    };
  };

  return { getRegisterPhoneNumberData, onPrimaryButtonClick };
};

export { useRegisterPhoneNumberData };
