import { useEffect, useRef, useState } from "react";

interface UseScrollVisibilityOptions {
  timeout?: number;
  showInitially?: boolean;
}

/**
 * Hook personalizado para controlar a visibilidade da barra de rolagem
 * @param options Opções de configuração
 * @param options.timeout Tempo em ms para ocultar a barra após inatividade (padrão: 1500ms)
 * @param options.showInitially Se a barra deve estar visível inicialmente (padrão: false)
 * @returns Um objeto com as propriedades e refs necessárias para implementar o comportamento
 */
export const useScrollVisibility = (options?: UseScrollVisibilityOptions) => {
  const { timeout = 1500, showInitially = false } = options || {};

  const [isScrolling, setIsScrolling] = useState(showInitially);
  const scrollTimerRef = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      // Ativa o estado de rolagem
      setIsScrolling(true);

      // Limpa o timer anterior se existir
      if (scrollTimerRef.current !== null) {
        window.clearTimeout(scrollTimerRef.current);
      }

      // Define um novo timer para desativar o estado de rolagem após o tempo definido
      scrollTimerRef.current = window.setTimeout(() => {
        setIsScrolling(false);
      }, timeout);
    };

    // Adiciona evento de scroll ao container
    container.addEventListener("scroll", handleScroll);

    // Função de limpeza para remover listeners e timers quando o componente for desmontado
    // eslint-disable-next-line consistent-return
    return () => {
      container.removeEventListener("scroll", handleScroll);
      if (scrollTimerRef.current !== null) {
        window.clearTimeout(scrollTimerRef.current);
      }
    };
  }, [timeout]);

  return {
    isScrolling,
    containerRef,
  };
};
