import { IconProps } from "typing";
import { theme } from "../themes";

export const OthersPayments = ({
  width = 24,
  height = 24,
  alt = "Outros pagamentos",
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 19.4783L12 20.4783L9.5 19.4783L7 20.4783V7.47827H17V20.4783L14.5 19.4783Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.4783H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.4783H10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.4783H13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 11.4783H19C20.105 11.4783 21 10.5833 21 9.47827V5.47827C21 4.37327 20.105 3.47827 19 3.47827H5C3.895 3.47827 3 4.37327 3 5.47827V9.47827C3 10.5833 3.895 11.4783 5 11.4783H7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
