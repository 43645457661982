import { NewOrdersDto } from "typing";

export function filterStoreOrders(
  source: NewOrdersDto | null | undefined,
  isUniqueClientAvailable: boolean
) {
  if (!isUniqueClientAvailable) {
    const reliableOrders = source?.items?.filter(
      (item) => item?.saleChannel !== 1
    );

    const storeOrders = source?.items?.filter(
      (item) => item?.saleChannel === 1
    );

    const totalResults = source?.totalResults || 0;
    const storeOrdersLength = storeOrders?.length || 0;
    return {
      ...source,
      items: reliableOrders,
      totalResults: totalResults - storeOrdersLength,
    } as NewOrdersDto;
  }

  return source;
}
