const getSaleChannel = (saleChannel?: number) => {
  if (saleChannel === 1) {
    return "loja";
  }

  if (saleChannel === 2) {
    return "site";
  }

  if (saleChannel === 4) {
    return "app";
  }

  return "site";
};

export { getSaleChannel };
