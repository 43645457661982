import { breakpoints } from "../tokens";

export const getDevices = (mobileFirst = false) => {
  const mobileFirstAsString = mobileFirst ? "min-width" : "max-width";

  return {
    mobileS: `(${mobileFirstAsString}: ${breakpoints.mobile.S})`,
    mobileM: `(${mobileFirstAsString}: ${breakpoints.mobile.M})`,
    mobileL: `(${mobileFirstAsString}: ${breakpoints.mobile.L})`,
    mobileXL: `(${mobileFirstAsString}: ${breakpoints.mobile.XL})`,
    tabletXS: `(${mobileFirstAsString}: ${breakpoints.tablet.XS})`,
    tabletSM: `(${mobileFirstAsString}: ${breakpoints.tablet.SM})`,
    tabletMD: `(${mobileFirstAsString}: ${breakpoints.tablet.MD})`,
    tabletLG: `(${mobileFirstAsString}: ${breakpoints.tablet.LG})`,
    tabletXL: `(${mobileFirstAsString}: ${breakpoints.tablet.XL})`,
    tablet2XL: `(${mobileFirstAsString}: ${breakpoints.tablet["2XL"]})`,
    tablet3XL: `(${mobileFirstAsString}: ${breakpoints.tablet["3XL"]})`,
    tablet4XL: `(${mobileFirstAsString}: ${breakpoints.tablet["4XL"]})`,
    laptopXS: `(${mobileFirstAsString}: ${breakpoints.laptop.XS})`,
    laptopSM: `(${mobileFirstAsString}: ${breakpoints.laptop.SM})`,
    laptopM: `(${mobileFirstAsString}: ${breakpoints.laptop.M})`,
    laptopL: `(${mobileFirstAsString}: ${breakpoints.laptop.L})`,
    laptopXL: `(${mobileFirstAsString}: ${breakpoints.laptop.XL})`,
    desktopS: `(${mobileFirstAsString}: ${breakpoints.desktop.S})`,
    desktopM: `(${mobileFirstAsString}: ${breakpoints.desktop.M})`,
  };
};
