import { buildTestIds, useToast } from "application";
import { FATypeValues } from "typing";
import {
  useAuthentication as defaultUseAuthentication,
  useTwoFactor as defaultUseTwoFactor,
  useShallow,
} from "application/state-manager";
import { notificationApi } from "implementations";
import { useRouter as defaultUseRouter } from "next/router";
import { useCallback, useState } from "react";
import { TwoFactorContent } from "../../../../../2fa";
import { methods } from "../ChooseAnotherMethod/data";
import {
  ArrowLeftIcon,
  ContentInformation,
  TwoFactorContainer,
  VerifyCodeText,
  WrapperIcon,
} from "./styles";

interface VerifyCodeProps {
  useAuthentication?: typeof defaultUseAuthentication;
  useTwoFactor?: typeof defaultUseTwoFactor;
  useRouter?: typeof defaultUseRouter;
}

const VerifyCode = ({
  useAuthentication = defaultUseAuthentication,
  useTwoFactor = defaultUseTwoFactor,
  useRouter = defaultUseRouter,
}: VerifyCodeProps) => {
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const router = useRouter();
  const { changeCurrentStep, userIdentification, identificationType } =
    useAuthentication(
      useShallow((state) => ({
        changeCurrentStep: state.changeCurrentStep,
        userIdentification: state.userIdentification,
        identificationType: state.identificationType,
      }))
    );

  const {
    channelSelected,
    availableMethods,
    isBlockedToSendCode,
    tokenCode,
    setIsTokenCodeValid,
    setValidateCode,
    clearTimer,
    setChannelSelected,
  } = useTwoFactor(
    useShallow((state) => ({
      channelSelected: state.channelSelected,
      availableMethods: state.availableMethods,
      isBlockedToSendCode: state.isBlockedToSendCode,
      tokenCode: state.tokenCode,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      setValidateCode: state.setValidateCode,
      isValidationActive: state.isValidationActive,
      clearTimer: state.clearTimer,
      setChannelSelected: state.setChannelSelected,
    }))
  );

  const { addToast } = useToast();

  const handleCodeValidation = useCallback(
    async (token: string, identifier: string) => {
      try {
        setIsValidatingCode(true);
        setChannelSelected(channelSelected);

        const handleValidation = async (
          location: string,
          hasLocation?: boolean
        ) => {
          const { isValid, errors } =
            await notificationApi.validateTwoFactorCodePublic({
              email: identificationType === "email" ? identifier : "",
              cpf: identificationType === "cpf" ? identifier : "",
              channel: channelSelected,
              faType: FATypeValues.RecoverAccount2FA,
              token,
              phoneNumber: "",
              location: hasLocation ? location : "",
              needsToLog: true,
            });

          if (!isValid && errors?.ErrorCodes?.includes("222")) {
            setIsTokenCodeValid(false);
            setValidateCode(true);

            clearTimer(FATypeValues.RecoverAccount2FA, channelSelected, true);
            return;
          }

          if (!isValid && !errors?.ErrorCodes?.includes("222")) {
            setIsTokenCodeValid(false);
            setValidateCode(true);
            addToast({
              type: "error",
              isNewToast: true,
              newToastTheme: "light",
              title: `Erro ao validar código`,
              description: errors?.Messages?.[0],
            });
            return;
          }

          clearTimer(FATypeValues.RecoverAccount2FA, channelSelected);
          setIsTokenCodeValid(true);

          changeCurrentStep("changePassword");
        };

        navigator?.geolocation?.getCurrentPosition(
          async (position) => {
            handleValidation(
              `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`,
              true
            );
          },
          async () => {
            handleValidation("");
          }
        );
      } finally {
        setIsValidatingCode(false);
      }
    },
    [
      setChannelSelected,
      identificationType,
      channelSelected,
      clearTimer,
      setIsTokenCodeValid,
      changeCurrentStep,
      setValidateCode,
      addToast,
    ]
  );

  const handleSelectAnotherTwoFactorType = () => {
    changeCurrentStep("verifyIdentity");
  };

  const getTitle = () => {
    if (isBlockedToSendCode) {
      return "Você está bloqueado";
    }

    if (router?.pathname === "/login") {
      return "Verificação de identidade";
    }

    return "Digite o código";
  };

  const shouldShowChooseAnotherTwoFactorMethod =
    (availableMethods?.emailConfirmed &&
      availableMethods?.phoneNumberConfirmed) ||
    (!availableMethods?.emailConfirmed &&
      !availableMethods?.phoneNumberConfirmed);

  return (
    <TwoFactorContainer {...buildTestIds("two-factor-container")}>
      <ContentInformation {...buildTestIds("content-information")}>
        {router?.pathname !== "/login" && (
          <WrapperIcon
            onClick={() => changeCurrentStep("login")}
            {...buildTestIds("back-button")}
          >
            <ArrowLeftIcon />
          </WrapperIcon>
        )}
        <VerifyCodeText {...buildTestIds("verify-code-text")}>
          {getTitle()}
        </VerifyCodeText>
      </ContentInformation>
      <TwoFactorContent
        options={methods(
          setChannelSelected,
          changeCurrentStep,
          {},
          {
            email: availableMethods?.emailConfirmed,
            sms: availableMethods?.phoneNumberConfirmed,
          }
        )}
        canChooseAnotherTwoFactorMethod={shouldShowChooseAnotherTwoFactorMethod}
        hasHeader={false}
        clientAction="forgotPassword"
        email={availableMethods?.email ?? userIdentification}
        hasError={isBlockedToSendCode}
        successTitle=""
        buttonHeight="45px"
        shouldMask={false}
        preventDefault
        buttonBorderRadius="8px"
        isUnderAction={isValidatingCode}
        onCallAction={() => {
          handleCodeValidation(tokenCode, userIdentification);
        }}
        phoneNumber={String(availableMethods?.phoneNumber)}
        viewType="component"
        sendCodeType={channelSelected === 1 ? "sms" : "email"}
        handleSelectAnotherTwoFactorType={handleSelectAnotherTwoFactorType}
        onClickIsInvalidClientInfo={() => changeCurrentStep("login")}
        verifyIdentityTitle="Verificação de identidade"
        verifyIdentityDescription="Para garantir a sua segurança, escolha um modo para autenticar a sua identidade."
      />
    </TwoFactorContainer>
  );
};

export { VerifyCode };
