export const getBranchName = (branchId: number | string) => {
  switch (Number(branchId)) {
    case 1:
      return "Garanhuns";
    case 2:
      return "Imbiribeira";
    case 3:
      return "Paralela";
    case 4:
      return "Tamarineira";
    case 5:
      return "Aracaju";
    case 6:
      return "João Pessoa";
    case 7:
      return "Ponta Negra";
    case 8:
      return "Caruaru";
    default:
      return "Imbiribeira";
  }
};
