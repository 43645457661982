import { ICrm } from "app-domain";
import {
  CrmDeliveryAddressObject,
  CrmTransactionObjectProps,
  CrmTransactionProductsListProps,
  OrderDetailsDto,
} from "typing";
import { CatalogApi, EnvsApi } from "../../services";
import { formatStringUrl } from "../../utils";

const useMapCrmTransactionObject = async (
  order: OrderDetailsDto | null | undefined,
  catalogApi: CatalogApi,
  crm: ICrm,
  envsApi: EnvsApi
) => {
  let firstRender = true;

  if (!order) return null;

  const orderBranchId = order?.packages?.at(0)?.branchId.toString() || "2";

  const transactionProducts = order?.packages?.map(
    (packageItem) => packageItem.orderItems[0]
  );

  const transactionProductIdsList = transactionProducts?.map(
    ({ productId }) => productId
  ) as string[];

  const productList = transactionProductIdsList?.join(",");

  const productListPromise = catalogApi.getProductListServerSide(
    productList,
    orderBranchId
  );

  const productsInfo = await Promise.all([productListPromise]).then(
    ([{ data }]) => data
  );

  if (!productsInfo?.length) return null;

  const productBreadcrumbs = productsInfo?.map(({ breadCrumbs }) =>
    Object.values(breadCrumbs)
      .reverse()
      .map(({ name }) => name)
  ) as unknown as string[];

  const orderInfo = order?.packages?.reduce(
    (accumulator, { shippingValue, totalPrice: totalPackagePrice }) => {
      const reliableShippingValue = shippingValue || 0;
      return {
        shippingTotal: accumulator.shippingTotal + (shippingValue || 0),
        totalPackagesPrice:
          accumulator.totalPackagesPrice + (totalPackagePrice || 0),
        totalPrice:
          accumulator.totalPrice + reliableShippingValue + totalPackagePrice,
      };
    },
    {
      shippingTotal: 0,
      totalPackagesPrice: 0,
      totalPrice: 0,
    }
  );

  const deliveryAddress = order?.client?.address;
  const deliveryObjectParams: CrmDeliveryAddressObject = {
    country: "BR",
    city: deliveryAddress?.city,
    district: deliveryAddress?.district,
  };

  const transactionProductsInfo = async (usedEnvsApi: EnvsApi) => {
    const { data: allConfigs } = await usedEnvsApi.getAllConfigs();

    return transactionProducts?.map((lineItem, index) => {
      const productUrl = `${allConfigs?.siteUrl}produto/${
        lineItem?.productId
      }/${formatStringUrl(lineItem?.description || "")}`;

      const productImage = lineItem?.mediaLink?.at(0)?.imageUrl;
      const productSubtotal = lineItem.price * lineItem.quantity;

      return {
        product: {
          id: `${lineItem?.productId}.${orderBranchId}`,
          name: lineItem?.description,
          taxonomy: productBreadcrumbs?.at(index),
          currency: "BRL",
          unit_price: lineItem?.price,
          unit_sale_price: lineItem?.price,
          url: productUrl,
          product_image_url: productImage,
        },
        quantity: lineItem?.quantity,
        subtotal: productSubtotal,
      };
    }) as unknown as CrmTransactionProductsListProps[];
  };

  const lineItems = await transactionProductsInfo(envsApi);

  const transactionObjectParams: CrmTransactionObjectProps = {
    order_id: order?.groupId?.toString(),
    currency: "BRL",
    total: Number(orderInfo?.totalPrice?.toFixed(2)),
    shipping_cost: Number(orderInfo?.shippingTotal?.toFixed(2)),
    delivery: deliveryObjectParams,
    line_items: lineItems,
    payment_type: order?.payments?.at(0)?.paymentType,
  };

  if (firstRender) {
    crm.transactionObject(transactionObjectParams);
    firstRender = false;
  }

  return null;
};

export { useMapCrmTransactionObject };
