import {
  CoupleNamesDto,
  ShippingBranchDto,
  WeddingListDtoFull,
  StatusGiftList,
} from "typing";

const getIsListInactive = (
  weddingList: CoupleNamesDto | WeddingListDtoFull | null | undefined
) => {
  const isOpenList = StatusGiftList.Open;
  return weddingList?.listStatusId !== isOpenList;
};

const sortingFavoritesStoresList = (
  array: ShippingBranchDto[] | null | undefined
) => {
  return array?.sort((a, b) => {
    const citiesList = a.city.localeCompare(b.city);

    if (citiesList === 0) {
      return a.district.localeCompare(b.district);
    }

    return citiesList;
  });
};

export { getIsListInactive, sortingFavoritesStoresList };
