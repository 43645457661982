import { ReactNode } from "react";

export type FilterOption = {
  id: string;
  text: string;
  component?: JSX.Element | null;
};

export interface NewFilterOption {
  name: string;
  value: string;
  component?: JSX.Element | null;
}

export type Filter = {
  id: number;
  title: string;
  icon: JSX.Element | ReactNode;
  options: FilterOption[];
};

export type FilterCollection = {
  column: Filter[];
};

export type FilterOptionsType = FilterCollection[];

export interface OptionsProps {
  filterOption: Filter;
  flexDirection?: string;
  justifyContent?: string;
  width?: string;
  height?: string;
  heightChecked?: string;
  widthChecked?: string;
  backgroundColor?: string;
}

// eslint-disable-next-line no-shadow
export enum FilterSessions {
  Status = 0,
  Date = 1,
  SaleChannel = 2,
  OrderType = 3,
}

export interface FilterSession {
  position: number;
  name: string;
  icon: ReactNode;
  filters: NewFilterOption[];
}

interface FilterComponentsProps {
  filterCollection: FilterSession[];
  isOpen: boolean;
  applyFilters: (isNewFilter?: boolean) => void;
  closeFilter: () => void;
  clearFilters: () => void;
  isNewFilterAvailable: boolean;
  selectedFilters: string[];
}

export type FilterModalProps = Omit<
  FilterComponentsProps,
  "selectedFilters"
> & {
  isButtonsDisabled?: boolean;
};

export type FilterFlyoutProps = FilterComponentsProps;

export interface FilterFieldProps {
  isOpen: boolean;
}

export interface FilterUIProps
  extends Omit<FilterComponentsProps, "filterCollection"> {
  filterFieldRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  selectedFiltersWithModalOpen: boolean;
  toggleShowFilter: () => void;
  setIsOpen: (isOpen: boolean) => void;
  filters: FilterSession[];
  isClientTabletXM?: boolean;
  isButtonsDisabled?: boolean;
}
