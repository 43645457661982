export const colors = <const>{
  common: {
    white: "#FFFFFF",
    black: "#000000",
    transparent: "transparent",
  },
  primary: {
    red: {
      light: {
        50: "#E8273F",
        150: "#EB3E53",
        250: "#ED5568",
        350: "#EF6B7C",
        450: "#F28290",
        550: "#F499A4",
        650: "#F7B0B8",
        750: "#F9C6CD",
        850: "#FBDDE1",
        950: "#FEF4F5",
      },
      dark: {
        50: "#BE0F2C",
        150: "#AA0E27",
        250: "#960C23",
        350: "#820A1E",
        450: "#6E0919",
        550: "#5A0715",
        650: "#460610",
        750: "#32040C",
        850: "#1E0207",
        950: "#0A0102",
      },
    },
    green: {
      light: {
        50: "#81C44C",
        150: "#8ECA5F",
        250: "#9BD172",
        350: "#A9D785",
        450: "#B6DD98",
        550: "#C3E3AA",
        650: "#D0E9BD",
        750: "#DEF0D0",
        850: "#EBF6E3",
        950: "#F8FCF6",
      },
      dark: {
        50: "#74B740",
        150: "#68A439",
        250: "#5A8F19",
        350: "#4F7D2C",
        450: "#436A25",
        550: "#37571E",
        650: "#2B4417",
        750: "#1F3011",
        850: "#121D0A",
        950: "#060A03",
      },
    },
    pear: {
      light: {
        50: "#C4DA39",
        150: "#CADE4E",
        250: "#D1E263",
        350: "#D7E678",
        450: "#DDEA8D",
        550: "#E3EDA1",
        650: "#E9F1B6",
        750: "#F0F5CB",
        850: "#F6F9E0",
        950: "#FCFDF5",
      },
      dark: {
        50: "#B7CD2D",
        150: "#A4B828",
        250: "#91A223",
        350: "#7D8C1F",
        450: "#6A771A",
        550: "#576115",
        650: "#444C10",
        750: "#30360C",
        850: "#1D2007",
        950: "#0A0B02",
      },
    },
    gray: {
      light: {
        50: "#5C5E62",
        150: "#6D6F73",
        250: "#7E8083",
        350: "#8F9194",
        450: "#A0A2A4",
        550: "#B2B3B5",
        650: "#C3C4C5",
        750: "#D4D5D6",
        850: "#E5E6E6",
        950: "#F6F7F7",
      },
      dark: {
        50: "#4F5256",
        150: "#47494D",
        250: "#3E4144",
        350: "#36383B",
        450: "#2E2F32",
        550: "#252728",
        650: "#1D1E1F",
        750: "#151617",
        850: "#0C0D0E",
        950: "#040405",
      },
    },
  },
  secondary: {
    yellow: {
      light: {
        50: "#FFCA0D",
        150: "#FFCF26",
        250: "#FFD540",
        350: "#FFDB59",
        450: "#FFE073",
        550: "#FFE68C",
        650: "#FFEBA6",
        750: "#FFF1BF",
        850: "#FFF7D9",
        950: "#FFFCF2",
      },
      dark: {
        50: "#F2BD00",
        150: "#D9A900",
        250: "#BF9500",
        350: "#A68100",
        450: "#8C6D00",
        550: "#735A00",
        650: "#594600",
        750: "#403200",
        850: "#261E00",
        950: "#0D0A00",
      },
    },
  },
  variants: {
    blackFriday: {
      "5": "#F8F8F8",
      "10": "#FBFBFB",
      "15": "#F6F7F7",
      "17": "#EBF6E3",
      "20": "#E5E6E6",
      "25": "#D4D5D6",
      "30": "#DDDDDD",
      "33": "#CFCFCF",
      "35": "#B2B3B5",
      "40": "#A0A2A4",
      "45": "#8F9194",
      "50": "#6D6F73",
      "75": "#5C5E62",
      "85": "#53565A",
      "80": "#7E7E7E",
      "100": "#47494D",
      "110": "#3E4144",
      "115": "#4A4A4A",
      "120": "#4D4D4D",
      "125": "#36383B",
      "150": "#2E2F32",
      "175": "#252728",
      "185": "#1D1E1F",
      "200": "#151617",
      "210": "#0C0D0E",
      "230": "#0A0A0A",
      "300": "#81C44C",
      "320": "#74B740",
      "400": "#FEF4F5",
      "420": "#E71C35",
      "480": "#DA0812",
    },
    opacity: {},
  },
};
