export const getMinutesAndSeconds = (dateString: Date): number => {
  if (!dateString) {
    return 120;
  }

  const date = new Date(dateString);
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const currentDate = new Date();
  const currentMinutes = currentDate.getMinutes();
  const currentSeconds = currentDate.getSeconds();

  const providedTotalSeconds = minutes * 60 + seconds;
  const currentTotalSeconds = currentMinutes * 60 + currentSeconds;

  return providedTotalSeconds - currentTotalSeconds;
};
