import { zodResolver } from "@hookform/resolvers/zod";
import {
  formatUserName,
  getGeolocation,
  ProfileApi,
  useAppContext,
  useEnvs,
} from "application";
import { useBranchLocation } from "application/context/BranchLocationContext";
import { useAuth } from "application/context/Identity/AuthContext";
import { useToast } from "application/hooks/Toast";
import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import {
  validateBirthdate,
  validateConfirmPassword,
  validateCpf,
  validateEmail,
  validateName,
  validatePassword,
  validatePhoneNumber,
} from "application/utils/validations";
import {
  appMonitoringClient,
  cookie,
  gtag,
  identityApi,
  jwtDecoder,
  notificationApi,
} from "implementations";
import { InsiderObject as Insider } from "infra";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AuthenticationProps,
  CreateSessionUser,
  ErrorInsertClient,
  HttpResponseErrorDto,
  IHttpResponse,
  ProfileDto,
  SessionTypeProps,
  ValidateInputProps,
} from "typing";

import {
  RegisterClientInfo,
  RegisterFormProps,
  registerFormValidateSchema,
} from "../schema";

const useFormBody = (profileApi: ProfileApi) => {
  const {
    setIsBlockedToSendCode,
    setIsTokenCodeValid,
    setValidateCode,
    setIsValidationActive,
    sendCode,
    setCodeTimerActive,
    tokenCode,
    isValidationActive,
    isSendCodeAvailable,
    isEmailDifferent,
  } = useTwoFactor(
    useShallow((state) => ({
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      setValidateCode: state.setValidateCode,
      setIsValidationActive: state.setIsValidationActive,
      sendCode: state.sendCode,
      tokenCode: state.tokenCode,
      isValidationActive: state.isValidationActive,
      setCodeTimerActive: state.setCodeTimerActive,
      isSendCodeAvailable: state.isSendCodeAvailable,
      isEmailDifferent: state.isEmailDifferent,
      setIsEmailDifferent: state.setIsEmailDifferent,
    }))
  );

  const { isClientMobile } = useAppContext();

  const showTwoFactorButton = !isClientMobile || !isValidationActive;
  const hideFormComponents = isValidationActive && isClientMobile;
  const toastErrorTitle = "Erro ao criar conta";
  const allConfigs = useEnvs((state) => state.allConfigs);
  const router = useRouter();
  const [showPasswordValidation, setShowPasswordValidation] =
    useState<boolean>(false);
  const { data: session } = useSession();
  const { addToast } = useToast();
  const [oAuthForm, setOAuthForm] = useState<ProfileDto>({} as ProfileDto);
  const [hasDisabledInputEmailByOAuth, setHasDisabledInputEmailByOAuth] =
    useState<"google" | "apple" | "">("");
  const [typeInputPassword, setTypeInputPassword] = useState<
    "password" | "text"
  >("password");
  const [typeInputConfirmPassword, setTypeInputConfirmPassword] = useState<
    "password" | "text"
  >("password");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formUnderAction, setFormUnderAction] = useState(false);

  const successOnCreateAccountTitle = "Sua conta foi cadastrada com sucesso!";

  const idToken = (session as unknown as SessionTypeProps)?.token?.id_token;
  const providerAuth = (session as unknown as SessionTypeProps)?.token
    ?.provider;

  const {
    keepUserLoggedIn,
    isAuthenticated,
    signOutAccount,
    onAuthenticateUser,
    userData,
  } = useAuth();

  const { getBranchLocationClient } = useBranchLocation();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    setFocus,
    control,
  } = useForm<RegisterFormProps>({
    resolver: zodResolver(registerFormValidateSchema),
  });

  const nameWatcher = watch("name");
  const cpfWatcher = watch("cpf");
  const birthdateWatcher = watch("birthdate");
  const phoneNumberWatcher = watch("phoneNumber");
  const emailWatcher = watch("email");
  const passwordWatcher = watch("password");
  const confirmPasswordWatcher = watch("confirmPassword");
  const acceptedTermsUseWatcher = watch("acceptedTermsUse");
  const trackerOrderByWhatsAppWatcher = watch("trackerOrderByWhatsApp");

  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);

    if (Number.isNaN(date.getTime())) {
      return "";
    }

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleManagementInputPasswordType = (type: "password" | "text") => {
    setTypeInputPassword(type);
  };

  const handleManagementInputConfirmPasswordType = (
    type: "password" | "text"
  ) => {
    setTypeInputConfirmPassword(type);
  };

  const fetchAPINotificationTrackerOrderByWhatsApp = async (
    tokenType: string,
    accessTokenIdentityAPI: string
  ) => {
    await notificationApi.setUserNotificationsSettingsWhatsApp(
      {
        token_type: tokenType,
        access_token: accessTokenIdentityAPI,
      },
      {
        notificationChannelId: 5,
        notificationTypeId: 1,
        receiveNotification: true,
      }
    );
  };

  const createSessionForUserOAuth = useCallback(
    async (
      token: string,
      error: ErrorInsertClient,
      trackerOrderByWhatsApp: boolean
    ) => {
      if (error) {
        addToast({
          title: toastErrorTitle,
          description: `${error?.errors?.Messages?.[0]}`,
          type: "error",
          isNewToast: true,
          newToastTheme: "light",
        });

        return;
      }

      const response = await identityApi.getTokenOAuth(
        {
          idToken,
          provider: providerAuth || hasDisabledInputEmailByOAuth,
          location: JSON.stringify(await getGeolocation()),
        },
        jwtDecoder
      );

      if (trackerOrderByWhatsApp) {
        await fetchAPINotificationTrackerOrderByWhatsApp(
          (response as unknown as AuthenticationProps).token_type,
          (response as unknown as AuthenticationProps).access_token
        );
      }

      keepUserLoggedIn({
        providerType: providerAuth || hasDisabledInputEmailByOAuth,
        token,
        accessToken: (response as unknown as AuthenticationProps).access_token,
        refreshToken: (response as unknown as AuthenticationProps)
          .refresh_token,
      });
    },
    [
      addToast,
      hasDisabledInputEmailByOAuth,
      idToken,
      keepUserLoggedIn,
      providerAuth,
    ]
  );

  const createSessionForUser = useCallback(
    async ({
      credentials,
      response,
      trackerOrderByWhatsApp,
    }: CreateSessionUser) => {
      if ((response as unknown as ErrorInsertClient)?.errors?.Messages) {
        addToast({
          title: toastErrorTitle,
          description: `${
            (response as unknown as ErrorInsertClient)?.errors
              ?.Messages?.[0] as unknown as string
          }`,
          type: "error",
          isNewToast: true,
          newToastTheme: "light",
        });
        return;
      }

      const data = await onAuthenticateUser({
        login: credentials.login,
        password: credentials.password,
        location: JSON.stringify(await getGeolocation()),
      });

      if (trackerOrderByWhatsApp) {
        await fetchAPINotificationTrackerOrderByWhatsApp(
          (data as unknown as AuthenticationProps).token_type,
          (data as unknown as AuthenticationProps).access_token
        );
      }

      if (router?.asPath?.includes("pagamento") && data) {
        setTimeout(() => {
          router.push("/checkout/pagamento");
        }, 9000);
        return;
      }

      if (data?.access_token) {
        router.push("/");
      }
    },
    [addToast, onAuthenticateUser, router]
  );

  // 2FA
  const handleInsertClientSuccessResponse = useCallback(
    async (
      res: IHttpResponse<ProfileDto, unknown, string[]>,
      body: ProfileDto,
      token?: string
    ) => {
      if (!token) {
        await createSessionForUser({
          credentials: {
            login: body.email,
            password: body.password,
          },
          response: res as unknown as ErrorInsertClient,
          trackerOrderByWhatsApp: body.trackerOrderByWhatsApp,
        });
      }

      if (token) {
        await createSessionForUserOAuth(
          token,
          res as unknown as ErrorInsertClient,
          body.trackerOrderByWhatsApp
        );
      }

      gtag.signUp("password");
      addToast({
        title: successOnCreateAccountTitle,
        type: "success",
        isNewToast: true,
        newToastTheme: "light",
      });
    },
    [addToast, createSessionForUser, createSessionForUserOAuth]
  );

  const handleInsertClientErrorResponse = useCallback(
    (res: { data: HttpResponseErrorDto }) => {
      if (res?.data?.status === 400) {
        if (res?.data?.detail?.toLowerCase()?.includes("0 tentativas")) {
          setIsBlockedToSendCode(true);
          return;
        }

        addToast({
          title: toastErrorTitle,
          description: res?.data.detail as string,
          type: "error",
          isNewToast: true,
          newToastTheme: "light",
        });
        if (res?.data?.detail?.toLowerCase()?.includes("já está cadastrado")) {
          setIsValidationActive(false);
          return;
        }
        if (res?.data?.detail?.includes("Código inválido")) {
          setIsTokenCodeValid(false);
        }
      } else {
        addToast({
          title:
            "Ocorreu um erro ao cadastrar o usuário. Favor, tente novamente",
          type: "error",
          isNewToast: true,
          newToastTheme: "light",
        });
      }
    },
    [
      addToast,
      setIsTokenCodeValid,
      setIsBlockedToSendCode,
      setIsValidationActive,
    ]
  );

  useEffect(() => {
    if (!router) return;

    const handleRouteChange = () => {
      setIsValidationActive(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, setIsValidationActive]);

  const insertClient = useCallback(
    async (body: ProfileDto) => {
      await profileApi
        .createBFFClient(body, hasDisabledInputEmailByOAuth || "password")
        .then(async (res) => {
          const response = res as unknown as { data: ProfileDto };
          const createdClient = response?.data?.id;

          if (createdClient && res) {
            Insider.sendEvent("events", [
              {
                event_name: "sign_up",
              },
            ]);
            await handleInsertClientSuccessResponse(res, body);
            setFormUnderAction(false);
            setIsValidationActive(false);
            return;
          }

          handleInsertClientErrorResponse(
            res as unknown as { data: HttpResponseErrorDto }
          );
          setFormUnderAction(false);
        })
        .catch((error) => {
          appMonitoringClient.captureException(error);
          setFormUnderAction(false);
        });
    },
    [
      handleInsertClientErrorResponse,
      handleInsertClientSuccessResponse,
      hasDisabledInputEmailByOAuth,
      profileApi,
      setIsValidationActive,
    ]
  );

  const validateToken = useCallback(() => {
    const branchLocation = getBranchLocationClient();

    const data: ProfileDto = {
      name: formatUserName(session?.user?.name ?? ""),
      email: session?.user?.email || "",
      birthdate: "",
      phoneNumber: "",
      cpf: "",
      password: "",
      acceptedTermsUse: false,
      trackerOrderByWhatsApp: false,
      defaultBranchId: Number(branchLocation),
      // faType: 5,
      // tokenValidation: "",
    };

    setOAuthForm(data);
    gtag.signUp("apple");
  }, [getBranchLocationClient, session?.user?.email, session?.user?.name]);

  const handleUserGoogleAuthenticate = useCallback(() => {
    if (session && !isAuthenticated) {
      const branchLocation = getBranchLocationClient();
      const user: ProfileDto = {
        name: formatUserName(session?.user?.name || ""),
        email: session?.user?.email || "",
        birthdate: "",
        phoneNumber: "",
        cpf: "",
        password: "",
        acceptedTermsUse: false,
        trackerOrderByWhatsApp: false,
        defaultBranchId: Number(branchLocation),
        // faType: 5,
        // tokenValidation: "",
      };

      setOAuthForm(user);
      gtag.signUp("google");
    }
  }, [getBranchLocationClient, isAuthenticated, session]);

  // 2FA
  const handleSignUp = useCallback(() => {
    setFormUnderAction(true);

    const branchLocation = getBranchLocationClient();

    const formatDateFromWatcher = formatDate(birthdateWatcher);

    const [day, month, year] = formatDateFromWatcher.toString().split("/");
    const formattedBirthDate = new Date(
      `${year}-${month}-${day}`
    ).toISOString();

    const body: ProfileDto = {
      name: formatUserName(nameWatcher),
      cpf: cpfWatcher,
      birthdate: formattedBirthDate,
      email: emailWatcher,
      password: passwordWatcher,
      phoneNumber: phoneNumberWatcher,
      acceptedTermsUse: acceptedTermsUseWatcher,
      trackerOrderByWhatsApp: !!trackerOrderByWhatsAppWatcher,
      defaultBranchId: Number(branchLocation),
      // faType = 5 significa validação feita por email
      faType: 5,
      tokenValidation: tokenCode,
    };

    setOAuthForm(body);

    insertClient(body);
    setValidateCode(true);
  }, [
    acceptedTermsUseWatcher,
    birthdateWatcher,
    cpfWatcher,
    emailWatcher,
    getBranchLocationClient,
    insertClient,
    nameWatcher,
    passwordWatcher,
    phoneNumberWatcher,
    setValidateCode,
    tokenCode,
    trackerOrderByWhatsAppWatcher,
  ]);

  const insertClientWithoutTwoFA = async (body: ProfileDto, token?: string) => {
    await profileApi
      .createClient(body, hasDisabledInputEmailByOAuth || "password")
      .then(async (res) => {
        if (res?.errors?.Messages?.[0]) {
          addToast({
            title: res?.errors?.Messages?.[0],
            type: "error",
            isNewToast: true,
            newToastTheme: "light",
          });
          setFormUnderAction(false);
          return;
        }
        if (!res) {
          addToast({
            title: "Ocorreu um erro inesperado, tente novamente",
            type: "error",
            isNewToast: true,
            newToastTheme: "light",
          });
          setFormUnderAction(false);
          return;
        }

        try {
          if (!token) {
            await createSessionForUser({
              credentials: {
                login: body.email,
                password: body.password,
              },
              response: res as unknown as ErrorInsertClient,
              trackerOrderByWhatsApp: body.trackerOrderByWhatsApp,
            });
          }

          if (token) {
            await createSessionForUserOAuth(
              token,
              res as unknown as ErrorInsertClient,
              body.trackerOrderByWhatsApp
            );
          }

          setFormUnderAction(false);
          Insider.sendEvent("events", [
            {
              event_name: "sign_up",
            },
          ]);

          addToast({
            title: successOnCreateAccountTitle,
            type: "success",
            isNewToast: true,
            newToastTheme: "light",
          });

          gtag.signUp("password");

          router.push("/");
        } catch (err) {
          setFormUnderAction(false);

          addToast({
            title: successOnCreateAccountTitle,
            type: "success",
            isNewToast: true,
            newToastTheme: "light",
          });

          gtag.signUp("password");

          router.push("/");
        }
      })
      .catch((error) => {
        appMonitoringClient.captureException(error);
        setFormUnderAction(false);
      });
  };

  const handleSignUpWithoutTwoFA = (formData: RegisterFormProps) => {
    setFormUnderAction(true);

    const branchLocation = getBranchLocationClient();

    const formatDateFromWatcher = formatDate(birthdateWatcher);

    const [day, month, year] = formatDateFromWatcher.toString().split("/");

    const formattedBirthDate = new Date(
      `${year}-${month}-${day}`
    ).toISOString();

    const body: ProfileDto = {
      name: formatUserName(formData.name),
      cpf: formData.cpf,
      birthdate: formattedBirthDate,
      email: formData.email,
      password: formData.password,
      phoneNumber: formData.phoneNumber,
      acceptedTermsUse: formData.acceptedTermsUse,
      trackerOrderByWhatsApp: formData.trackerOrderByWhatsApp ?? false,
      defaultBranchId: Number(branchLocation),
    };

    setOAuthForm(body);

    insertClientWithoutTwoFA(body);
  };

  const removeSessionOnIncomingSignUpPage = useCallback(async () => {
    if (userData?.name && oAuthForm?.email) {
      signOutAccount(cookie);
      setOAuthForm({} as ProfileDto);
    }
  }, [oAuthForm.email, signOutAccount, userData?.name]);

  useEffect(() => {
    removeSessionOnIncomingSignUpPage();
  }, [removeSessionOnIncomingSignUpPage]);

  useEffect(() => {
    if (providerAuth === "google") {
      setHasDisabledInputEmailByOAuth("google");
      handleUserGoogleAuthenticate();

      setValue("name", formatUserName(oAuthForm?.name));
      setValue("email", oAuthForm.email);
    }
  }, [
    handleUserGoogleAuthenticate,
    setValue,
    oAuthForm.name,
    oAuthForm.email,
    providerAuth,
    hasDisabledInputEmailByOAuth,
  ]);

  useEffect(() => {
    if (providerAuth === "apple") {
      setHasDisabledInputEmailByOAuth("apple");
      validateToken();
      setValue("name", formatUserName(oAuthForm.name ?? ""));
      setValue("email", oAuthForm.email);
    }
  }, [
    oAuthForm.email,
    oAuthForm.name,
    providerAuth,
    hasDisabledInputEmailByOAuth,
    setValue,
    validateToken,
  ]);

  const sanitizeBirthdateWatcher = formatDate(birthdateWatcher);

  const isInvalidNameAndSurname: string = validateName(nameWatcher);
  const isInvalidCpf: string = validateCpf(cpfWatcher);
  const isInvalidBirthdate: string = validateBirthdate(
    sanitizeBirthdateWatcher
  );

  const isInvalidPhoneNumber: string = validatePhoneNumber(phoneNumberWatcher);
  const isInvalidEmail: string = validateEmail(emailWatcher);
  const isInvalidPassword: string = validatePassword(passwordWatcher);
  const isInvalidConfirmPassword: string = validateConfirmPassword(
    passwordWatcher,
    confirmPasswordWatcher
  );

  const isDisabled =
    isInvalidNameAndSurname ||
    isInvalidBirthdate ||
    isInvalidCpf ||
    isInvalidEmail ||
    isInvalidPhoneNumber ||
    !acceptedTermsUseWatcher ||
    passwordStrength !== 5;

  const validateInputValue = (type: ValidateInputProps) => {
    switch (type) {
      case "name":
        return Boolean(isInvalidNameAndSurname);
      case "cpf":
        return Boolean(isInvalidCpf);
      case "birthdate":
        return Boolean(isInvalidBirthdate);
      case "phoneNumber":
        return Boolean(isInvalidPhoneNumber);
      case "email":
        return Boolean(isInvalidEmail);
      case "password":
        return Boolean(isInvalidPassword);
      case "confirmPassword":
        return Boolean(isInvalidConfirmPassword);
      default:
        return false;
    }
  };

  const basicFieldValidation = (field: ValidateInputProps) => {
    return (
      (validateInputValue(field) && watch(field)?.length) ||
      (formSubmitted && !watch(field)?.length)
    );
  };

  const handleFormFieldError = (field: ValidateInputProps) => {
    switch (field) {
      case "email":
        return validateInputValue(field) && watch(field)?.length > 1;
      case "name":
        return (
          (validateInputValue(field) && watch(field)?.length) || !!errors.name
        );
      case "cpf":
        return basicFieldValidation(field);
      case "birthdate":
        return validateInputValue("birthdate");
      case "password":
        return (
          (validateInputValue(field) && watch(field)?.length) ||
          !!errors?.password
        );
      case "phoneNumber":
        return (
          (validateInputValue(field) && watch(field)?.length) ||
          !!errors?.phoneNumber
        );
      case "confirmPassword":
        return (
          (validateInputValue(field) && watch(field)?.length) ||
          !!errors?.confirmPassword
        );

      default:
        return false;
    }
  };

  const onlyNumbers = "Apenas números";

  useEffect(() => {
    if (router?.asPath?.includes("pagamento") && isAuthenticated) {
      router.push("checkout/pagamento");
    }
  }, [isAuthenticated, router]);

  const handleValidateTwoFactorTokenEmail = async (
    type: number,
    emailValidate: string,
    token: string
  ) => {
    const sanitizedEmail = emailValidate.replace(/@/g, "%40");

    const result = await notificationApi.validateTwoFactorTokenEmail(
      type,
      sanitizedEmail,
      token
    );
    setIsTokenCodeValid(result.isValidEmail);
    setValidateCode(true);
  };

  const [clientInfo, setClientInfo] = useState<RegisterClientInfo | null>(null);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleContinueRegister = async () => {
    if (isSendCodeAvailable || isEmailDifferent) {
      setClientInfo({
        name: formatUserName(nameWatcher),
        cpf: cpfWatcher,
        birthdate: birthdateWatcher,
        phoneNumber: phoneNumberWatcher,
        email: emailWatcher,
        password: passwordWatcher,
        acceptedTermsUse: acceptedTermsUseWatcher,
        trackerOrderByWhatsAppState: !!trackerOrderByWhatsAppWatcher,
      });

      const sendCodeResult = await sendCode({
        sendCodeType: "email",
        notificationApi,
        infoValue: emailWatcher?.replace(/@/g, "%40"),
        addToast,
        errorTitle: toastErrorTitle,
      });

      setIsCodeSent(sendCodeResult);
      setIsValidationActive(true);
      setCodeTimerActive(true);

      return sendCodeResult;
    }
    setIsValidationActive(true);
    setCodeTimerActive(true);

    return false;
  };

  return {
    allConfigs,
    handleContinueRegister,
    showPasswordValidation,
    setShowPasswordValidation,
    typeInputPassword,
    typeInputConfirmPassword,
    setPasswordStrength,
    setFormSubmitted,
    formSubmitted,
    formUnderAction,
    onlyNumbers,
    idToken,
    providerAuth,
    register,
    handleSubmit,
    setValue,
    setFocus,
    watch,
    errors,
    isSubmitting,
    nameWatcher,
    cpfWatcher,
    birthdateWatcher,
    phoneNumberWatcher,
    emailWatcher,
    passwordWatcher,
    confirmPasswordWatcher,
    acceptedTermsUseWatcher,
    handleManagementInputPasswordType,
    handleManagementInputConfirmPasswordType,
    handleSignUp,
    handleSignUpWithoutTwoFA,
    isDisabled,
    handleFormFieldError,
    isInvalidNameAndSurname,
    isInvalidCpf,
    isInvalidBirthdate,
    isInvalidPhoneNumber,
    isInvalidEmail,
    isInvalidPassword,
    oAuthForm,
    hasDisabledInputEmailByOAuth,
    validateInputValue,
    handleValidateTwoFactorTokenEmail,
    insertClient,
    insertClientWithoutTwoFA,
    clientInfo,
    isCodeSent,
    isClientMobile,
    showTwoFactorButton,
    hideFormComponents,
    router,
    control,
  };
};

export { useFormBody };
