import { MultipleColorsIconProps } from "typing";

type UniqueClientImageProps = MultipleColorsIconProps & {
  dataTestId?: string;
};
export const UniqueClientImage = ({
  width = 116,
  height = 99,
  dataTestId = "unique-client-banner-image",
}: UniqueClientImageProps) => {
  return (
    <svg
      width={width}
      height={height}
      data-testid={dataTestId}
      viewBox="0 0 116 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ borderRadius: "8px 0 0 8px", height: "100%" }}
    >
      <path d="M54 0H0V54H54V0Z" fill="#C1D82F" />
      <path
        d="M29.8347 40.9088H13.8816C12.1205 40.9088 10.6875 39.4753 10.6875 37.7137V16.3152C10.6875 14.5536 12.1205 13.1201 13.8816 13.1201H29.8347C31.5957 13.1201 33.0287 14.5536 33.0287 16.3152V20.9668C33.0287 21.4561 32.6316 21.8533 32.1425 21.8533C31.6533 21.8533 31.2562 21.4561 31.2562 20.9668V16.3152C31.2562 15.5322 30.6174 14.899 29.8404 14.899H13.8873C13.1046 14.899 12.4716 15.5322 12.4716 16.3152V37.7137C12.4716 38.4966 13.1046 39.1299 13.8873 39.1299H29.8404C30.6231 39.1299 31.2562 38.4966 31.2562 37.7137V33.3096C31.2562 32.8203 31.6533 32.4231 32.1425 32.4231C32.6316 32.4231 33.0287 32.8203 33.0287 33.3096V37.7137C33.0287 39.4753 31.5957 40.9088 29.8347 40.9088Z"
        fill="#E3EDA1"
      />
      <path
        d="M28.2401 19.4758H15.4638C14.9746 19.4758 14.5775 19.0786 14.5775 18.5892C14.5775 18.0999 14.9746 17.6969 15.4638 17.6969H28.2401C28.7293 17.6969 29.1264 18.0941 29.1264 18.5892C29.1264 19.0843 28.7293 19.4758 28.2401 19.4758Z"
        fill="#E3EDA1"
      />
      <path
        d="M24.6719 25.0829H15.4638C14.9746 25.0829 14.5775 24.6857 14.5775 24.1964C14.5775 23.707 14.9746 23.3098 15.4638 23.3098H24.6719C25.1611 23.3098 25.5582 23.707 25.5582 24.1964C25.5582 24.6857 25.1611 25.0829 24.6719 25.0829Z"
        fill="#E3EDA1"
      />
      <path
        d="M24.5339 30.6959H15.4697C14.9805 30.6959 14.5834 30.2987 14.5834 29.8093C14.5834 29.32 14.9805 28.9228 15.4697 28.9228H24.5339C25.0231 28.9228 25.4202 29.32 25.4202 29.8093C25.4202 30.2987 25.0231 30.6959 24.5339 30.6959Z"
        fill="#E3EDA1"
      />
      <path
        d="M28.2401 36.3032H15.4638C14.9746 36.3032 14.5775 35.906 14.5775 35.4167C14.5775 34.9273 14.9746 34.5301 15.4638 34.5301H28.2401C28.7293 34.5301 29.1264 34.9273 29.1264 35.4167C29.1264 35.906 28.7293 36.3032 28.2401 36.3032Z"
        fill="#E3EDA1"
      />
      <path
        d="M41.0106 40.1316C40.3948 40.1316 39.8136 39.8898 39.3819 39.458L33.4484 33.5226C32.2514 34.1041 30.9047 34.3401 29.5753 34.1962C27.9811 34.0235 26.479 33.3039 25.3453 32.1698C24.0101 30.8341 23.2734 29.0553 23.2734 27.1612C23.2734 25.2672 24.0101 23.4883 25.3453 22.1527C26.6804 20.8171 28.4588 20.0802 30.3522 20.0802C32.2456 20.0802 34.0182 20.8171 35.3591 22.1527C36.4929 23.2868 37.2123 24.7894 37.3849 26.384C37.5288 27.7139 37.2928 29.0668 36.7116 30.2585L42.6451 36.1938C43.0824 36.6314 43.3242 37.2071 43.3242 37.8288C43.3242 38.4506 43.0824 39.0263 42.6451 39.4638C42.2077 39.9013 41.6322 40.1373 41.0106 40.1373M33.5981 31.5365C33.8283 31.5365 34.0527 31.6228 34.2254 31.7956L40.6308 38.203C40.7286 38.3009 40.861 38.3585 41.0049 38.3585C41.1487 38.3585 41.2811 38.3009 41.3789 38.203C41.4768 38.1051 41.5343 37.9727 41.5343 37.8288C41.5343 37.6849 41.4768 37.5525 41.3789 37.4546L34.9735 31.0472C34.68 30.7535 34.6282 30.2987 34.8469 29.9476C35.4685 28.9459 35.739 27.7484 35.6066 26.574C35.4742 25.3651 34.9505 24.2712 34.0872 23.4077C33.0859 22.406 31.7564 21.8533 30.3407 21.8533C28.9249 21.8533 27.5898 22.406 26.5884 23.4077C25.587 24.4094 25.0345 25.7393 25.0345 27.1555C25.0345 28.5717 25.587 29.9073 26.5884 30.909C27.4516 31.7725 28.5451 32.2964 29.7537 32.4288C30.9277 32.5555 32.1248 32.2849 33.1261 31.6631C33.27 31.5768 33.4312 31.5307 33.5923 31.5307L33.5981 31.5365Z"
        fill="#E3EDA1"
      />
      <path d="M92 60H54V98H92V60Z" fill="#7AC143" />
      <path
        d="M80.3484 80.4828C80.1864 80.4828 80.0284 80.4221 79.9069 80.3005C78.0642 78.4572 75.61 77.4404 73.0019 77.4404C70.3938 77.4404 67.9396 78.4572 66.0969 80.3005C65.8539 80.5436 65.457 80.5436 65.214 80.3005C64.971 80.0575 64.971 79.6604 65.214 79.4174C67.2956 77.3351 70.0617 76.1886 73.0019 76.1886C75.9421 76.1886 78.7122 77.3351 80.7939 79.4174C81.0369 79.6604 81.0369 80.0575 80.7939 80.3005C80.6724 80.4221 80.5104 80.4828 80.3524 80.4828"
        fill="#DEF0D0"
      />
      <path
        d="M69.2597 83.2457C69.0977 83.2457 68.9397 83.1849 68.8182 83.0634C68.5752 82.8203 68.5752 82.4233 68.8182 82.1803C71.1267 79.8711 74.8809 79.8711 77.1893 82.1803C77.4323 82.4233 77.4323 82.8203 77.1893 83.0634C76.9463 83.3065 76.5494 83.3065 76.3065 83.0634C74.4881 81.2444 71.5276 81.2444 69.7052 83.0634C69.5837 83.1849 69.4217 83.2457 69.2637 83.2457"
        fill="#DEF0D0"
      />
      <path
        d="M84.0378 77.2337C83.8758 77.2337 83.7178 77.173 83.5963 77.0514C80.7655 74.2197 77.0031 72.664 73.0059 72.664C69.0087 72.664 65.2423 74.2237 62.4114 77.0514C62.1684 77.2945 61.7716 77.2945 61.5286 77.0514C61.2856 76.8084 61.2856 76.4113 61.5286 76.1683C64.5943 73.1015 68.6685 71.4122 73.0059 71.4122C77.3433 71.4122 81.4175 73.1015 84.4833 76.1683C84.7263 76.4113 84.7263 76.8084 84.4833 77.0514C84.3618 77.173 84.1998 77.2337 84.0418 77.2337"
        fill="#DEF0D0"
      />
      <path
        d="M74.1035 85.486C74.1035 86.0936 73.6094 86.5879 73.002 86.5879C72.3945 86.5879 71.9004 86.0936 71.9004 85.486C71.9004 84.8783 72.3945 84.384 73.002 84.384C73.6094 84.384 74.1035 84.8783 74.1035 85.486Z"
        fill="#DEF0D0"
      />
      <path d="M115.29 0H54.6111V60H115.29V0Z" fill="#AA0E27" />
      <path
        d="M80.278 48.2434H67.7136C66.8343 48.2434 66.1213 47.5384 66.1213 46.6689V25.2837C66.1213 24.6178 66.6679 24.0773 67.3413 24.0773H79.9136C80.587 24.0773 81.1336 24.6178 81.1336 25.2837V46.1597C81.3633 46.379 81.5059 46.6846 81.5059 47.0292C81.5059 47.6951 80.9593 48.2434 80.2859 48.2434M68.5692 45.8229H78.6936V26.4979H68.5692V45.8229Z"
        fill="#ED5568"
      />
      <path
        d="M100.772 48.2434H88.2078C87.5344 48.2434 86.9878 47.7029 86.9878 47.0292C86.9878 46.6924 87.1304 46.3869 87.3601 46.1597V25.2837C87.3601 24.6178 87.9067 24.0773 88.5801 24.0773H101.145C101.818 24.0773 102.365 24.6178 102.365 25.2837V46.6689C102.365 47.5384 101.652 48.2434 100.772 48.2434ZM89.8001 45.8229H99.9245V26.4979H89.8001V45.8229Z"
        fill="#ED5568"
      />
      <path
        d="M103.181 26.4978H88.5724C87.8991 26.4978 87.3524 25.9573 87.3524 25.2914V17.5912C87.1227 17.3718 86.9801 17.0663 86.9801 16.7217C86.9801 16.0558 87.5267 15.5153 88.2001 15.5153H103.181C104.06 15.5153 104.773 16.2203 104.773 17.0898V24.9232C104.773 25.7928 104.06 26.4978 103.181 26.4978ZM89.8004 24.0772H102.333V17.928H89.8004V24.0772Z"
        fill="#ED5568"
      />
      <path
        d="M79.9136 26.4978H65.3053C64.4259 26.4978 63.713 25.7928 63.713 24.9232V17.0898C63.713 16.2203 64.4259 15.5153 65.3053 15.5153H80.2859C80.9593 15.5153 81.5059 16.0558 81.5059 16.7217C81.5059 17.0585 81.3633 17.3718 81.1336 17.5912V25.2914C81.1336 25.9573 80.5869 26.4978 79.9136 26.4978ZM66.1609 24.0772H78.6936V17.928H66.1609V24.0772Z"
        fill="#ED5568"
      />
      <path
        d="M84.643 17.9279H75.1049C72.2133 17.9279 69.3693 15.335 69.3614 12.6952C69.3614 11.6925 69.6307 9.89863 71.4211 9.23279C73.164 8.58261 75.8496 9.29545 79.4145 11.3556C82.211 12.9693 84.6668 14.912 85.562 15.9225C85.8789 16.2829 85.9502 16.7842 85.7521 17.2229C85.5541 17.6537 85.1184 17.9357 84.6351 17.9357M72.8313 11.4261C72.6015 11.4261 72.4193 11.4575 72.2767 11.5045C72.15 11.5515 71.8014 11.6846 71.8014 12.703C71.8014 14.019 73.5601 15.5152 75.097 15.5152H81.3316C80.4364 14.8572 79.3511 14.1365 78.1786 13.455C75.5723 11.951 73.8057 11.4261 72.8313 11.4261Z"
        fill="#ED5568"
      />
      <path
        d="M93.3731 17.928H83.835C83.3597 17.928 82.9239 17.6539 82.718 17.2152C82.5199 16.7844 82.5912 16.2752 82.9081 15.9149C83.7954 14.9044 86.2591 12.9617 89.0556 11.3558C92.6205 9.30346 95.3061 8.58279 97.049 9.23296C98.8394 9.8988 99.1087 11.6848 99.1087 12.6953C99.1087 15.3352 96.2647 17.928 93.3652 17.928M87.1306 15.5075H93.3652C94.9021 15.5075 96.6608 14.0113 96.6608 12.6953C96.6608 11.677 96.3202 11.5517 96.1855 11.5047C95.4408 11.2305 93.4999 11.5987 90.2835 13.4552C89.1032 14.1367 88.0258 14.8573 87.1306 15.5154V15.5075Z"
        fill="#ED5568"
      />
      <path
        d="M88.1683 48.2435H80.3175C79.4223 48.2435 78.6855 47.5228 78.6855 46.6298V17.1213C78.6855 16.2283 79.4144 15.5077 80.3175 15.5077H88.1683C89.0634 15.5077 89.8002 16.2283 89.8002 17.1213V46.6298C89.8002 47.515 89.0714 48.2435 88.1683 48.2435ZM81.1335 45.823H87.3523V17.9282H81.1335V45.823Z"
        fill="#ED5568"
      />
      <path
        d="M3.11461 57.3938C3.71769 57.3938 4.20658 56.8579 4.20658 56.1969C4.20658 55.5359 3.71769 55 3.11461 55C2.51154 55 2.02264 55.5359 2.02264 56.1969C2.02264 56.8579 2.51154 57.3938 3.11461 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 57.3938C8.13127 57.3938 8.62016 56.8579 8.62016 56.1969C8.62016 55.5358 8.13127 54.9999 7.52819 54.9999C6.92511 54.9999 6.43622 55.5358 6.43622 56.1969C6.43622 56.8579 6.92511 57.3938 7.52819 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 57.3938C12.5444 57.3938 13.0332 56.8579 13.0332 56.1969C13.0332 55.5358 12.5444 54.9999 11.9413 54.9999C11.3382 54.9999 10.8493 55.5358 10.8493 56.1969C10.8493 56.8579 11.3382 57.3938 11.9413 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 57.3938C16.9579 57.3938 17.4468 56.8579 17.4468 56.1969C17.4468 55.5358 16.9579 54.9999 16.3548 54.9999C15.7518 54.9999 15.2629 55.5358 15.2629 56.1969C15.2629 56.8579 15.7518 57.3938 16.3548 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 57.3938C21.3656 57.3938 21.8545 56.8579 21.8545 56.1969C21.8545 55.5358 21.3656 54.9999 20.7625 54.9999C20.1594 54.9999 19.6705 55.5358 19.6705 56.1969C19.6705 56.8579 20.1594 57.3938 20.7625 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 57.3938C25.7792 57.3938 26.268 56.8579 26.268 56.1969C26.268 55.5358 25.7792 54.9999 25.1761 54.9999C24.573 54.9999 24.0841 55.5358 24.0841 56.1969C24.0841 56.8579 24.573 57.3938 25.1761 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 57.3938C30.1922 57.3938 30.6811 56.8579 30.6811 56.1969C30.6811 55.5358 30.1922 54.9999 29.5891 54.9999C28.986 54.9999 28.4971 55.5358 28.4971 56.1969C28.4971 56.8579 28.986 57.3938 29.5891 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0027 57.3938C34.6058 57.3938 35.0947 56.8579 35.0947 56.1969C35.0947 55.5358 34.6058 54.9999 34.0027 54.9999C33.3997 54.9999 32.9108 55.5358 32.9108 56.1969C32.9108 56.8579 33.3997 57.3938 34.0027 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 57.3938C39.0191 57.3938 39.508 56.8579 39.508 56.1969C39.508 55.5358 39.0191 54.9999 38.416 54.9999C37.8129 54.9999 37.324 55.5358 37.324 56.1969C37.324 56.8579 37.8129 57.3938 38.416 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 57.3938C43.4325 57.3938 43.9214 56.8579 43.9214 56.1969C43.9214 55.5358 43.4325 54.9999 42.8294 54.9999C42.2263 54.9999 41.7374 55.5358 41.7374 56.1969C41.7374 56.8579 42.2263 57.3938 42.8294 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 57.3938C47.8458 57.3938 48.3347 56.8579 48.3347 56.1969C48.3347 55.5358 47.8458 54.9999 47.2427 54.9999C46.6397 54.9999 46.1508 55.5358 46.1508 56.1969C46.1508 56.8579 46.6397 57.3938 47.2427 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 57.3938C52.2591 57.3938 52.748 56.8579 52.748 56.1969C52.748 55.5358 52.2591 54.9999 51.656 54.9999C51.0529 54.9999 50.564 55.5358 50.564 56.1969C50.564 56.8579 51.0529 57.3938 51.656 57.3938Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 62.0258C3.71769 62.0258 4.20658 61.4899 4.20658 60.8289C4.20658 60.1678 3.71769 59.632 3.11461 59.632C2.51154 59.632 2.02264 60.1678 2.02264 60.8289C2.02264 61.4899 2.51154 62.0258 3.11461 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 62.0258C8.13127 62.0258 8.62016 61.49 8.62016 60.8289C8.62016 60.1679 8.13127 59.632 7.52819 59.632C6.92511 59.632 6.43622 60.1679 6.43622 60.8289C6.43622 61.49 6.92511 62.0258 7.52819 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 62.0258C12.5444 62.0258 13.0332 61.49 13.0332 60.8289C13.0332 60.1679 12.5444 59.632 11.9413 59.632C11.3382 59.632 10.8493 60.1679 10.8493 60.8289C10.8493 61.49 11.3382 62.0258 11.9413 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 62.0258C16.9579 62.0258 17.4468 61.49 17.4468 60.8289C17.4468 60.1679 16.9579 59.632 16.3548 59.632C15.7518 59.632 15.2629 60.1679 15.2629 60.8289C15.2629 61.49 15.7518 62.0258 16.3548 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 62.0258C21.3656 62.0258 21.8545 61.49 21.8545 60.8289C21.8545 60.1679 21.3656 59.632 20.7625 59.632C20.1594 59.632 19.6705 60.1679 19.6705 60.8289C19.6705 61.49 20.1594 62.0258 20.7625 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 62.0258C25.7792 62.0258 26.268 61.49 26.268 60.8289C26.268 60.1679 25.7792 59.632 25.1761 59.632C24.573 59.632 24.0841 60.1679 24.0841 60.8289C24.0841 61.49 24.573 62.0258 25.1761 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 62.0258C30.1922 62.0258 30.6811 61.49 30.6811 60.8289C30.6811 60.1679 30.1922 59.632 29.5891 59.632C28.986 59.632 28.4971 60.1679 28.4971 60.8289C28.4971 61.49 28.986 62.0258 29.5891 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 62.0258C34.6056 62.0258 35.0945 61.49 35.0945 60.8289C35.0945 60.1679 34.6056 59.632 34.0025 59.632C33.3994 59.632 32.9105 60.1679 32.9105 60.8289C32.9105 61.49 33.3994 62.0258 34.0025 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 62.0258C39.0191 62.0258 39.508 61.49 39.508 60.8289C39.508 60.1679 39.0191 59.632 38.416 59.632C37.8129 59.632 37.324 60.1679 37.324 60.8289C37.324 61.49 37.8129 62.0258 38.416 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 62.0258C43.4325 62.0258 43.9214 61.49 43.9214 60.8289C43.9214 60.1679 43.4325 59.632 42.8294 59.632C42.2263 59.632 41.7374 60.1679 41.7374 60.8289C41.7374 61.49 42.2263 62.0258 42.8294 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 62.0258C47.8458 62.0258 48.3347 61.49 48.3347 60.8289C48.3347 60.1679 47.8458 59.632 47.2427 59.632C46.6397 59.632 46.1508 60.1679 46.1508 60.8289C46.1508 61.49 46.6397 62.0258 47.2427 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 62.0258C52.2591 62.0258 52.748 61.49 52.748 60.8289C52.748 60.1679 52.2591 59.632 51.656 59.632C51.0529 59.632 50.564 60.1679 50.564 60.8289C50.564 61.49 51.0529 62.0258 51.656 62.0258Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 66.6579C3.71769 66.6579 4.20658 66.122 4.20658 65.461C4.20658 64.7999 3.71769 64.2641 3.11461 64.2641C2.51154 64.2641 2.02264 64.7999 2.02264 65.461C2.02264 66.122 2.51154 66.6579 3.11461 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 66.6579C8.13127 66.6579 8.62016 66.122 8.62016 65.461C8.62016 64.7999 8.13127 64.264 7.52819 64.264C6.92511 64.264 6.43622 64.7999 6.43622 65.461C6.43622 66.122 6.92511 66.6579 7.52819 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 66.6579C12.5444 66.6579 13.0332 66.122 13.0332 65.461C13.0332 64.7999 12.5444 64.264 11.9413 64.264C11.3382 64.264 10.8493 64.7999 10.8493 65.461C10.8493 66.122 11.3382 66.6579 11.9413 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 66.6579C16.9579 66.6579 17.4468 66.122 17.4468 65.461C17.4468 64.7999 16.9579 64.264 16.3548 64.264C15.7518 64.264 15.2629 64.7999 15.2629 65.461C15.2629 66.122 15.7518 66.6579 16.3548 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 66.6579C21.3656 66.6579 21.8545 66.122 21.8545 65.461C21.8545 64.7999 21.3656 64.264 20.7625 64.264C20.1594 64.264 19.6705 64.7999 19.6705 65.461C19.6705 66.122 20.1594 66.6579 20.7625 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 66.6579C25.7792 66.6579 26.268 66.122 26.268 65.461C26.268 64.7999 25.7792 64.264 25.1761 64.264C24.573 64.264 24.0841 64.7999 24.0841 65.461C24.0841 66.122 24.573 66.6579 25.1761 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 66.6579C30.1922 66.6579 30.6811 66.122 30.6811 65.461C30.6811 64.7999 30.1922 64.264 29.5891 64.264C28.986 64.264 28.4971 64.7999 28.4971 65.461C28.4971 66.122 28.986 66.6579 29.5891 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 66.6579C34.6056 66.6579 35.0945 66.122 35.0945 65.461C35.0945 64.7999 34.6056 64.264 34.0025 64.264C33.3994 64.264 32.9105 64.7999 32.9105 65.461C32.9105 66.122 33.3994 66.6579 34.0025 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 66.6579C39.0191 66.6579 39.508 66.122 39.508 65.461C39.508 64.7999 39.0191 64.264 38.416 64.264C37.8129 64.264 37.324 64.7999 37.324 65.461C37.324 66.122 37.8129 66.6579 38.416 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 66.6579C43.4325 66.6579 43.9214 66.122 43.9214 65.461C43.9214 64.7999 43.4325 64.264 42.8294 64.264C42.2263 64.264 41.7374 64.7999 41.7374 65.461C41.7374 66.122 42.2263 66.6579 42.8294 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 66.6579C47.8458 66.6579 48.3347 66.122 48.3347 65.461C48.3347 64.7999 47.8458 64.264 47.2427 64.264C46.6397 64.264 46.1508 64.7999 46.1508 65.461C46.1508 66.122 46.6397 66.6579 47.2427 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 66.6579C52.2591 66.6579 52.748 66.122 52.748 65.461C52.748 64.7999 52.2591 64.264 51.656 64.264C51.0529 64.264 50.564 64.7999 50.564 65.461C50.564 66.122 51.0529 66.6579 51.656 66.6579Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 71.2891C3.71769 71.2891 4.20658 70.7532 4.20658 70.0922C4.20658 69.4312 3.71769 68.8953 3.11461 68.8953C2.51154 68.8953 2.02264 69.4312 2.02264 70.0922C2.02264 70.7532 2.51154 71.2891 3.11461 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 71.2891C8.13127 71.2891 8.62016 70.7532 8.62016 70.0922C8.62016 69.4311 8.13127 68.8953 7.52819 68.8953C6.92511 68.8953 6.43622 69.4311 6.43622 70.0922C6.43622 70.7532 6.92511 71.2891 7.52819 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 71.2891C12.5444 71.2891 13.0332 70.7532 13.0332 70.0922C13.0332 69.4311 12.5444 68.8953 11.9413 68.8953C11.3382 68.8953 10.8493 69.4311 10.8493 70.0922C10.8493 70.7532 11.3382 71.2891 11.9413 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 71.2891C16.9579 71.2891 17.4468 70.7532 17.4468 70.0922C17.4468 69.4311 16.9579 68.8953 16.3548 68.8953C15.7518 68.8953 15.2629 69.4311 15.2629 70.0922C15.2629 70.7532 15.7518 71.2891 16.3548 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 71.2891C21.3656 71.2891 21.8545 70.7532 21.8545 70.0922C21.8545 69.4311 21.3656 68.8953 20.7625 68.8953C20.1594 68.8953 19.6705 69.4311 19.6705 70.0922C19.6705 70.7532 20.1594 71.2891 20.7625 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 71.2891C25.7792 71.2891 26.268 70.7532 26.268 70.0922C26.268 69.4311 25.7792 68.8953 25.1761 68.8953C24.573 68.8953 24.0841 69.4311 24.0841 70.0922C24.0841 70.7532 24.573 71.2891 25.1761 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 71.2891C30.1922 71.2891 30.6811 70.7532 30.6811 70.0922C30.6811 69.4311 30.1922 68.8953 29.5891 68.8953C28.986 68.8953 28.4971 69.4311 28.4971 70.0922C28.4971 70.7532 28.986 71.2891 29.5891 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 71.2891C34.6056 71.2891 35.0945 70.7532 35.0945 70.0922C35.0945 69.4311 34.6056 68.8953 34.0025 68.8953C33.3994 68.8953 32.9105 69.4311 32.9105 70.0922C32.9105 70.7532 33.3994 71.2891 34.0025 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 71.2891C39.0191 71.2891 39.508 70.7532 39.508 70.0922C39.508 69.4311 39.0191 68.8953 38.416 68.8953C37.8129 68.8953 37.324 69.4311 37.324 70.0922C37.324 70.7532 37.8129 71.2891 38.416 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 71.2891C43.4325 71.2891 43.9214 70.7532 43.9214 70.0922C43.9214 69.4311 43.4325 68.8953 42.8294 68.8953C42.2263 68.8953 41.7374 69.4311 41.7374 70.0922C41.7374 70.7532 42.2263 71.2891 42.8294 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 71.2891C47.8458 71.2891 48.3347 70.7532 48.3347 70.0922C48.3347 69.4311 47.8458 68.8953 47.2427 68.8953C46.6397 68.8953 46.1508 69.4311 46.1508 70.0922C46.1508 70.7532 46.6397 71.2891 47.2427 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 71.2891C52.2591 71.2891 52.748 70.7532 52.748 70.0922C52.748 69.4311 52.2591 68.8953 51.656 68.8953C51.0529 68.8953 50.564 69.4311 50.564 70.0922C50.564 70.7532 51.0529 71.2891 51.656 71.2891Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 75.9212C3.71769 75.9212 4.20658 75.3854 4.20658 74.7243C4.20658 74.0633 3.71769 73.5274 3.11461 73.5274C2.51154 73.5274 2.02264 74.0633 2.02264 74.7243C2.02264 75.3854 2.51154 75.9212 3.11461 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 75.9212C8.13127 75.9212 8.62016 75.3854 8.62016 74.7243C8.62016 74.0633 8.13127 73.5274 7.52819 73.5274C6.92511 73.5274 6.43622 74.0633 6.43622 74.7243C6.43622 75.3854 6.92511 75.9212 7.52819 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 75.9212C12.5444 75.9212 13.0332 75.3854 13.0332 74.7243C13.0332 74.0633 12.5444 73.5274 11.9413 73.5274C11.3382 73.5274 10.8493 74.0633 10.8493 74.7243C10.8493 75.3854 11.3382 75.9212 11.9413 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 75.9212C16.9579 75.9212 17.4468 75.3854 17.4468 74.7243C17.4468 74.0633 16.9579 73.5274 16.3548 73.5274C15.7518 73.5274 15.2629 74.0633 15.2629 74.7243C15.2629 75.3854 15.7518 75.9212 16.3548 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 75.9212C21.3656 75.9212 21.8545 75.3854 21.8545 74.7243C21.8545 74.0633 21.3656 73.5274 20.7625 73.5274C20.1594 73.5274 19.6705 74.0633 19.6705 74.7243C19.6705 75.3854 20.1594 75.9212 20.7625 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 75.9212C25.7792 75.9212 26.268 75.3854 26.268 74.7243C26.268 74.0633 25.7792 73.5274 25.1761 73.5274C24.573 73.5274 24.0841 74.0633 24.0841 74.7243C24.0841 75.3854 24.573 75.9212 25.1761 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 75.9212C30.1922 75.9212 30.6811 75.3854 30.6811 74.7243C30.6811 74.0633 30.1922 73.5274 29.5891 73.5274C28.986 73.5274 28.4971 74.0633 28.4971 74.7243C28.4971 75.3854 28.986 75.9212 29.5891 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 75.9212C34.6056 75.9212 35.0945 75.3854 35.0945 74.7243C35.0945 74.0633 34.6056 73.5274 34.0025 73.5274C33.3994 73.5274 32.9105 74.0633 32.9105 74.7243C32.9105 75.3854 33.3994 75.9212 34.0025 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 75.9212C39.0191 75.9212 39.508 75.3854 39.508 74.7243C39.508 74.0633 39.0191 73.5274 38.416 73.5274C37.8129 73.5274 37.324 74.0633 37.324 74.7243C37.324 75.3854 37.8129 75.9212 38.416 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 75.9212C43.4325 75.9212 43.9214 75.3854 43.9214 74.7243C43.9214 74.0633 43.4325 73.5274 42.8294 73.5274C42.2263 73.5274 41.7374 74.0633 41.7374 74.7243C41.7374 75.3854 42.2263 75.9212 42.8294 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 75.9212C47.8458 75.9212 48.3347 75.3854 48.3347 74.7243C48.3347 74.0633 47.8458 73.5274 47.2427 73.5274C46.6397 73.5274 46.1508 74.0633 46.1508 74.7243C46.1508 75.3854 46.6397 75.9212 47.2427 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 75.9212C52.2591 75.9212 52.748 75.3854 52.748 74.7243C52.748 74.0633 52.2591 73.5274 51.656 73.5274C51.0529 73.5274 50.564 74.0633 50.564 74.7243C50.564 75.3854 51.0529 75.9212 51.656 75.9212Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 80.5525C3.71769 80.5525 4.20658 80.0166 4.20658 79.3555C4.20658 78.6945 3.71769 78.1586 3.11461 78.1586C2.51154 78.1586 2.02264 78.6945 2.02264 79.3555C2.02264 80.0166 2.51154 80.5525 3.11461 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 80.5525C8.13127 80.5525 8.62016 80.0166 8.62016 79.3555C8.62016 78.6945 8.13127 78.1586 7.52819 78.1586C6.92511 78.1586 6.43622 78.6945 6.43622 79.3555C6.43622 80.0166 6.92511 80.5525 7.52819 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 80.5525C12.5444 80.5525 13.0332 80.0166 13.0332 79.3555C13.0332 78.6945 12.5444 78.1586 11.9413 78.1586C11.3382 78.1586 10.8493 78.6945 10.8493 79.3555C10.8493 80.0166 11.3382 80.5525 11.9413 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 80.5525C16.9579 80.5525 17.4468 80.0166 17.4468 79.3555C17.4468 78.6945 16.9579 78.1586 16.3548 78.1586C15.7518 78.1586 15.2629 78.6945 15.2629 79.3555C15.2629 80.0166 15.7518 80.5525 16.3548 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 80.5525C21.3656 80.5525 21.8545 80.0166 21.8545 79.3555C21.8545 78.6945 21.3656 78.1586 20.7625 78.1586C20.1594 78.1586 19.6705 78.6945 19.6705 79.3555C19.6705 80.0166 20.1594 80.5525 20.7625 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 80.5525C25.7792 80.5525 26.268 80.0166 26.268 79.3555C26.268 78.6945 25.7792 78.1586 25.1761 78.1586C24.573 78.1586 24.0841 78.6945 24.0841 79.3555C24.0841 80.0166 24.573 80.5525 25.1761 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 80.5525C30.1922 80.5525 30.6811 80.0166 30.6811 79.3555C30.6811 78.6945 30.1922 78.1586 29.5891 78.1586C28.986 78.1586 28.4971 78.6945 28.4971 79.3555C28.4971 80.0166 28.986 80.5525 29.5891 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 80.5525C34.6056 80.5525 35.0945 80.0166 35.0945 79.3555C35.0945 78.6945 34.6056 78.1586 34.0025 78.1586C33.3994 78.1586 32.9105 78.6945 32.9105 79.3555C32.9105 80.0166 33.3994 80.5525 34.0025 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 80.5525C39.0191 80.5525 39.508 80.0166 39.508 79.3555C39.508 78.6945 39.0191 78.1586 38.416 78.1586C37.8129 78.1586 37.324 78.6945 37.324 79.3555C37.324 80.0166 37.8129 80.5525 38.416 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 80.5525C43.4325 80.5525 43.9214 80.0166 43.9214 79.3555C43.9214 78.6945 43.4325 78.1586 42.8294 78.1586C42.2263 78.1586 41.7374 78.6945 41.7374 79.3555C41.7374 80.0166 42.2263 80.5525 42.8294 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 80.5525C47.8458 80.5525 48.3347 80.0166 48.3347 79.3555C48.3347 78.6945 47.8458 78.1586 47.2427 78.1586C46.6397 78.1586 46.1508 78.6945 46.1508 79.3555C46.1508 80.0166 46.6397 80.5525 47.2427 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 80.5525C52.2591 80.5525 52.748 80.0166 52.748 79.3555C52.748 78.6945 52.2591 78.1586 51.656 78.1586C51.0529 78.1586 50.564 78.6945 50.564 79.3555C50.564 80.0166 51.0529 80.5525 51.656 80.5525Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 85.1842C3.71769 85.1842 4.20658 84.6483 4.20658 83.9873C4.20658 83.3263 3.71769 82.7904 3.11461 82.7904C2.51154 82.7904 2.02264 83.3263 2.02264 83.9873C2.02264 84.6483 2.51154 85.1842 3.11461 85.1842Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 85.1843C8.13127 85.1843 8.62016 84.6484 8.62016 83.9874C8.62016 83.3263 8.13127 82.7904 7.52819 82.7904C6.92511 82.7904 6.43622 83.3263 6.43622 83.9874C6.43622 84.6484 6.92511 85.1843 7.52819 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 85.1843C12.5444 85.1843 13.0332 84.6484 13.0332 83.9874C13.0332 83.3263 12.5444 82.7904 11.9413 82.7904C11.3382 82.7904 10.8493 83.3263 10.8493 83.9874C10.8493 84.6484 11.3382 85.1843 11.9413 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 85.1843C16.9579 85.1843 17.4468 84.6484 17.4468 83.9874C17.4468 83.3263 16.9579 82.7904 16.3548 82.7904C15.7518 82.7904 15.2629 83.3263 15.2629 83.9874C15.2629 84.6484 15.7518 85.1843 16.3548 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 85.1843C21.3656 85.1843 21.8545 84.6484 21.8545 83.9874C21.8545 83.3263 21.3656 82.7904 20.7625 82.7904C20.1594 82.7904 19.6705 83.3263 19.6705 83.9874C19.6705 84.6484 20.1594 85.1843 20.7625 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 85.1843C25.7792 85.1843 26.268 84.6484 26.268 83.9874C26.268 83.3263 25.7792 82.7904 25.1761 82.7904C24.573 82.7904 24.0841 83.3263 24.0841 83.9874C24.0841 84.6484 24.573 85.1843 25.1761 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 85.1843C30.1922 85.1843 30.6811 84.6484 30.6811 83.9874C30.6811 83.3263 30.1922 82.7904 29.5891 82.7904C28.986 82.7904 28.4971 83.3263 28.4971 83.9874C28.4971 84.6484 28.986 85.1843 29.5891 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 85.1843C34.6056 85.1843 35.0945 84.6484 35.0945 83.9874C35.0945 83.3263 34.6056 82.7904 34.0025 82.7904C33.3994 82.7904 32.9105 83.3263 32.9105 83.9874C32.9105 84.6484 33.3994 85.1843 34.0025 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 85.1843C39.0191 85.1843 39.508 84.6484 39.508 83.9874C39.508 83.3263 39.0191 82.7904 38.416 82.7904C37.8129 82.7904 37.324 83.3263 37.324 83.9874C37.324 84.6484 37.8129 85.1843 38.416 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 85.1843C43.4325 85.1843 43.9214 84.6484 43.9214 83.9874C43.9214 83.3263 43.4325 82.7904 42.8294 82.7904C42.2263 82.7904 41.7374 83.3263 41.7374 83.9874C41.7374 84.6484 42.2263 85.1843 42.8294 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 85.1843C47.8458 85.1843 48.3347 84.6484 48.3347 83.9874C48.3347 83.3263 47.8458 82.7904 47.2427 82.7904C46.6397 82.7904 46.1508 83.3263 46.1508 83.9874C46.1508 84.6484 46.6397 85.1843 47.2427 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 85.1843C52.2591 85.1843 52.748 84.6484 52.748 83.9874C52.748 83.3263 52.2591 82.7904 51.656 82.7904C51.0529 82.7904 50.564 83.3263 50.564 83.9874C50.564 84.6484 51.0529 85.1843 51.656 85.1843Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 89.8163C3.71769 89.8163 4.20658 89.2805 4.20658 88.6194C4.20658 87.9584 3.71769 87.4225 3.11461 87.4225C2.51154 87.4225 2.02264 87.9584 2.02264 88.6194C2.02264 89.2805 2.51154 89.8163 3.11461 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 89.8163C8.13127 89.8163 8.62016 89.2804 8.62016 88.6194C8.62016 87.9584 8.13127 87.4225 7.52819 87.4225C6.92511 87.4225 6.43622 87.9584 6.43622 88.6194C6.43622 89.2804 6.92511 89.8163 7.52819 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 89.8163C12.5444 89.8163 13.0332 89.2804 13.0332 88.6194C13.0332 87.9584 12.5444 87.4225 11.9413 87.4225C11.3382 87.4225 10.8493 87.9584 10.8493 88.6194C10.8493 89.2804 11.3382 89.8163 11.9413 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 89.8163C16.9579 89.8163 17.4468 89.2804 17.4468 88.6194C17.4468 87.9584 16.9579 87.4225 16.3548 87.4225C15.7518 87.4225 15.2629 87.9584 15.2629 88.6194C15.2629 89.2804 15.7518 89.8163 16.3548 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 89.8163C21.3656 89.8163 21.8545 89.2804 21.8545 88.6194C21.8545 87.9584 21.3656 87.4225 20.7625 87.4225C20.1594 87.4225 19.6705 87.9584 19.6705 88.6194C19.6705 89.2804 20.1594 89.8163 20.7625 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 89.8163C25.7792 89.8163 26.268 89.2804 26.268 88.6194C26.268 87.9584 25.7792 87.4225 25.1761 87.4225C24.573 87.4225 24.0841 87.9584 24.0841 88.6194C24.0841 89.2804 24.573 89.8163 25.1761 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 89.8163C30.1922 89.8163 30.6811 89.2804 30.6811 88.6194C30.6811 87.9584 30.1922 87.4225 29.5891 87.4225C28.986 87.4225 28.4971 87.9584 28.4971 88.6194C28.4971 89.2804 28.986 89.8163 29.5891 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 89.8163C34.6056 89.8163 35.0945 89.2804 35.0945 88.6194C35.0945 87.9584 34.6056 87.4225 34.0025 87.4225C33.3994 87.4225 32.9105 87.9584 32.9105 88.6194C32.9105 89.2804 33.3994 89.8163 34.0025 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 89.8163C39.0191 89.8163 39.508 89.2804 39.508 88.6194C39.508 87.9584 39.0191 87.4225 38.416 87.4225C37.8129 87.4225 37.324 87.9584 37.324 88.6194C37.324 89.2804 37.8129 89.8163 38.416 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 89.8163C43.4325 89.8163 43.9214 89.2804 43.9214 88.6194C43.9214 87.9584 43.4325 87.4225 42.8294 87.4225C42.2263 87.4225 41.7374 87.9584 41.7374 88.6194C41.7374 89.2804 42.2263 89.8163 42.8294 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 89.8163C47.8458 89.8163 48.3347 89.2804 48.3347 88.6194C48.3347 87.9584 47.8458 87.4225 47.2427 87.4225C46.6397 87.4225 46.1508 87.9584 46.1508 88.6194C46.1508 89.2804 46.6397 89.8163 47.2427 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 89.8163C52.2591 89.8163 52.748 89.2804 52.748 88.6194C52.748 87.9584 52.2591 87.4225 51.656 87.4225C51.0529 87.4225 50.564 87.9584 50.564 88.6194C50.564 89.2804 51.0529 89.8163 51.656 89.8163Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 94.4483C3.71769 94.4483 4.20658 93.9124 4.20658 93.2514C4.20658 92.5903 3.71769 92.0545 3.11461 92.0545C2.51154 92.0545 2.02264 92.5903 2.02264 93.2514C2.02264 93.9124 2.51154 94.4483 3.11461 94.4483Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 94.4482C8.13127 94.4482 8.62016 93.9124 8.62016 93.2513C8.62016 92.5903 8.13127 92.0544 7.52819 92.0544C6.92511 92.0544 6.43622 92.5903 6.43622 93.2513C6.43622 93.9124 6.92511 94.4482 7.52819 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 94.4482C12.5444 94.4482 13.0332 93.9124 13.0332 93.2513C13.0332 92.5903 12.5444 92.0544 11.9413 92.0544C11.3382 92.0544 10.8493 92.5903 10.8493 93.2513C10.8493 93.9124 11.3382 94.4482 11.9413 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 94.4482C16.9579 94.4482 17.4468 93.9124 17.4468 93.2513C17.4468 92.5903 16.9579 92.0544 16.3548 92.0544C15.7518 92.0544 15.2629 92.5903 15.2629 93.2513C15.2629 93.9124 15.7518 94.4482 16.3548 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 94.4482C21.3656 94.4482 21.8545 93.9124 21.8545 93.2513C21.8545 92.5903 21.3656 92.0544 20.7625 92.0544C20.1594 92.0544 19.6705 92.5903 19.6705 93.2513C19.6705 93.9124 20.1594 94.4482 20.7625 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 94.4482C25.7792 94.4482 26.268 93.9124 26.268 93.2513C26.268 92.5903 25.7792 92.0544 25.1761 92.0544C24.573 92.0544 24.0841 92.5903 24.0841 93.2513C24.0841 93.9124 24.573 94.4482 25.1761 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 94.4482C30.1922 94.4482 30.6811 93.9124 30.6811 93.2513C30.6811 92.5903 30.1922 92.0544 29.5891 92.0544C28.986 92.0544 28.4971 92.5903 28.4971 93.2513C28.4971 93.9124 28.986 94.4482 29.5891 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 94.4482C34.6056 94.4482 35.0945 93.9124 35.0945 93.2513C35.0945 92.5903 34.6056 92.0544 34.0025 92.0544C33.3994 92.0544 32.9105 92.5903 32.9105 93.2513C32.9105 93.9124 33.3994 94.4482 34.0025 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 94.4482C39.0191 94.4482 39.508 93.9124 39.508 93.2513C39.508 92.5903 39.0191 92.0544 38.416 92.0544C37.8129 92.0544 37.324 92.5903 37.324 93.2513C37.324 93.9124 37.8129 94.4482 38.416 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 94.4482C43.4325 94.4482 43.9214 93.9124 43.9214 93.2513C43.9214 92.5903 43.4325 92.0544 42.8294 92.0544C42.2263 92.0544 41.7374 92.5903 41.7374 93.2513C41.7374 93.9124 42.2263 94.4482 42.8294 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 94.4482C47.8458 94.4482 48.3347 93.9124 48.3347 93.2513C48.3347 92.5903 47.8458 92.0544 47.2427 92.0544C46.6397 92.0544 46.1508 92.5903 46.1508 93.2513C46.1508 93.9124 46.6397 94.4482 47.2427 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 94.4482C52.2591 94.4482 52.748 93.9124 52.748 93.2513C52.748 92.5903 52.2591 92.0544 51.656 92.0544C51.0529 92.0544 50.564 92.5903 50.564 93.2513C50.564 93.9124 51.0529 94.4482 51.656 94.4482Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.11461 99.0804C3.71769 99.0804 4.20658 98.5445 4.20658 97.8835C4.20658 97.2225 3.71769 96.6866 3.11461 96.6866C2.51154 96.6866 2.02264 97.2225 2.02264 97.8835C2.02264 98.5445 2.51154 99.0804 3.11461 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M7.52819 99.0804C8.13127 99.0804 8.62016 98.5445 8.62016 97.8835C8.62016 97.2225 8.13127 96.6866 7.52819 96.6866C6.92511 96.6866 6.43622 97.2225 6.43622 97.8835C6.43622 98.5445 6.92511 99.0804 7.52819 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.9413 99.0804C12.5444 99.0804 13.0332 98.5445 13.0332 97.8835C13.0332 97.2225 12.5444 96.6866 11.9413 96.6866C11.3382 96.6866 10.8493 97.2225 10.8493 97.8835C10.8493 98.5445 11.3382 99.0804 11.9413 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M16.3548 99.0804C16.9579 99.0804 17.4468 98.5445 17.4468 97.8835C17.4468 97.2225 16.9579 96.6866 16.3548 96.6866C15.7518 96.6866 15.2629 97.2225 15.2629 97.8835C15.2629 98.5445 15.7518 99.0804 16.3548 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.7625 99.0804C21.3656 99.0804 21.8545 98.5445 21.8545 97.8835C21.8545 97.2225 21.3656 96.6866 20.7625 96.6866C20.1594 96.6866 19.6705 97.2225 19.6705 97.8835C19.6705 98.5445 20.1594 99.0804 20.7625 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.1761 99.0804C25.7792 99.0804 26.268 98.5445 26.268 97.8835C26.268 97.2225 25.7792 96.6866 25.1761 96.6866C24.573 96.6866 24.0841 97.2225 24.0841 97.8835C24.0841 98.5445 24.573 99.0804 25.1761 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.5891 99.0804C30.1922 99.0804 30.6811 98.5445 30.6811 97.8835C30.6811 97.2225 30.1922 96.6866 29.5891 96.6866C28.986 96.6866 28.4971 97.2225 28.4971 97.8835C28.4971 98.5445 28.986 99.0804 29.5891 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M34.0025 99.0804C34.6056 99.0804 35.0945 98.5445 35.0945 97.8835C35.0945 97.2225 34.6056 96.6866 34.0025 96.6866C33.3994 96.6866 32.9105 97.2225 32.9105 97.8835C32.9105 98.5445 33.3994 99.0804 34.0025 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M38.416 99.0804C39.0191 99.0804 39.508 98.5445 39.508 97.8835C39.508 97.2225 39.0191 96.6866 38.416 96.6866C37.8129 96.6866 37.324 97.2225 37.324 97.8835C37.324 98.5445 37.8129 99.0804 38.416 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M42.8294 99.0804C43.4325 99.0804 43.9214 98.5445 43.9214 97.8835C43.9214 97.2225 43.4325 96.6866 42.8294 96.6866C42.2263 96.6866 41.7374 97.2225 41.7374 97.8835C41.7374 98.5445 42.2263 99.0804 42.8294 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M47.2427 99.0804C47.8458 99.0804 48.3347 98.5445 48.3347 97.8835C48.3347 97.2225 47.8458 96.6866 47.2427 96.6866C46.6397 96.6866 46.1508 97.2225 46.1508 97.8835C46.1508 98.5445 46.6397 99.0804 47.2427 99.0804Z"
        fill="#E5E6E6"
      />
      <path
        d="M51.656 99.0804C52.2591 99.0804 52.748 98.5445 52.748 97.8835C52.748 97.2225 52.2591 96.6866 51.656 96.6866C51.0529 96.6866 50.564 97.2225 50.564 97.8835C50.564 98.5445 51.0529 99.0804 51.656 99.0804Z"
        fill="#E5E6E6"
      />
    </svg>
  );
};
