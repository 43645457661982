import { MultipleColorsIconProps } from "typing";

type UniqueClientXSMobileInfoProps = MultipleColorsIconProps & {
  dataTestId?: string;
};
export const UniqueClientMediumSMobileInfo = ({
  width = 32,
  height = 114,
  dataTestId = "unique-client-banner-xs-image",
}: UniqueClientXSMobileInfoProps) => {
  return (
    <svg
      width={width}
      height={height}
      data-testid={dataTestId}
      viewBox="0 0 32 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32 0H0V32H32V0Z" fill="#AA0E27" />
      <path
        d="M14.4151 24.7656H8.79097C8.39735 24.7656 8.0782 24.4464 8.0782 24.0528V14.3719C8.0782 14.0704 8.32288 13.8258 8.6243 13.8258H14.252C14.5534 13.8258 14.7981 14.0704 14.7981 14.3719V23.8223C14.901 23.9216 14.9648 24.0599 14.9648 24.2159C14.9648 24.5174 14.7201 24.7656 14.4187 24.7656M9.17395 23.6698H13.7059V14.9215H9.17395V23.6698Z"
        fill="#ED5568"
      />
      <path
        d="M23.5896 24.7656H17.9654C17.664 24.7656 17.4193 24.5209 17.4193 24.2159C17.4193 24.0635 17.4831 23.9252 17.586 23.8223V14.3719C17.586 14.0704 17.8307 13.8258 18.1321 13.8258H23.7562C24.0577 13.8258 24.3024 14.0704 24.3024 14.3719V24.0528C24.3024 24.4464 23.9832 24.7656 23.5896 24.7656ZM18.6782 23.6698H23.2101V14.9215H18.6782V23.6698Z"
        fill="#ED5568"
      />
      <path
        d="M24.6677 14.9214H18.1284C17.827 14.9214 17.5823 14.6767 17.5823 14.3753V10.8893C17.4794 10.79 17.4156 10.6517 17.4156 10.4957C17.4156 10.1943 17.6603 9.94957 17.9617 9.94957H24.6677C25.0613 9.94957 25.3805 10.2687 25.3805 10.6624V14.2086C25.3805 14.6023 25.0613 14.9214 24.6677 14.9214ZM18.6781 13.8256H24.2882V11.0418H18.6781V13.8256Z"
        fill="#ED5568"
      />
      <path
        d="M14.2522 14.9214H7.71291C7.31927 14.9214 7.00011 14.6023 7.00011 14.2086V10.6624C7.00011 10.2687 7.31927 9.94957 7.71291 9.94957H14.4189C14.7203 9.94957 14.965 10.1943 14.965 10.4957C14.965 10.6482 14.9012 10.79 14.7983 10.8893V14.3753C14.7983 14.6767 14.5536 14.9214 14.2522 14.9214ZM8.0959 13.8256H13.7061V11.0418H8.0959V13.8256Z"
        fill="#ED5568"
      />
      <path
        d="M16.3693 11.0417H12.0996C10.8053 11.0417 9.53216 9.86793 9.52861 8.67284C9.52861 8.21892 9.64919 7.40683 10.4506 7.1054C11.2308 6.81106 12.433 7.13377 14.0288 8.06643C15.2806 8.79696 16.38 9.67643 16.7807 10.1339C16.9226 10.297 16.9545 10.524 16.8658 10.7226C16.7772 10.9176 16.5821 11.0453 16.3658 11.0453M11.0819 8.09835C10.979 8.09835 10.8975 8.11254 10.8336 8.13381C10.7769 8.15509 10.6209 8.21538 10.6209 8.67639C10.6209 9.27216 11.4081 9.9495 12.0961 9.9495H14.887C14.4863 9.65161 14.0004 9.32536 13.4756 9.01683C12.3089 8.33595 11.5181 8.09835 11.0819 8.09835Z"
        fill="#ED5568"
      />
      <path
        d="M20.2775 11.0418H16.0078C15.795 11.0418 15.6 10.9176 15.5078 10.7191C15.4191 10.524 15.451 10.2935 15.5929 10.1304C15.9901 9.67291 17.0929 8.79344 18.3448 8.06646C19.9406 7.13734 21.1428 6.81108 21.9229 7.10542C22.7244 7.40685 22.845 8.2154 22.845 8.67287C22.845 9.86796 21.5719 11.0418 20.2739 11.0418M17.483 9.94597H20.2739C20.9619 9.94597 21.7492 9.26864 21.7492 8.67287C21.7492 8.21186 21.5967 8.15512 21.5364 8.13384C21.203 8.00972 20.3342 8.17639 18.8944 9.01686C18.366 9.32538 17.8838 9.65163 17.483 9.94952V9.94597Z"
        fill="#ED5568"
      />
      <path
        d="M17.9471 24.765H14.433C14.0323 24.765 13.7025 24.4388 13.7025 24.0345V10.6766C13.7025 10.2724 14.0287 9.94612 14.433 9.94612H17.9471C18.3478 9.94612 18.6776 10.2724 18.6776 10.6766V24.0345C18.6776 24.4352 18.3514 24.765 17.9471 24.765ZM14.7982 23.6693H17.5819V11.0418H14.7982V23.6693Z"
        fill="#ED5568"
      />
      <rect width="32" height="32" transform="translate(0 32)" fill="#C1D82F" />
      <path
        d="M19.542 56.7511H9.9254C8.86383 56.7511 8 55.8354 8 54.7101V41.041C8 39.9157 8.86383 39 9.9254 39H19.542C20.6036 39 21.4674 39.9157 21.4674 41.041V44.0124C21.4674 44.325 21.228 44.5787 20.9331 44.5787C20.6382 44.5787 20.3989 44.325 20.3989 44.0124V41.041C20.3989 40.5409 20.0138 40.1363 19.5455 40.1363H9.92887C9.45706 40.1363 9.07545 40.5409 9.07545 41.041V54.7101C9.07545 55.2103 9.45706 55.6148 9.92887 55.6148H19.5455C20.0173 55.6148 20.3989 55.2103 20.3989 54.7101V51.8969C20.3989 51.5843 20.6382 51.3305 20.9331 51.3305C21.228 51.3305 21.4674 51.5843 21.4674 51.8969V54.7101C21.4674 55.8354 20.6036 56.7511 19.542 56.7511Z"
        fill="#E3EDA1"
      />
      <path
        d="M18.5809 43.0599H10.8793C10.5845 43.0599 10.3451 42.8062 10.3451 42.4936C10.3451 42.181 10.5845 41.9236 10.8793 41.9236H18.5809C18.8758 41.9236 19.1152 42.1773 19.1152 42.4936C19.1152 42.8099 18.8758 43.0599 18.5809 43.0599Z"
        fill="#E3EDA1"
      />
      <path
        d="M16.43 46.6417H10.8793C10.5845 46.6417 10.3451 46.388 10.3451 46.0754C10.3451 45.7628 10.5845 45.5091 10.8793 45.5091H16.43C16.7249 45.5091 16.9643 45.7628 16.9643 46.0754C16.9643 46.388 16.7249 46.6417 16.43 46.6417Z"
        fill="#E3EDA1"
      />
      <path
        d="M16.3469 50.2272H10.8829C10.588 50.2272 10.3486 49.9735 10.3486 49.6609C10.3486 49.3483 10.588 49.0946 10.8829 49.0946H16.3469C16.6417 49.0946 16.8811 49.3483 16.8811 49.6609C16.8811 49.9735 16.6417 50.2272 16.3469 50.2272Z"
        fill="#E3EDA1"
      />
      <path
        d="M18.5809 53.8091H10.8793C10.5845 53.8091 10.3451 53.5554 10.3451 53.2428C10.3451 52.9302 10.5845 52.6765 10.8793 52.6765H18.5809C18.8758 52.6765 19.1152 52.9302 19.1152 53.2428C19.1152 53.5554 18.8758 53.8091 18.5809 53.8091Z"
        fill="#E3EDA1"
      />
      <path
        d="M26.2793 56.2546C25.9081 56.2546 25.5577 56.1002 25.2975 55.8244L21.7208 52.0329C20.9992 52.4043 20.1874 52.5551 19.386 52.4632C18.4251 52.3528 17.5196 51.8932 16.8362 51.1687C16.0313 50.3155 15.5873 49.1792 15.5873 47.9693C15.5873 46.7594 16.0313 45.6231 16.8362 44.7699C17.641 43.9167 18.713 43.446 19.8544 43.446C20.9957 43.446 22.0643 43.9167 22.8726 44.7699C23.556 45.4944 23.9896 46.4542 24.0937 47.4728C24.1805 48.3223 24.0382 49.1865 23.6878 49.9478L27.2646 53.7392C27.5282 54.0187 27.6739 54.3865 27.6739 54.7836C27.6739 55.1808 27.5282 55.5486 27.2646 55.828C27.0009 56.1075 26.654 56.2583 26.2793 56.2583M21.811 50.7642C21.9498 50.7642 22.0851 50.8193 22.1891 50.9297L26.0503 55.0227C26.1093 55.0852 26.1891 55.122 26.2758 55.122C26.3626 55.122 26.4424 55.0852 26.5013 55.0227C26.5603 54.9602 26.595 54.8756 26.595 54.7836C26.595 54.6917 26.5603 54.6071 26.5013 54.5446L22.6401 50.4516C22.4632 50.264 22.432 49.9735 22.5638 49.7492C22.9385 49.1093 23.1015 48.3444 23.0217 47.5942C22.942 46.8219 22.6263 46.1232 22.1059 45.5716C21.5022 44.9317 20.7009 44.5787 19.8474 44.5787C18.994 44.5787 18.1892 44.9317 17.5855 45.5716C16.9819 46.2115 16.6489 47.061 16.6489 47.9656C16.6489 48.8703 16.9819 49.7235 17.5855 50.3633C18.1059 50.915 18.7651 51.2496 19.4936 51.3342C20.2013 51.4151 20.9229 51.2422 21.5265 50.8451C21.6133 50.7899 21.7104 50.7605 21.8075 50.7605L21.811 50.7642Z"
        fill="#E3EDA1"
      />
      <rect width="32" height="32" transform="translate(0 64)" fill="#E5E6E6" />
      <path
        d="M22.1878 81.2487C22.0513 81.2487 21.9183 81.1975 21.816 81.0951C20.2643 79.5429 18.1976 78.6866 16.0012 78.6866C13.8049 78.6866 11.7382 79.5429 10.1865 81.0951C9.98185 81.2998 9.64763 81.2998 9.443 81.0951C9.23838 80.8904 9.23838 80.5561 9.443 80.3514C11.196 78.5979 13.5253 77.6324 16.0012 77.6324C18.4772 77.6324 20.8099 78.5979 22.5629 80.3514C22.7675 80.5561 22.7675 80.8904 22.5629 81.0951C22.4606 81.1975 22.3242 81.2487 22.1912 81.2487"
        fill="white"
      />
      <path
        d="M12.8507 83.5753C12.7143 83.5753 12.5813 83.5242 12.479 83.4218C12.2744 83.2171 12.2744 82.8828 12.479 82.6781C14.4229 80.7335 17.5844 80.7335 19.5283 82.6781C19.733 82.8828 19.733 83.2171 19.5283 83.4218C19.3237 83.6265 18.9895 83.6265 18.7849 83.4218C17.2536 81.89 14.7606 81.89 13.2259 83.4218C13.1236 83.5242 12.9871 83.5753 12.8541 83.5753"
        fill="white"
      />
      <path
        d="M25.2949 78.5126C25.1585 78.5126 25.0255 78.4614 24.9232 78.3591C22.5393 75.9745 19.371 74.6644 16.0049 74.6644C12.6388 74.6644 9.46714 75.9779 7.08326 78.3591C6.87863 78.5638 6.54441 78.5638 6.33979 78.3591C6.13516 78.1544 6.13516 77.8201 6.33979 77.6154C8.92148 75.0329 12.3524 73.6103 16.0049 73.6103C19.6575 73.6103 23.0884 75.0329 25.6701 77.6154C25.8747 77.8201 25.8747 78.1544 25.6701 78.3591C25.5678 78.4614 25.4313 78.5126 25.2983 78.5126"
        fill="white"
      />
      <path
        d="M16.9296 85.4619C16.9296 85.9736 16.5135 86.3898 16.002 86.3898C15.4904 86.3898 15.0743 85.9736 15.0743 85.4619C15.0743 84.9501 15.4904 84.5339 16.002 84.5339C16.5135 84.5339 16.9296 84.9501 16.9296 85.4619Z"
        fill="white"
      />
      <path
        d="M0.688866 97.436C1.06932 97.436 1.37773 97.1146 1.37773 96.718C1.37773 96.3215 1.06932 96 0.688866 96C0.308416 96 0 96.3215 0 96.718C0 97.1146 0.308416 97.436 0.688866 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 97.436C3.85361 97.436 4.16202 97.1146 4.16202 96.718C4.16202 96.3215 3.85361 96 3.47316 96C3.09271 96 2.78429 96.3215 2.78429 96.718C2.78429 97.1146 3.09271 97.436 3.47316 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 97.436C6.6376 97.436 6.94602 97.1146 6.94602 96.718C6.94602 96.3215 6.6376 96 6.25715 96C5.8767 96 5.56828 96.3215 5.56828 96.718C5.56828 97.1146 5.8767 97.436 6.25715 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 97.436C9.42189 97.436 9.73031 97.1146 9.73031 96.718C9.73031 96.3215 9.42189 96 9.04144 96C8.66099 96 8.35257 96.3215 8.35257 96.718C8.35257 97.1146 8.66099 97.436 9.04144 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 97.436C12.2024 97.436 12.5108 97.1146 12.5108 96.718C12.5108 96.3215 12.2024 96 11.822 96C11.4415 96 11.1331 96.3215 11.1331 96.718C11.1331 97.1146 11.4415 97.436 11.822 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 97.436C14.9867 97.436 15.2951 97.1146 15.2951 96.718C15.2951 96.3215 14.9867 96 14.6063 96C14.2258 96 13.9174 96.3215 13.9174 96.718C13.9174 97.1146 14.2258 97.436 14.6063 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 97.436C17.7707 97.436 18.0791 97.1146 18.0791 96.718C18.0791 96.3215 17.7707 96 17.3902 96C17.0098 96 16.7014 96.3215 16.7014 96.718C16.7014 97.1146 17.0098 97.436 17.3902 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1745 97.436C20.555 97.436 20.8634 97.1146 20.8634 96.718C20.8634 96.3215 20.555 96 20.1745 96C19.7941 96 19.4857 96.3215 19.4857 96.718C19.4857 97.1146 19.7941 97.436 20.1745 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 97.436C23.3391 97.436 23.6476 97.1146 23.6476 96.718C23.6476 96.3215 23.3391 96 22.9587 96C22.5782 96 22.2698 96.3215 22.2698 96.718C22.2698 97.1146 22.5782 97.436 22.9587 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 97.436C26.1233 97.436 26.4317 97.1146 26.4317 96.718C26.4317 96.3215 26.1233 96 25.7428 96C25.3624 96 25.054 96.3215 25.054 96.718C25.054 97.1146 25.3624 97.436 25.7428 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 97.436C28.9074 97.436 29.2158 97.1146 29.2158 96.718C29.2158 96.3215 28.9074 96 28.527 96C28.1465 96 27.8381 96.3215 27.8381 96.718C27.8381 97.1146 28.1465 97.436 28.527 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 97.436C31.6916 97.436 32 97.1146 32 96.718C32 96.3215 31.6916 96 31.3111 96C30.9307 96 30.6223 96.3215 30.6223 96.718C30.6223 97.1146 30.9307 97.436 31.3111 97.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 100.215C1.06932 100.215 1.37773 99.8932 1.37773 99.4966C1.37773 99.1001 1.06932 98.7786 0.688866 98.7786C0.308416 98.7786 0 99.1001 0 99.4966C0 99.8932 0.308416 100.215 0.688866 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 100.215C3.85361 100.215 4.16202 99.8932 4.16202 99.4966C4.16202 99.1001 3.85361 98.7786 3.47316 98.7786C3.09271 98.7786 2.78429 99.1001 2.78429 99.4966C2.78429 99.8932 3.09271 100.215 3.47316 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 100.215C6.6376 100.215 6.94602 99.8932 6.94602 99.4966C6.94602 99.1001 6.6376 98.7786 6.25715 98.7786C5.8767 98.7786 5.56828 99.1001 5.56828 99.4966C5.56828 99.8932 5.8767 100.215 6.25715 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 100.215C9.42189 100.215 9.73031 99.8932 9.73031 99.4966C9.73031 99.1001 9.42189 98.7786 9.04144 98.7786C8.66099 98.7786 8.35257 99.1001 8.35257 99.4966C8.35257 99.8932 8.66099 100.215 9.04144 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 100.215C12.2024 100.215 12.5108 99.8932 12.5108 99.4966C12.5108 99.1001 12.2024 98.7786 11.822 98.7786C11.4415 98.7786 11.1331 99.1001 11.1331 99.4966C11.1331 99.8932 11.4415 100.215 11.822 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 100.215C14.9867 100.215 15.2951 99.8932 15.2951 99.4966C15.2951 99.1001 14.9867 98.7786 14.6063 98.7786C14.2258 98.7786 13.9174 99.1001 13.9174 99.4966C13.9174 99.8932 14.2258 100.215 14.6063 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 100.215C17.7707 100.215 18.0791 99.8932 18.0791 99.4966C18.0791 99.1001 17.7707 98.7786 17.3902 98.7786C17.0098 98.7786 16.7014 99.1001 16.7014 99.4966C16.7014 99.8932 17.0098 100.215 17.3902 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 100.215C20.5549 100.215 20.8633 99.8932 20.8633 99.4966C20.8633 99.1001 20.5549 98.7786 20.1744 98.7786C19.794 98.7786 19.4855 99.1001 19.4855 99.4966C19.4855 99.8932 19.794 100.215 20.1744 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 100.215C23.3391 100.215 23.6476 99.8932 23.6476 99.4966C23.6476 99.1001 23.3391 98.7786 22.9587 98.7786C22.5782 98.7786 22.2698 99.1001 22.2698 99.4966C22.2698 99.8932 22.5782 100.215 22.9587 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 100.215C26.1233 100.215 26.4317 99.8932 26.4317 99.4966C26.4317 99.1001 26.1233 98.7786 25.7428 98.7786C25.3624 98.7786 25.054 99.1001 25.054 99.4966C25.054 99.8932 25.3624 100.215 25.7428 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 100.215C28.9074 100.215 29.2158 99.8932 29.2158 99.4966C29.2158 99.1001 28.9074 98.7786 28.527 98.7786C28.1465 98.7786 27.8381 99.1001 27.8381 99.4966C27.8381 99.8932 28.1465 100.215 28.527 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 100.215C31.6916 100.215 32 99.8932 32 99.4966C32 99.1001 31.6916 98.7786 31.3111 98.7786C30.9307 98.7786 30.6223 99.1001 30.6223 99.4966C30.6223 99.8932 30.9307 100.215 31.3111 100.215Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 102.993C1.06932 102.993 1.37773 102.672 1.37773 102.275C1.37773 101.879 1.06932 101.557 0.688866 101.557C0.308416 101.557 0 101.879 0 102.275C0 102.672 0.308416 102.993 0.688866 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 102.993C3.85361 102.993 4.16202 102.672 4.16202 102.275C4.16202 101.879 3.85361 101.557 3.47316 101.557C3.09271 101.557 2.78429 101.879 2.78429 102.275C2.78429 102.672 3.09271 102.993 3.47316 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 102.993C6.6376 102.993 6.94602 102.672 6.94602 102.275C6.94602 101.879 6.6376 101.557 6.25715 101.557C5.8767 101.557 5.56828 101.879 5.56828 102.275C5.56828 102.672 5.8767 102.993 6.25715 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 102.993C9.42189 102.993 9.73031 102.672 9.73031 102.275C9.73031 101.879 9.42189 101.557 9.04144 101.557C8.66099 101.557 8.35257 101.879 8.35257 102.275C8.35257 102.672 8.66099 102.993 9.04144 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 102.993C12.2024 102.993 12.5108 102.672 12.5108 102.275C12.5108 101.879 12.2024 101.557 11.822 101.557C11.4415 101.557 11.1331 101.879 11.1331 102.275C11.1331 102.672 11.4415 102.993 11.822 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 102.993C14.9867 102.993 15.2951 102.672 15.2951 102.275C15.2951 101.879 14.9867 101.557 14.6063 101.557C14.2258 101.557 13.9174 101.879 13.9174 102.275C13.9174 102.672 14.2258 102.993 14.6063 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 102.993C17.7707 102.993 18.0791 102.672 18.0791 102.275C18.0791 101.879 17.7707 101.557 17.3902 101.557C17.0098 101.557 16.7014 101.879 16.7014 102.275C16.7014 102.672 17.0098 102.993 17.3902 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 102.993C20.5549 102.993 20.8633 102.672 20.8633 102.275C20.8633 101.879 20.5549 101.557 20.1744 101.557C19.794 101.557 19.4855 101.879 19.4855 102.275C19.4855 102.672 19.794 102.993 20.1744 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 102.993C23.3391 102.993 23.6476 102.672 23.6476 102.275C23.6476 101.879 23.3391 101.557 22.9587 101.557C22.5782 101.557 22.2698 101.879 22.2698 102.275C22.2698 102.672 22.5782 102.993 22.9587 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 102.993C26.1233 102.993 26.4317 102.672 26.4317 102.275C26.4317 101.879 26.1233 101.557 25.7428 101.557C25.3624 101.557 25.054 101.879 25.054 102.275C25.054 102.672 25.3624 102.993 25.7428 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 102.993C28.9074 102.993 29.2158 102.672 29.2158 102.275C29.2158 101.879 28.9074 101.557 28.527 101.557C28.1465 101.557 27.8381 101.879 27.8381 102.275C27.8381 102.672 28.1465 102.993 28.527 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 102.993C31.6916 102.993 32 102.672 32 102.275C32 101.879 31.6916 101.557 31.3111 101.557C30.9307 101.557 30.6223 101.879 30.6223 102.275C30.6223 102.672 30.9307 102.993 31.3111 102.993Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 105.772C1.06932 105.772 1.37773 105.45 1.37773 105.054C1.37773 104.657 1.06932 104.336 0.688866 104.336C0.308416 104.336 0 104.657 0 105.054C0 105.45 0.308416 105.772 0.688866 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 105.772C3.85361 105.772 4.16202 105.45 4.16202 105.054C4.16202 104.657 3.85361 104.336 3.47316 104.336C3.09271 104.336 2.78429 104.657 2.78429 105.054C2.78429 105.45 3.09271 105.772 3.47316 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 105.772C6.6376 105.772 6.94602 105.45 6.94602 105.054C6.94602 104.657 6.6376 104.336 6.25715 104.336C5.8767 104.336 5.56828 104.657 5.56828 105.054C5.56828 105.45 5.8767 105.772 6.25715 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 105.772C9.42189 105.772 9.73031 105.45 9.73031 105.054C9.73031 104.657 9.42189 104.336 9.04144 104.336C8.66099 104.336 8.35257 104.657 8.35257 105.054C8.35257 105.45 8.66099 105.772 9.04144 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 105.772C12.2024 105.772 12.5108 105.45 12.5108 105.054C12.5108 104.657 12.2024 104.336 11.822 104.336C11.4415 104.336 11.1331 104.657 11.1331 105.054C11.1331 105.45 11.4415 105.772 11.822 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 105.772C14.9867 105.772 15.2951 105.45 15.2951 105.054C15.2951 104.657 14.9867 104.336 14.6063 104.336C14.2258 104.336 13.9174 104.657 13.9174 105.054C13.9174 105.45 14.2258 105.772 14.6063 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 105.772C17.7707 105.772 18.0791 105.45 18.0791 105.054C18.0791 104.657 17.7707 104.336 17.3902 104.336C17.0098 104.336 16.7014 104.657 16.7014 105.054C16.7014 105.45 17.0098 105.772 17.3902 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 105.772C20.5549 105.772 20.8633 105.45 20.8633 105.054C20.8633 104.657 20.5549 104.336 20.1744 104.336C19.794 104.336 19.4855 104.657 19.4855 105.054C19.4855 105.45 19.794 105.772 20.1744 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 105.772C23.3391 105.772 23.6476 105.45 23.6476 105.054C23.6476 104.657 23.3391 104.336 22.9587 104.336C22.5782 104.336 22.2698 104.657 22.2698 105.054C22.2698 105.45 22.5782 105.772 22.9587 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 105.772C26.1233 105.772 26.4317 105.45 26.4317 105.054C26.4317 104.657 26.1233 104.336 25.7428 104.336C25.3624 104.336 25.054 104.657 25.054 105.054C25.054 105.45 25.3624 105.772 25.7428 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 105.772C28.9074 105.772 29.2158 105.45 29.2158 105.054C29.2158 104.657 28.9074 104.336 28.527 104.336C28.1465 104.336 27.8381 104.657 27.8381 105.054C27.8381 105.45 28.1465 105.772 28.527 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 105.772C31.6916 105.772 32 105.45 32 105.054C32 104.657 31.6916 104.336 31.3111 104.336C30.9307 104.336 30.6223 104.657 30.6223 105.054C30.6223 105.45 30.9307 105.772 31.3111 105.772Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 108.55C1.06932 108.55 1.37773 108.229 1.37773 107.832C1.37773 107.436 1.06932 107.114 0.688866 107.114C0.308416 107.114 0 107.436 0 107.832C0 108.229 0.308416 108.55 0.688866 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 108.55C3.85361 108.55 4.16202 108.229 4.16202 107.832C4.16202 107.436 3.85361 107.114 3.47316 107.114C3.09271 107.114 2.78429 107.436 2.78429 107.832C2.78429 108.229 3.09271 108.55 3.47316 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 108.55C6.6376 108.55 6.94602 108.229 6.94602 107.832C6.94602 107.436 6.6376 107.114 6.25715 107.114C5.8767 107.114 5.56828 107.436 5.56828 107.832C5.56828 108.229 5.8767 108.55 6.25715 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 108.55C9.42189 108.55 9.73031 108.229 9.73031 107.832C9.73031 107.436 9.42189 107.114 9.04144 107.114C8.66099 107.114 8.35257 107.436 8.35257 107.832C8.35257 108.229 8.66099 108.55 9.04144 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 108.55C12.2024 108.55 12.5108 108.229 12.5108 107.832C12.5108 107.436 12.2024 107.114 11.822 107.114C11.4415 107.114 11.1331 107.436 11.1331 107.832C11.1331 108.229 11.4415 108.55 11.822 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 108.55C14.9867 108.55 15.2951 108.229 15.2951 107.832C15.2951 107.436 14.9867 107.114 14.6063 107.114C14.2258 107.114 13.9174 107.436 13.9174 107.832C13.9174 108.229 14.2258 108.55 14.6063 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 108.55C17.7707 108.55 18.0791 108.229 18.0791 107.832C18.0791 107.436 17.7707 107.114 17.3902 107.114C17.0098 107.114 16.7014 107.436 16.7014 107.832C16.7014 108.229 17.0098 108.55 17.3902 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 108.55C20.5549 108.55 20.8633 108.229 20.8633 107.832C20.8633 107.436 20.5549 107.114 20.1744 107.114C19.794 107.114 19.4855 107.436 19.4855 107.832C19.4855 108.229 19.794 108.55 20.1744 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 108.55C23.3391 108.55 23.6476 108.229 23.6476 107.832C23.6476 107.436 23.3391 107.114 22.9587 107.114C22.5782 107.114 22.2698 107.436 22.2698 107.832C22.2698 108.229 22.5782 108.55 22.9587 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 108.55C26.1233 108.55 26.4317 108.229 26.4317 107.832C26.4317 107.436 26.1233 107.114 25.7428 107.114C25.3624 107.114 25.054 107.436 25.054 107.832C25.054 108.229 25.3624 108.55 25.7428 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 108.55C28.9074 108.55 29.2158 108.229 29.2158 107.832C29.2158 107.436 28.9074 107.114 28.527 107.114C28.1465 107.114 27.8381 107.436 27.8381 107.832C27.8381 108.229 28.1465 108.55 28.527 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 108.55C31.6916 108.55 32 108.229 32 107.832C32 107.436 31.6916 107.114 31.3111 107.114C30.9307 107.114 30.6223 107.436 30.6223 107.832C30.6223 108.229 30.9307 108.55 31.3111 108.55Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 111.329C1.06932 111.329 1.37773 111.007 1.37773 110.61C1.37773 110.214 1.06932 109.892 0.688866 109.892C0.308416 109.892 0 110.214 0 110.61C0 111.007 0.308416 111.329 0.688866 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 111.329C3.85361 111.329 4.16202 111.007 4.16202 110.61C4.16202 110.214 3.85361 109.892 3.47316 109.892C3.09271 109.892 2.78429 110.214 2.78429 110.61C2.78429 111.007 3.09271 111.329 3.47316 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 111.329C6.6376 111.329 6.94602 111.007 6.94602 110.61C6.94602 110.214 6.6376 109.892 6.25715 109.892C5.8767 109.892 5.56828 110.214 5.56828 110.61C5.56828 111.007 5.8767 111.329 6.25715 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 111.329C9.42189 111.329 9.73031 111.007 9.73031 110.61C9.73031 110.214 9.42189 109.892 9.04144 109.892C8.66099 109.892 8.35257 110.214 8.35257 110.61C8.35257 111.007 8.66099 111.329 9.04144 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 111.329C12.2024 111.329 12.5108 111.007 12.5108 110.61C12.5108 110.214 12.2024 109.892 11.822 109.892C11.4415 109.892 11.1331 110.214 11.1331 110.61C11.1331 111.007 11.4415 111.329 11.822 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 111.329C14.9867 111.329 15.2951 111.007 15.2951 110.61C15.2951 110.214 14.9867 109.892 14.6063 109.892C14.2258 109.892 13.9174 110.214 13.9174 110.61C13.9174 111.007 14.2258 111.329 14.6063 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 111.329C17.7707 111.329 18.0791 111.007 18.0791 110.61C18.0791 110.214 17.7707 109.892 17.3902 109.892C17.0098 109.892 16.7014 110.214 16.7014 110.61C16.7014 111.007 17.0098 111.329 17.3902 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 111.329C20.5549 111.329 20.8633 111.007 20.8633 110.61C20.8633 110.214 20.5549 109.892 20.1744 109.892C19.794 109.892 19.4855 110.214 19.4855 110.61C19.4855 111.007 19.794 111.329 20.1744 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 111.329C23.3391 111.329 23.6476 111.007 23.6476 110.61C23.6476 110.214 23.3391 109.892 22.9587 109.892C22.5782 109.892 22.2698 110.214 22.2698 110.61C22.2698 111.007 22.5782 111.329 22.9587 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 111.329C26.1233 111.329 26.4317 111.007 26.4317 110.61C26.4317 110.214 26.1233 109.892 25.7428 109.892C25.3624 109.892 25.054 110.214 25.054 110.61C25.054 111.007 25.3624 111.329 25.7428 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 111.329C28.9074 111.329 29.2158 111.007 29.2158 110.61C29.2158 110.214 28.9074 109.892 28.527 109.892C28.1465 109.892 27.8381 110.214 27.8381 110.61C27.8381 111.007 28.1465 111.329 28.527 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 111.329C31.6916 111.329 32 111.007 32 110.61C32 110.214 31.6916 109.892 31.3111 109.892C30.9307 109.892 30.6223 110.214 30.6223 110.61C30.6223 111.007 30.9307 111.329 31.3111 111.329Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 114.107C1.06932 114.107 1.37773 113.786 1.37773 113.389C1.37773 112.993 1.06932 112.671 0.688866 112.671C0.308416 112.671 0 112.993 0 113.389C0 113.786 0.308416 114.107 0.688866 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 114.107C3.85361 114.107 4.16202 113.786 4.16202 113.389C4.16202 112.993 3.85361 112.671 3.47316 112.671C3.09271 112.671 2.78429 112.993 2.78429 113.389C2.78429 113.786 3.09271 114.107 3.47316 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 114.107C6.6376 114.107 6.94602 113.786 6.94602 113.389C6.94602 112.993 6.6376 112.671 6.25715 112.671C5.8767 112.671 5.56828 112.993 5.56828 113.389C5.56828 113.786 5.8767 114.107 6.25715 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 114.107C9.42189 114.107 9.73031 113.786 9.73031 113.389C9.73031 112.993 9.42189 112.671 9.04144 112.671C8.66099 112.671 8.35257 112.993 8.35257 113.389C8.35257 113.786 8.66099 114.107 9.04144 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 114.107C12.2024 114.107 12.5108 113.786 12.5108 113.389C12.5108 112.993 12.2024 112.671 11.822 112.671C11.4415 112.671 11.1331 112.993 11.1331 113.389C11.1331 113.786 11.4415 114.107 11.822 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 114.107C14.9867 114.107 15.2951 113.786 15.2951 113.389C15.2951 112.993 14.9867 112.671 14.6063 112.671C14.2258 112.671 13.9174 112.993 13.9174 113.389C13.9174 113.786 14.2258 114.107 14.6063 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 114.107C17.7707 114.107 18.0791 113.786 18.0791 113.389C18.0791 112.993 17.7707 112.671 17.3902 112.671C17.0098 112.671 16.7014 112.993 16.7014 113.389C16.7014 113.786 17.0098 114.107 17.3902 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 114.107C20.5549 114.107 20.8633 113.786 20.8633 113.389C20.8633 112.993 20.5549 112.671 20.1744 112.671C19.794 112.671 19.4855 112.993 19.4855 113.389C19.4855 113.786 19.794 114.107 20.1744 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 114.107C23.3391 114.107 23.6476 113.786 23.6476 113.389C23.6476 112.993 23.3391 112.671 22.9587 112.671C22.5782 112.671 22.2698 112.993 22.2698 113.389C22.2698 113.786 22.5782 114.107 22.9587 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 114.107C26.1233 114.107 26.4317 113.786 26.4317 113.389C26.4317 112.993 26.1233 112.671 25.7428 112.671C25.3624 112.671 25.054 112.993 25.054 113.389C25.054 113.786 25.3624 114.107 25.7428 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 114.107C28.9074 114.107 29.2158 113.786 29.2158 113.389C29.2158 112.993 28.9074 112.671 28.527 112.671C28.1465 112.671 27.8381 112.993 27.8381 113.389C27.8381 113.786 28.1465 114.107 28.527 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 114.107C31.6916 114.107 32 113.786 32 113.389C32 112.993 31.6916 112.671 31.3111 112.671C30.9307 112.671 30.6223 112.993 30.6223 113.389C30.6223 113.786 30.9307 114.107 31.3111 114.107Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 116.886C1.06932 116.886 1.37773 116.564 1.37773 116.168C1.37773 115.771 1.06932 115.45 0.688866 115.45C0.308416 115.45 0 115.771 0 116.168C0 116.564 0.308416 116.886 0.688866 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 116.886C3.85361 116.886 4.16202 116.564 4.16202 116.168C4.16202 115.771 3.85361 115.45 3.47316 115.45C3.09271 115.45 2.78429 115.771 2.78429 116.168C2.78429 116.564 3.09271 116.886 3.47316 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 116.886C6.6376 116.886 6.94602 116.564 6.94602 116.168C6.94602 115.771 6.6376 115.45 6.25715 115.45C5.8767 115.45 5.56828 115.771 5.56828 116.168C5.56828 116.564 5.8767 116.886 6.25715 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 116.886C9.42189 116.886 9.73031 116.564 9.73031 116.168C9.73031 115.771 9.42189 115.45 9.04144 115.45C8.66099 115.45 8.35257 115.771 8.35257 116.168C8.35257 116.564 8.66099 116.886 9.04144 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 116.886C12.2024 116.886 12.5108 116.564 12.5108 116.168C12.5108 115.771 12.2024 115.45 11.822 115.45C11.4415 115.45 11.1331 115.771 11.1331 116.168C11.1331 116.564 11.4415 116.886 11.822 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 116.886C14.9867 116.886 15.2951 116.564 15.2951 116.168C15.2951 115.771 14.9867 115.45 14.6063 115.45C14.2258 115.45 13.9174 115.771 13.9174 116.168C13.9174 116.564 14.2258 116.886 14.6063 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 116.886C17.7707 116.886 18.0791 116.564 18.0791 116.168C18.0791 115.771 17.7707 115.45 17.3902 115.45C17.0098 115.45 16.7014 115.771 16.7014 116.168C16.7014 116.564 17.0098 116.886 17.3902 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 116.886C20.5549 116.886 20.8633 116.564 20.8633 116.168C20.8633 115.771 20.5549 115.45 20.1744 115.45C19.794 115.45 19.4855 115.771 19.4855 116.168C19.4855 116.564 19.794 116.886 20.1744 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 116.886C23.3391 116.886 23.6476 116.564 23.6476 116.168C23.6476 115.771 23.3391 115.45 22.9587 115.45C22.5782 115.45 22.2698 115.771 22.2698 116.168C22.2698 116.564 22.5782 116.886 22.9587 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 116.886C26.1233 116.886 26.4317 116.564 26.4317 116.168C26.4317 115.771 26.1233 115.45 25.7428 115.45C25.3624 115.45 25.054 115.771 25.054 116.168C25.054 116.564 25.3624 116.886 25.7428 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 116.886C28.9074 116.886 29.2158 116.564 29.2158 116.168C29.2158 115.771 28.9074 115.45 28.527 115.45C28.1465 115.45 27.8381 115.771 27.8381 116.168C27.8381 116.564 28.1465 116.886 28.527 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 116.886C31.6916 116.886 32 116.564 32 116.168C32 115.771 31.6916 115.45 31.3111 115.45C30.9307 115.45 30.6223 115.771 30.6223 116.168C30.6223 116.564 30.9307 116.886 31.3111 116.886Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 119.664C1.06932 119.664 1.37773 119.343 1.37773 118.946C1.37773 118.55 1.06932 118.228 0.688866 118.228C0.308416 118.228 0 118.55 0 118.946C0 119.343 0.308416 119.664 0.688866 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 119.664C3.85361 119.664 4.16202 119.343 4.16202 118.946C4.16202 118.55 3.85361 118.228 3.47316 118.228C3.09271 118.228 2.78429 118.55 2.78429 118.946C2.78429 119.343 3.09271 119.664 3.47316 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 119.664C6.6376 119.664 6.94602 119.343 6.94602 118.946C6.94602 118.55 6.6376 118.228 6.25715 118.228C5.8767 118.228 5.56828 118.55 5.56828 118.946C5.56828 119.343 5.8767 119.664 6.25715 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 119.664C9.42189 119.664 9.73031 119.343 9.73031 118.946C9.73031 118.55 9.42189 118.228 9.04144 118.228C8.66099 118.228 8.35257 118.55 8.35257 118.946C8.35257 119.343 8.66099 119.664 9.04144 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 119.664C12.2024 119.664 12.5108 119.343 12.5108 118.946C12.5108 118.55 12.2024 118.228 11.822 118.228C11.4415 118.228 11.1331 118.55 11.1331 118.946C11.1331 119.343 11.4415 119.664 11.822 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 119.664C14.9867 119.664 15.2951 119.343 15.2951 118.946C15.2951 118.55 14.9867 118.228 14.6063 118.228C14.2258 118.228 13.9174 118.55 13.9174 118.946C13.9174 119.343 14.2258 119.664 14.6063 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 119.664C17.7707 119.664 18.0791 119.343 18.0791 118.946C18.0791 118.55 17.7707 118.228 17.3902 118.228C17.0098 118.228 16.7014 118.55 16.7014 118.946C16.7014 119.343 17.0098 119.664 17.3902 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 119.664C20.5549 119.664 20.8633 119.343 20.8633 118.946C20.8633 118.55 20.5549 118.228 20.1744 118.228C19.794 118.228 19.4855 118.55 19.4855 118.946C19.4855 119.343 19.794 119.664 20.1744 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 119.664C23.3391 119.664 23.6476 119.343 23.6476 118.946C23.6476 118.55 23.3391 118.228 22.9587 118.228C22.5782 118.228 22.2698 118.55 22.2698 118.946C22.2698 119.343 22.5782 119.664 22.9587 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 119.664C26.1233 119.664 26.4317 119.343 26.4317 118.946C26.4317 118.55 26.1233 118.228 25.7428 118.228C25.3624 118.228 25.054 118.55 25.054 118.946C25.054 119.343 25.3624 119.664 25.7428 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 119.664C28.9074 119.664 29.2158 119.343 29.2158 118.946C29.2158 118.55 28.9074 118.228 28.527 118.228C28.1465 118.228 27.8381 118.55 27.8381 118.946C27.8381 119.343 28.1465 119.664 28.527 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 119.664C31.6916 119.664 32 119.343 32 118.946C32 118.55 31.6916 118.228 31.3111 118.228C30.9307 118.228 30.6223 118.55 30.6223 118.946C30.6223 119.343 30.9307 119.664 31.3111 119.664Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 122.443C1.06932 122.443 1.37773 122.122 1.37773 121.725C1.37773 121.329 1.06932 121.007 0.688866 121.007C0.308416 121.007 0 121.329 0 121.725C0 122.122 0.308416 122.443 0.688866 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 122.443C3.85361 122.443 4.16202 122.122 4.16202 121.725C4.16202 121.329 3.85361 121.007 3.47316 121.007C3.09271 121.007 2.78429 121.329 2.78429 121.725C2.78429 122.122 3.09271 122.443 3.47316 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 122.443C6.6376 122.443 6.94602 122.122 6.94602 121.725C6.94602 121.329 6.6376 121.007 6.25715 121.007C5.8767 121.007 5.56828 121.329 5.56828 121.725C5.56828 122.122 5.8767 122.443 6.25715 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 122.443C9.42189 122.443 9.73031 122.122 9.73031 121.725C9.73031 121.329 9.42189 121.007 9.04144 121.007C8.66099 121.007 8.35257 121.329 8.35257 121.725C8.35257 122.122 8.66099 122.443 9.04144 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 122.443C12.2024 122.443 12.5108 122.122 12.5108 121.725C12.5108 121.329 12.2024 121.007 11.822 121.007C11.4415 121.007 11.1331 121.329 11.1331 121.725C11.1331 122.122 11.4415 122.443 11.822 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 122.443C14.9867 122.443 15.2951 122.122 15.2951 121.725C15.2951 121.329 14.9867 121.007 14.6063 121.007C14.2258 121.007 13.9174 121.329 13.9174 121.725C13.9174 122.122 14.2258 122.443 14.6063 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 122.443C17.7707 122.443 18.0791 122.122 18.0791 121.725C18.0791 121.329 17.7707 121.007 17.3902 121.007C17.0098 121.007 16.7014 121.329 16.7014 121.725C16.7014 122.122 17.0098 122.443 17.3902 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 122.443C20.5549 122.443 20.8633 122.122 20.8633 121.725C20.8633 121.329 20.5549 121.007 20.1744 121.007C19.794 121.007 19.4855 121.329 19.4855 121.725C19.4855 122.122 19.794 122.443 20.1744 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 122.443C23.3391 122.443 23.6476 122.122 23.6476 121.725C23.6476 121.329 23.3391 121.007 22.9587 121.007C22.5782 121.007 22.2698 121.329 22.2698 121.725C22.2698 122.122 22.5782 122.443 22.9587 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 122.443C26.1233 122.443 26.4317 122.122 26.4317 121.725C26.4317 121.329 26.1233 121.007 25.7428 121.007C25.3624 121.007 25.054 121.329 25.054 121.725C25.054 122.122 25.3624 122.443 25.7428 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 122.443C28.9074 122.443 29.2158 122.122 29.2158 121.725C29.2158 121.329 28.9074 121.007 28.527 121.007C28.1465 121.007 27.8381 121.329 27.8381 121.725C27.8381 122.122 28.1465 122.443 28.527 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 122.443C31.6916 122.443 32 122.122 32 121.725C32 121.329 31.6916 121.007 31.3111 121.007C30.9307 121.007 30.6223 121.329 30.6223 121.725C30.6223 122.122 30.9307 122.443 31.3111 122.443Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 125.221C1.06932 125.221 1.37773 124.9 1.37773 124.503C1.37773 124.107 1.06932 123.785 0.688866 123.785C0.308416 123.785 0 124.107 0 124.503C0 124.9 0.308416 125.221 0.688866 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 125.221C3.85361 125.221 4.16202 124.9 4.16202 124.503C4.16202 124.107 3.85361 123.785 3.47316 123.785C3.09271 123.785 2.78429 124.107 2.78429 124.503C2.78429 124.9 3.09271 125.221 3.47316 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 125.221C6.6376 125.221 6.94602 124.9 6.94602 124.503C6.94602 124.107 6.6376 123.785 6.25715 123.785C5.8767 123.785 5.56828 124.107 5.56828 124.503C5.56828 124.9 5.8767 125.221 6.25715 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 125.221C9.42189 125.221 9.73031 124.9 9.73031 124.503C9.73031 124.107 9.42189 123.785 9.04144 123.785C8.66099 123.785 8.35257 124.107 8.35257 124.503C8.35257 124.9 8.66099 125.221 9.04144 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 125.221C12.2024 125.221 12.5108 124.9 12.5108 124.503C12.5108 124.107 12.2024 123.785 11.822 123.785C11.4415 123.785 11.1331 124.107 11.1331 124.503C11.1331 124.9 11.4415 125.221 11.822 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 125.221C14.9867 125.221 15.2951 124.9 15.2951 124.503C15.2951 124.107 14.9867 123.785 14.6063 123.785C14.2258 123.785 13.9174 124.107 13.9174 124.503C13.9174 124.9 14.2258 125.221 14.6063 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 125.221C17.7707 125.221 18.0791 124.9 18.0791 124.503C18.0791 124.107 17.7707 123.785 17.3902 123.785C17.0098 123.785 16.7014 124.107 16.7014 124.503C16.7014 124.9 17.0098 125.221 17.3902 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 125.221C20.5549 125.221 20.8633 124.9 20.8633 124.503C20.8633 124.107 20.5549 123.785 20.1744 123.785C19.794 123.785 19.4855 124.107 19.4855 124.503C19.4855 124.9 19.794 125.221 20.1744 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 125.221C23.3391 125.221 23.6476 124.9 23.6476 124.503C23.6476 124.107 23.3391 123.785 22.9587 123.785C22.5782 123.785 22.2698 124.107 22.2698 124.503C22.2698 124.9 22.5782 125.221 22.9587 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 125.221C26.1233 125.221 26.4317 124.9 26.4317 124.503C26.4317 124.107 26.1233 123.785 25.7428 123.785C25.3624 123.785 25.054 124.107 25.054 124.503C25.054 124.9 25.3624 125.221 25.7428 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 125.221C28.9074 125.221 29.2158 124.9 29.2158 124.503C29.2158 124.107 28.9074 123.785 28.527 123.785C28.1465 123.785 27.8381 124.107 27.8381 124.503C27.8381 124.9 28.1465 125.221 28.527 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 125.221C31.6916 125.221 32 124.9 32 124.503C32 124.107 31.6916 123.785 31.3111 123.785C30.9307 123.785 30.6223 124.107 30.6223 124.503C30.6223 124.9 30.9307 125.221 31.3111 125.221Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 128C1.06932 128 1.37773 127.679 1.37773 127.282C1.37773 126.886 1.06932 126.564 0.688866 126.564C0.308416 126.564 0 126.886 0 127.282C0 127.679 0.308416 128 0.688866 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47316 128C3.85361 128 4.16202 127.679 4.16202 127.282C4.16202 126.886 3.85361 126.564 3.47316 126.564C3.09271 126.564 2.78429 126.886 2.78429 127.282C2.78429 127.679 3.09271 128 3.47316 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25715 128C6.6376 128 6.94602 127.679 6.94602 127.282C6.94602 126.886 6.6376 126.564 6.25715 126.564C5.8767 126.564 5.56828 126.886 5.56828 127.282C5.56828 127.679 5.8767 128 6.25715 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04144 128C9.42189 128 9.73031 127.679 9.73031 127.282C9.73031 126.886 9.42189 126.564 9.04144 126.564C8.66099 126.564 8.35257 126.886 8.35257 127.282C8.35257 127.679 8.66099 128 9.04144 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 128C12.2024 128 12.5108 127.679 12.5108 127.282C12.5108 126.886 12.2024 126.564 11.822 126.564C11.4415 126.564 11.1331 126.886 11.1331 127.282C11.1331 127.679 11.4415 128 11.822 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6063 128C14.9867 128 15.2951 127.679 15.2951 127.282C15.2951 126.886 14.9867 126.564 14.6063 126.564C14.2258 126.564 13.9174 126.886 13.9174 127.282C13.9174 127.679 14.2258 128 14.6063 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 128C17.7707 128 18.0791 127.679 18.0791 127.282C18.0791 126.886 17.7707 126.564 17.3902 126.564C17.0098 126.564 16.7014 126.886 16.7014 127.282C16.7014 127.679 17.0098 128 17.3902 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 128C20.5549 128 20.8633 127.679 20.8633 127.282C20.8633 126.886 20.5549 126.564 20.1744 126.564C19.794 126.564 19.4855 126.886 19.4855 127.282C19.4855 127.679 19.794 128 20.1744 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 128C23.3391 128 23.6476 127.679 23.6476 127.282C23.6476 126.886 23.3391 126.564 22.9587 126.564C22.5782 126.564 22.2698 126.886 22.2698 127.282C22.2698 127.679 22.5782 128 22.9587 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 128C26.1233 128 26.4317 127.679 26.4317 127.282C26.4317 126.886 26.1233 126.564 25.7428 126.564C25.3624 126.564 25.054 126.886 25.054 127.282C25.054 127.679 25.3624 128 25.7428 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 128C28.9074 128 29.2158 127.679 29.2158 127.282C29.2158 126.886 28.9074 126.564 28.527 126.564C28.1465 126.564 27.8381 126.886 27.8381 127.282C27.8381 127.679 28.1465 128 28.527 128Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 128C31.6916 128 32 127.679 32 127.282C32 126.886 31.6916 126.564 31.3111 126.564C30.9307 126.564 30.6223 126.886 30.6223 127.282C30.6223 127.679 30.9307 128 31.3111 128Z"
        fill="#E5E6E6"
      />
    </svg>
  );
};
