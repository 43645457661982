import { getDevices } from "./helpers/getDevices";
import {
  breakpoints,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  space,
} from "./tokens";

export const oficialTheme = {
  device: {
    mobileFirst: getDevices(true),
    desktopFirst: getDevices(false),
    breakpoints,
  },
  breakpoints,
  colors,
  lineHeight,
  typography: {
    fontFamily,
    fontSize,
    fontWeight,
  },
  space,
} as const;

export type OficialThemeType = typeof oficialTheme;
