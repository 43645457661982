type ValidateEmailResponse =
  | ""
  | "O campo e-mail é obrigatório!"
  | "O E-mail precisa ser diferente do atual cadastrado"
  | "E-mail não é válido";

export const validEmailRegex =
  // eslint-disable-next-line security/detect-unsafe-regex, no-useless-escape
  /^(([^<>()[\]~^?°`´ªº'/!%\\@#$¨&*£¢¬¹²³"=§áéíóúàèìòùâêîôûãõäëïöüç¡¿ãõ|,;:\s@"]+(\.[^<>()[\]\.,;:\s@\._~^?°`´ªº'/!@#$¨&*£¢¬¹²³%"+=§áéíóúàèìòùâêîôûãõäëïöüç¡¿ãõ\\|"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+(?<![-])\.)*(?!-)[^<>()[\]\.,;:\s@\"]{1}([^-][^<>()[\]\.,;:\s@\"]+)*(?<!-)\.[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const validCpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const validateEmail = (
  email: string | undefined,
  currentEmail?: string,
  isRequired = true
): ValidateEmailResponse => {
  const emailValidated = email ?? "";
  const blank = "";

  const emailStatus = {
    emailIsRequired: "O campo e-mail é obrigatório!",
    emailIsInvalid: "E-mail não é válido",
    emailIsTheSame: "O E-mail precisa ser diferente do atual cadastrado",
  } as const;

  if (isRequired && !email) {
    return emailStatus.emailIsRequired;
  }

  if (isRequired && !email?.match(validEmailRegex)) {
    return emailStatus.emailIsInvalid;
  }

  if (
    !isRequired &&
    emailValidated?.length > 0 &&
    !email?.match(validEmailRegex)
  ) {
    return emailStatus.emailIsInvalid;
  }
  if (email === currentEmail) {
    return emailStatus.emailIsTheSame;
  }
  return blank;
};

export const handleEmailValidation = (email: string | undefined) => {
  const textError = email ? validateEmail(email, undefined, false) : "";

  const hasError = email && email.length === 0 ? false : !!textError;

  const hasSuccessfully = email && email.length > 0 && !validateEmail(email);

  return { textError, hasError, hasSuccessfully };
};

export const validateEmailFormWeddingList = (email: string | undefined) => {
  const isValidEmail = validateEmail(email, undefined, false);

  return isValidEmail === "";
};
