import { IconProps } from "typing";
import { theme } from "../themes";

export const DevicesIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["550"],
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8704 18.995H2.99536C2.44336 18.995 1.99536 18.547 1.99536 17.995V16.995C1.99536 16.443 2.44336 15.995 2.99536 15.995H13.8704"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9954 4.995C19.9954 3.89 19.1004 2.995 17.9954 2.995H4.99536C3.89036 2.995 2.99536 3.89 2.99536 4.995V15.995"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8704 7.995H19.9954C21.1004 7.995 21.9954 8.89 21.9954 9.995V18.995C21.9954 20.1 21.1004 20.995 19.9954 20.995H15.8704C14.7654 20.995 13.8704 20.1 13.8704 18.995V9.995C13.8704 8.89 14.7654 7.995 15.8704 7.995Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9954 7.995V4.995"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0554 18.745H18.8054"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
