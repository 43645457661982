import {
  ArrowButtonProps,
  CRMProperties,
  ProductCardDto,
  SliderProps,
} from "typing";
import { memo, useCallback, useMemo } from "react";
import { buildTestIds, useAppContext } from "application";
import { ProductCardStyle, SliderContainer } from "./styles";
import ProductCard from "../../../../ProductCard";
import RecommendationCarouselProductCard from "../../../../RecommendationCarouselProductCard";
import { Slider } from "../../../../Slider";

type CustomProductListSliderProps = {
  productCards: ProductCardDto[];
  isVertical: boolean;
  customTheme: ArrowButtonProps;
  slidesToShow: number;
  height: number;
  arrowsIsActive: boolean;
  isBlackFriday?: boolean;
  crm?: CRMProperties | null;
  isCrmProductList?: boolean;
  isCrmProductListMinimalist?: boolean;
};

const CustomProductListSlider = memo(
  ({
    productCards,
    isVertical,
    customTheme,
    slidesToShow,
    arrowsIsActive,
    isBlackFriday,
    height,
    crm,
    isCrmProductList,
    isCrmProductListMinimalist,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  CustomProductListSliderProps) => {
    const { isClientLaptop } = useAppContext();

    const slidesToScroll = useMemo(() => {
      if (isClientLaptop) return 4;

      return 5;
    }, [isClientLaptop]);

    const sliderProps: SliderProps = {
      speed: 700,
      slidesToShow,
      slidesToScroll,
      arrowsIsActive,
      touchMove: true,
      swipe: false,
      vertical: isVertical,
      verticalSwiping: isVertical,
    };

    const getProductCartType = useCallback(
      (product: ProductCardDto) => {
        return isCrmProductList ? (
          <RecommendationCarouselProductCard
            className={
              crm
                ? "ins-product-box ins-element-link ins-add-to-cart-wrapper ins-sr-api"
                : ""
            }
            data-event-collection={crm ? "true" : "false"}
            data-ins-product-id={crm ? `${product?.id}` : ""}
            key={`${product.id}`}
            isVertical={isVertical}
            item={{
              ...product,
              index: productCards.indexOf(product) + 1,
            }}
            isPlaylist
            isMinimalist={isCrmProductListMinimalist}
          />
        ) : (
          <ProductCard
            className={
              crm
                ? "ins-product-box ins-element-link ins-add-to-cart-wrapper ins-sr-api"
                : ""
            }
            data-event-collection={crm ? "true" : "false"}
            data-ins-product-id={crm ? `${product?.id}` : ""}
            key={`${product.id}`}
            isVertical={isVertical}
            item={{
              ...product,
              index: productCards.indexOf(product) + 1,
            }}
            shouldShowInGridOnMobile={false}
            isPlaylist
          />
        );
      },
      [
        crm,
        isCrmProductList,
        isCrmProductListMinimalist,
        isVertical,
        productCards,
      ]
    );

    return (
      <SliderContainer
        className={crm ? "ins-web-smart-recommender-body" : ""}
        data-recommended-items={crm ? crm?.recommendedProductsId : ""}
      >
        <Slider
          {...buildTestIds("custom-product-list-slider")}
          customTheme={customTheme}
          isBlackTheme={isBlackFriday}
          {...sliderProps}
        >
          {productCards?.map((product) => (
            <ProductCardStyle
              className={crm ? "ins-web-smart-recommender-box-item" : ""}
              {...buildTestIds("product-card-style")}
              key={`box-${product.id}`}
              isVertical={isVertical}
              height={height}
            >
              {getProductCartType(product)}
            </ProductCardStyle>
          ))}
        </Slider>
      </SliderContainer>
    );
  }
);

CustomProductListSlider.displayName = "CustomProductListSlider";

export { CustomProductListSlider };
