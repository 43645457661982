import { MutableRefObject, useEffect } from "react";

interface ClickOutsideProps {
  ref: MutableRefObject<unknown>;
  extraRef?: MutableRefObject<unknown>;
  callback: () => void;
}

type RefCurrentFakeType = { contains: (eventTarget: Node) => boolean };

export const useClickOutside = ({
  ref,
  extraRef,
  callback,
}: ClickOutsideProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const extraCondition = extraRef
        ? !(extraRef?.current as RefCurrentFakeType).contains(
            event.target as Node
          )
        : true;
      if (
        ref?.current &&
        !(ref?.current as RefCurrentFakeType)?.contains(event.target as Node) &&
        extraCondition
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, extraRef, ref]);
};
