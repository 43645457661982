type ValidateBirthdateResponse =
  | ""
  | "O campo data de nascimento é obrigatório!"
  | "Data no formato inválido!"
  | "A data de nascimento não pode ser anterior ao ano de 1901."
  | "A data de nascimento não pode ser maior que a data atual."
  | "É necessario ter mais de 18 anos para se cadastrar.";

export const ERROR_MESSAGES_BIRTHDATE = {
  birthdateRequired:
    "O campo data de nascimento é obrigatório!" as ValidateBirthdateResponse,
  birthdateInvalid: "Data no formato inválido!" as ValidateBirthdateResponse,
  birthdateMinDate:
    "A data de nascimento não pode ser anterior ao ano de 1901." as ValidateBirthdateResponse,
  birthdateMajority:
    "É necessario ter mais de 18 anos para se cadastrar." as ValidateBirthdateResponse,
  birthdateMaxDate:
    "A data de nascimento não pode ser maior que a data atual." as ValidateBirthdateResponse,
};

const MAJORITY_AGE = 18;
export const isFebruaryInvalid = (
  day: string | number,
  month: string | number,
  year: string | number
) => {
  return (
    Number(month) === 2 &&
    ((Number(day) > 28 && Number(year) % 4 !== 0) ||
      (Number(day) > 29 && Number(year) % 4 === 0))
  );
};

export const quantityOfDaysIsBiggerThanThirty = (
  day: string | number,
  month: string | number
) => {
  return (
    (Number(month) === 4 ||
      Number(month) === 11 ||
      Number(month) === 6 ||
      Number(month) === 9) &&
    Number(day) > 30
  );
};

export const validateBirthdate = (
  birthdate: string
): ValidateBirthdateResponse => {
  if (typeof birthdate !== "string" || birthdate === "") return "";

  const [day, month, year] = birthdate.split("/");

  if (Number(year) < 1901) return ERROR_MESSAGES_BIRTHDATE.birthdateMinDate;

  if (Number(day) > 31 || Number(day) === 0)
    return ERROR_MESSAGES_BIRTHDATE.birthdateInvalid;

  if (Number(month) > 12 || Number(month) === 0)
    return ERROR_MESSAGES_BIRTHDATE.birthdateInvalid;

  if (
    quantityOfDaysIsBiggerThanThirty(day, month) ||
    isFebruaryInvalid(day, month, year)
  )
    return ERROR_MESSAGES_BIRTHDATE.birthdateInvalid;

  const birthdateDate = new Date(`${year}-${month}-${day} 00:00:00`);
  const oldDate = new Date("1900-12-31");
  const todayDate = new Date();

  if (birthdateDate <= oldDate)
    return ERROR_MESSAGES_BIRTHDATE.birthdateMinDate;
  if (birthdateDate >= todayDate)
    return ERROR_MESSAGES_BIRTHDATE.birthdateMaxDate;

  const currentYear = todayDate.getFullYear();
  const birthYear = Number(year);

  if (currentYear - birthYear < MAJORITY_AGE) {
    return ERROR_MESSAGES_BIRTHDATE.birthdateMajority;
  }

  return "";
};
