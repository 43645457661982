import { IconProps } from "typing";
import { theme } from "../themes";

export const DownPayment = ({
  width = 24,
  height = 25,
  color = theme.colors.neutral["550"],
  alt = "Entrada",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M2.99609 16.4799L5.5477 12.2273C5.84137 11.7378 6.26852 11.3423 6.77905 11.087L7.36448 10.7943C7.78122 10.5859 8.24075 10.4774 8.70668 10.4774H9.99901"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.002 5.47534C18.107 5.47534 19.0028 6.37115 19.0028 7.47618"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0007 3.97473H13.0003C11.3427 3.97473 9.99902 5.31844 9.99902 6.97598V15.4795"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5041 10.4774V16.4799C20.5041 18.1375 19.1604 19.4812 17.5029 19.4812H13.001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.002 2.47409C19.7645 2.47409 22.004 4.7136 22.004 7.47617"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99873 15.4795H10.9996L13.191 13.2889C13.7897 12.6905 14.7445 12.6435 15.399 13.1803C16.0535 13.717 16.1944 14.6626 15.7248 15.3668L12.7307 19.8566C12.2561 20.5684 11.5659 21.1093 10.7613 21.4L7.76562 22.4824"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
