import {
  buildTestIds,
  formatStringUrl,
  getProductImageUrl,
  getSalePrice,
  getSpotPrice,
  useAddItemToCart,
  useCartContext,
  useToast,
} from "application";
import { appMonitoringClient, cookie } from "implementations";
import { memo } from "react";
import { RecommendationCarouselProductCardProps } from "typing";
import { CartOutlineIcon, CouponTag, theme } from "ui";
import { useRouter } from "next/router";
import { Link } from "../Link";
import {
  CouponTagWrapper,
  AddToCartButton,
  RecommendationProductCardContainer,
  RecommendationProductCardContent,
  RecommendationProductCardImageContainer,
  RecommendationProductCardImageContent,
  RecommendationProductCardInfoArea,
  RecommendationProductCardInfoContainer,
  RecommendationProductCardTitle,
  RecommendationProductCardCurrentPrice,
} from "./styles";
import {
  handleSelectItemGtag,
  useGetProductCardInfo,
} from "../ProductCard/data";

import { DynamicRecommendationProductCardVerticalImage } from "./data";
import RecommendationTypeBadge from "./components/RecommendationTypeBadge";

const RecommendationCarouselProductCard = memo(
  ({
    item,
    playlistId,
    playlistName,
    searchTerm,
    isVertical,
    index,
    isPlaylist,
    className,
    preloadComponent = false,
    isMinimalist,
    hasCta = true,
    recommendationType,
  }: RecommendationCarouselProductCardProps) => {
    const router = useRouter();
    const cart = useCartContext();

    const stayOnPage = true;

    const { sendItemToCart, isSendingItemToCart } = useAddItemToCart({
      router,
      cart,
      useToast,
      stayOnPage,
    });

    const { isSearchPath, isExpandedPlaylistPath, isProductScreen } =
      useGetProductCardInfo(item, !!isVertical, index);

    const selectItemGtag = handleSelectItemGtag({
      item,
      isSearchPath,
      searchTerm,
      playlistName,
      isExpandedPlaylistPath,
      playlistId,
    });

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      const destination = event?.currentTarget?.getAttribute("href");

      if (destination && window && window?.location?.href !== destination) {
        cookie.setCookie({
          name: `currentPage_default`,
          value: "1",
          options: {
            maxAge: 600,
          },
        });
        window.location.href = destination;
      }
    };

    return (
      <RecommendationProductCardContainer
        className={className}
        {...buildTestIds(
          `recommendation-product-card-container${
            isProductScreen ? "-as-tag-link" : "-as-component-link"
          }`
        )}
        isPlaylist={isPlaylist}
        isMinimalist={isMinimalist}
      >
        {item?.coupons?.length > 0 && (
          <CouponTagWrapper
            {...buildTestIds("recommendation-product-card-coupon-tag-wrapper")}
          >
            <CouponTag alt="cupom" />
          </CouponTagWrapper>
        )}
        <RecommendationProductCardContent
          {...buildTestIds("recommendation-product-card-content")}
          isMinimalist={isMinimalist}
        >
          <RecommendationProductCardImageContainer
            href={`/produto/${item?.id}/${formatStringUrl(item?.description)}`}
            target="_self"
            {...buildTestIds("recommendation-product-card-image")}
            isMinimalist={isMinimalist}
          >
            <RecommendationProductCardImageContent
              {...buildTestIds("recommendation-product-card-image-content")}
              isMinimalist={isMinimalist}
            >
              <DynamicRecommendationProductCardVerticalImage
                {...buildTestIds("recommendation-product-card-dynamic-image")}
                imageUrl={getProductImageUrl(item) as string}
                description={item?.description}
                preloadComponent={preloadComponent}
              />
            </RecommendationProductCardImageContent>
          </RecommendationProductCardImageContainer>
          {!isMinimalist ? (
            <RecommendationProductCardInfoArea
              {...buildTestIds("recommendation-product-card-info-area")}
            >
              <RecommendationProductCardInfoContainer
                {...buildTestIds("recommendation-product-card-info-container")}
              >
                <RecommendationProductCardTitle
                  {...buildTestIds("recommendation-product-card-title")}
                  as={isProductScreen ? "a" : Link}
                  href={`/produto/${item?.id}/${formatStringUrl(
                    item?.description
                  )}`}
                  target="_self"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    handleLinkClick(event);
                    selectItemGtag();
                  }}
                >
                  {item?.description}
                </RecommendationProductCardTitle>
                {getSpotPrice(item) ? (
                  <RecommendationProductCardCurrentPrice
                    {...buildTestIds(
                      "recommendation-product-card-current-price"
                    )}
                  >
                    {getSpotPrice(item)}
                  </RecommendationProductCardCurrentPrice>
                ) : (
                  <RecommendationProductCardCurrentPrice
                    {...buildTestIds(
                      "recommendation-product-card-current-price"
                    )}
                  >
                    {getSalePrice(item, appMonitoringClient)}
                  </RecommendationProductCardCurrentPrice>
                )}
                {hasCta ? (
                  <AddToCartButton
                    onClick={() =>
                      sendItemToCart({
                        quantity: item?.quantity,
                        price: getSpotPrice(item),
                        productId: item?.id,
                      })
                    }
                    isDisabled={isSendingItemToCart}
                    {...buildTestIds("add-to-cart-button")}
                  >
                    <CartOutlineIcon
                      color={`${theme.colors.secondary["357"]}`}
                      width="16px"
                      height="16px"
                    />
                    Adicionar
                  </AddToCartButton>
                ) : null}
              </RecommendationProductCardInfoContainer>
            </RecommendationProductCardInfoArea>
          ) : recommendationType ? (
            <RecommendationTypeBadge
              recommendationTypeAbbreviation={
                recommendationType?.recommendationTypeAbbreviation
              }
              recommendationTypeText={
                recommendationType?.recommendationTypeText
              }
            />
          ) : null}
        </RecommendationProductCardContent>
      </RecommendationProductCardContainer>
    );
  }
);

RecommendationCarouselProductCard.displayName =
  "RecommendationCarouselProductCard";

export default RecommendationCarouselProductCard;

export { RecommendationCarouselProductCard as RecommendationCarouselProductCardComponent };
