import { IconProps } from "typing";
import { theme } from "../themes";

export const ArrowUpCircle = ({
  color = theme.colors.neutral[100],
  height = 56,
  width = 56,
  alt = "Seta para cima",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 256 256"
    >
      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm37.66,101.66a8,8,0,0,1-11.32,0L136,107.31V168a8,8,0,0,1-16,0V107.31l-18.34,18.35a8,8,0,0,1-11.32-11.32l32-32a8,8,0,0,1,11.32,0l32,32A8,8,0,0,1,165.66,125.66Z" />
    </svg>
  );
};
