import {
  IClientIp,
  IEventEmitter,
  IJwtDecoder,
  IStateManager,
  IWebSocketClient,
} from "application";
import {
  BackofficeApi,
  CatalogApi,
  EnvsApi,
  // GoogleAuthenticateApi,
  IdentityApi,
  NotificationApi,
  PaymentApi,
  ProfileApi,
  PurchaseApi,
  ShippingApi,
  ShoppingApi,
  SupportContactApi,
  UtilInfoApi,
  WeddingGiftListApi,
} from "application/services/apis";
import {
  AllowMeFingerprint,
  BroadcastChannelRaw,
  clarityRaw,
  ClearSaleFingerprint,
  Cookie,
  CookiesNext,
  CreditCardTypeLib,
  DocumentEventListener,
  Encryption,
  GenesysChatBot,
  gtagRaw,
  ImmerImpl,
  InsiderObject,
  JwtDecode,
  MockedClient,
  RequestIp,
  SecuredSessionStorage,
  SentryClient,
  SWRHttpClient,
  UUIDLib,
  WebSocketRaw,
  ZustandImpl,
} from "infra";

const httpClient = SWRHttpClient;
const appMonitoringClient = SentryClient;
const creditCardUtils = CreditCardTypeLib;
const heatmapClient = clarityRaw;

const cookie = CookiesNext;
const cookieParser = Cookie;

const genesys = GenesysChatBot;
const gtag = gtagRaw;
const crm = InsiderObject;
const allowMeFingerprint = AllowMeFingerprint;
const clearSaleFingerprint = ClearSaleFingerprint;

const idGenerator = UUIDLib;

const envsApi = new EnvsApi(cookie);

const jwtDecoder: IJwtDecoder = new JwtDecode();
const webSocketClient: IWebSocketClient = new WebSocketRaw(envsApi);
const eventEmitter: IEventEmitter = new DocumentEventListener();

const backofficeApi = new BackofficeApi(httpClient, envsApi);
const catalogApi = new CatalogApi(httpClient, appMonitoringClient, envsApi);
const shippingApi = new ShippingApi(httpClient, envsApi);
const shoppingApi = new ShoppingApi(httpClient, appMonitoringClient, envsApi);
const notificationApi = new NotificationApi(
  httpClient,
  appMonitoringClient,
  envsApi
);

const identityApi = new IdentityApi(
  httpClient,
  appMonitoringClient,
  gtag,
  envsApi
);
const profileApi = new ProfileApi(
  httpClient,
  appMonitoringClient,
  gtag,
  envsApi
);
const supportContactApi = new SupportContactApi(MockedClient);
const utilInfoApi = new UtilInfoApi(MockedClient, envsApi);
const weddingGiftListApi = new WeddingGiftListApi(
  httpClient,
  appMonitoringClient,
  envsApi
);
const purchaseApi = new PurchaseApi(
  httpClient,
  appMonitoringClient,
  gtag,
  envsApi
);
const paymentApi = new PaymentApi(httpClient, appMonitoringClient, envsApi);

const clientIp: IClientIp = new RequestIp(cookie);

const stateManager: IStateManager = {
  createStore: ZustandImpl.createStore,
  produceNewState: ImmerImpl.produceNewState,
};

const browserBroadcast = new BroadcastChannelRaw();

const encrypt = new Encryption();

const securedSessionStorage = new SecuredSessionStorage(encrypt);

export {
  allowMeFingerprint,
  appMonitoringClient,
  backofficeApi,
  browserBroadcast,
  catalogApi,
  clearSaleFingerprint,
  clientIp,
  cookie,
  cookieParser,
  creditCardUtils,
  crm,
  encrypt,
  envsApi,
  eventEmitter,
  genesys,
  gtag,
  heatmapClient,
  identityApi,
  idGenerator,
  jwtDecoder,
  notificationApi,
  paymentApi,
  profileApi,
  purchaseApi,
  securedSessionStorage,
  shippingApi,
  shoppingApi,
  stateManager,
  supportContactApi,
  utilInfoApi,
  webSocketClient,
  weddingGiftListApi,
};
