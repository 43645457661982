import { IconProps } from "typing";
import { theme } from "../themes";

export const DisconnectIcon = ({
  width = 20,
  height = 20,
  alt = "Ícone de desconectar dispositivo",
  color = theme.colors.neutral["110"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      aria-label={alt}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 16.3317V6.75249C9.16667 6.17749 8.87083 5.64332 8.38333 5.33915L5.05 3.25582C3.94 2.56248 2.5 3.35998 2.5 4.66915V14.2475C2.5 14.8225 2.79583 15.3567 3.28333 15.6608L6.61667 17.7442C7.72667 18.4383 9.16667 17.64 9.16667 16.3317Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 9.66667H12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 8L12.5 9.66667L14.1667 11.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 16.3333H12.4998C13.4207 16.3333 14.1665 15.5875 14.1665 14.6667V13.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1665 5.5V4.66667C14.1665 3.74583 13.4207 3 12.4998 3H4.1665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
