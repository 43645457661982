import { IconProps } from "typing";
import { theme } from "../../themes";

export const DeviceSmartphoneIcon = ({
  width = theme.space["x11.75"],
  height = theme.space["x11.75"],
  strokeWidth = "1.5",
  color = theme.colors.neutral["550"],
  alt = "Icone de computador",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <path
        d="M20.1111 8.02783V11.5278C20.1111 12.1714 20.5466 12.6945 21.0833 12.6945H26.9166C27.4533 12.6945 27.8889 12.1714 27.8889 11.5278V8.02783"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8221 34.2779H26.1777"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1666 8.02783H29.8333C31.9819 8.02783 33.7222 9.76811 33.7222 11.9167V35.2501C33.7222 37.3987 31.9819 39.1389 29.8333 39.1389H18.1666C16.018 39.1389 14.2777 37.3987 14.2777 35.2501V11.9167C14.2777 9.76811 16.018 8.02783 18.1666 8.02783Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
