import { IconProps } from "typing";
import { theme } from "../../../themes";

export const BrowserDefault = ({
  width = theme.space.x15,
  height = theme.space.x15,
  strokeWidth = "1.5",
  alt = "Autenticação por Aplicativo",
  color = theme.colors.neutral["560"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 6.2002C33.334 16.0542 33.334 31.9462 24 41.8002"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9995 41.8002C14.6655 31.9462 14.6655 16.0542 23.9995 6.2002"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 6C33.964 6 42 14.036 42 24C42 33.964 33.964 42 24 42"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 42C14.036 42 6 33.964 6 24C6 14.036 14.036 6 24 6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01953 18H40.9795"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01953 30H40.9795"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
