import {
  IAppMonitoringClient,
  ICookie,
  IHeatmapClient,
  IJwtDecoder,
} from "app-domain/abstractions";
import { signIn, signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AuthenticationProps,
  GetTokensProps,
  GoogleAuthSessionProps,
  KeepUserLoggedInProps,
  UserData,
  UserProps,
  oAuth,
} from "typing";

import { useShallow } from "zustand/react/shallow";
import { useCountdown } from "../../hooks/Countdown";
import { useToast } from "../../hooks/Toast";
import { IdentityApi, ProfileApi, PurchaseApi } from "../../services";
import {
  useChatbot,
  useConfirmationTypes,
  useCrm,
  useEnvs,
  useLoginAuthentication,
  useRegisterPhoneNumber,
  useTwoFactor,
  useUniqueClient,
} from "../../state-manager";
import {
  getClientLocationDetails,
  getGeolocation,
  validatePhoneNumber,
} from "../../utils";
import { useModalGroup } from "../ModalGroupContext";
import {
  deleteTokenCartANONYMOUS,
  getTokenCartANONYMOUS,
} from "../ShoppingHookContext/utilsCart";
import { useWebSocket } from "../WebSocket";

const cookieModalToConfirmEmail =
  "@FC:Ecom:ModalToConfirmEmail:HadVisualization";
const cookieStoreCEP = "@FC:Ecom:Dropdown:Cep";
const keyZipCode = "@FC:Ecom:Dropdown:Cep";
// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
const accessTokenCookieKey = "@FC:Ecom:Token:Access";
// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
const refreshTokenCookieKey = "@FC:Ecom:Token:Refresh";
// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
const accessTokenGoogleCookieKey = "@FC:Ecom:Token:Access:google";
// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
const accessTokenAppleCookieKey = "@FC:Ecom:Token:Access:apple";

const myPanelStr = "meu-painel";
const googleTagManagerEmailUser = "ECO-UserEmail";

export interface AuthContextProps {
  userData: UserData;
  isAuthenticated: boolean | undefined;
  // authWindowProvider: string;
  onAuthenticateUser({
    login,
    password,
    location,
  }: UserProps): Promise<AuthenticationProps | null>;
  onAuthenticateUserOAuth(provider: oAuth): void;
  signOutAccount(cookie: ICookie): void;
  getTokens(): GetTokensProps | undefined;
  keepUserLoggedIn: (data: KeepUserLoggedInProps) => void;
  // onChangeStateFromInitial(): void;
}

interface AuthProviderProps {
  children: ReactNode;
  identityApi: IdentityApi;
  appMonitoringClient: IAppMonitoringClient;
  cookie: ICookie;
  jwtDecoder: IJwtDecoder;
  heatmapClient: IHeatmapClient;
  purchaseApi: PurchaseApi;
  profileApi: ProfileApi;
}

const optionsMaxAgeEqualSixMonth = {
  options: {
    maxAge: 60 * 60 * 24 * 182,
  },
};

const optionsMaxAgeEqualOneYear = {
  options: {
    maxAge: 60 * 60 * 24 * 365,
  },
};

export const AuthContext = createContext({} as AuthContextProps);

export const useAuth = (): AuthContextProps => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return authContext;
};

// interface AuthWindowProps {
//   provider: oAuth;
//   status: "authenticated" | "loading" | "unauthenticated";
// }

// const providerUrl = {
//   google: "/sign-in/google",
//   apple: "/sign-in/apple",
// };

// const AuthWindow = ({ provider, status }: AuthWindowProps) => {
//   const oAuthWindowRef = useRef<boolean | null>(null);
//   const { onChangeStateFromInitial } = useAuth();

//   const handleCloseNewAuthWindow = useCallback(() => {
//     if (oAuthWindowRef.current) {
//       onChangeStateFromInitial();
//       oAuthWindowRef.current = null;
//     }
//   }, [onChangeStateFromInitial]);

//   useEffect(() => {
//     if (provider === "apple" && status === "authenticated") window.close();
//   }, [provider, status]);

//   useEffect(() => {
//     if (provider) {
//       oAuthWindowRef.current = true;
//     }

//     document.body.addEventListener("click", handleCloseNewAuthWindow, true);
//   }, [provider, handleCloseNewAuthWindow]);

//   return (
//     <AuthWindowContainer
//       ref={oAuthWindowRef as unknown as Ref<NewWindow>}
//       url={providerUrl[provider as "google" | "apple"]}
//     />
//   );
// };

export const AuthProvider = ({
  children,
  identityApi,
  appMonitoringClient,
  cookie,
  purchaseApi,
  jwtDecoder,
  heatmapClient,
  profileApi,
}: AuthProviderProps) => {
  const { setShowChatbot, setShowSaleChatbot } = useChatbot(
    useShallow((state) => ({
      setShowChatbot: state.setShowChatbot,
      setShowSaleChatbot: state.setShowSaleChatbot,
    }))
  );
  const [userData, setUserData] = useState<UserData>({} as UserData);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  );
  // const [isToOpenNewOAuthWindow, setIsToOpenNewOAuthWindow] =
  //   useState<boolean>(false);
  const allConfigs = useEnvs((state) => state.allConfigs);
  const [authWindowProvider, setAuthWindowProvider] = useState<oAuth>("");
  const { data: session, status } = useSession();

  const router = useRouter();

  const { addToast } = useToast();
  const {
    handleTriggerModalEmailConfirmation,
    handleTriggerModalChangePassword,
  } = useModalGroup();
  const { changeConditionToStartCountdown } = useCountdown();
  const setUserCrmId = useCrm((state) => state.setUserCrmId);

  const {
    createWebSocketConnection,
    destroyWebSocketConnection,
    emitEventClientLogin,
  } = useWebSocket();

  const { setIsRegisterPhoneNumberModalOpen, changeCurrentStep } =
    useRegisterPhoneNumber(
      useShallow((state) => ({
        setIsRegisterPhoneNumberModalOpen:
          state.setIsRegisterPhoneNumberModalOpen,
        changeCurrentStep: state.changeCurrentStep,
      }))
    );

  const { setIsValidationActive } = useTwoFactor(
    useShallow((state) => ({
      setIsValidationActive: state.setIsValidationActive,
    }))
  );

  const {
    setIsLoginAuthenticationModalOpen,
    changeLoginStep,
    setIsPhoneValidationModalOpen,
    changeValidationStep,
  } = useLoginAuthentication(
    useShallow((state) => ({
      setIsLoginAuthenticationModalOpen:
        state.setIsLoginAuthenticationModalOpen,
      changeLoginStep: state.changeLoginStep,
      setIsPhoneValidationModalOpen: state.setIsPhoneValidationModalOpen,
      changeValidationStep: state.changeValidationStep,
    }))
  );

  const {
    setIsUniqueClientModalOpen,
    setUniqueClientInfo,
    changeUniqueClientStep,
  } = useUniqueClient(
    useShallow((state) => ({
      setIsUniqueClientModalOpen: state.setIsUniqueClientModalOpen,
      setUniqueClientInfo: state.setUniqueClientInfo,
      changeUniqueClientStep: state.changeUniqueClientStep,
    }))
  );

  const { setAvailableMethods } = useConfirmationTypes(
    useShallow((state) => ({
      setAvailableMethods: state.setAvailableMethods,
    }))
  );

  const setDataInUser = useCallback(
    (token: string) => {
      const userDataDecoded = jwtDecoder.decode<UserData>(token);
      setUserData(userDataDecoded);

      if (allConfigs?.isWebsocketAvailable === "true") {
        createWebSocketConnection(userDataDecoded?.email, token);
      }

      sessionStorage.setItem(
        googleTagManagerEmailUser,
        userDataDecoded?.email || ""
      );
      appMonitoringClient.configureScope(
        { email: userDataDecoded.email },
        token
      );

      if (userDataDecoded?.email) {
        heatmapClient.customIdentifiers(
          userDataDecoded?.email,
          userDataDecoded?.client_id
        );
      }

      setIsAuthenticated(true);
      setAuthWindowProvider("");

      const shouldRenderModalEmailConfirmation = cookie.getCookie({
        name: cookieModalToConfirmEmail,
      });

      if (allConfigs?.isEmailConfirmationAvailable === "true") {
        if (
          !userDataDecoded?.email_verified &&
          !shouldRenderModalEmailConfirmation
        ) {
          handleTriggerModalEmailConfirmation(
            userDataDecoded?.email_verified,
            userDataDecoded?.email
          );
          return null;
        }

        if (userDataDecoded?.email_verified && userDataDecoded?.need_update) {
          handleTriggerModalChangePassword(userDataDecoded?.need_update);
          return null;
        }
      }

      return null;
    },
    [
      jwtDecoder,
      allConfigs?.isWebsocketAvailable,
      allConfigs?.isEmailConfirmationAvailable,
      appMonitoringClient,
      cookie,
      heatmapClient,
      createWebSocketConnection,
      handleTriggerModalEmailConfirmation,
      handleTriggerModalChangePassword,
    ]
  );

  const fetchConfirmationTypes = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (token: string, email?: string, cpf?: string) => {
      const accessToken = cookie.getCookie({ name: accessTokenCookieKey });

      const { data: dataClient } = await profileApi.fetchClient(token);

      const confirmationTypesResponse =
        await identityApi.receiveConfirmationTypes({
          cpf: cpf ?? dataClient?.cpf ?? "",
          email: email ?? dataClient?.email ?? "",
          jwt: token ?? accessToken,
        });

      try {
        if (router?.pathname === "/" || router?.pathname === "/login") {
          if (allConfigs?.isUniqueClientAvailable === "true") {
            const { data: clientInfo } =
              await purchaseApi.getVerifyUniqueClient(token ?? accessToken);

            setUniqueClientInfo(clientInfo);

            const needsToValidateEmail =
              clientInfo?.emailValidation?.isSameInPhysicalStoreAndEcommerce &&
              !clientInfo?.emailValidation?.isEmailVerified;

            const needsToValidatePhoneNumber =
              clientInfo?.phoneNumberValidation
                ?.isSameInPhysicalStoreAndEcommerce &&
              !clientInfo?.phoneNumberValidation?.isPhoneNumberVerified;

            const isPhoneNumberValidated =
              clientInfo?.phoneNumberValidation?.isPhoneNumberVerified &&
              clientInfo?.phoneNumberValidation
                ?.isSameInPhysicalStoreAndEcommerce;

            const isEmailValidated =
              clientInfo?.emailValidation?.isEmailVerified &&
              clientInfo?.emailValidation?.isSameInPhysicalStoreAndEcommerce;

            const shouldShowUniqueClientModal =
              clientInfo?.isCustomerEligible &&
              ((needsToValidateEmail && needsToValidatePhoneNumber) ||
                (needsToValidateEmail &&
                  (!clientInfo?.phoneNumberValidation
                    ?.isSameInPhysicalStoreAndEcommerce ||
                    !isPhoneNumberValidated)) ||
                (needsToValidatePhoneNumber &&
                  (!clientInfo?.emailValidation
                    ?.isSameInPhysicalStoreAndEcommerce ||
                    !isEmailValidated)));

            if (shouldShowUniqueClientModal) {
              changeUniqueClientStep("modalCall");
              setIsUniqueClientModalOpen(true);
              return;
            }
          }

          setAvailableMethods(confirmationTypesResponse?.data);

          const shouldShowRegisterPhoneNumberModal =
            (confirmationTypesResponse?.data &&
              (confirmationTypesResponse?.data?.phoneNumber === "" ||
                !confirmationTypesResponse?.data?.phoneNumber) &&
              !dataClient?.phoneNumber) ||
            (!!validatePhoneNumber(dataClient?.phoneNumber) &&
              dataClient?.phoneNumber);

          if (shouldShowRegisterPhoneNumberModal) {
            changeCurrentStep("modalCall");
            changeValidationStep("modalCall");
            setIsRegisterPhoneNumberModalOpen(true);
            return;
          }

          const show2faModal =
            confirmationTypesResponse?.data?.emailConfirmed === false &&
            confirmationTypesResponse?.data?.phoneNumberConfirmed === false;

          if (show2faModal) {
            changeLoginStep("firstStep");
            setIsValidationActive(true);
            setIsLoginAuthenticationModalOpen(true);
            return;
          }

          if (
            !confirmationTypesResponse?.data?.phoneNumberConfirmed &&
            confirmationTypesResponse?.data?.phoneNumber
          ) {
            changeCurrentStep("modalCall");
            setIsPhoneValidationModalOpen(true);
          }
        }
      } catch (error) {
        appMonitoringClient.captureException(error);
      }
    },
    [
      router?.pathname,
      identityApi,
      setAvailableMethods,
      allConfigs?.isUniqueClientAvailable,
      purchaseApi,
      changeCurrentStep,
      cookie,
      setIsRegisterPhoneNumberModalOpen,
      setUniqueClientInfo,
      changeUniqueClientStep,
      setIsUniqueClientModalOpen,
      changeLoginStep,
      setIsValidationActive,
      setIsLoginAuthenticationModalOpen,
      changeValidationStep,
      setIsPhoneValidationModalOpen,
      appMonitoringClient,
      profileApi,
    ]
  );

  const keepUserLoggedIn = useCallback(
    async (data: KeepUserLoggedInProps) => {
      cookie.setCookie({
        name: accessTokenCookieKey,
        value: data.token,
        options: optionsMaxAgeEqualSixMonth.options,
      });

      cookie.setCookie({
        name: refreshTokenCookieKey,
        value: data?.refreshToken || "",
        options: optionsMaxAgeEqualOneYear.options,
      });

      if (data.accessToken) {
        cookie.setCookie({
          name: `@FC:Ecom:Auth:${data.providerType}`,
          value: data.accessToken,
          options: optionsMaxAgeEqualSixMonth.options,
        });
        /**
         * O hook signOut está presente aqui com o motivo de remover os cookies inseridos pela lib (next-auth)
         * - Caso for remover o hook validar com infra/cloud como vai ficar com o nginx
         */
        await signOut({
          redirect: false,
        });
      }

      setDataInUser(data.token);
      setShowChatbot(true);
      setIsAuthenticated(true);
      // setIsToOpenNewOAuthWindow(false);
    },
    [cookie, setDataInUser, setShowChatbot]
  );

  // const handleManagementModalOAuth = () => {
  //   setIsToOpenNewOAuthWindow((state) => !state);
  // };

  // const onChangeStateFromInitial = useCallback(() => {
  //   setIsToOpenNewOAuthWindow(false);
  // }, []);

  const getTokens = useCallback(() => {
    const token = cookie.getCookie({ name: accessTokenCookieKey });
    const refreshToken = cookie.getCookie({ name: refreshTokenCookieKey });

    if (!token) return;
    if (!refreshToken) return;

    // eslint-disable-next-line consistent-return
    return {
      token,
      refreshToken,
    };
  }, [cookie]);

  const onAuthenticateUser = useCallback(
    async ({ login, password, location }: UserProps) => {
      try {
        const data = await identityApi.getToken(
          {
            login,
            password,
            cookieIdCart: getTokenCartANONYMOUS(cookie),
            location,
          },
          jwtDecoder
        );

        if (!data?.access_token && router.route === "/cadastro") {
          router.push("/login");
        }

        if (!data?.access_token || !data) return null;

        deleteTokenCartANONYMOUS(cookie);

        await keepUserLoggedIn({
          providerType: "identity",
          token: data.access_token,
          refreshToken: data.refresh_token,
          accessToken: null,
        });

        const clientLocation = getClientLocationDetails(cookie);

        localStorage?.removeItem(keyZipCode);

        emitEventClientLogin(clientLocation);

        const routesMatch = ["/cadastro", "/login"].some(
          (route) => route === router.route
        );

        const isCPF =
          login.replace(/\D/g, "").length >= 11 && !login.includes("@");

        fetchConfirmationTypes(
          data.access_token,
          isCPF ? "" : login,
          isCPF ? login : ""
        );

        // artur.castro validar essa lógica que inseri abaixo. O reload é necessário em qual cenário e quando o usuário se deslogar e estiver em que rota? Qual o propósito desse reload? Fazer reload sempre que o usuário desloga trás uma experiência que não é muito legal.
        if (routesMatch && !router?.query?.redirectTo) {
          router.replace("/");
        } else if (router?.query?.redirectTo) {
          router.reload();
        }

        return data;
      } catch (error) {
        appMonitoringClient.captureException(error);
        return null;
      }
    },
    [
      appMonitoringClient,
      cookie,
      emitEventClientLogin,
      identityApi,
      jwtDecoder,
      keepUserLoggedIn,
      router,
      fetchConfirmationTypes,
    ]
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onConnectToIdentityApi = useCallback(async () => {
    if (!session) return;

    const { token } = session as unknown as GoogleAuthSessionProps;
    const body = {
      idToken: token?.id_token,
      provider: token?.provider,
      cookieIdCart: getTokenCartANONYMOUS(cookie),
      location: JSON.stringify(await getGeolocation()),
    };
    appMonitoringClient.captureMessage(
      "Usuário será conectado com API Identity",
      {
        level: "info",
        tags: {
          idToken: token?.id_token,
          provider: token?.provider,
        },
      }
    );

    const data = await identityApi.getTokenOAuth(body, jwtDecoder);

    deleteTokenCartANONYMOUS(cookie);

    if (!data) {
      // setIsToOpenNewOAuthWindow(false);
      setAuthWindowProvider("");
      addToast({
        title: "Ops! Algo deu errado :(",
        type: "error",
      });

      await signOut({
        redirect: false,
      });

      return;
    }

    appMonitoringClient.captureMessage(
      "Usuário será conectado com API Identity",
      {
        level: "info",
        tags: {
          idToken: token?.id_token,
          provider: token?.provider,
          accessToken: (data as AuthenticationProps)?.access_token,
        },
      }
    );

    const addressRouterSignUp = "/cadastro";

    if (router.route === addressRouterSignUp) {
      await signOut({
        redirect: false,
      });
    }
    const userDoesNotExist = data === "invalid_grant";

    const userDoesNotExistAccessByPageSignUp =
      userDoesNotExist && router.route === addressRouterSignUp;

    const userDoesNotExistAccessAnyOtherPageDifferentFromSignUp =
      userDoesNotExist && router.route !== addressRouterSignUp;

    if (userDoesNotExistAccessByPageSignUp) {
      // setIsToOpenNewOAuthWindow(false);
      return;
    }

    if (userDoesNotExistAccessAnyOtherPageDifferentFromSignUp) {
      // setIsToOpenNewOAuthWindow(false);
      router.push(addressRouterSignUp);
      return;
    }

    await signOut({
      redirect: false,
    });

    if (
      (data as AuthenticationProps)?.access_token &&
      (data as AuthenticationProps)?.refresh_token
    ) {
      await keepUserLoggedIn({
        providerType: authWindowProvider,
        token: (data as AuthenticationProps)?.access_token,
        accessToken: token.accessToken,
        refreshToken: (data as AuthenticationProps)?.refresh_token,
      });

      const clientLocation = getClientLocationDetails(cookie);

      emitEventClientLogin(clientLocation);

      if (!(data as AuthenticationProps)?.access_token) {
        return;
      }

      fetchConfirmationTypes((data as AuthenticationProps)?.access_token);
      const routesMatch = ["/cadastro", "/login"].some(
        (route) => route === router.route
      );

      // artur.castro validar essa lógica que inseri abaixo. O reload é necessário em qual cenário e quando o usuário se deslogar e estiver em que rota? Qual o propósito desse reload? Fazer reload sempre que o usuário desloga trás uma experiência que não é muito legal.
      if (routesMatch && !router?.query?.redirectTo) {
        router.replace("/");
        return;
      }

      if (router?.query?.redirectTo) {
        router.reload();
      }
    }
  }, [
    session,
    cookie,
    appMonitoringClient,
    identityApi,
    jwtDecoder,
    router,
    addToast,
    keepUserLoggedIn,
    authWindowProvider,
    emitEventClientLogin,
    fetchConfirmationTypes,
  ]);

  const onAuthenticateUserOAuth = useCallback(async (provider: oAuth) => {
    // const destinationPage = router.route;
    // const redirectTo = Array.isArray(router?.query?.redirectTo)
    //   ? destinationPage
    //   : router?.query?.redirectTo || destinationPage;

    if (provider === "google") {
      await signIn("google");

      return;
    }

    if (provider === "apple") {
      await signIn("apple");
    }
  }, []);

  const verifyAccessToken = useCallback(() => {
    const isUserAuthenticated = cookie.getCookie({
      name: accessTokenCookieKey,
    });

    if (!isUserAuthenticated) {
      setIsAuthenticated(false);
      return;
    }

    setShowChatbot(true);
    setDataInUser(isUserAuthenticated);
  }, [cookie, setDataInUser, setShowChatbot]);

  const signOutAccount = useCallback(
    async (cookieImpl: ICookie) => {
      const routesMatch = ["/cadastro"].some((route) => route === router.route);
      sessionStorage.setItem(googleTagManagerEmailUser, "");

      setShowSaleChatbot(false);
      setShowChatbot(false);

      localStorage?.removeItem(keyZipCode);

      if (!routesMatch) {
        cookieImpl.deleteCookie({ name: accessTokenCookieKey });
        cookieImpl.deleteCookie({ name: refreshTokenCookieKey });
        cookieImpl.deleteCookie({ name: accessTokenGoogleCookieKey });
        cookieImpl.deleteCookie({ name: accessTokenAppleCookieKey });
        cookieImpl.deleteCookie({ name: cookieStoreCEP });
        cookieImpl.deleteCookie({ name: "@FC:Ecom:Token:userList" });
        cookieImpl.deleteCookie({
          name: "@FC:Ecom:ModalToConfirmEmail:HadVisualization",
        });
        await fetch("/api/profile/change-password/remove-validation-cookie", {
          method: "POST",
        });
        heatmapClient.resetCustomIdentifiers();
        changeConditionToStartCountdown(false);
        setUserCrmId(null);
        await signOut({
          redirect: false,
        });

        setIsAuthenticated(false);
        setUserData({} as UserData);
        destroyWebSocketConnection();
        appMonitoringClient.configureScope(null);

        // artur.castro validar essa lógica que inseri abaixo. O reload é necessário em qual cenário e quando o usuário se deslogar e estiver em que rota? Qual o propósito desse reload? Fazer reload sempre que o usuário desloga trás uma experiência que não é muito legal.
        if (
          router.route?.includes("checkout") ||
          router.route?.includes(myPanelStr) ||
          router.route?.includes("conta")
        ) {
          router.reload();
        }
      }
    },
    [
      router,
      heatmapClient,
      changeConditionToStartCountdown,
      setShowChatbot,
      setUserCrmId,
      destroyWebSocketConnection,
      appMonitoringClient,
      setShowSaleChatbot,
    ]
  );

  useEffect(() => {
    verifyAccessToken();
  }, [isAuthenticated, verifyAccessToken]);

  useEffect(() => {
    if (session && status === "authenticated") {
      if (!userData) {
        setIsAuthenticated(true);
        setUserData((prevUserData) => ({
          ...prevUserData,
          email: session?.user?.email || "",
          name: session?.user?.name || "",
        }));
      }
      onConnectToIdentityApi();
    }
  }, [session, onConnectToIdentityApi, status, userData]);

  const authContextProviderValue = useMemo(() => {
    return {
      userData,
      isAuthenticated,
      onAuthenticateUser,
      onAuthenticateUserOAuth,
      signOutAccount,
      keepUserLoggedIn,
      // onChangeStateFromInitial,
      authWindowProvider,
      getTokens,
    };
  }, [
    userData,
    isAuthenticated,
    onAuthenticateUser,
    onAuthenticateUserOAuth,
    signOutAccount,
    keepUserLoggedIn,
    // onChangeStateFromInitial,
    authWindowProvider,
    getTokens,
  ]);

  return (
    <AuthContext.Provider value={authContextProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};
