import {
  getPhoneNumberInvalidInformation,
  useAuthentication,
  useShallow,
  useTwoFactor,
} from "application";
import { useRouter } from "next/router";
import { useCallback } from "react";

const useEmailFeedbackData = () => {
  const router = useRouter();
  const { closeAuthenticationModal } = useAuthentication(
    useShallow((state) => ({
      closeAuthenticationModal: state.closeAuthenticationModal,
    }))
  );

  const { isPhoneNumberInvalid } = useTwoFactor(
    useShallow((state) => ({
      isPhoneNumberInvalid: state.isPhoneNumberInvalid,
    }))
  );

  const { buttonAction, buttonText, description, title } =
    getPhoneNumberInvalidInformation();

  const returnTitle = useCallback(() => {
    if (isPhoneNumberInvalid) {
      return title;
    }

    return "E-mail não encontrado";
  }, [isPhoneNumberInvalid, title]);

  const returnDescription = useCallback(() => {
    if (isPhoneNumberInvalid) {
      return description;
    }

    return "Não foi possível encontrar o e-mail digitado em nosso sistema. Por favor, verifique se o endereço de e-mail está correto ou tente usar um endereço diferente.";
  }, [description, isPhoneNumberInvalid]);

  const returnButtonText = useCallback(() => {
    if (isPhoneNumberInvalid) {
      return buttonText;
    }

    return "Criar cadastro";
  }, [buttonText, isPhoneNumberInvalid]);

  const returnButtonAction = useCallback(() => {
    if (isPhoneNumberInvalid) {
      return buttonAction;
    }

    return "/cadastro";
  }, [buttonAction, isPhoneNumberInvalid]);

  const handleRedirect = useCallback(() => {
    router.push(returnButtonAction());
  }, [returnButtonAction, router]);

  return {
    closeAuthenticationModal,
    handleRedirect,
    returnButtonText,
    returnDescription,
    returnTitle,
  };
};

export { useEmailFeedbackData };
