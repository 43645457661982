import { useState } from "react";
import { buildTestIds } from "application";
import {
  CharacterCounter,
  Container,
  LabelContainer,
  LabelText,
  TextArea,
  TextAreaMessageError,
} from "./styles";

type TextAreaFCProps = {
  error?: boolean;
  helperTextError?: string;
  handleOnChange: (value: string) => void;
  label?: string;
  labelOptional?: string;
  maxLength: number;
  maxLengthOfMessage: boolean;
  placeholder?: string;
  rows: number;
  testId: string;
  testIdLabel: string;
};

const FCTextArea = ({
  error = false,
  helperTextError = " ",
  handleOnChange,
  labelOptional,
  label,
  maxLength = 500,
  maxLengthOfMessage = false,
  placeholder,
  rows = 5,
  testId = "TextArea",
  testIdLabel = "Label",
}: TextAreaFCProps) => {
  const [text, setText] = useState("");

  return (
    <div>
      <LabelContainer>
        <LabelText {...buildTestIds(testIdLabel)}>{label}</LabelText>
        <LabelText>{labelOptional}</LabelText>
      </LabelContainer>
      <Container maxLengthOfMessage={maxLengthOfMessage}>
        <TextArea
          id="charCounter"
          rows={rows}
          maxLength={maxLength}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleOnChange(e.target.value);
            setText(e.target.value);
          }}
          value={text}
          placeholder={placeholder}
          {...buildTestIds(testId)}
        />
        <CharacterCounter maxLengthOfMessage={maxLengthOfMessage}>
          {text.length}/{maxLength}
        </CharacterCounter>
      </Container>
      {error ? (
        <TextAreaMessageError {...buildTestIds("text-area-error")}>
          {helperTextError}
        </TextAreaMessageError>
      ) : null}
    </div>
  );
};

export { FCTextArea };
