import { createStore } from "../adapter";

type AlertState = {
  isAlertOpen: boolean;
  hasPendingOrders: boolean;
};
type AlertFunction = {
  setIsAlertOpen: (value: boolean) => void;
  setHasPendingOrders: (value: boolean) => void;
};

type AlertProps = AlertState & AlertFunction;

const useAlert = createStore<AlertProps>((set) => ({
  isAlertOpen: false,
  setIsAlertOpen: (value) => {
    set({ isAlertOpen: value });
  },
  hasPendingOrders: false,
  setHasPendingOrders: (value) => {
    set({ hasPendingOrders: value });
  },
}));

export { useAlert };
