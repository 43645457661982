// Importações
import { ChangeEvent } from "react";
import { UseFormRegister } from "react-hook-form";
import { IFormValues } from "../types";
import { Address } from "./Address";

// ====================
// Enumerações
// ====================
// eslint-disable-next-line no-shadow
export enum SaleChannel {
  STORE = 1,
  DIGITAL = 2,
  APP = 4,
}

export type OrderOrigin = "loja" | "digital";

// ====================
// Tipos Básicos
// ====================

// Tipos de Cartão
export type CardBrands =
  | "Mastercard"
  | "Visa"
  | "Hiper"
  | "Amex"
  | "Hipercard"
  | "Elo"
  | "Jcb"
  | "Diners";

// Tipos de Pagamento
export type PaymentTypes =
  | "DebitCard"
  | "CreditCard"
  | "Pix"
  | "Cash"
  | "DownPayment"
  | "Others";

// Status do Pedido
export type OrderStatusName =
  | "cancelado"
  | "entregue"
  | "aprovado"
  | "faturamento"
  | "aguardando pagamento"
  | "retirada disponível"
  | "em cancelamento"
  | "processando"
  | "em transporte"
  | "retirado"
  | "em análise"
  | "";

export type OrderStatus =
  | "All"
  | "Pending"
  | "InProgress"
  | "Concluded"
  | "Cancelled"
  | "Shipping";

export type AlertStatus =
  | "Aguardando pagamento"
  | "Em revisão"
  | "Retirada disponível";

export type MonthFilter = 0 | 3 | 6 | 12;

// ====================
// Interfaces de Produto
// ====================

// Produto Base
interface BaseProduct {
  productId: string;
  description: string;
  price: number;
  quantity: number;
  codeColor?: number;
  paintColor: string | null;
}

// Produto da Loja Física
export interface StoreProduct extends BaseProduct {
  imageUrl: string | null;
  measuredUnity: string;
  packagingQuantity: string;
  packagingType: string;
  descriptionPackagingUnitSingular: string;
  descriptionPackagingUnitPlural: string;
}

// ====================
// Interfaces de Mídia
// ====================

export type MediaLinkType = {
  defaultImage: string;
  description: string;
  imageUrl: string;
  videoUrl: string;
};

// Produto Digital (E-commerce)
export interface DigitalProduct extends BaseProduct {
  fatembal?: number;
  packaging: number;
  packagingQuantity: number;
  branchProduct?: number;
  sellerId?: number;
  isPickUpInStore: boolean;
  branchIdForPickup?: number;
  descriptionPackagingUnitSingular: string;
  descriptionPackagingUnitPlural: string;
  measuredUnity: string;
  addressForPickup?: string;
  mediaLink: MediaLinkType[];
}

// Tipo para Produto em Pacote
export type PackageTypeProduct = {
  productId: string;
  description: string;
  imageUrl: string;
  measuredUnity: string;
  descriptionPackagingUnitSingular: string;
  descriptionPackagingUnitPlural: string;
  price: number;
  quantity: number;
  packingLevel: number;
  paintColor?: string;
  codeColor?: number;
};

// Item de Pedido por Telefone
export type OrderItemsPackageByPhone = {
  orderId: number;
  productId: string;
  description: string;
  fatembal: number;
  price: number;
  quantity: number;
  packaging: number;
  packagingQuantity: number;
  branchProduct: number;
  sellerId: number;
  isPickUpInStore: false;
  branchIdForPickup: number;
  descriptionPackagingUnitSingular: string;
  descriptionPackagingUnitPlural: string;
  measuredUnity: string;
  addressForPickup: string | null;
  mediaLink: [
    {
      defaultImage: string | null;
      description: string | null;
      imageUrl: string;
      videoUrl: string | null;
    }
  ];
};

// ====================
// Interfaces de Pagamento
// ====================

// Interface de Pagamento Base
interface BasePayment {
  paymentType: PaymentTypes;
  totalValue: number;
}

// Pagamento da Loja Física
export type StorePayment = BasePayment;

export type CardInformation = {
  lastFourNumbers: string;
  banner: CardBrands;
};

// Pagamento do E-commerce
export interface DigitalPayment extends BasePayment {
  installments: number;
  installmentValue: number;
  card: CardInformation | null;
  orderId: number | null;
}

// Tipo de Pagamento Geral
export type Payments = DigitalPayment;

// ====================
// Interfaces de Cliente
// ====================

export type Client = {
  clientId: number;
  name: string;
  address: Address;
};

export type ClientInfo = Client;

// ====================
// Interfaces de Lista de Casamento
// ====================

export type WeddingList = {
  listId: number;
  listHost: string;
  listSecondaryHost: string;
  listImageUrl: string;
};

// ====================
// Interfaces de Pacote
// ====================

// Interface de Pacote Base
interface BasePackage {
  totalValue: number;
  numberOfItems?: number;
  branchIdForNfe?: number;
  hasTaxDocument?: boolean;
  orderRemoteId?: number;
  weddingList: WeddingList | null;
}

// Pacote da Loja Física
export interface StorePackage extends BasePackage {
  packageId: string;
  product: StoreProduct[];
}

// ====================
// Interfaces de Envio (Shipping)
// ====================

// Envio
export type Shipping = {
  orderId: number;
  deliveryType: string;
  datePrevDescription: string;
  datePrevDescriptionTp: string | null;
};

// Status de Pacotes
export type PackagesStatus = {
  currentStatus: string;
  previousStatus: string;
  nextStatus: string;
};

// Pacote do E-commerce
export interface DigitalPackage extends BasePackage {
  id: number;
  orderId: number;
  ordertHash: string;
  statusForShow: string;
  detailDescriptionStatus: string;
  trackingStatus: string | null;
  loggedBranchId?: number;
  totalPrice: number;
  totalShippingCost?: number;
  maxNumberInstallments?: number;
  maxDeliveryTime?: string;
  createdOn: Date;
  lastUpdateOn: Date;
  faturadoOn: Date;
  refundGa?: number;
  omniChannelId: number;
  isGift: boolean;
  isInStorePayment: boolean;
  orderMarketplaceId?: string | null;
  origin?: string | null;
  discount?: number;
  discountDelivery?: number;
  isAuthorized?: boolean;
  branchId: number;
  emailPickUpInStore?: string;
  namePickUpInStore?: string;
  cpfPickUpInStore?: string;
  shippingValue?: number;
  valueServed?: number;
  freightServiced?: number;
  quantityServed?: number;
  deliveryTime?: string;
  carrierId: number;
  isActiveIntelipost: boolean;
  isActiveSode: boolean;
  sequential: number;
  deliveryDate: Date;
  shipping?: Shipping | null;
  orderItems: DigitalProduct[];
  canCancel: boolean;
  forecastDateDescription: string;
  status?: PackagesStatus;
}

// Tipo de Pacote
export type PackagesType = {
  id: number;
  ordertHash: string;
  numberOfItems: number;
  status: OrderStatusName;
  deliveryType: string;
  detailDescription?: string;
  branchIdForPickup?: number;
  branchIdForNfe?: number;
  isActiveIntelipost?: boolean;
  totalValue: number;
  weddingList?: WeddingList;
};

// Status de Pedido por Telefone
export type StatusOrderByPhone = {
  currentStatus: string;
  previousStatus: string;
  nextStatus: string;
};

// Envio por Telefone
export type ShippingPackageByPhone = {
  orderId: number;
  deliveryType: string;
  datePrevDescription: string;
};

// Pacote por Telefone
export type PackagesByPhoneDto = {
  id: number;
  orderId: number;
  ordertHash: string;
  status: StatusOrderByPhone;
  loggedBranchId: number;
  totalPrice: number;
  totalShippingCost: number;
  maxNumberInstallments: number;
  maxDeliveryTime: string;
  createdOn: string;
  lastUpdateOn: string;
  faturadoOn: string;
  refundGa: number;
  omniChannelId: number;
  isGift: boolean;
  isInStorePayment: boolean;
  orderMarketplaceId: null;
  origin: null;
  discount: number;
  discountDelivery: number;
  isAuthorized: boolean;
  branchId: number;
  emailPickUpInStore: string;
  namePickUpInStore: string;
  cpfPickUpInStore: string;
  shippingValue: number;
  valueServed: number;
  freightServiced: number;
  quantityServed: number;
  deliveryTime: string;
  carrierId: number;
  isActiveIntelipost: boolean;
  isActiveSode: boolean;
  sequential: number;
  deliveryDate: string;
  shipping: ShippingPackageByPhone;
  orderItems: OrderItemsPackageByPhone[];
  weddingList: string | null;
  canCancel: true;
};

// Pacote com Status de Alerta
export type packageWithStatusAlert = {
  id: number;
  ordertHash: string;
  status: AlertStatus;
  deliveryType: string;
};

// Interface para genérico pacotes
export type Packages = DigitalPackage;

// ====================
// Interfaces de Endereço
// ====================

export type AddressPackageByPhone = {
  zipCode: string;
  street: string;
  state: string;
  city: string;
  district: string;
  number: number;
  addressComplement: string;
  referencePoint: string;
};

// ====================
// Interfaces de Pedido
// ====================

// Tipo Base de Pedido
export type OrderType = {
  forecastDateDescription?: string;
  clientId: number;
  groupId: number;
  branchId: number;
  isOmnichannel: boolean;
  saleChannel: number;
  packages: PackagesType[];
  createdOn: Date;
  isGift: boolean;
};

// Novo Tipo de Pedido
export type NewOrderType = Omit<OrderType, "packages"> & {
  packages: (Omit<PackagesType, "product"> & {
    product: PackageTypeProduct[];
  })[];
};

// Interface para Pedido da Loja
export interface StoreOrderDto {
  orderId: number;
  branchId: number;
  branchName: string;
  createdOn: Date;
  saleChannel: SaleChannel;
  isGift: boolean;
  payments: StorePayment[];
  packages: StorePackage[];
  hasTaxDocument?: boolean;
}

// Interface para Pedido Digital (E-commerce)
export interface DigitalOrderDto {
  groupId: number;
  saleChannel: SaleChannel;
  client: Client | null;
  payments: DigitalPayment[];
  packages: DigitalPackage[];
  hasTaxDocument?: boolean;
}

// Pedido por Telefone
export interface OrderByPhoneDto {
  address: AddressPackageByPhone;
  packages: PackagesByPhoneDto[];
  client: string;
}

// ====================
// Interfaces de Status de Pedido
// ====================

// Status do Pedido
export interface OrderStatusDto {
  idStatus: number;
  status: string;
  statusDescription: string;
  statusAdditional: string;
  receiver: string;
  creationDate: Date;
}

// Status de Pedido TMS
export interface OrderStatusTMS {
  statusId: number;
  lastUpdateDate: Date | string;
  mainMessage: string;
  additionalMessage: string | null;
  deliveryForecast: Date | string;
  rescheduledDeliveryForecast: Date | null;
  isRescheduled: boolean;
  details: string | null;
  logisticStatus: string | null;
}

// Resposta de Status do Pedido
export interface OrderStatusResponse {
  statusList: OrderStatusTMS[];
}

// ====================
// Interfaces de DTO
// ====================

// DTO de Pedidos
export interface OrdersDto {
  search: string;
  currentPage: number;
  hasNext: boolean;
  items: OrderType[];
  pageSize: number;
  totalPages: number;
  totalResults: number;
}

// DTO de Novos Pedidos
export interface NewOrdersDto {
  search: string;
  currentPage: number;
  hasNext: boolean;
  items: NewOrderType[];
  pageSize: number;
  totalPages: number;
  totalResults: number;
  monthsReturned: MonthFilter;
}

// DTO de Cancelamento
export interface CancelOrderDto {
  reasonCancellation: string;
  reasonDescription?: string;
}

// DTO de Itens do Pacote
export type OrderPackageItems = DigitalProduct;

// DTO de Status de Alerta
export interface AlertStatusDto {
  clientId: number;
  groupId: number;
  numberOfItems: number;
  messageMainAlertStatus: string;
  messageActionAlertStatus: string;
  linkAlertStatus: string;
  packageWithStatusAlert: packageWithStatusAlert[];
}

// ====================
// Interfaces de Filtro
// ====================

// Filtro de Pedido
export interface OrderFilter {
  name: string;
  filterType: number;
}

// Array de Filtros de Pedido
export type OrderFilters = OrderFilter[];

// Parâmetros de Consulta de Pedidos
export interface OrdersQueryParams {
  jwt?: string;
  condition?: boolean;
  search?: string;
  startDate?: string;
  endDate?: string;
  isGift?: boolean;
  saleChannel?: number;
  status?: string;
  currentPage: number;
  pageSize: number;
  getOmnichannel?: boolean;
  filters?: string[];
}

// ====================
// Interfaces de Resposta
// ====================

// Resposta de Alteração de Responsável pelo Pickup
export interface ChangeResponsiblePickupResponse {
  isValid?: boolean;
  status?: number;
  errors?: {
    Reason?: string[] | Error;
    messages?: string[];
    statusCode?: string[];
    status?: number;
  };
}

export type OrderDetailsDto = DigitalOrderDto;

// ====================
// Interfaces de UI
// ====================

// Props da UI de Meus Pedidos
export interface MyOrdersUIProps
  extends Pick<NewOrdersDto, "pageSize" | "currentPage"> {
  indexBased: number;
  setCurrentPage: (newCurrentPage: number) => void;
  orders?: NewOrdersDto | null;
  isLoading: boolean;
  selectedOrderStatus: string;
  typedTerm: string;
  totalProducts: number;
  register: UseFormRegister<IFormValues>;
  onChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onClearSearch?: () => void;
  handleCloseAlert: () => void;
  hasFiltersApplied?: boolean;
  alertStatus?: AlertStatusDto;
}
