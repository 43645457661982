export const isValidDayForMonth = (
  day: number,
  month: number,
  year: number
): boolean => {
  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
  const monthsWith30Days = [4, 6, 9, 11];

  if (monthsWith31Days.includes(month) && day <= 31) {
    return true;
  }

  if (monthsWith30Days.includes(month) && day <= 30) {
    return true;
  }

  if (month === 2) {
    const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    if (isLeapYear && day <= 29) return true;
    if (!isLeapYear && day <= 28) return true;
  }

  return false;
};
