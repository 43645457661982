import {
  ClientAction,
  ReceiveConfirmationResponse,
  SendCodeType,
  SendTokenResponseDto,
  Step,
  StepName,
  ToastMessageProps,
} from "typing";
import { theme } from "ui";
import { NotificationApi } from "../../services";
import { createStore } from "../adapter";

type ButtonCtaType = {
  label: string;
  onClick: () => void;
};

export interface TwoFactorStateState {
  email: string;
  phoneNumber: string;
  isValidationActive: boolean;
  isBlockedToSendCode: boolean | null;
  tokenCode: string;
  isTokenCodeValid: boolean | null;
  validateCode: boolean;
  isUnderAction: boolean;
  isCodeComplete: boolean;
  clientAction: ClientAction | null;
  canChooseAnotherTwoFactorMethod: boolean;
  isCodeTimerActive: boolean;
  isSendCodeAvailable: boolean;
  clipboardText: string;
  isEmailDifferent: boolean;
  initTimer: boolean;
  channelSelected: number;
  availableMethods: ReceiveConfirmationResponse | null;
  isValidatingCode: boolean;
  userIdentification: string;
  faType?: number;
  openEditDataModal: boolean;
  steps: Step[];
  currentStep: StepName;
  openValidateDataModal: boolean;
  changeDataTitle: string;
  changeDataDescription: string;
  buttonCta: ButtonCtaType;
  cpf: string;
  isClientNotFound: boolean;
  isChangingAvailableMethods: boolean;
  isValidatingOnMobile: boolean;
  hasAuthenticationMethodChanged: boolean;
  isRequesting: boolean;
  isPhoneNumberInvalid: boolean;
}

export type SendCodeProps = {
  sendCodeType: Exclude<SendCodeType, "app">;
  infoValue: string;
  errorTitle: string;
  notificationApi: NotificationApi;
  faType?: number;
  addToast: (toastMessageProps: ToastMessageProps) => void;
  email?: string;
  cpf?: string;
  phoneNumber?: string;
  token?: string;
};

export type ValidateToken = {
  token: string;
  notificationApi: NotificationApi;
  addToast: (toastMessageProps: ToastMessageProps) => void;
  accessToken: string;
};

export type handleStartStepParams = {
  sendTokenCallback: (channel: number) => void;
  availableMethods: ReceiveConfirmationResponse;
  isRequesting: boolean;
  setIsValidationActive: (value: boolean) => void;
  changeCurrentStep: (value: StepName) => void;
  isValidationActive: boolean;
  isBlockedToSendCode: boolean;
  setIsBlockedToSendCode: (value: boolean) => void;
};

export interface TwoFactorStateDispatch {
  setEmail: (email: string) => void;
  setIsValidatingCode: (value: boolean) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setIsValidationActive: (value: boolean) => void;
  resetInfo: () => void;
  setIsBlockedToSendCode: (value: boolean | null) => void;
  setTokenCode: (tokenCode: string) => void;
  setIsTokenCodeValid: (value: boolean | null) => void;
  setValidateCode: (value: boolean) => void;
  setIsUnderAction: (value: boolean) => void;
  setIsCodeComplete: (value: boolean) => void;
  setClientAction: (value: ClientAction) => void;
  setCanChooseAnotherTwoFactorMethod: (value: boolean) => void;
  setChannelSelected: (value: number) => void;
  setCodeTimerActive: (value: boolean) => void;
  setIsSendCodeAvailable: (value: boolean) => void;
  setIsEmailDifferent: (value: boolean) => void;
  setClipboardText: (text: string) => void;
  setInitTimer: (value: boolean) => void;
  clearTimer: (faType: number, channel: number, blockRequest?: boolean) => void;
  getTimer: (
    faType: number,
    channel: number,
    changeValidateState?: boolean
  ) => { isTimerRunning: boolean };
  setAvailableMethods: (value: ReceiveConfirmationResponse | null) => void;
  setUserIdentification: (value: string) => void;
  setFaType: (value?: number) => void;
  setOpenEditDataModal: (value: boolean) => void;
  setSteps: (value: Step[]) => void;
  changeCurrentStep: (value: StepName) => void;
  setOpenValidateDataModal: (value: boolean) => void;
  setChangeDataTitle: (value: string) => void;
  setChangeDataDescription: (value: string) => void;
  changeButtonCta: (value: ButtonCtaType) => void;
  setCpf: (value: string) => void;
  setHasAuthenticationMethodChanged: (value: boolean) => void;
  setIsChangingAvailableMethods: (value: boolean) => void;
  setIsValidatingOnMobile: (value: boolean) => void;
  setIsRequesting: (value: boolean) => void;
  setIsPhoneNumberInvalid: (value: boolean) => void;
}

export interface TwoFactorFunctions {
  sendCode: (sendCodeProps: SendCodeProps) => Promise<boolean>;
  validateToken: (validateTokenProps: ValidateToken) => void;
  handleStartStep: (params: handleStartStepParams) => void;
}

type TwoFactorState = TwoFactorStateState &
  TwoFactorStateDispatch &
  TwoFactorFunctions;

const useTwoFactor = createStore<TwoFactorState>((set, get) => ({
  initTimer: false,
  email: "",
  isValidatingCode: false,
  phoneNumber: "",
  tokenCode: "",
  isEmailDifferent: false,
  isTokenCodeValid: null,
  isBlockedToSendCode: false,
  isValidationActive: false,
  isSendCodeAvailable: true,
  validateCode: false,
  isUnderAction: false,
  isCodeTimerActive: false,
  isCodeComplete: false,
  hasAuthenticationMethodChanged: false,
  clientAction: null,
  canChooseAnotherTwoFactorMethod: false,
  setInitTimer: (value: boolean) => set({ initTimer: value }),
  setIsEmailDifferent: (value: boolean) => set({ isEmailDifferent: value }),
  setEmail: (email) =>
    set((state) => ({
      email,
      isEmailDifferent: state.email !== email && state.email !== "",
    })),
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
  setIsValidationActive: (isValidationActive) => set({ isValidationActive }),
  setTokenCode: (tokenCode) => set({ tokenCode }),
  setIsUnderAction: (isUnderAction) => set({ isUnderAction }),
  setIsCodeComplete: (isCodeComplete) => set({ isCodeComplete }),
  setIsTokenCodeValid: (value: boolean | null) =>
    set({ isTokenCodeValid: value }),
  setIsBlockedToSendCode: (value: boolean | null) =>
    set({ isBlockedToSendCode: value }),
  setValidateCode: (value: boolean) => set({ validateCode: value }),
  setClientAction: (clientAction) => set({ clientAction }),
  setCanChooseAnotherTwoFactorMethod: (value: boolean) =>
    set({ canChooseAnotherTwoFactorMethod: value }),
  resetInfo: () => {
    set({
      isValidationActive: false,
      email: "",
      phoneNumber: "",
      isTokenCodeValid: false,
      clientAction: null,
    });
  },
  sendCode: async ({
    sendCodeType,
    notificationApi,
    addToast,
    infoValue,
    errorTitle,
    faType,
    email,
    cpf,
    phoneNumber,
    token,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  SendCodeProps) => {
    let hasValidResult = false;
    const storagedTime = localStorage.getItem(
      `timeSent_${faType}_${get().channelSelected}`
    );

    const actualTime = Date.now();

    const diffTime = Math.floor(
      Number(storagedTime) - Number(actualTime) / 1000
    );

    if (diffTime && diffTime > 0 && storagedTime) {
      get().setIsValidationActive(true);

      if (get().currentStep === "chooseAnotherMethod") {
        get().changeCurrentStep("verifyIdentity");
      }
    }

    set({
      isUnderAction: true,
    });

    if (
      sendCodeType === "email" &&
      ![
        "loginValidation",
        "changeEmail",
        "changePassword",
        "changePhoneNumber",
        "requestExclusionAccount",
        "editData",
        "requestSecurityIssue",
      ].includes(String(get()?.clientAction))
    ) {
      const result = await notificationApi.sendTwoFactorEmail(
        faType ?? 5,
        infoValue
      );
      hasValidResult = result.isValidEmail;
    }

    if (
      sendCodeType === "sms" &&
      get()?.faType === 6 &&
      ![
        "loginValidation",
        "changeEmail",
        "changePassword",
        "changePhoneNumber",
        "requestExclusionAccount",
        "editData",
        "requestSecurityIssue",
      ].includes(String(get()?.clientAction))
    ) {
      const result = (await notificationApi.sendTokenForSMS(
        Number(faType),
        infoValue
      )) as unknown as SendTokenResponseDto;

      hasValidResult = !!result?.validationResult?.isValid;
    }

    if (
      sendCodeType === "sms" &&
      get()?.faType !== 6 &&
      get()?.faType !== 13 &&
      ![
        "loginValidation",
        "changeEmail",
        "changePassword",
        "changePhoneNumber",
        "requestExclusionAccount",
        "editData",
        "requestSecurityIssue",
      ].includes(String(get()?.clientAction))
    ) {
      const result = (await notificationApi.sendTwoFactorSMS(
        2,
        infoValue
      )) as unknown as SendTokenResponseDto;
      hasValidResult = !!result?.validationResult?.isValid;
    } else if (
      sendCodeType === "new2FA" ||
      [
        "loginValidation",
        "changeEmail",
        "changePassword",
        "changePhoneNumber",
        "requestExclusionAccount",
        "editData",
        "requestSecurityIssue",
      ].includes(String(get()?.clientAction))
    ) {
      const requestBody = {
        email,
        cpf,
        faType: get()?.faType ?? null,
        channel: get()?.channelSelected,
        phoneNumber,
      };
      const response = (await notificationApi.sendTwoFactorTokenByChannel(
        String(token),
        requestBody
      )) as unknown as SendTokenResponseDto;

      hasValidResult = response?.validationResult?.isValid;
    }

    set({
      tokenCode: "",
      isBlockedToSendCode: !hasValidResult,
      validateCode: false,
      isUnderAction: false,
    });

    if (hasValidResult) {
      addToast({
        title: "Código enviado!",
        isNewToast: true,
        newToastTheme: "light",
        type: "success",
        timerInMilliseconds: 3000,
      });

      set({ isSendCodeAvailable: false });

      setTimeout(() => {
        set({ isSendCodeAvailable: true });
      }, 60000);
    } else {
      addToast({
        isNewToast: true,
        newToastTheme: "light",
        title: errorTitle,
        description:
          "Você excedeu o limite de tentativas de validação do código de verificação.",
        type: "error",
        timerInMilliseconds: 3000,
      });
    }

    return hasValidResult;
  },
  validateToken: async ({
    token,
    notificationApi,
    addToast,
    accessToken,
  }: ValidateToken) => {
    const dataName =
      get().channelSelected === 1
        ? "Telefone"
        : get().channelSelected === 2
        ? "e-mail"
        : "";
    const { isValid, errors } = await notificationApi.validateTwoFactorCode({
      channel: get().channelSelected,
      faType: Number(get().faType),
      cpf: String(get().cpf),
      email: String(get().email),
      phoneNumber: String(get().phoneNumber),
      token,
      jwt: accessToken,
    });

    if (!isValid && errors?.statusCode?.includes("222")) {
      get().setIsBlockedToSendCode(true);
      get().setIsValidationActive(true);
      localStorage.removeItem(
        `timeSent_${get().faType}_${get().channelSelected}`
      );
      localStorage.removeItem(
        `countdownTime_${get().faType}_${get().channelSelected}`
      );
      localStorage.removeItem(
        `countdownTimeTimestamp_${get().faType}_${get().channelSelected}`
      );
      return;
    }

    if (!isValid && !errors?.statusCode?.includes("222")) {
      get().setIsTokenCodeValid(false);
      get().setValidateCode(true);
      addToast({
        type: "error",
        isNewToast: true,
        newToastTheme: "light",
        title: `Erro ao validar ${dataName}`,
        description: errors?.messages?.[0] ?? "Erro ao validar código",
      });
      localStorage.removeItem(
        `timeSent_${get().faType}_${get().channelSelected}`
      );
      localStorage.removeItem(
        `countdownTime_${get().faType}_${get().channelSelected}`
      );
      localStorage.removeItem(
        `countdownTimeTimestamp_${get().faType}_${get().channelSelected}`
      );
      return;
    }

    get().setIsValidationActive(false);
    addToast({
      type: "success",
      isNewToast: true,
      newToastTheme: "light",
      title: `${dataName} validado com sucesso`,
      description: `Parabéns, sua conta está mais segura após a validação do seu ${dataName}.`,
    });

    localStorage.removeItem(
      `timeSent_${get().faType}_${get().channelSelected}`
    );
    localStorage.removeItem(
      `countdownTime_${get().faType}_${get().channelSelected}`
    );
    localStorage.removeItem(
      `countdownTimeTimestamp_${get().faType}_${get().channelSelected}`
    );
    get().setIsChangingAvailableMethods(true);
  },
  channelSelected: 2,
  setChannelSelected: (channelSelected) => set({ channelSelected }),
  availableMethods: {} as ReceiveConfirmationResponse,
  setAvailableMethods: (availableMethods) => set({ availableMethods }),
  setCodeTimerActive: (value: boolean) => set({ isCodeTimerActive: value }),
  setIsSendCodeAvailable: (value: boolean) =>
    set({ isSendCodeAvailable: value }),
  clipboardText: "",
  setClipboardText: (clipboardText) => set({ clipboardText }),
  setIsValidatingCode: (isValidatingCode) => set({ isValidatingCode }),
  userIdentification: "",
  setUserIdentification: (userIdentification) => set({ userIdentification }),
  faType: undefined,
  setFaType: (faType) => set({ faType }),
  openEditDataModal: false,
  setOpenEditDataModal: (openEditDataModal) => set({ openEditDataModal }),
  steps: [],
  setSteps: (steps) => set({ steps }),
  currentStep: "verifyIdentity",
  changeCurrentStep: (value) => {
    const newSteps: Step[] = [
      {
        name: "verifyIdentity",
        statusColor:
          value === "verifyIdentity" || value === "chooseAnotherMethod"
            ? `${theme.colors.secondary["380"]}`
            : `${theme.colors.secondary["37"]}`,
      },
      {
        name: "updatePhoneNumber",
        statusColor:
          value === "editData"
            ? `${theme.colors.secondary["380"]}`
            : value === "verifyCode"
            ? `${theme.colors.secondary["37"]}`
            : undefined,
      },
      {
        name: "verifyPhoneNumber",
        statusColor:
          value === "verifyCode"
            ? `${theme.colors.secondary["380"]}`
            : undefined,
      },
    ];

    set({
      currentStep: value,
      steps: newSteps,
    });
  },
  openValidateDataModal: false,
  setOpenValidateDataModal: (openValidateDataModal) =>
    set({ openValidateDataModal }),
  changeDataTitle: "",
  setChangeDataTitle: (changeDataTitle) => set({ changeDataTitle }),
  changeDataDescription: "",
  setChangeDataDescription: (changeDataDescription) =>
    set({ changeDataDescription }),
  buttonCta: {
    label: "",
    onClick: () => [],
  },
  changeButtonCta: (buttonCta) => set({ buttonCta }),
  cpf: "",
  setCpf: (cpf) => set({ cpf }),
  isClientNotFound: false,
  setIsClientNotFound: (value: boolean) => set({ isClientNotFound: value }),
  isChangingAvailableMethods: true,
  setIsChangingAvailableMethods: (value: boolean) =>
    set({ isChangingAvailableMethods: value }),
  isValidatingOnMobile: false,
  setIsValidatingOnMobile: (value: boolean) =>
    set({ isValidatingOnMobile: value }),
  setHasAuthenticationMethodChanged: (value: boolean) =>
    set({ hasAuthenticationMethodChanged: value }),
  clearTimer: (faType, channel, blockRequest) => {
    get().setInitTimer(false);
    get().setCodeTimerActive(false);
    get().setIsValidatingCode(false);
    get().setIsSendCodeAvailable(!blockRequest);
    get().setIsTokenCodeValid(!blockRequest);
    get().setIsBlockedToSendCode(!!blockRequest);

    localStorage.removeItem(`timeSent_${faType}_${channel}`);
    localStorage.removeItem(`countdownTime_${faType}_${channel}`);
    localStorage.removeItem(`countdownTimeTimestamp_${faType}_${channel}`);
  },
  getTimer: (faType, channel, changeValidateState) => {
    const storagedTime = localStorage.getItem(`timeSent_${faType}_${channel}`);

    const actualTime = Date.now();

    const diffTime = Math.floor(
      Number(storagedTime) - Number(actualTime) / 1000
    );

    const timeRunning = Boolean(diffTime && diffTime > 0 && storagedTime);

    if (changeValidateState) get().setIsValidationActive(timeRunning);

    if (diffTime && diffTime <= 0) get().clearTimer(faType, channel);

    return {
      isTimerRunning: timeRunning,
    };
  },
  isRequesting: false,
  setIsRequesting: (isRequesting) => set({ isRequesting }),
  handleStartStep: ({
    sendTokenCallback,
    availableMethods,
    isRequesting,
    setIsValidationActive,
    changeCurrentStep,
    isValidationActive,
    isBlockedToSendCode,
    setIsBlockedToSendCode,
  }: handleStartStepParams) => {
    if (!availableMethods || isRequesting) return;
    const { emailConfirmed, phoneNumberConfirmed } = availableMethods;

    if (!emailConfirmed && phoneNumberConfirmed) {
      sendTokenCallback(1);
      return;
    }

    if (emailConfirmed && !phoneNumberConfirmed) {
      sendTokenCallback(2);
      return;
    }

    if (
      (emailConfirmed && phoneNumberConfirmed) ||
      (!emailConfirmed && !phoneNumberConfirmed)
    ) {
      setIsValidationActive(true);
      changeCurrentStep("verifyIdentity");
      if (!isValidationActive && isBlockedToSendCode) {
        setIsBlockedToSendCode(false);
        changeCurrentStep("verifyIdentity");
      }
    }
  },
  isPhoneNumberInvalid: false,
  setIsPhoneNumberInvalid: (isPhoneNumberInvalid) =>
    set({ isPhoneNumberInvalid }),
}));

export { useTwoFactor };
