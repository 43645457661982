import { NextRouter } from "next/router";
import { useState } from "react";
import { ControlledCartAddProductResponse } from "typing";
import { CartContextType } from "../../context/ShoppingHookContext/typesContextCart";
import { ToastContextProps } from "../Toast";

interface AddItemToCartProps {
  router: NextRouter;
  cart: CartContextType;
  useToast(): ToastContextProps;
  stayOnPage?: boolean;
}

type ProductProps = {
  packingLevel?: number;
  quantity?: number;
  productId: string;
  price: string | number | null;
};

const useAddItemToCart = ({
  cart,
  useToast,
  router,
  stayOnPage = false,
}: AddItemToCartProps) => {
  const [isSendingItemToCart, setIsSendingItemToCart] = useState(false);

  const { addToast } = useToast();

  const sendItemToCart = async ({
    packingLevel,
    quantity,
    productId,
    price,
  }: ProductProps) => {
    setIsSendingItemToCart(true);
    const cartProductItem = cart?.cartItems?.find(
      (item) => item?.productId === productId
    );

    if (cartProductItem) {
      await cart
        .updateQuantityItemCart({
          idProductCart: cartProductItem.id,
          quantity: cartProductItem.quantity + 1,
          cartId: cartProductItem.cartId,
          showPopUp: false,
        })
        .then((res) => {
          const response = res as { hasError: boolean };

          if (response?.hasError) {
            addToast({
              isNewToast: true,
              title:
                "Ops, ocorreu um erro ao atualizar seu carrinho. Por favor, tente novamente em instantes.",
              type: "error",
              newToastTheme: "light",
            });
            return;
          }
          if (!stayOnPage) {
            router.push("/checkout/meu-carrinho");
          }
          addToast({
            isNewToast: true,
            title: "Carrinho atualizado com sucesso!",
            type: "success",
            newToastTheme: "light",
          });
        })
        .catch(() => {
          addToast({
            isNewToast: true,
            title:
              "Erro ao atualizar seu carrinho. Por favor tente novamente mais tarde",
            type: "error",
            newToastTheme: "light",
          });
        })
        .finally(() => {
          setIsSendingItemToCart(false);
        });
      return;
    }

    await cart
      .addProductToControlledCart({
        productId,
        quantity: 1,
        package: packingLevel || 0,
        packingQuantity: quantity || 1,
        sellerId: 0,
        price: Number(price) || 0,
        showPopUp: false,
        cartId: cart.id,
      })
      .then((res) => {
        const response = res as ControlledCartAddProductResponse;
        if (response?.limitTotalItens === null) {
          addToast({
            isNewToast: true,
            title:
              "Ops, ocorreu um erro inesperado. Por favor, tente novamente em instantes.",
            type: "error",
            newToastTheme: "light",
          });
          return;
        }
        if (!stayOnPage) {
          router.push("/checkout/meu-carrinho");
        }
        addToast({
          isNewToast: true,
          title: "Produto adicionado ao carrinho com sucesso!",
          type: "success",
          newToastTheme: "light",
        });
      })
      .catch(() => {
        addToast({
          isNewToast: true,
          title:
            "Ops, ocorreu um erro inesperado. Por favor, tente novamente em instantes.",
          type: "error",
          newToastTheme: "light",
        });
      })
      .finally(() => {
        setIsSendingItemToCart(false);
      });
  };

  return { sendItemToCart, isSendingItemToCart };
};

export { useAddItemToCart };
