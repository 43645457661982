export const fontSize = <const>{
  sm: {
    1: "0.625rem",
    2: "0.75rem",
    3: "0.875rem",
  },
  md: {
    1: "1rem",
    2: "1.125rem",
    3: "1.25rem",
  },
  lg: {
    1: "1.5rem",
    2: "1.75rem",
    3: "2rem",
  },
  xlg: {
    1: "2.5rem",
    2: "2.625rem",
    3: "3rem",
    4: "3.375rem",
  },
};
