/* eslint-disable class-methods-use-this */
import {
  AddressDto,
  AddressEditBodyDto,
  AddressPostDto,
  AddressesProps,
  BffChangePasswordDto,
  ChangePhoneDto,
  ClientDto,
  DeleteAccountDto,
  DeleteAccountResponse,
  EditDataBody,
  ErrorDto,
  HttpResponseErrorDto,
  IHttpResponse,
  PasswordChangeDuePurchaseTimeDTO,
  ProfileDto,
} from "typing";

import {
  IAppMonitoringClient,
  IGtag,
  IHttpClient,
} from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import { getApisUrlFallback, isObjectEmpty } from "../../../utils";
import { EnvsApi } from "../Envs";

const contentType = "application/json-patch+json";
const accept = "application/json";

class ProfileApi implements IApi {
  baseUrl = "";

  baseUrlV2 = "";

  middlewareBaseUrl = "/api/profile/";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private gtag: IGtag,
    private envsApi: EnvsApi
  ) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}profile/`;
      this.baseUrlV2 = `${apisBaseUrl || fallback}bff-site/profile/`;
    }

    this.appMonitoringClient = appMonitoringClient;
  }

  private defaultError = "Ocorreu um erro inesperado, tente novamente";

  // eslint-disable-next-line class-methods-use-this
  serverClientsEndpoint = () => {
    return `/api/clients`;
  };

  postBFFClientsEndpoint = () => {
    return `${this.baseUrlV2}create-client`;
  };

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  createClient = async (
    body: ProfileDto,
    provider: "google" | "apple" | "password"
  ) => {
    try {
      this.gtag.signUp(provider);
      return this.httpClient.usePost<ProfileDto, HttpResponseErrorDto>(
        this.serverClientsEndpoint(),
        body as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  createBFFClient = async (
    body: ProfileDto,
    provider: "google" | "apple" | "password"
  ) => {
    try {
      this.gtag.signUp(provider);
      return this.httpClient.usePost<ProfileDto, HttpResponseErrorDto>(
        `${this.middlewareBaseUrl}create-client`,
        body as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  getClientEndpoint = () => {
    return `${this.baseUrl}v1/clients`;
  };

  getClient = (
    accessToken: string | undefined,
    condition = true
  ): IHttpResponse<ClientDto, unknown> => {
    const { data, error, isLoading, trigger } =
      this.httpClient.useGet<ClientDto>(
        this.getClientEndpoint(),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        condition
      ) as IHttpResponse<ClientDto, unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  fetchClient = async (accessToken: string | undefined) => {
    try {
      const response = await fetch(this.getClientEndpoint(), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();

      return {
        data,
        error: null,
      };
    } catch (err) {
      const error = isObjectEmpty(err as { [key: string]: string })
        ? null
        : JSON.stringify(err);
      this.appMonitoringClient.captureException(error ?? this.defaultError);
      return { data: null, error };
    }
  };

  changeUserDataEndpoint = () => {
    return `${this.baseUrl}v1/clients`;
  };

  // eslint-disable-next-line class-methods-use-this
  constructChangePhoneBody = (newPhoneNumber: string): BodyInit[] => {
    const phoneBody = {
      op: "replace",
      path: "/PhoneNumber",
      value: newPhoneNumber,
    } as unknown as BodyInit;
    return [phoneBody];
  };

  // eslint-disable-next-line class-methods-use-this
  constructChangeNameBody = (newName: string): BodyInit[] => {
    const nameBody = {
      op: "replace",
      path: "/Name",
      value: newName,
    } as unknown as BodyInit;
    return [nameBody];
  };

  private changePhoneMappedErrors = (status: string) => {
    return (
      {
        "400": this.defaultError,
        "500": this.defaultError,
      }[`${status}`] || this.defaultError
    );
  };

  changePhone = async (accessToken: string, changePhoneDto: ChangePhoneDto) => {
    try {
      const { newPhoneNumber } = changePhoneDto;
      const response = (await this.httpClient.usePatch(
        `${this.changeUserDataEndpoint()}`,
        {
          accept,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": contentType,
        },
        this.constructChangePhoneBody(newPhoneNumber)
      )) as { status?: number };
      const responseStatus = response?.status?.toString();
      if (responseStatus) {
        const error = this.changePhoneMappedErrors(responseStatus);
        return {
          data: null,
          error,
        };
      }
      return { data: response, error: null };
    } catch (err) {
      const error = isObjectEmpty(err as { [key: string]: string })
        ? null
        : JSON.stringify(err);
      this.appMonitoringClient.captureException(error ?? this.defaultError);
      return { data: null, error };
    }
  };

  changeName = async (accessToken: string, newName: string) => {
    try {
      const response = (await this.httpClient.usePatch(
        `${this.changeUserDataEndpoint()}`,
        {
          accept,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": contentType,
        },
        this.constructChangeNameBody(newName)
      )) as unknown as ClientDto;

      return { data: response, error: null };
    } catch (err) {
      const error = isObjectEmpty(err as { [key: string]: string })
        ? null
        : JSON.stringify(err);
      this.appMonitoringClient.captureException(error ?? this.defaultError);
      return { data: null, error };
    }
  };

  clientsAddressEndpoint = (pageSize?: number) => {
    return `${this.baseUrl}v1/addresses${
      pageSize ? `?PageSize=${pageSize}` : ""
    }`;
  };

  getAllAddresses = (
    jwt: string | undefined,
    condition = false
  ): IHttpResponse<AddressDto[], unknown> => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet<
      AddressDto[]
    >(
      this.clientsAddressEndpoint(30),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<AddressesProps[], unknown>;
    return {
      data,
      error,
      isLoading,
      trigger,
    };
  };

  // private createClientMappedErrors = (status: string) => {
  //   return (
  //     {
  //       "400": this.defaultError,
  //       "500": this.defaultError,
  //     }[`${status}`] || this.defaultError
  //   );
  // };

  createClientAddress = async (body: AddressPostDto, jwt: string) => {
    try {
      return await this.httpClient.usePost<AddressPostDto>(
        "/api/clients/addresses",
        body as unknown as BodyInit,
        {
          headers: {
            "Content-Type": accept,
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  deleteClientAddressEndPoint = (clientAddresses: string) => {
    return `${this.baseUrl}v1/addresses/${clientAddresses}`;
  };

  deleteClientAddresses = async (
    jwt: string | undefined,
    clientAddresses: string,
    body?: string
  ) => {
    try {
      return await this.httpClient.useDelete<AddressPostDto>(
        this.deleteClientAddressEndPoint(clientAddresses),
        {
          Authorization: `Bearer ${jwt}`,
        },
        body as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  editClientAddressEndPoint = (clientAddresses: string) => {
    return `${this.baseUrl}v1/addresses/${clientAddresses}`;
  };

  editClientAddresses = async (
    jwt: string | undefined,
    clientAddressId: string,
    body: AddressEditBodyDto[]
  ) => {
    try {
      return await this.httpClient.usePatch(
        this.editClientAddressEndPoint(clientAddressId),
        {
          "Content-Type": contentType,
          Authorization: `Bearer ${jwt}`,
        },
        body as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  changePasswordEndpoint = () => {
    return "/api/profile/change-password";
  };

  changePassword = async ({
    email,
    cpf,
    faType,
    channel,
    jwt,
    token,
    phoneNumber,
    oldPassword,
    newPassword,
  }: BffChangePasswordDto) => {
    try {
      return await this.httpClient.usePost(
        this.changePasswordEndpoint(),
        {
          email,
          cpf,
          faType,
          channel,
          token,
          phoneNumber,
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  editDataEndpoint = () => {
    return "/api/profile/update-data";
  };

  editData = async (body: EditDataBody, jwt: string) => {
    try {
      return await this.httpClient.usePost<EditDataBody>(
        "/api/profile/update-data",
        body as unknown as BodyInit,
        {
          headers: {
            "Content-Type": accept,
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  passwordChangeDuePurchaseTimeEndpoint = (identifier: string) => {
    return `${this.baseUrl}v1/clients/password-change-due-purchase-time?identifier=${identifier}`;
  };

  passwordChangeDuePurchaseTime = async (identifier: string) => {
    try {
      const response = await fetch(
        `${this.passwordChangeDuePurchaseTimeEndpoint(identifier)}`
      );

      return (await response.json()) as PasswordChangeDuePurchaseTimeDTO;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  deleteAccount = async ({
    body,
    jwt,
  }: {
    body: DeleteAccountDto;
    jwt: string;
  }) => {
    try {
      const response = (await this.httpClient.useDelete<DeleteAccountResponse>(
        this.serverClientsEndpoint(),
        {
          Authorization: `Bearer ${jwt}`,
        },
        body as unknown as BodyInit
      )) as IHttpResponse<DeleteAccountResponse, ErrorDto>;

      return {
        data: response?.data,
        error: null,
        statusCode: response.statusCode,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        error,
      };
    }
  };
}

export { ProfileApi };
