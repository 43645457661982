/* eslint-disable import/no-extraneous-dependencies */
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale/pt-BR";
import { useMemo, useState } from "react";
import { useController } from "react-hook-form";
import InputMask from "react-input-mask";
import { ReactDatePickerProps } from "typing";
import { isFutureDate, isInvalidYear, isValidDayForMonth } from "infra/utils";
import * as S from "./styles";

registerLocale("pt-BR", ptBR);

const ReactDatePicker = ({
  onChange,
  id,
  required,
  onBlur,
  placeholder,
  open = false,
  setIsOpen,
  onInputClick,
  onClickOutside,
  onInputChangesMonitoringErrors,
  ...props
}: ReactDatePickerProps) => {
  const today = useMemo(() => new Date(), []);
  const [inputedDate, setInputedDate] = useState<string>("");

  const { field } = useController({
    name: id || "date-picker",
    control: props.control,
    rules: {
      onBlur,
      onChange,
      value: inputedDate,
      required,
      maxLength: 10,
      pattern: {
        value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
        message: "Data inválida",
      },
    },
  });

  const isValidDate = (date: string): boolean => {
    const pattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    const invalidDate =
      !pattern.test(date) ||
      date === "00/00/0000" ||
      isInvalidYear(date) ||
      isFutureDate(date);

    if (invalidDate) {
      return false;
    }

    const [day, month, year] = date.split("/").map(Number);

    return isValidDayForMonth(day, month, year);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (onInputChangesMonitoringErrors) {
      onInputChangesMonitoringErrors(inputValue);
    }

    if (!isValidDate(inputValue)) {
      setInputedDate("");
      field.onChange("");
      return;
    }

    field.onChange(inputValue);
    setInputedDate(inputValue);
  };

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      const formattedDate = date
        .toISOString()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/");
      setInputedDate(formattedDate);
      field.onChange(date);
    }
  };

  const handleClickOutside = () => {
    if (setIsOpen) setIsOpen(!open);

    if (onClickOutside) onClickOutside();
  };

  const handleInputClick = () => {
    if (setIsOpen) setIsOpen(true);

    if (onInputClick) onInputClick();
  };

  const handleRawChange = (
    e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    const rawValue = e ? (e.target as HTMLInputElement).value : "";

    field.value = rawValue;
    setInputedDate(rawValue);

    if (!rawValue || rawValue.length !== 10) return;

    if (!isValidDate(rawValue)) {
      setInputedDate("");
      field.onChange("");
      return;
    }

    const isoDate = new Date(
      `${rawValue.slice(0, 10).split("/").reverse().join("-")}T00:00:00`
    );

    setInputedDate(rawValue.slice(0, 10));
    field.onChange(isoDate);
  };

  const selectedDate = isValidDate(inputedDate)
    ? new Date(`${inputedDate.split("/").reverse().join("-")}T00:00:00`)
    : null;

  return (
    <S.StyledDatePickerWrapper>
      <DatePicker
        {...field}
        {...props}
        autoComplete="off"
        startDate={today}
        selected={selectedDate}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder || "dd/mm/aaaa"}
        locale={ptBR}
        open={open}
        onClickOutside={handleClickOutside}
        onInputClick={handleInputClick}
        onSelect={handleDateSelect}
        maxDate={today}
        closeOnScroll
        formatWeekDay={(date) => date.substring(0, 3)}
        minDate={new Date("1900-01-01")}
        dropdownMode="select"
        showYearDropdown
        showMonthDropdown
        useShortMonthInDropdown
        onChangeRaw={handleRawChange}
        onKeyDown={(e) => e.preventDefault()}
        customInput={
          <InputMask
            mask="99/99/9999"
            value={field.value}
            onChange={(e) => {
              handleInputChange(e);
              field.onChange(e.target.value);
            }}
            maskChar={null}
          />
        }
      />
    </S.StyledDatePickerWrapper>
  );
};

export { ReactDatePicker };
