import { IconProps } from "typing";
import { theme } from "../../themes";

export const DeviceComputerIcon = ({
  width = theme.space["x11.75"],
  height = theme.space["x11.75"],
  strokeWidth = "1.5",
  color = theme.colors.neutral["550"],
  alt = "Icone de computador",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
      data-testeid="device-icon"
    >
      <rect
        x="6.49268"
        y="8.43799"
        width="35.0146"
        height="25.2883"
        rx="2.33333"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2463 39.5621H32.7536"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
