import {
  buildTestIds,
  getClientBranchId,
  getProductPosition,
  useAppContext,
} from "application";
import { catalogApi, cookie, gtag } from "implementations";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import {
  CustomBreadcrumbLabel,
  SearchResultByTermDto,
  SearchResultProps,
} from "typing";
import { CloseIcon, Column, Row, SpaceWrapper, Text, theme } from "ui";

import { Breadcrumb } from "../../Breadcrumb";
import BreadcrumbFilter from "../../BreadcrumbFilter";
import { Label } from "../../Form";
import { Paginator } from "../../Paginator";
import Playlist from "../../Playlist";
import ProductFilterSidebar from "../../ProductFilterSidebar";
import { Skeleton } from "../../Skeleton";
import { useFilterProductsUtilities, useLogicFilterProducts } from "./data";
import { FilterParams, SelectedFilters } from "./data/types";
import {
  cookiesKey,
  getItemListId,
  getItemListName,
  identifier,
  mapPageSourceToUrlType,
  options,
  setCookiesItem,
  tabletSkeletonCardsGrid,
} from "./data/utils";
import {
  BreadcrumbBox,
  CustomSelect,
  FilterButton,
  FilterIcon,
  FilterProductsContainer,
  FilterProductsSearchContainer,
  SearchResultContent,
  SearchResultCounter,
  SearchResultHeader,
  SearchResultTitle,
  SelectSortBy,
} from "./styles";

export type FilterProductsProps = SearchResultProps & {
  preloadComponent?: boolean;
};

const FilterProducts = ({
  data,
  totalRows,
  term,
  title,
  playlistId,
  isBlackTheme,
  source = "search",
  preloadComponent = false,
}: // italo.albuquerque remover eslint-disable abaixo e ver como reduzir complexidade desse componente
// eslint-disable-next-line sonarjs/cognitive-complexity
FilterProductsProps) => {
  const branchId = getClientBranchId(cookie);
  const [isLoading, setIsLoading] = useState(true);
  const currentPage = cookie.getCookie({ name: cookiesKey }) || "1";
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [hasClickedFilter, setHasClickedFilter] = useState(false);
  const [sortByOption, setSortByOption] = useState("");
  const [currentProductTerm, setCurrentProductTerm] = useState<
    string | undefined
  >("");
  const [sendEvent, setSendEvent] = useState(true);
  const [countRows, setCountRows] = useState(totalRows);
  const [currentSelectedBreadcrumbFilter, setCurrentSelectedBreadcrumbFilter] =
    useState<SelectedFilters[]>([]);

  const router = useRouter();
  const filterProductsTerm = term || String(router?.query?.term);

  const {
    isClientTabletXL,
    isClientTablet,
    isClientMobile,
    setCloseCategoriesDropDown,
  } = useAppContext();

  const {
    productListId,
    params,
    searchedData,
    currentSelectedFilter,
    filterChangeHandler,
    pageChangeHandler,
    resetFilters,
    itemListIdInfo,
    setParams,
    setSearchedData,
    setCurrentSelectedFilter,
  } = useLogicFilterProducts(hasClickedFilter, currentPage);

  const {
    filterProductsContainerRef,
    scrollIntoSelfContainer,
    searchResultContent,
    searchResultContentResponsivePadding,
    spaceWrapperResponsivePadding,
  } = useFilterProductsUtilities(isClientMobile);

  const storedCurrentPage = cookie.getCookie({
    name: "currentPage_filterProducts",
  });
  const newUpdatedParams: FilterParams = {
    ...params,
    pageIndex: String(storedCurrentPage),
  };

  const { data: searchedDataByTerm, isLoading: searchedDataIsLoading } =
    catalogApi.getProductsSearchByTermsFilter(
      branchId,
      filterProductsTerm,
      newUpdatedParams?.selectedFilterCategories?.length
        ? newUpdatedParams.selectedFilterCategories.join(";")
        : "",
      newUpdatedParams?.selectedFilterBrands?.length
        ? newUpdatedParams.selectedFilterBrands.join(";")
        : "",
      newUpdatedParams?.selectedFilterModels?.length
        ? newUpdatedParams.selectedFilterModels.join(";")
        : "",
      newUpdatedParams?.selectedFilterAttrAgg?.length
        ? newUpdatedParams.selectedFilterAttrAgg.join(";")
        : "",
      storedCurrentPage,
      newUpdatedParams?.sortByOption,
      isClientMobile ? "46" : "45",
      !!(newUpdatedParams && source === "search" && branchId),
      Number(newUpdatedParams?.pageIndex) > 1 ? undefined : data
    );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const searchByTerm = useCallback(async () => {
    const storedTerm = cookie.getCookie({ name: "filterProductsTerm" });

    catalogApi.getProductsSearchByTerms(
      String(branchId),
      filterProductsTerm,
      storedCurrentPage,
      isClientMobile ? "46" : "45"
    );

    setCookiesItem(filterProductsTerm, "filterProductsTerm");
    if (filterProductsTerm !== storedTerm) {
      setCookiesItem("1");
    }
    if (branchId && source !== "search") {
      setIsLoading(true);
      const {
        data: sortData,
        totalRows: totalResult,
        // error,
      } = playlistId
        ? await catalogApi.getPlaylistProducts({
            playlistId,
            branchId,
            currentPage: storedCurrentPage,
            sortBy: sortByOption || "",
            category: params?.selectedFilterCategories?.length
              ? params.selectedFilterCategories.join(";")
              : "",
            brand: params?.selectedFilterBrands?.length
              ? params.selectedFilterBrands.join(";")
              : "",
            model: params?.selectedFilterModels?.length
              ? params.selectedFilterModels.join(";")
              : "",
            attrAgg: params?.selectedFilterAttrAgg?.length
              ? params.selectedFilterAttrAgg.join(";")
              : "",
            pageSize: isClientMobile ? "46" : "45",
          })
        : await catalogApi.getPlaylistProductsByTypeAndUrlFilter(
            mapPageSourceToUrlType(source),
            filterProductsTerm,
            branchId,
            params?.selectedFilterCategories?.length
              ? params.selectedFilterCategories.join(";")
              : "",
            params?.selectedFilterBrands?.length
              ? params.selectedFilterBrands.join(";")
              : "",
            params?.selectedFilterModels?.length
              ? params.selectedFilterModels.join(";")
              : "",
            params?.selectedFilterAttrAgg?.length
              ? params.selectedFilterAttrAgg.join(";")
              : "",
            currentPage,
            sortByOption,
            isClientMobile ? "46" : "45"
          );
      setSearchedData(sortData as SearchResultByTermDto);
      setCountRows(totalResult ? Number(totalResult) : 0);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProductsTerm, params, currentPage, sortByOption, branchId]);

  const customLabels: CustomBreadcrumbLabel[] = [
    {
      label: title || filterProductsTerm,
      redirectTo: `/${
        source === "category" ? "Categoria" : "pesquisa"
      }/${filterProductsTerm}`,
    },
  ];

  const itemListName = getItemListName(
    title || "",
    filterProductsTerm,
    router.pathname
  );

  const itemListId = getItemListId(
    filterProductsTerm || "",
    itemListIdInfo,
    router.pathname
  );

  const [existH1, setExistH1] = useState(false);
  // const { asPath } = useRouter();

  useEffect(() => {
    if (searchedDataByTerm && source === "search") {
      setCurrentProductTerm(filterProductsTerm);
      setSearchedData(searchedDataByTerm);
      setCountRows(
        Number(searchedDataByTerm.headers?.totalResults || totalRows)
      );
    } else {
      setSearchedData({});
    }

    setIsLoading(searchedDataIsLoading);
  }, [
    filterProductsTerm,
    searchedDataByTerm,
    searchedDataIsLoading,
    setSearchedData,
    source,
    totalRows,
  ]);

  useEffect(() => {
    if (currentPage) {
      setSendEvent(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchedData?.products?.length && sendEvent) {
      gtag?.viewItemList(itemListName, itemListId, [
        ...(searchedData?.products || []).map((product, index) => ({
          ...product,
          index: getProductPosition(
            index + 1,
            isClientMobile ? 46 : 45,
            Number(currentPage)
          ),
        })),
      ]);
      setSendEvent(false);
    }
  }, [
    itemListId,
    itemListName,
    searchedData?.products,
    isClientMobile,
    currentPage,
    sendEvent,
  ]);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      if (router?.query?.term?.includes(String(currentProductTerm))) {
        return;
      }

      setIsLoading(true);
      resetFilters();
    });
  }, [currentProductTerm, resetFilters, router]);

  useEffect(() => {
    searchByTerm();
  }, [searchByTerm]);

  useEffect(() => {
    setCloseCategoriesDropDown(isLoading);
  }, [isLoading, setCloseCategoriesDropDown]);

  useEffect(() => {
    const returnsQuantityOfH1 = document.querySelectorAll("h1").length;
    if (returnsQuantityOfH1) {
      setExistH1(false);
    } else {
      setExistH1(true);
    }
  }, []);

  return (
    <FilterProductsSearchContainer
      {...buildTestIds("filter-products-search-container")}
      isBlackTheme={isBlackTheme}
    >
      <FilterProductsContainer
        {...buildTestIds("filter-products-container")}
        ref={filterProductsContainerRef}
      >
        <Row>
          <SpaceWrapper
            padding={`${theme.space.x5} ${theme.space.x4} ${theme.space.x2}`}
          >
            <BreadcrumbBox {...buildTestIds("breadcrumb-box")}>
              <Breadcrumb customLabels={customLabels} />
            </BreadcrumbBox>
          </SpaceWrapper>
        </Row>
        <Row>
          <SearchResultHeader
            {...buildTestIds("search-result-header")}
            isBlackTheme={isBlackTheme}
            padding={`${theme.space.x5} ${theme.space.x0}`}
          >
            <Column
              sm="8"
              md="9"
              padding={`${theme.space.x0} ${theme.space.x4}`}
            >
              <SearchResultTitle
                as={!existH1 ? "h2" : "h1"}
                {...buildTestIds("search-result-title")}
              >
                {source === "category" || source === "highlight"
                  ? title ?? filterProductsTerm
                  : `Pesquisa por: "${title ?? filterProductsTerm}"`}
              </SearchResultTitle>
              <SearchResultCounter
                {...buildTestIds("search-result-counter")}
              >{`${countRows ?? "Buscando os melhores produtos"} ${
                countRows === undefined
                  ? ""
                  : countRows === 1
                  ? "Produto"
                  : "Produtos"
              }`}</SearchResultCounter>
            </Column>
            <Column
              xs="12"
              sm="4"
              md="3"
              display="flex"
              flexDirection="row"
              justifyContent={isClientMobile ? "space-between" : "end"}
              alignItems="end"
              padding={
                isClientMobile
                  ? `${theme.space.x1} ${theme.space.x2}`
                  : `${theme.space.x0} ${theme.space.x4}`
              }
            >
              {isClientTabletXL && (
                <FilterButton
                  {...buildTestIds("filter-button")}
                  width="auto"
                  height="42px"
                  onClick={() => setOpenMobileFilter((prev) => !prev)}
                >
                  {!openMobileFilter ? (
                    <FilterIcon {...buildTestIds("filter-icon")} />
                  ) : (
                    <CloseIcon
                      width="20px"
                      height="20px"
                      color={theme.colors.neutral.white}
                    />
                  )}
                  <Text
                    {...buildTestIds("filter-button-text")}
                    fontSize={isClientMobile ? "1.2rem" : "1.3rem"}
                    margin="0 0 0 8px"
                  >
                    Filtros
                  </Text>
                </FilterButton>
              )}

              <SelectSortBy
                {...buildTestIds("select-sort-by")}
                isMobile={isClientMobile}
              >
                <Label
                  {...buildTestIds("select-sort-by-label")}
                  fontSize="14px"
                  color={theme.colors.neutral["550"]}
                  text="Ordenar por"
                />
                <CustomSelect
                  {...buildTestIds("custom-select-sort-by-select")}
                  autoFocus={false}
                  blurInputOnSelect={false}
                  isSearchable={false}
                  instanceId={1}
                  options={options}
                  defaultValue={options[0]}
                  classNamePrefix="react-select"
                  onChange={(choice) => {
                    const selectedOption = choice as {
                      value: string;
                    };
                    if (selectedOption?.value) {
                      setSortByOption(selectedOption.value);
                      const updatedParams = {
                        ...params,
                        pageIndex: String(storedCurrentPage) || "1",
                        sortByOption: selectedOption.value,
                      };
                      setParams(updatedParams);
                      setCookiesItem("1");
                    }
                  }}
                />
              </SelectSortBy>
            </Column>
          </SearchResultHeader>
        </Row>
        <Row>
          <SearchResultContent
            {...buildTestIds("search-result-content")}
            padding={searchResultContentResponsivePadding}
          >
            <SpaceWrapper padding={searchResultContent}>
              <ProductFilterSidebar
                {...buildTestIds("product-filter-sidebar")}
                facets={searchedData?.facets}
                openMobile={openMobileFilter}
                isLoading={isLoading}
                onApplyFilters={(
                  activeCategoryFilters,
                  activeBrandFilters,
                  activeModelFilters,
                  activeAttrAggFilters
                ) => {
                  filterChangeHandler(
                    activeCategoryFilters,
                    activeBrandFilters,
                    activeModelFilters,
                    activeAttrAggFilters
                  );
                }}
                handlerClick={() => {
                  scrollIntoSelfContainer();
                  setHasClickedFilter(true);
                }}
                selectedFilters={(selectedFilter) => {
                  setCurrentSelectedFilter(selectedFilter);
                }}
                selectedBreadcrumbFilters={currentSelectedBreadcrumbFilter}
                isBlackTheme={isBlackTheme}
              />
            </SpaceWrapper>
            {!openMobileFilter && (
              <SpaceWrapper padding={spaceWrapperResponsivePadding}>
                <BreadcrumbFilter
                  {...buildTestIds("breadcrumb-filter")}
                  selectedBreadcrumbFilters={(selectedFilters) => {
                    setCurrentSelectedBreadcrumbFilter(selectedFilters);
                  }}
                  handlerClick={() => {
                    setCookiesItem("1");
                    scrollIntoSelfContainer();
                  }}
                  selectedFilters={currentSelectedFilter}
                  isBlackTheme={isBlackTheme}
                />
                {isLoading ? (
                  <Skeleton
                    {...buildTestIds("skeleton")}
                    type="productListGridCard"
                    count={15}
                    width="100%"
                    height={isClientMobile ? "325px" : "350px"}
                    style={
                      isClientMobile
                        ? {
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            width: "100%",
                            marginLeft: "0px",
                          }
                        : isClientTablet
                        ? {
                            display: "grid",
                            gridTemplateColumns: tabletSkeletonCardsGrid,
                            marginLeft: "0px",
                          }
                        : {
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                            width: "100%",
                            marginLeft: "0px",
                          }
                    }
                    gap="7px"
                  />
                ) : (
                  <>
                    <Playlist
                      {...buildTestIds("playlist-component")}
                      playlistId={Number(productListId)}
                      playlistName={String(title)}
                      searchTerm={currentProductTerm}
                      productCards={[
                        ...(searchedData?.products || []).map(
                          (product, index) => ({
                            ...product,
                            index: getProductPosition(
                              index,
                              isClientMobile ? 46 : 45,
                              Number(storedCurrentPage)
                            ),
                          })
                        ),
                      ]}
                      preloadComponent={preloadComponent}
                    />
                    {searchedData?.products && countRows > 14 ? (
                      <Paginator
                        {...buildTestIds("paginator")}
                        identifier={identifier}
                        visiblePages={5}
                        pageSize={isClientMobile ? 46 : 45}
                        totalRows={countRows}
                        justifyContent="center"
                        loading={isLoading}
                        page={Number(currentPage)}
                        pageChangeHandler={pageChangeHandler}
                        pageClickHandler={() => scrollIntoSelfContainer()}
                        isBlackTheme={isBlackTheme}
                      />
                    ) : null}
                  </>
                )}
              </SpaceWrapper>
            )}
          </SearchResultContent>
        </Row>
      </FilterProductsContainer>
    </FilterProductsSearchContainer>
  );
};

export { FilterProducts };
