export const space = <const>{
  xs: {
    1: "0.25rem", // 4px
    2: "0.5rem", // 8px
    3: "0.625rem", // 10px
    4: "0.75rem", // 12px
    5: "1rem", // 16px
  },

  sm: {
    1: "1.25rem", // 20px
    2: "1.5rem", // 24px
    3: "1.75rem", // 28px
    4: "2rem", // 32px
    5: "2.5rem", // 40px
  },

  md: {
    1: "3rem", // 48px
    2: "3.5rem", // 56px
    3: "4rem", // 64px
    4: "5rem", // 80px
    5: "6rem", // 96px
  },

  lg: {
    1: "7rem", // 112px
    2: "8rem", // 128px
    3: "10rem", // 160px
    4: "12rem", // 192px
    5: "14rem", // 224px
  },

  xl: {
    1: "16rem", // 256px
    2: "18rem", // 288px
    3: "20rem", // 320px
    4: "22rem", // 352px
    5: "24rem", // 384px
  },

  xxl: {
    1: "28rem", // 448px
    2: "32rem", // 512px
    3: "36rem", // 576px
    4: "40rem", // 640px
    5: "44rem", // 704px
  },

  percent: {
    1: "10%",
    2: "25%",
    3: "50%",
    4: "75%",
    5: "100%",
  },
};
