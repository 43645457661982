import { CardName, PaymentTypes } from "typing";
import { Banknote, DownPayment, OthersPayments, PixIconWithBG } from "ui";
import { getSmallMappedCard } from "../getSmallMappedCard";

export const getPaymentIcon = (
  paymentMethod: PaymentTypes,
  banner?: CardName
) => {
  const payment = paymentMethod.toLowerCase();
  switch (payment) {
    case "cash":
      return <Banknote />;
    case "downpayment":
      return <DownPayment />;
    case "others":
    case "googlepay":
    case "giftcard":
    case "cupom":
    case "boleto":
      return <OthersPayments />;
    case "pix":
      return <PixIconWithBG />;
    default:
      return getSmallMappedCard(banner);
  }
};
