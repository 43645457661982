import styled, { css } from "styled-components";
import { theme } from "ui";

type StyledCheckboxProps = {
  hasError?: boolean;
  color?: string;
  width?: string;
  height?: string;
  borderColor?: string;
  borderRadius?: string;
  left?: string;
  sizeChecked?: string;
  heightChecked?: string;
  widthChecked?: string;
  cursor?: string;
  checkboxWrapperWidth?: string;
  isRounded?: boolean;
  backgroundColor?: string;
  isMobile?: boolean;
};

export const CheckboxWrapper = styled.div<StyledCheckboxProps>`
  cursor: ${({ cursor }) => cursor ?? "default"};
  width: ${({ checkboxWrapperWidth }) => checkboxWrapperWidth ?? "auto"};
`;

interface CheckboxLabelProps {
  display?: string;
  alignItems?: string;
  gap?: string;
  flexDirection?: string;
  justifyContent?: string;
  cursor?: string;
}

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: ${({ display }) => display ?? "block"};
  cursor: ${({ cursor }) => cursor ?? "auto"};
  gap: ${({ gap }) => gap ?? `${theme.space.x2}`};
  width: 100%;
  transition: all 0.2s ease-in-out;

  ${({ display, flexDirection, justifyContent, alignItems }) =>
    display === "flex"
      ? css`
          flex-direction: ${flexDirection};
          justify-content: ${justifyContent};
          align-items: ${alignItems};
        `
      : ""};
`;

interface CheckboxSpanProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  cursor?: string;
}

export const CheckboxSpan = styled.span<CheckboxSpanProps>`
  color: ${({ color }) => color ?? `${theme.colors.neutral["550"]}`};
  font-size: ${({ fontSize }) =>
    fontSize ?? `${theme.typography.fontSize.sm.x5}`};
  font-weight: ${({ fontWeight }) =>
    fontWeight ?? `${theme.typography.fontWeight["400"]}`};
  font-family: ${({ fontFamily }) =>
    fontFamily ?? `${theme.typography.fontFamily.chivo}`};
  cursor: ${({ cursor }) => cursor ?? "pointer"};
`;

export const StyledCheckbox = styled.input.attrs({
  type: "checkbox",
})<StyledCheckboxProps>`
  -webkit-appearance: none;
  appearance: none;
  width: ${({ width }) => width ?? "1.6em"};
  height: ${({ height }) => height ?? "1.6em"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ?? `${theme.space["x0.5"]}`};
  border: ${theme.space.xpx} solid
    ${({ borderColor }) => borderColor ?? `${theme.colors.neutral["300"]}`};
  outline: none;
  cursor: pointer;
  pointer-events: initial;
  transition: all 0.2s ease-in;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    border: ${theme.space.xpx} solid
      ${({ color }) => color ?? theme.colors.primary["200"]};
  }

  &:checked {
    border: ${theme.space.xpx} solid
      ${({ color }) => color ?? theme.colors.primary["200"]};
    position: relative;
    background-color: ${({ color }) => color ?? theme.colors.primary["200"]};
  }

  &:checked::before {
    content: "";
    font-size: ${({ sizeChecked }) => sizeChecked ?? "1.5em"};
    color: ${theme.colors.neutral.white};
    position: absolute;
    left: ${({ left }) => left ?? "2px"};
    top: -4px;
    width: ${({ widthChecked }) => widthChecked ?? "11px"};
    height: ${({ heightChecked }) => heightChecked ?? "11px"};
    border-style: solid;
    transform-origin: bottom left;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);

    ${({ isRounded }) =>
      isRounded &&
      css`
        background-color: ${theme.colors.neutral.white};
        border-radius: 50%;
        left: 3.5px;
        top: 3px;
        width: ${theme.space.x3};
        height: ${theme.space.x3};
        transform: none;
        border: none;

        @media ${theme.device.desktopFirst.mobileXL} {
          left: 1.8px;
          top: 1.5px;
          width: 11.1px;
          height: 11.9px;
        }
      `}

    ${({ isMobile }) =>
      isMobile &&
      css`
        left: 1.8px;
        top: 1.5px;
        width: 11.1px;
        height: 11.9px;
      `}
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CheckboxContent = styled.div``;
