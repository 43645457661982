const getProductPosition = (
  productPosition: number,
  totalProducts?: number,
  pageIndex?: number
) => {
  if (totalProducts && pageIndex) {
    return productPosition + totalProducts * (pageIndex - 1);
  }

  return productPosition;
};

export { getProductPosition };
