import { buildTestIds } from "application";
import {
  useAuthentication as defaultUseAuthentication,
  useTwoFactor as defaultUseTwoFactor,
  useShallow,
} from "application/state-manager";
import { identityApi, notificationApi } from "implementations";
import { useRouter } from "next/router";
import { theme } from "ui";
import { useCallback } from "react";
import { SendTokenResponseDto } from "typing";
import { TwoFactorContent } from "../../../../../2fa";
import { methods } from "./data";
import {
  ArrowLeftIcon,
  ChooseAnotherMethodDescription,
  ContentInformation,
  IdentityVerificationText,
  TwoFactorContainer,
  WrapperIcon,
} from "./styles";

interface ChooseAnotherMethodProps {
  useAuthentication?: typeof defaultUseAuthentication;
  useTwoFactor?: typeof defaultUseTwoFactor;
}

const ChooseAnotherMethod = ({
  useAuthentication = defaultUseAuthentication,
  useTwoFactor = defaultUseTwoFactor,
}: ChooseAnotherMethodProps) => {
  const {
    changeCurrentStep,
    userIdentification,
    identificationType,
    currentStep,
    isChangePasswordDueToPurchase,
  } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
      userIdentification: state.userIdentification,
      identificationType: state.identificationType,
      currentStep: state.currentStep,
      isChangePasswordDueToPurchase: state.isChangePasswordDueToPurchase,
    }))
  );

  const {
    setChannelSelected,
    availableMethods,
    setIsBlockedToSendCode,
    setIsValidationActive,
    setAvailableMethods,
    setFaType,
    getTimer,
    setClientAction,
    setIsPhoneNumberInvalid,
  } = useTwoFactor(
    useShallow((state) => ({
      setChannelSelected: state.setChannelSelected,
      availableMethods: state.availableMethods,
      channelSelected: state.channelSelected,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setIsValidationActive: state.setIsValidationActive,
      setAvailableMethods: state.setAvailableMethods,
      setFaType: state.setFaType,
      getTimer: state.getTimer,
      setClientAction: state.setClientAction,
      setIsPhoneNumberInvalid: state.setIsPhoneNumberInvalid,
    }))
  );

  const onVerifyIdentity = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (channel: number) => {
      setFaType(9);
      setClientAction("forgotPassword");

      const { isTimerRunning } = getTimer(9, channel);

      if (isTimerRunning) {
        setIsValidationActive(true);

        if (currentStep === "verifyIdentity") {
          changeCurrentStep("verifyCode");
        }
        return;
      }

      const response = (await notificationApi.sendTwoFactorCodePublic({
        faType: 9,
        email: identificationType === "email" ? userIdentification : "",
        cpf: identificationType === "cpf" ? userIdentification : "",
        channel: channel ?? 2,
        phoneNumber: "",
        token: "",
      })) as unknown as SendTokenResponseDto;

      if (
        !response?.validationResult?.errors?.some(
          (error) => error?.propertyName?.toLowerCase() === "phonenumber"
        )
      ) {
        setIsPhoneNumberInvalid(false);
      }

      if (response?.validationResult?.isValid) {
        const { data: newAvailableMethods } =
          await identityApi.receiveConfirmationTypesPublic({
            email: identificationType === "email" ? userIdentification : "",
            cpf: identificationType === "cpf" ? userIdentification : "",
          });
        setIsBlockedToSendCode(false);
        setAvailableMethods(newAvailableMethods);
        setIsValidationActive(true);
        changeCurrentStep("verifyCode");
        return;
      }

      if (
        response?.validationResult?.errors?.[0]?.errorMessage ===
        "Cliente não encontrado"
      ) {
        changeCurrentStep("emailFeedback");
        return;
      }

      if (
        response?.validationResult?.errors?.[0]?.errorCode === "222" ||
        [
          "Tente novamente em 24 horas.",
          "Operação bloqueada devido ao número de tentativas excessivas na solicitação do código.",
        ]?.includes(
          String(response?.validationResult?.errors?.[0]?.errorMessage)
        )
      ) {
        setIsBlockedToSendCode(true);
        setIsValidationActive(true);
        changeCurrentStep("verifyCode");
        return;
      }

      if (response?.validationResult?.errors?.[0]?.errorCode === "666") {
        changeCurrentStep("verifyCode");
        return;
      }

      if (
        response?.validationResult?.errors?.some(
          (error) => error?.propertyName?.toLowerCase() === "phonenumber"
        )
      ) {
        changeCurrentStep("phoneNumberFeedback");
        setIsPhoneNumberInvalid(true);
      }
    },
    [
      changeCurrentStep,
      currentStep,
      getTimer,
      identificationType,
      setAvailableMethods,
      setClientAction,
      setFaType,
      setIsBlockedToSendCode,
      setIsValidationActive,
      userIdentification,
      setIsPhoneNumberInvalid,
    ]
  );

  const router = useRouter();

  return (
    <TwoFactorContainer {...buildTestIds("two-factor-container")}>
      <ContentInformation
        marginBottom={
          isChangePasswordDueToPurchase
            ? `${theme.space.x4}`
            : `${theme.space.x6}`
        }
        {...buildTestIds("content-information")}
      >
        {router?.pathname !== "/login" && (
          <WrapperIcon
            onClick={() =>
              changeCurrentStep(
                isChangePasswordDueToPurchase
                  ? "changePasswordDuePurchaseTime"
                  : "login"
              )
            }
            {...buildTestIds("back-button")}
          >
            <ArrowLeftIcon color={theme.colors.neutral["330"]} />
          </WrapperIcon>
        )}
        <IdentityVerificationText
          {...buildTestIds("identity-verification-text")}
        >
          Verificação de identidade
        </IdentityVerificationText>
      </ContentInformation>
      {isChangePasswordDueToPurchase ? (
        <ChooseAnotherMethodDescription>
          Para garantir a sua segurança, escolha um modo para autenticar a sua
          identidade.
        </ChooseAnotherMethodDescription>
      ) : null}
      <TwoFactorContent
        options={methods(
          setChannelSelected,
          changeCurrentStep,
          {
            email: availableMethods?.email,
            sms: availableMethods?.phoneNumber,
          },
          {
            email: availableMethods?.emailConfirmed,
            sms: availableMethods?.phoneNumberConfirmed,
          },
          onVerifyIdentity
        )}
        canChooseAnotherTwoFactorMethod={false}
        onClickFailureButton={() => changeCurrentStep("login")}
        clientAction="forgotPassword"
        email={String(availableMethods?.email)}
        hasError={null}
        phoneNumber={String(availableMethods?.phoneNumber)}
        shouldMask={false}
        viewType="component"
        isUnderAction={false}
        onCallAction={() => changeCurrentStep("verifyCode")}
        sendCodeType="email"
        onClickIsInvalidClientInfo={() => changeCurrentStep("forgotPassword")}
        isVerifyIdentity
        verifyIdentityDescription="Para garantir a sua segurança, escolha um modo para autenticar a sua identidade."
      />
    </TwoFactorContainer>
  );
};

export { ChooseAnotherMethod };
