import { fontSize } from "./font-size";

export const lineHeight = <const>{
  sm: {
    1: `calc(1 / ${fontSize.sm[1].replace("rem", "")})`, // proportion 1
    2: `calc(1.25 / ${fontSize.sm[2].replace("rem", "")})`, // proportion 1.25
    3: `calc(1.375 / ${fontSize.sm[3].replace("rem", "")})`, // proportion 1.375
  },
  md: {
    1: `calc(1.5 / ${fontSize.md[1].replace("rem", "")})`, // proportion 1.5
    2: `calc(1.625 / ${fontSize.md[2].replace("rem", "")})`, // proportion 1.625
    3: `calc(1.75 / ${fontSize.md[3].replace("rem", "")})`, // proportion 1.75
  },
  lg: {
    1: `calc(2 / ${fontSize.lg[1].replace("rem", "")})`, // proportion 2
    2: `calc(2.25 / ${fontSize.lg[2].replace("rem", "")})`, // proportion 2.25
    3: `calc(2.5 / ${fontSize.lg[3].replace("rem", "")})`, // proportion 2.5
  },
  xlg: {
    1: `calc(3 / ${fontSize.xlg[1].replace("rem", "")})`, // proportion 3
    2: `calc(3.25 / ${fontSize.xlg[2].replace("rem", "")})`, // proportion 3.25
    3: `calc(3.5 / ${fontSize.xlg[3].replace("rem", "")})`, // proportion 3.5
    4: `calc(3.75 / ${fontSize.xlg[4].replace("rem", "")})`, // proportion 3.75
  },
};
