import { CustomHighlightCategoryDto, StepName } from "typing";
import { theme } from "ui";
import {
  GiftBoxIcon,
  CardIcon,
  HouseIcon,
  AlliancesIcon,
  Close,
} from "../mobile/SidebarMenuMobile/styles";
import { ForgotPasswordLogin } from "../AuthContent/ForgotPassword";
import { Login } from "../AuthContent/Login";
import { PasswordFeedback } from "../AuthContent/PasswordFeedback";
import { EmailFeedback } from "../AuthContent/EmailFeedback";
import { VerifyCode } from "../AuthContent/VerifyCode";
import { ChooseAnotherMethod } from "../AuthContent/ChooseAnotherMethod";
import { ChooseNewPassword } from "../AuthContent/ChooseNewPassword";
import { ChangePasswordDuePurchaseTime } from "../AuthContent/ChangePasswordDuePurchaseTime";

export const userAuthenticatedActions = [
  {
    id: 1,
    title: "Meus Cartões",
    path: "/conta/meus-cartoes",
    isLogout: false,
    hasBorderBottom: true,
    icon: <CardIcon />,
  },
  {
    id: 2,
    title: `Meus Endereços`,
    path: "/conta/meus-enderecos",
    isLogout: false,
    hasBorderBottom: true,
    icon: <HouseIcon />,
  },
  {
    id: 3,
    title: "Minhas Listas",
    path: "/conta/minhas-listas",
    isLogout: false,
    hasBorderBottom: true,
    icon: <GiftBoxIcon />,
  },
  {
    id: 4,
    title: "Lista de Casamento",
    path: "/lista-de-casamento",
    isLogout: false,
    hasBorderBottom: true,
    icon: <AlliancesIcon />,
  },
  {
    id: 5,
    title: "Fazer Logout",
    path: "/#",
    isLogout: true,
    hasBorderBottom: false,
    icon: <Close />,
  },
];

type HighlightCategoryOption = {
  name: string;
  descriptionUrl: string;
  excludeDivider?: boolean;
  color?: string;
};

// Quando implementar a http request para buscar highlight categories separadamente, substituir essa lógica
export const handleHighlightCategories = (
  highlightCategories?: CustomHighlightCategoryDto[]
): HighlightCategoryOption[] => {
  if (!highlightCategories?.length) {
    return [];
  }

  const formattedHighlightCategories = highlightCategories?.map((category) => {
    const { name, descriptionUrl } = category;

    return { name, descriptionUrl };
  });

  // console.log("formattedHighlightCategories: ", formattedHighlightCategories);

  return [
    ...formattedHighlightCategories,
    {
      name: "Ver todas categorias",
      descriptionUrl: "",
      excludeDivider: true,
      color: theme.colors.primary["200"],
    },
  ];
};

// Essa lista abaixo precisará ser substituída pelos stories, os quais virão da Home personalizada pelo Backoffice.
export const listCategories = [
  {
    name: "Eletro e eletrônicos",
    image:
      "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb_dev/10588_thumb",
  },
  {
    name: "Utilidades domésticas",
    image:
      "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb_dev/10587_thumb",
  },
  {
    name: "Móveis e acessórios",
    image:
      "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb_dev/10585_thumb",
  },
  {
    name: "Decoração",
    image:
      "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb_dev/10583_thumb",
  },
  {
    name: "Pisos e revestimentos",
    image:
      "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb_dev/10586_thumb",
  },
  {
    name: "Ar e ventilação",
    image:
      "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb_dev/10584_thumb",
  },
];

export interface CompanyProductsProps {
  title: string;
  path: string;
  desktop: boolean;
}

export const companyProducts = [
  {
    title: "Lista de Casamento",
    path: "/lista-de-casamento",
    desktop: true,
  },
  {
    title: "Clube do Profissional",
    path: "https://clubedoprofissional.ferreiracosta.com/",
    desktop: true,
  },
  {
    title: "Nossas Lojas",
    path: "https://ferreiracosta.movidesk.com/kb/article/156436/nossas-lojas?ticketId=&q=",
    desktop: true,
  },
  {
    title: "Para Empresas",
    path: "http://empresas.ferreiracosta.com/",
    desktop: true,
  },
  {
    title: "Televendas",
    path: "https://ferreiracosta.movidesk.com/kb/article/156443/qual-o-horario-de-funcionamento-e-contatos-do-vendas-corporativa?ticketId=&q=",
    desktop: false,
  },
  {
    title: "Política de Entrega",
    path: "https://ferreiracosta.movidesk.com/kb/article/156345/politica-de-entrega-da-ferreira-costa?ticketId=&q=",
    desktop: false,
  },
];

const getAuthContent = (
  loginMode: "page" | "modal",
  currentStep: StepName | null,
  isMyCartPage?: boolean
) => {
  switch (currentStep) {
    case "login":
      return <Login loginMode={loginMode} isMyCartPage={isMyCartPage} />;
    case "forgotPassword":
      return <ForgotPasswordLogin />;
    case "verifyCode":
      return <VerifyCode />;
    case "emailFeedback":
    case "phoneNumberFeedback":
      return <EmailFeedback />;
    case "verifyIdentity":
      return <ChooseAnotherMethod />;
    case "changePassword":
      return <ChooseNewPassword />;
    case "passwordFeedback":
      return <PasswordFeedback />;
    case "changePasswordDuePurchaseTime":
      return <ChangePasswordDuePurchaseTime />;
    default:
      return null;
  }
};

export { getAuthContent };
