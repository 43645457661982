import { createGlobalStyle } from "styled-components";
import { theme } from "ui";

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    ::-moz-selection { /* Code for Firefox */
      color: ${theme.colors.neutral.white};
      background: ${theme.colors.primary["200"]};

    }

    ::selection {
      color:${theme.colors.neutral.white};
      background: ${theme.colors.primary["200"]};

    }
  }

  html, body {
    font-family: 'Chivo', Arial, Helvetica, sans-serif;
    font-size: ${theme.typography.fontSize.sm.x3};
    font-weight: 500;
    font-style: normal;
    scroll-behavior: smooth;
    scroll-snap-align: center;
    margin: 0 auto;
    width: auto;
    -webkit-font-smoothing: antialiased;

    & * {
      font-family: 'Chivo', Arial, Helvetica, sans-serif;
    }
  }

  :focus {
    outline: none;
  }

  a, a:link, button {
    -webkit-tap-highlight-color: rgba(218, 8, 18, 0.2);
  }

  a, a:link {
    text-decoration: none;
    font-family: 'Chivo', sans-serif;
    outline: 0;
  }

  .carousel .control-dots {
    z-index: 1;
    bottom: 0px;
  }

  .carousel .control-dots .dot {
    border-radius: 3px !important;
    width: ${theme.space.x5};
    height: 5px;
    background: rgba(189,189,189,0.5);
    box-shadow: none;
  }

  .carousel .control-dots .selected {
    width: 30px;
    background: ${theme.colors.primary["200"]};
  }

  main {
    background-color: ${theme.colors.neutral["25"]};
  }

  path, rect, circle {
    transition: stroke .2s ease-in;
  }

  .slick-prev:before {
    display: none;
  }

  .slick-next:before {
    display: none;
  }

  .slick-disabled {
    background: ${theme.colors.neutral["50"]};
    opacity: 1;
    visibility: visible;

    &:hover {
      background: ${theme.colors.neutral["50"]};
    }
    cursor: default;

    path {
      stroke: ${theme.colors.neutral["99"]};
    }

    &:hover path {
      stroke: ${theme.colors.neutral["99"]};
    }
  }

  .slick-dots {
    display: flex !important;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
  }

  .slick-dots li {
    width: initial;
    height: initial;
    margin: 0;
    padding: 0;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: hidden;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }


  .swiper-initialized {
    display: flex;
    align-items: center;
    .swiper-button-next, .swiper-button-prev{
            top: 40%;
          }
  }

  .swiper-button-next {
    height: 60px;
    width: 60px;
    border-radius: 35px;
    right: 10px;


    padding: 0px 0px 0px 4px;

    background: ${theme.colors.neutral.white};

    box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-25"]};

    &:hover path {
      stroke: ${theme.colors.neutral.white}
    }

    &:hover {
      background: ${theme.colors.primary["200"]};
      transition: 0.8s;
    }

    &::after{
      content: "";
      width: 13px;
      height: 13px;
      border: 3px solid ${theme.colors.neutral["450"]};
      border-radius: 2px;
      display: block;
      position: absolute;
      top: 21px;
      right: 25px;
      border-top: 0;
      border-right: 0;
      transform: rotate(-135deg);
      transition: all 0.3s ease-in-out;
    }

    &:hover::after{
      border-color: white;
      border-top: 0;
      border-right: 0;
    }


    @media ${theme.device.desktopFirst.tablet}{
      display: none;
    }
  }

  .swiper-button-prev {
    display: flex;
    justify-content: center;

    height: 60px;
    width: 60px;

    border-radius: 35px;
    padding: 0px 0px 0px 4px;
    background: ${theme.colors.neutral.white};

    &:hover {
      background: ${theme.colors.primary["200"]};
      transition: 0.8s;
    }

    &:hover path {
      stroke: ${theme.colors.neutral.white}
    }

    svg {
      margin-top: 15px;
      margin-left: 15px;
    }

    box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-25"]};

    &::after{
      content: "";
      width: 13px;
      height: 13px;
      border: 3px solid ${theme.colors.neutral["450"]};
      border-radius: 2px;
      display: block;
      position: absolute;
      top: 21px;
      right: 20px;
      border-top: 0;
      border-right: 0;
      transform: rotate(45deg);
      transition: all 0.3s ease-in-out;
    }

    &:hover::after{
      border-color: white;
      border-top: 0;
      border-right: 0;
    }

    @media ${theme.device.desktopFirst.tablet}{
      display: none;
    }
  }
`;
