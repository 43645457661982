const formatDate = (data: Date, IsformateBr?: boolean) => {
  const year = data.getFullYear();
  const month = (data.getMonth() + 1).toString().padStart(2, "0");
  const day = data.getDate().toString().padStart(2, "0");
  const hour = data.getHours().toString().padStart(2, "0");
  const minutes = data.getMinutes().toString().padStart(2, "0");
  const seconds = data.getSeconds().toString().padStart(2, "0");

  if (IsformateBr) {
    return `${day}/${month}/${year}`;
  }

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
};

export { formatDate };
