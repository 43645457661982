import {
  buildTestIds,
  formatTestId,
  getUserInitials,
  useAppContext,
  useClickOutside,
  useDebounce,
  useHeaderContext,
} from "application";
import { useAuth } from "application/context/Identity/AuthContext";
import { useAuthentication, useShallow } from "application/state-manager";
import { useRouter } from "next/router";
import {
  MouseEvent,
  RefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Alliances,
  BoxButtonAuth,
  FCLogoBlackFriday,
  FlexContainer,
  HamburgerIcon,
  ImageCircle,
  theme,
  UserIcon,
  UserInitials,
  UserWrapper,
} from "ui";
import { Link } from "../../../../Link";
import { Skeleton } from "../../../../Skeleton";
import { useMappedBackofficeComponent } from "../../../../backoffice/hooks";
import { withBlackThemeValidation } from "../../../../hocs";
import { Divider } from "../../../Divider/styles";
import { LinkRedirectHome } from "../../../LinkRedirectHome";
import { DesktopLogoFC, LogoWrapper } from "../../styles";
import { LoginAuthenticationModal } from "../AuthContent/Login/LoginAuthenticationModal";
import { CartButton } from "../CartButton";
import { ModalAuthentication } from "../ModalAuthentication";
import { SearchInput } from "../SearchInput";
import { ListCategoriesInner } from "./Inner";
import { DropdownUserAuthenticated } from "./components/DropdownUserAuthenticated";
import {
  ArrowRightIcon,
  CategoryButtonDropDown,
  CategoryListedInDropdown,
  CategoryNameContainer,
  CategoryTitle,
  CategoryTitleSection,
  ContentHeaderCategoriesDesktop,
  ContentHeaderDesktop,
  ContentHeaderWrapper,
  DropdownContainer,
  DropdownContainerList,
  DropdownContainerListItem,
  DropdownWrapper,
  EnterOrCreateAccountText,
  FcLogoBlackWrapper,
  HeaderUserActionsSection,
  LinkWrapperUser,
  ListCategoriesAside,
  ListCategoriesContainerHeader,
  ListCategoriesTitle,
  SubMenuContainer,
  SubMenuContent,
  SubMenuContentHeader,
  WeddingGiftListText,
  WelcomeText,
  WrapperList,
} from "./styles";

interface ListCategoriesProps {
  refHeaderContainer: RefObject<HTMLElement>;
  isPageWeddingList?: boolean;
  isBlackTheme?: boolean;
}

const ListCategories = ({
  refHeaderContainer,
  isPageWeddingList,
  isBlackTheme,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ListCategoriesProps) => {
  const { isAuthenticated, userData } = useAuth();
  const router = useRouter();
  const {
    categories,
    isSidebarMenuOpened,
    isOpenedDropdownUser,
    setIsSidebarMenuOpened,
    setIsOpenedDropdownUser,
    closeCategoriesDropDown,
  } = useAppContext();
  const DropdownContainerListRef = useRef<HTMLUListElement>(null);
  const ModalAuthRef = useRef<HTMLElement>(null);
  const CategoryButtonDropDownRef = useRef(null);
  const [isDropdownHoveredAction, setIsDropdownHoveredAction] =
    useState<boolean>(false);
  const [isDropdownClickedAction, setIsDropdownClickedAction] =
    useState<boolean>(false);
  const [loginContainerOnHover, setLoginContainerOnHover] =
    useState<boolean>(false);
  const [weddingGiftListContainerOnHover, setWeddingGiftListContainerOnHover] =
    useState<boolean>(false);
  const { setIsAuthenticationDropdownActive } = useAuthentication(
    useShallow((state) => ({
      setIsAuthenticationDropdownActive:
        state.setIsAuthenticationDropdownActive,
    }))
  );

  useClickOutside({
    ref: ModalAuthRef,
    callback: () => {
      if (isOpenedDropdownUser) {
        setIsOpenedDropdownUser(false);
      }
    },
  });

  useClickOutside({
    ref: CategoryButtonDropDownRef,
    callback: () => {
      if (isDropdownClickedAction) {
        setIsDropdownClickedAction(false);
      }
    },
  });

  refHeaderContainer?.current?.addEventListener("mouseleave", () =>
    setIsDropdownHoveredAction(false)
  );

  const debouncedIsDropdownHovered = useDebounce(isDropdownHoveredAction, 300);
  const debouncedIsDropdownClicked = useDebounce(isDropdownClickedAction, 300);

  useEffect(() => {
    setIsSidebarMenuOpened(
      Boolean(debouncedIsDropdownHovered) || Boolean(debouncedIsDropdownClicked)
    );
  }, [
    debouncedIsDropdownHovered,
    debouncedIsDropdownClicked,
    setIsSidebarMenuOpened,
  ]);

  const handleProfileDropdown = (event: SyntheticEvent<EventTarget>) => {
    event?.preventDefault();
    event?.stopPropagation();
  };

  const handleOpenDropdownUserAuthenticated = () => {
    // eslint-disable-next-line no-restricted-globals
    event?.preventDefault();
    setIsOpenedDropdownUser((state) => !state);
  };

  const handleClickDropDownClose = () => {
    setIsDropdownHoveredAction(false);
    setIsDropdownClickedAction(false);
  };

  useEffect(() => {
    if (!closeCategoriesDropDown) {
      handleClickDropDownClose();
    }
  }, [closeCategoriesDropDown]);

  const { highlightCategories, isLoadingHighlightCategories } =
    useHeaderContext();

  const highlightCategoriesComponent = useMappedBackofficeComponent(
    {
      type: "highlightCategories",
      subComponents: null,
      displayName: "Menu de Destaques",
      config: null,
      properties: [],
    },
    {
      isLoading: isLoadingHighlightCategories,
    },
    highlightCategories
  );

  const openLoginModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsAuthenticationDropdownActive(true);
  };

  const isHomePage = router?.pathname === "/";

  return (
    <ListCategoriesContainerHeader isBlackTheme={!!isBlackTheme}>
      <ContentHeaderDesktop {...buildTestIds("content-header-desktop")}>
        <ContentHeaderWrapper>
          <LinkRedirectHome {...buildTestIds("link-redirect-home")}>
            {isHomePage && (
              <ListCategoriesTitle>
                Ferreira Costa – Home Center com os Melhores Preços do Brasil
              </ListCategoriesTitle>
            )}
            {!isBlackTheme || isPageWeddingList ? (
              router?.asPath === "/" ? (
                <LogoWrapper id="logo-wapper" {...buildTestIds("logo-wrapper")}>
                  <DesktopLogoFC
                    layout="fill"
                    src="https://d2urakutajlof6.cloudfront.net/logo/logo_fc.svg"
                    alt="Logo escrito Ferreira Costa, em branco com bordas vermelhas"
                    data-testid="desktop-logo-fc"
                    data-cy="desktop-logo-fc"
                    priority
                  />
                </LogoWrapper>
              ) : (
                <LogoWrapper id="logo-wapper" data-testid="logo-wrapper">
                  <DesktopLogoFC
                    layout="fill"
                    src="https://d2urakutajlof6.cloudfront.net/logo/logo_fc.svg"
                    alt="Logo escrito Ferreira Costa, em branco com bordas vermelhas"
                    data-testid="desktop-logo-fc"
                    data-cy="desktop-logo-fc"
                    priority
                  />
                </LogoWrapper>
              )
            ) : router?.asPath === "/" ? (
              <ListCategoriesTitle>
                <FcLogoBlackWrapper>
                  <FCLogoBlackFriday
                    alt="Logo escrito Ferreira Costa Black Friday, em branco com bordas vermelhas e contorno branco"
                    data-cy="desktop-logo-fc-bf"
                    data-testid="desktop-logo-fc-bf"
                  />
                </FcLogoBlackWrapper>
              </ListCategoriesTitle>
            ) : (
              <FcLogoBlackWrapper>
                <FCLogoBlackFriday
                  alt="Logo escrito Ferreira Costa Black Friday, em branco com bordas vermelhas e contorno branco"
                  data-cy="desktop-logo-fc-bf"
                  data-testid="desktop-logo-fc-bf"
                />
              </FcLogoBlackWrapper>
            )}
          </LinkRedirectHome>
          <SearchInput isDesktop />
        </ContentHeaderWrapper>
        <ListCategoriesAside {...buildTestIds("list-categories-aside")}>
          <HeaderUserActionsSection>
            <Link
              {...buildTestIds("wedding-gift-list-link")}
              href="/lista-de-casamento"
              target="_self"
            >
              <FlexContainer
                {...buildTestIds("wedding-gift-list-container")}
                alignItems="center"
                gap="8px"
                onMouseEnter={(event: SyntheticEvent<EventTarget>) => {
                  event?.preventDefault();
                  event?.stopPropagation();
                  setWeddingGiftListContainerOnHover(true);
                }}
                onMouseLeave={(event: SyntheticEvent<EventTarget>) => {
                  event?.preventDefault();
                  event?.stopPropagation();
                  setWeddingGiftListContainerOnHover(false);
                }}
              >
                <Alliances
                  color={
                    isBlackTheme
                      ? theme.colors.blackFriday["20"]
                      : weddingGiftListContainerOnHover
                      ? theme.colors.primary["200"]
                      : theme.colors.neutral["999"]
                  }
                />
                <WeddingGiftListText
                  {...buildTestIds("wedding-gift-list-text")}
                  mouseOver={weddingGiftListContainerOnHover}
                >
                  Lista de <br /> casamento
                </WeddingGiftListText>
              </FlexContainer>
            </Link>
            <LinkWrapperUser
              href="/"
              aria-label="Área de login do usuário"
              {...buildTestIds("link-wrapper-user-desk")}
              onClick={(event) =>
                isAuthenticated ? handleProfileDropdown : openLoginModal(event)
              }
            >
              {isAuthenticated ? (
                <BoxButtonAuth ref={ModalAuthRef}>
                  <UserWrapper
                    {...buildTestIds("wrapper-user-authenticated")}
                    onClick={() => handleOpenDropdownUserAuthenticated()}
                  >
                    <ImageCircle {...buildTestIds("image-circle")}>
                      <UserInitials {...buildTestIds("user-initial")}>
                        {userData?.name
                          ? getUserInitials(userData?.name)
                          : null}
                      </UserInitials>
                    </ImageCircle>
                    <WelcomeText {...buildTestIds("greeting-message")}>
                      Olá, <br />{" "}
                      {userData?.name ? userData?.name?.split(" ")[0] : null}
                    </WelcomeText>
                  </UserWrapper>
                  <DropdownUserAuthenticated
                    handleCloseDropdownUserAuthenticated={
                      handleOpenDropdownUserAuthenticated
                    }
                    isOpenedDropdownUser={isOpenedDropdownUser}
                  />
                </BoxButtonAuth>
              ) : (
                <FlexContainer
                  position="relative"
                  alignItems="center"
                  gap={theme.space.x2}
                  whiteSpace="nowrap"
                  onMouseEnter={(event: SyntheticEvent<EventTarget>) => {
                    event?.preventDefault();
                    event?.stopPropagation();
                    setLoginContainerOnHover(true);
                  }}
                  onMouseLeave={(event: SyntheticEvent<EventTarget>) => {
                    event?.preventDefault();
                    event?.stopPropagation();
                    setLoginContainerOnHover(false);
                  }}
                  {...buildTestIds("user-unaunthenticated-container")}
                >
                  <UserIcon
                    {...buildTestIds("user-icon-component")}
                    alt="Ícone de usuário"
                    aria-label="Área de login do usuário"
                    color={
                      isBlackTheme
                        ? theme.colors.blackFriday["20"]
                        : loginContainerOnHover
                        ? theme.colors.primary["200"]
                        : theme.colors.neutral["999"]
                    }
                  />
                  <EnterOrCreateAccountText
                    {...buildTestIds("instruction-message")}
                    mouseOver={loginContainerOnHover}
                  >
                    Entrar ou criar <br />
                    conta
                  </EnterOrCreateAccountText>
                  <ModalAuthentication
                    {...buildTestIds("modal-authentication")}
                  />
                </FlexContainer>
              )}
            </LinkWrapperUser>
          </HeaderUserActionsSection>
          <CartButton />
        </ListCategoriesAside>
      </ContentHeaderDesktop>
      <ContentHeaderCategoriesDesktop
        {...buildTestIds("content-header-categories-desktop")}
      >
        <CategoryButtonDropDown
          {...buildTestIds("category-button-dropdown")}
          onMouseUp={() => setIsDropdownClickedAction((state) => !state)}
          onMouseEnter={() => setIsDropdownHoveredAction(true)}
          isDropdownOpen={isSidebarMenuOpened}
          ref={CategoryButtonDropDownRef}
        >
          <DropdownContainer {...buildTestIds("category-dropdown-container")}>
            <DropdownWrapper>
              <DropdownContainerList
                {...buildTestIds("dropdown-container-categories-list-header")}
                ref={DropdownContainerListRef}
              >
                {categories?.data?.length ? (
                  categories.data.map((category) => (
                    <DropdownContainerListItem
                      {...buildTestIds(
                        `category-item-${formatTestId(category.title)}`
                      )}
                      key={`${category.id}`}
                    >
                      <WrapperList>
                        <CategoryListedInDropdown
                          {...buildTestIds("category-item-listed-in-dropdown")}
                          aria-label="Item da lista de categorias"
                          href={`/${category?.descriptionUrl}`}
                          target="_self"
                        >
                          {category.title}
                        </CategoryListedInDropdown>
                        <ArrowRightIcon />
                      </WrapperList>
                      <SubMenuContainer>
                        <SubMenuContent>
                          <SubMenuContentHeader>
                            <CategoryTitle
                              {...buildTestIds("category-title-submenu")}
                              href={`/${category?.descriptionUrl}`}
                              target="_self"
                            >
                              {category.title}
                            </CategoryTitle>
                            <Divider />
                          </SubMenuContentHeader>
                          <ListCategoriesInner category={category} level={2} />
                        </SubMenuContent>
                      </SubMenuContainer>
                    </DropdownContainerListItem>
                  ))
                ) : (
                  <Skeleton count={10} width="17.25rem" />
                )}
              </DropdownContainerList>
            </DropdownWrapper>
          </DropdownContainer>
          <CategoryNameContainer alignItems="center">
            <HamburgerIcon color={theme.colors.primary["200"]} />
            <CategoryTitleSection {...buildTestIds("category-title-section")}>
              Categorias
            </CategoryTitleSection>
          </CategoryNameContainer>
        </CategoryButtonDropDown>

        {isLoadingHighlightCategories ? (
          <Skeleton
            {...buildTestIds("skeleton-list-categories")}
            type="chip"
            count={5}
            hasMargin={false}
          />
        ) : (
          highlightCategoriesComponent
        )}
      </ContentHeaderCategoriesDesktop>
      {router?.pathname === "/" ? <LoginAuthenticationModal /> : null}
    </ListCategoriesContainerHeader>
  );
};

ListCategories.displayName = "ListCategories";

const ListCategoriesWithBlackThemeValidation =
  withBlackThemeValidation<ListCategoriesProps>(ListCategories);

export { ListCategoriesWithBlackThemeValidation as ListCategories };
