import { PaginationHeadersDto } from "./Headers";
// eslint-disable-next-line import/no-cycle
import { EnvironmentDto } from "./WeddingList";

export interface CoupleDetailDto {
  id: number;
  name: string;
  partnerName: string;
  fatherName: string;
  motherName: string;
  partnerFatherName: string;
  partnerMotherName: string;
  when: Date;
  city: string;
  listImageUrl?: string;
  headers?: PaginationHeadersDto;
  listLink: string;
}

export interface LinkRecommendationPerson {
  [key: string]: string;
  primaryHostName: string;
  secondaryHostName: string;
}

export type PartnersOrder = { name: string; surname: string };
export type ListProduct = {
  quantity: number;
};

// eslint-disable-next-line no-shadow
export enum StatusGiftList {
  Open = 1,
  ExclusionPending = 2,
  ClosingPending = 3,
  PendingFilling = 4,
  Closed = 5,
  Deleted = 6,
  Blocked = 7,
  Pending = 99,
}

export interface CoupleNamesDto {
  id: number;
  typeListId: number;
  partner1: PartnersOrder;
  partner2: PartnersOrder;
  listProducts: ListProduct[];
  environments: EnvironmentDto[];
  eventType: string;
  eventDate: string;
  listLink: string;
  city: string;
  createdOn: string;
  createdBy: number;
  updatedOn: string;
  updatedBy: number;
  isActive: number;
  addressId: number;
  hasAcceptedTerms: number;
  branchId: number;
  listStatusId: StatusGiftList;
  branchApprovalPending: number;
  referenceOldList: string;
  lastFiveNumbersGiftCard: string;
  closedOn: string;
  phoneNumber: string;
  isCreatePassword: number;
  dateQuantityAltered: number;
  origin: number;
  isPrivate: number;
  productsQuantity: number;
  qrCode: number;
  status: "Aberta" | "Bloqueada" | "Excluída" | "Fechada";
  branchName: string;
  pendingReason: string;
  backgroundImageUrl?: string;
  listImageUrl?: string;
  giftsQuantity: number;
  canClose: boolean;
  canDelete: boolean;
}

export interface PublishListDto {
  id: number;
  partner1: PartnersOrder;
  partner2: PartnersOrder;
  qrCode?: number;
  listLink?: string;
  listImageUrl?: string;
}

export interface PostMyListForEmailDto {
  emails: string[];
  message: string;
}

export interface SendMyListForEmailDto {
  accessToken: string;
  listId: number;
  emails: string[];
  message: string;
}

export interface WeddingGiftsAndCredits {
  giftsReceived: number;
  credit: number;
  totalProducts: number;
  balance: number;
  debitEffected: number;
  refundCredit: number;
  refundDebit: number;
  reserveDebit: number;
}

export type OperationTypeProps = "All" | "Debit" | "Credit";
