import { ShippingApi, useDebounce } from "application";
import { validateZipCode } from "../schema";

const useGetAddressByCity = (
  typedCity: string,
  isTypingCity: boolean,
  selectedCity: string | undefined,
  shippingApi: ShippingApi
) => {
  const formattedTypedCity = typedCity?.split("-")?.[0]?.trim();

  const debouncedTypedCity = useDebounce(formattedTypedCity, 500);

  const typedCityHasMoreThanTwoCharacters = debouncedTypedCity?.length >= 3;

  const { data: addressByCityList, isLoading } = shippingApi.getAddressByCity(
    debouncedTypedCity,
    5,
    typedCityHasMoreThanTwoCharacters && isTypingCity
  );

  const cityNotFound = Boolean(
    !isLoading &&
      typedCityHasMoreThanTwoCharacters &&
      !addressByCityList?.length
  );

  const conditionSuccessToCity = Boolean(
    typedCityHasMoreThanTwoCharacters && selectedCity && !isTypingCity
  );

  const conditionErrorToCity = Boolean(
    typedCityHasMoreThanTwoCharacters && cityNotFound && !selectedCity
  );

  return {
    addressByCityList,
    conditionErrorToCity,
    conditionSuccessToCity,
  };
};

const useGetAddressByZipCode = (
  typedZipCode: string,
  shippingApi: ShippingApi
) => {
  const isZipCodeLengthValid = typedZipCode?.length === 9;
  const isInvalidZipCode = validateZipCode(typedZipCode);

  const { data: addressByZipCode, isLoading } = shippingApi.getAddressByZipCode(
    typedZipCode,
    isZipCodeLengthValid
  );

  const hasZipCodeError = !!(
    isZipCodeLengthValid &&
    (isInvalidZipCode || (!isLoading && !addressByZipCode))
  );

  return {
    addressByZipCode,
    isZipCodeLengthValid,
    isInvalidZipCode,
    hasZipCodeError,
  };
};

export { useGetAddressByCity, useGetAddressByZipCode };
