import { WorkflowTypes } from "typing";
import { createStore } from "../adapter";

export type SecurityState = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  type: "modal" | "component";
  setType: (value: "modal" | "component") => void;
  hasError: boolean;
  setHasError: (value: boolean) => void;
  sendCodeType: "email" | "sms";
  setCodeType: (value: "email" | "sms") => void;
  workflow: WorkflowTypes;
  setWorkflow: (value: WorkflowTypes) => void;
  isInvalidPhoneNumber?: boolean;
};

export const useSecurityStore = createStore<SecurityState>((set) => ({
  openModal: false,
  hasError: false,
  setOpenModal: (value) => set({ openModal: value }),
  type: "modal",
  setType: (value) => set({ type: value }),
  setHasError: (value) => set({ hasError: value }),
  sendCodeType: "email",
  setCodeType: (value) => set({ sendCodeType: value }),
  workflow: null,
  setWorkflow: (value) => set({ workflow: value }),
  isInvalidPhoneNumber: false,
}));
