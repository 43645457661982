import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { CategoryDto, IHttpResponse, PageThemeMap } from "typing";
import { IStateManager } from "../entities";
import {
  useIsClientDesktop,
  useIsClientDesktopS,
  useIsClientLaptop,
  useIsClientLaptopL,
  useIsClientLaptopM,
  useIsClientLaptopS,
  useIsClientLaptopXL,
  useIsClientMobile,
  useIsClientMobileL,
  useIsClientMobileM,
  useIsClientMobileS,
  useIsClientTablet,
  useIsClientTabletL,
  useIsClientTabletM,
  useIsClientTabletML,
  useIsClientTabletSmall,
  useIsClientTabletXL,
  useIsClientTabletXM,
  useIsOnlyClientLaptop,
  useIsOnlyClientMobile,
  useIsOnlyClientTablet,
  useIsClientMobileXS,
  useIsOnlyMobileXSToLaptop,
} from "../hooks/userClient";
import { CatalogApi } from "../services";
import { getCategories } from "./category";

export interface SharedState {
  categories: IHttpResponse<CategoryDto[], unknown>;
  isBlackTheme: boolean;
  isClientLaptop: boolean;
  isClientTabletXL: boolean;
  isClientTabletL: boolean;
  isClientTablet: boolean;
  isClientTabletSmall: boolean;
  isClientMobile: boolean;
  isClientMobileXS: boolean;
  isClientMobileS: boolean;
  isClientMobileM: boolean;
  isClientMobileL: boolean;
  isClientTabletM: boolean;
  isClientTabletML: boolean;
  isClientTabletXM: boolean;
  isClientLaptopL: boolean;
  isClientLaptopXL: boolean;
  isClientLaptopM: boolean;
  isClientLaptopS: boolean;
  isClientDesktopS: boolean;
  isClientDesktop: boolean;
  isOnlyClientLaptop: boolean;
  isOnlyClientTablet: boolean;
  isOnlyClientMobile: boolean;
  isOnlyMobileXSToLaptop: boolean;
  isPageWeddingList: boolean;
  focusOnInputSearch: boolean;
  isOpenedLogin: boolean;
  isOpenedDropdownUser: boolean;
  isSafari: boolean;
  isFirefox: boolean;
  isShowModalForgotPassword: boolean;
  showComponentForgotPassword: boolean;
  isSidebarMenuOpened: boolean;
  setIsOpenedLogin: Dispatch<SetStateAction<boolean>>;
  setIsOpenedDropdownUser: Dispatch<SetStateAction<boolean>>;
  setIsSidebarMenuOpened: Dispatch<SetStateAction<boolean>>;
  closeCategoriesDropDown: boolean;
  setCloseCategoriesDropDown: Dispatch<SetStateAction<boolean>>;
  onPageWeddingList: () => void;
  handleFocusInputSearch: () => void;
  onOpenForgotPasswordModal: () => void;
  onCloseForgotPasswordModal: () => void;
  onCloseForgotPasswordComponent: () => void;
  withBlackTheme: (value: boolean) => void;
  onManagementOpenOrClosedForgotPasswordModal: () => void;
  onManagementOpenOrClosedForgotPasswordComponent: () => void;
  pageThemeMap: PageThemeMap;
  setPageThemeMap: Dispatch<SetStateAction<PageThemeMap>>;
  setIsViewMorePaymentMethodsOpen: Dispatch<SetStateAction<boolean>>;
  isViewMorePaymentMethodsOpen: boolean;
}

interface AppWrapperProviderProps {
  children: ReactNode;
  catalogApi: CatalogApi;
  stateManager: IStateManager;
}

const AppContext = createContext({} as SharedState);

export const AppWrapper = ({
  children,
  catalogApi,
  stateManager,
}: AppWrapperProviderProps) => {
  const [isOpenedLogin, setIsOpenedLogin] = useState<boolean>(false);
  const [isOpenedDropdownUser, setIsOpenedDropdownUser] =
    useState<boolean>(false);
  const [isPageWeddingList, setIsPageWeddingList] = useState<boolean>(false);
  const [focusOnInputSearch, setFocusOnInputSearch] = useState<boolean>(false);
  const [isShowModalForgotPassword, setIsShowModalForgotPassword] =
    useState<boolean>(false);
  const [showComponentForgotPassword, setShowComponentForgotPassword] =
    useState<boolean>(false);
  const [isSidebarMenuOpened, setIsSidebarMenuOpened] =
    useState<boolean>(false);
  const [closeCategoriesDropDown, setCloseCategoriesDropDown] = useState(false);
  const [isBlackTheme, setIsBlackTheme] = useState(false);
  const [isViewMorePaymentMethodsOpen, setIsViewMorePaymentMethodsOpen] =
    useState<boolean>(false);

  const withBlackTheme = useCallback((value: boolean) => {
    setIsBlackTheme(value);
  }, []);

  const isClientLaptop = useIsClientLaptop();
  const isClientLaptopS = useIsClientLaptopS();
  const isClientTabletXL = useIsClientTabletXL();
  const isClientTabletL = useIsClientTabletL();
  const isClientTablet = useIsClientTablet();
  const isClientTabletSmall = useIsClientTabletSmall();
  const isClientMobile = useIsClientMobile();
  const isClientMobileS = useIsClientMobileS();
  const isClientMobileXS = useIsClientMobileXS();
  const isClientTabletML = useIsClientTabletML();
  const isClientMobileM = useIsClientMobileM();
  const isClientMobileL = useIsClientMobileL();
  const isClientTabletM = useIsClientTabletM();
  const isClientTabletXM = useIsClientTabletXM();
  const isClientLaptopL = useIsClientLaptopL();
  const isClientLaptopXL = useIsClientLaptopXL();
  const isClientLaptopM = useIsClientLaptopM();
  const isClientDesktopS = useIsClientDesktopS();
  const isClientDesktop = useIsClientDesktop();

  const isOnlyClientLaptop = useIsOnlyClientLaptop();
  const isOnlyClientTablet = useIsOnlyClientTablet();
  const isOnlyClientMobile = useIsOnlyClientMobile();
  const isOnlyMobileXSToLaptop = useIsOnlyMobileXSToLaptop();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);

  const categories = getCategories(catalogApi, stateManager);

  const [pageThemeMap, setPageThemeMap] = useState<PageThemeMap>({
    home: "default",
    category: "default",
    highlight: "default",
    searchResult: "default",
    login: "default",
    product: "default",
    cart: "default",
    myAddresses: "default",
    myOrders: "default",
    myWeddingLists: "default",
    myCreditCards: "default",
    changePassword: "default",
    myOrdersByPhone: "default",
    detailedOrder: "default",
    myData: "default",
  });

  const handleFocusInputSearch = () => {
    setFocusOnInputSearch((state) => !state);
  };

  const onPageWeddingList = () => {
    setIsPageWeddingList(true);
  };

  const onManagementOpenOrClosedForgotPasswordModal = useCallback(() => {
    setIsShowModalForgotPassword((state) => !state);
  }, []);

  const onManagementOpenOrClosedForgotPasswordComponent = useCallback(() => {
    setShowComponentForgotPassword((state) => !state);
  }, []);

  const onOpenForgotPasswordModal = useCallback(() => {
    setIsShowModalForgotPassword(true);
  }, []);

  const onCloseForgotPasswordModal = useCallback(() => {
    setIsShowModalForgotPassword(false);
  }, []);

  const onCloseForgotPasswordComponent = useCallback(() => {
    setShowComponentForgotPassword(true);
  }, []);

  const appContextProviderValue = useMemo(() => {
    return {
      isPageWeddingList,
      onPageWeddingList,
      categories,
      isBlackTheme,
      withBlackTheme,
      isClientLaptop,
      isClientTabletXL,
      isClientTabletL,
      isClientTablet,
      isClientTabletSmall,
      isClientMobile,
      isClientMobileS,
      isClientMobileXS,
      isClientMobileM,
      isClientMobileL,
      isClientTabletML,
      isClientTabletM,
      isClientTabletXM,
      isClientLaptopL,
      isClientLaptopXL,
      isClientLaptopM,
      isClientLaptopS,
      isClientDesktopS,
      isClientDesktop,
      isOnlyClientLaptop,
      isOnlyClientTablet,
      isOnlyClientMobile,
      isOnlyMobileXSToLaptop,
      focusOnInputSearch,
      handleFocusInputSearch,
      isOpenedLogin,
      setIsOpenedLogin,
      isOpenedDropdownUser,
      setIsOpenedDropdownUser,
      isShowModalForgotPassword,
      showComponentForgotPassword,
      onOpenForgotPasswordModal,
      onCloseForgotPasswordModal,
      onCloseForgotPasswordComponent,
      onManagementOpenOrClosedForgotPasswordModal,
      onManagementOpenOrClosedForgotPasswordComponent,
      isSidebarMenuOpened,
      setIsSidebarMenuOpened,
      isSafari,
      closeCategoriesDropDown,
      setCloseCategoriesDropDown,
      isFirefox,
      pageThemeMap,
      setPageThemeMap,
      setIsViewMorePaymentMethodsOpen,
      isViewMorePaymentMethodsOpen,
    };
  }, [
    isPageWeddingList,
    categories,
    isBlackTheme,
    withBlackTheme,
    isClientLaptop,
    isClientLaptopS,
    isClientTabletXL,
    isClientTabletL,
    isClientTablet,
    isClientTabletSmall,
    isClientMobile,
    isClientMobileS,
    isClientMobileXS,
    isClientMobileM,
    isClientMobileL,
    isClientTabletML,
    isClientTabletM,
    isClientTabletXM,
    isClientLaptopL,
    isClientLaptopXL,
    isClientLaptopM,
    isClientDesktopS,
    isClientDesktop,
    isOnlyClientLaptop,
    isOnlyClientTablet,
    isOnlyClientMobile,
    isOnlyMobileXSToLaptop,
    focusOnInputSearch,
    isOpenedLogin,
    isOpenedDropdownUser,
    isShowModalForgotPassword,
    showComponentForgotPassword,
    onOpenForgotPasswordModal,
    onCloseForgotPasswordModal,
    onCloseForgotPasswordComponent,
    onManagementOpenOrClosedForgotPasswordModal,
    onManagementOpenOrClosedForgotPasswordComponent,
    isSidebarMenuOpened,
    isSafari,
    closeCategoriesDropDown,
    isFirefox,
    pageThemeMap,
    setIsViewMorePaymentMethodsOpen,
    isViewMorePaymentMethodsOpen,
  ]);

  return (
    <AppContext.Provider value={appContextProviderValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
